import React, {useContext} from 'react';
import Context from '../../context/ContextProvider';
import btnReiniciar from '../../images/btn_reiniciar.png';
import btnDescargar from '../../images/btn_descargar.png';
import * as svg from 'save-svg-as-png';
import imgBethoven from '../../images/bethoven_color.png';

import './Botones.scss'

const DibujoPrueba = () => {

    const stateColor = useContext(Context);
    const { colorObtenido } = stateColor;

    const obtenetID = (e) =>{
        e.target.style.fill = colorObtenido;        
    }

    const reiniciar = () => {
        window.location.reload();
    }

    const download = () => {
        svg.saveSvgAsPng(document.getElementById('Capa_1'), 'artesania.png');
    };

    
    return (
        <div className='div-dibujo'>
           <div className='imagenes_bethoven'>
            <img src={imgBethoven} alt={imgBethoven} />
            <svg version="1.1" id="Capa_1" x="0px" y="0px"
                width="581px" height="581px" viewBox="0 0 581 581"
                onClick={obtenetID}
            >
            <rect x="2.5" y="3.16" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" width="191.98" height="191.98"/>
            <path id="XMLID_59_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M111.81,189.75
                c-0.37-2-30.97-38.88-32.5-52.53c-1.53-13.64-2.95-24.71-3.52-29.16c-0.57-4.45-0.99-5.67-6.91-8.91
                c-5.92-3.24-9.14-17.73-7.35-22.76s-0.97-14.68,1.8-18.26c2.77-3.58,24.07-28.39,26.97-34.1c2.9-5.7,11.26-10.84,14.92-10.84
                c3.66,0,15.23,4.04,22.42,4.59c7.19,0.55,19.71-0.59,22.58,5.97c2.87,6.56,7.14,5.96,10,11.96c2.85,6.01,2.24,9.43,3.69,15.42
                s-0.84,12.94,1.45,16.74c2.28,3.8,8.94,6.5,7.46,11.28c-1.48,4.78-7.44,7.08-11.02,7.93c-3.58,0.84-4.45,1.74-5.7,7.93
                c-1.26,6.19-3.51,11.11-6.52,14.29c-3.01,3.18-4.49,15.67-5.04,18.2c-0.55,2.53-9.18,4.6-7.44,6.76c1.74,2.16,5.48,1.07,6.46,4.89
                c0.98,3.82,9.16,12.16,12.54,17.98c3.38,5.83,14.63,15.78,16.93,17.63c2.31,1.86,10.63,20.37,10.63,20.37h-69.68L111.81,189.75z"/>
            <g id="XMLID_1_">
                <path id="XMLID_55_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M194.49,195.15
                    c0,0-13.13-22.55-15.94-25.53s-4.2-0.35-7.18-1.4c-2.98-1.05-7.71-10.33-12.08-15.24c-4.38-4.9-13.45-9.87-12.16-10.34
                    c1.28-0.47,8.45-5.02,8.45-5.02s-0.63-0.58-2.5-2.22c-1.87-1.63-10.16-2.45-10.97-2.57c-0.82-0.12,0.23-1.63,1.87-2.8
                    c1.63-1.17,4.44-10.39,5.72-14.94c1.28-4.55,4.79-10.04,5.14-13.89s0-9.57,0-11.68c0-2.1,0.35-3.15,0.7-4.09
                    c0.35-0.93-1.28-1.52-3.5-2.69s-9.46-0.58-10.27,0c-0.82,0.58-2.8,1.52-4.9,1.52c-2.1,0-2.13,0.81-3.04,3.15
                    c-0.35,0.9-1.4,0.16-2.02,0.47c-0.62,0.31,0.7,1.09,1.63,1.87c0.93,0.78,0,8.87,0.16,10.35c0.16,1.48,1.01,1.01,1.63,2.02
                    c0.62,1.01,0.23,4.75-0.62,5.37c-0.86,0.62-2.1,1.63-2.72,2.26c-0.62,0.62-2.1,1.01-2.57,1.71c-0.47,0.7,4.75,2.02,5.76,2.49
                    c1.01,0.47,4.05-0.39,4.9-1.71c0.86-1.32,0.08-2.88-0.62-3.97c-0.7-1.09-1.63-3.97-1.71-5.76c-0.08-1.79-1.32-2.26-1.63-3.42
                    s0.54-1.25,1.63-0.95c1.09,0.29-0.16-2.86,0-3.87c0.16-1.01-0.16-1.17,1.32-0.86c1.48,0.31,2.26-0.16,1.71-0.16
                    c-0.54,0-3.19-1.71-2.41-2.65c0.78-0.93,2.1-1.01,2.1-1.01s0.08,2.88,3.04,2.88s2.65-2.1,2.96-2.26c0.31-0.16,1.17,0.62,0.86,1.63
                    c-0.31,1.01-3.19,1.87-3.19,1.87s1.25,0.31,2.18-0.16c0.93-0.47,1.4-0.23,0.62,0.7c-0.78,0.93-0.47,1.17-0.31,1.87
                    c0.16,0.7-0.86,1.95-0.86,2.72c0,0.78,2.18-0.78,2.72-1.09c0.54-0.31,1.17-2.26,1.87-3.66c0.7-1.4,1.17-0.85,1.35,2.48
                    c0.18,3.33,0,3.68-2.8,7.88s-4.2,9.46-4.9,11.73c-0.7,2.28-1.4,7.71-1.49,11.21c-0.09,3.5-4.81,3.33-7.44,3.5
                    c-2.63,0.18-2.63,2.1-2.1,4.2c0.53,2.1,0,1.75-2.28,2.63c-2.28,0.88-2.8-2.28-3.33-3.15c-0.53-0.88-1.26,1-2.8,2.63
                    c-0.76,0.81-3.06,2.33-6.04,1.39c-2.98-0.94-6.39-3.84-8.67-6.12c-2.28-2.28-5.78-1.05-7.53-1.93
                    c-1.75-0.88-12.08-12.26-11.91-11.38c0.18,0.88,10.68,12.08,11.56,13.48c0.88,1.4,7.88,4.55,9.28,4.55c1.4,0,4.73,2.63,7,3.68
                    c2.28,1.05,4.38,2.28,5.78,4.2c1.4,1.93-3.15,2.98-3.15,2.98s5.25,0.35,7,0.18c1.75-0.18,3.5-3.33,4.38-4.03
                    c0.88-0.7,6.3-3.5,7.71-4.38c1.4-0.88,2.8,1.23,1.05,1.93c-1.75,0.7-1.93,2.98,0.18,4.03c2.1,1.05,6.13,3.5,7.71,7
                    s-2.1,2.28,0,5.43c2.1,3.15,8.06,2.28,10.68,4.73c2.63,2.45,4.55,9.46,6.48,13.48c1.93,4.03,6.13,4.2,7.71,8.93
                    c1.58,4.73,0.7,9.77,0.7,9.77H194.49z M141.68,89.99c-0.31,0-0.56-0.25-0.56-0.56c0-0.31,0.25-0.56,0.56-0.56s0.56,0.25,0.56,0.56
                    C142.24,89.74,141.99,89.99,141.68,89.99z M150.78,87.97c-0.31,0.7-0.93,1.71-1.71,1.4s-3.82-1.61-5.92-1.79
                    c-1.56-0.13-2-1.09-0.77-1.25c1.23-0.16,4.19-0.23,4.74-0.54c0.54-0.31,2.02-0.54,3.27,0c1.25,0.54,1.25,0.54,1.25,0.54
                    S151.09,87.27,150.78,87.97z"/>
                <path id="XMLID_52_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M124.94,82.99
                    c-3.15,0-3.04-0.82-7.01-2.8c-3.97-1.98-4.79-0.58-6.3,0c-1.52,0.58-1.87,0.35-4.79,0.93c-2.92,0.58-6.3,2.45-5.95,3.04
                    c0.35,0.58,2.08,1.79,3.04,0.82c2.49-2.53,6.07-2.76,7.55-2.45c1.48,0.31-2.72,1.71-2.1,1.87c0.62,0.16,2.96-1.09,4.11-1.4
                    c1.15-0.31,5.39,2.02,2.2,1.87c-3.19-0.16-5.68,0.62-6.85,1.01c-1.17,0.39-1.87,0.62-3.35,0.08c-1.48-0.54-1.32,0.47-1.01,0.94
                    c0.31,0.47,1.87,0.39,1.01,1.13c-0.86,0.74-1.48-0.27-2.33,0.35c-0.86,0.62,1.79,0.62,1.71,1.07c-0.08,0.45,1.09,0.49,2.02,0
                    c0.93-0.49,2.33-0.91,2.33-0.91c1.87,2.65,3.58,2.65,3.58,2.65c-1.25-0.47-2.26-2.33-2.18-3.15c0.08-0.82,1.73-0.56,1.73-0.56
                    s0.53,2.94,3.41,2.94c2.88,0,3.27-2.94,3.27-2.94s1.48,0.99,1.17,1.98c-0.31,0.99-3.5,1.58-3.5,1.58s0.78,0.39,0.86,1.17
                    c0.08,0.78-3.46,0.86-3.5,1.56c-0.04,0.7,4.36,1.28,5.41,1.28c1.05,0,3.39-2.69,3.62-3.74c0.23-1.05,0-2.45,0.82-2.41
                    c0.82,0.04,2.1-0.51,3.04-1.42c0.93-0.91,2.69-3.99,2.69-5.37C129.61,80.71,128.09,82.99,124.94,82.99z M114.04,88.01
                    c-0.31,0-0.56-0.25-0.56-0.56c0-0.31,0.25-0.56,0.56-0.56s0.56,0.25,0.56,0.56C114.61,87.76,114.36,88.01,114.04,88.01z"/>
                <path id="XMLID_51_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M119.45,107.04
                    c1.17,0.7,1.75,0.93,2.33,0.7c0.58-0.23,0,0.12,0.58-0.23c0.58-0.35,2.45-0.64,3.74,0.32c1.28,0.96,3.15,2.01,2.22,2.36
                    c-0.93,0.35-1.87,0.23-2.92,0c-1.05-0.23-2.33,0.58-4.32-0.7S119.45,107.04,119.45,107.04z"/>
                <path id="XMLID_50_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M121.05,101.9
                    c0,0-4.4,3.5-6.44,4.75c-2.04,1.25,1.32,0.47,3.22-0.31C119.73,105.56,121.05,101.9,121.05,101.9z"/>
                <path id="XMLID_49_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M139.73,111.01
                    c0,0,1.32,0.99,2.51,3.74c1.2,2.75,1.3,6.54,1.3,6.54s0.12-1.01-1.3-1.48c-1.42-0.47-0.8-0.93-0.56-2.18
                    C141.91,116.38,140.2,111.01,139.73,111.01z"/>
                <path id="XMLID_48_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M128.13,112.8
                    c-0.5,1.16-0.08,1.48,0,2.26c0.08,0.78,0.12,1.79,0.45,1.32c0.33-0.47,0.18-1.25,0.49-2.1
                    C129.38,113.42,128.53,111.88,128.13,112.8z"/>
                <path id="XMLID_47_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M112.35,120.97
                    c-1.23-0.16-1.62-1.32,0-2.49c1.62-1.17,2.14-2.88,2.89-2.34c0.75,0.54,1.79,2.46,4.19,2.36s4.34-1.82,6.83-1.58
                    c2.49,0.23,2.65,1.87,4.44,1.01c1.79-0.86,2.65,0.47,4.13,0.93c1.48,0.47,4.59-0.54,4.36,0.31s-1.32,0.78-1.63,1.25
                    c-0.31,0.47,1.5,1.79,1.65,3.81c0.14,2.02,0.77,2.18-0.4,2.18c-1.17,0-1.87-0.54-2.33,0.39c-0.47,0.93-1.71,1.87-1.71,1.87
                    s-0.62-3.19-1.71-3.04c-1.09,0.16-3.11-0.16-3.42-0.47c-0.31-0.31-2.84-0.08-4.14,0.23c-1.31,0.31-6.29-0.93-4.89-1.48
                    c1.4-0.54,2.02-0.96,2.49-1.57c0.47-0.61,0.54-2.56,1.4-1.47c0.86,1.09,3.04,0.54,5.14,0.78c2.1,0.23,3.81-0.39,4.05-1.01
                    c0.23-0.62-0.16-1.32-0.16-1.32s-18.98-0.7-19.53,0C113.42,120.04,113.54,121.13,112.35,120.97z"/>
                <path id="XMLID_46_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M119.45,95.01
                    c0,0-0.03,0.03-0.08,0.09c-0.03,0.03-0.06,0.07-0.11,0.11c-0.04,0.04-0.09,0.09-0.15,0.14c-0.06,0.05-0.12,0.1-0.19,0.16
                    c-0.07,0.06-0.15,0.11-0.24,0.17c-0.09,0.06-0.18,0.12-0.28,0.18c-0.1,0.06-0.21,0.13-0.32,0.19c-0.12,0.06-0.24,0.12-0.36,0.19
                    c-0.13,0.06-0.26,0.11-0.4,0.18c-0.07,0.03-0.14,0.06-0.21,0.09c-0.07,0.03-0.15,0.05-0.22,0.08c-0.15,0.05-0.3,0.1-0.46,0.16
                    c-0.16,0.04-0.32,0.09-0.48,0.13c-0.08,0.02-0.16,0.04-0.25,0.07c-0.08,0.02-0.17,0.03-0.25,0.05c-0.34,0.06-0.68,0.14-1.02,0.17
                    c-0.17,0.02-0.35,0.04-0.52,0.06c-0.17,0.02-0.34,0.02-0.51,0.03c-0.34,0.01-0.68,0.03-1,0.01c-0.32-0.01-0.64-0.02-0.93-0.05
                    c-0.3-0.03-0.57-0.07-0.83-0.1c-0.13-0.02-0.25-0.03-0.37-0.05c-0.12-0.02-0.23-0.05-0.33-0.07c-0.21-0.04-0.38-0.08-0.53-0.11
                    c-0.29-0.07-0.46-0.11-0.46-0.11s0.17-0.01,0.47-0.01c0.15,0,0.33,0,0.54,0.01c0.1,0,0.21,0,0.33,0.01c0.12,0,0.24,0,0.37,0
                    c0.25,0,0.53-0.01,0.82,0c0.29,0,0.6-0.02,0.91-0.03c0.16-0.01,0.32,0,0.48-0.02c0.16-0.01,0.33-0.03,0.49-0.04
                    c0.16-0.01,0.33-0.02,0.5-0.04c0.17-0.02,0.33-0.04,0.5-0.06c0.33-0.03,0.66-0.09,0.99-0.14c0.16-0.02,0.32-0.06,0.48-0.09
                    c0.16-0.03,0.31-0.07,0.47-0.1c0.15-0.04,0.3-0.08,0.45-0.12c0.07-0.02,0.15-0.04,0.22-0.06c0.07-0.02,0.14-0.04,0.21-0.06
                    c0.14-0.04,0.27-0.08,0.4-0.12c0.13-0.05,0.25-0.09,0.37-0.13c0.12-0.04,0.23-0.09,0.34-0.13c0.11-0.04,0.21-0.08,0.3-0.13
                    c0.09-0.04,0.18-0.09,0.26-0.12c0.08-0.04,0.15-0.08,0.22-0.11c0.13-0.07,0.23-0.13,0.3-0.17
                    C119.42,95.04,119.45,95.01,119.45,95.01z"/>
                <path id="XMLID_45_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M137.08,97
                    c0,0,0.08,0.03,0.22,0.11c0.07,0.04,0.15,0.08,0.24,0.14c0.09,0.06,0.19,0.12,0.3,0.2c0.21,0.16,0.45,0.35,0.69,0.58
                    c0.06,0.06,0.12,0.12,0.18,0.18c0.06,0.06,0.12,0.12,0.18,0.19c0.12,0.13,0.23,0.26,0.34,0.4l0.16,0.21
                    c0.05,0.07,0.1,0.14,0.15,0.21c0.1,0.14,0.19,0.29,0.27,0.43c0.09,0.14,0.16,0.28,0.23,0.42c0.03,0.07,0.07,0.13,0.1,0.2
                    c0.03,0.07,0.05,0.13,0.08,0.19c0.05,0.12,0.09,0.24,0.13,0.34c0.03,0.1,0.05,0.2,0.07,0.27c0.04,0.15,0.05,0.24,0.05,0.24
                    s-0.06-0.07-0.16-0.19c-0.09-0.12-0.23-0.29-0.39-0.49c-0.08-0.1-0.16-0.21-0.25-0.33c-0.09-0.12-0.18-0.24-0.28-0.36
                    c-0.05-0.06-0.1-0.13-0.15-0.19c-0.05-0.06-0.1-0.13-0.15-0.19c-0.1-0.13-0.21-0.27-0.31-0.4c-0.1-0.13-0.21-0.26-0.31-0.4
                    c-0.1-0.13-0.21-0.26-0.31-0.38c-0.2-0.25-0.38-0.48-0.54-0.69c-0.16-0.21-0.29-0.38-0.38-0.5c-0.05-0.06-0.08-0.11-0.11-0.14
                    C137.1,97.02,137.08,97,137.08,97z"/>
                <path id="XMLID_44_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M142.39,104.6
                    c0,0,0.02,0.03,0.06,0.08c0.04,0.05,0.1,0.14,0.16,0.25c0.06,0.11,0.14,0.25,0.22,0.41c0.08,0.16,0.16,0.34,0.25,0.54
                    c0.17,0.4,0.35,0.87,0.54,1.37c0.09,0.25,0.19,0.51,0.29,0.77c0.1,0.26,0.2,0.53,0.31,0.79c0.1,0.26,0.21,0.52,0.33,0.77
                    c0.11,0.25,0.23,0.5,0.35,0.73c0.12,0.23,0.24,0.45,0.37,0.65c0.13,0.2,0.26,0.39,0.38,0.56c0.07,0.08,0.13,0.16,0.19,0.23
                    c0.06,0.07,0.13,0.14,0.18,0.2c0.12,0.12,0.23,0.23,0.33,0.3c0.09,0.08,0.17,0.14,0.23,0.18c0.05,0.04,0.08,0.06,0.08,0.06
                    s-0.03-0.01-0.1-0.03c-0.06-0.02-0.16-0.05-0.28-0.11c-0.06-0.03-0.12-0.06-0.19-0.1c-0.07-0.04-0.14-0.08-0.22-0.13
                    c-0.07-0.05-0.15-0.11-0.23-0.17c-0.08-0.06-0.16-0.13-0.24-0.21c-0.16-0.15-0.33-0.33-0.49-0.53c-0.16-0.2-0.32-0.42-0.46-0.65
                    c-0.14-0.24-0.29-0.49-0.41-0.75c-0.13-0.26-0.25-0.53-0.36-0.8c-0.22-0.54-0.4-1.1-0.55-1.62c-0.08-0.26-0.14-0.51-0.2-0.75
                    c-0.06-0.24-0.11-0.47-0.17-0.68c-0.05-0.21-0.1-0.4-0.14-0.57c-0.04-0.17-0.08-0.32-0.12-0.44c-0.04-0.12-0.06-0.22-0.09-0.28
                    C142.4,104.64,142.39,104.6,142.39,104.6z"/>
                <path id="XMLID_43_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M138.78,115.68
                    c0,0,0.04,0.05,0.1,0.13c0.03,0.04,0.07,0.09,0.1,0.15c0.04,0.06,0.08,0.13,0.12,0.2c0.09,0.15,0.18,0.33,0.26,0.53
                    c0.02,0.05,0.04,0.1,0.06,0.16c0.02,0.05,0.04,0.11,0.05,0.16c0.03,0.11,0.06,0.23,0.08,0.35c0.02,0.12,0.03,0.24,0.03,0.36
                    c0,0.06,0,0.12,0,0.18c0,0.06,0,0.11-0.01,0.17c-0.01,0.06-0.01,0.11-0.02,0.16c-0.01,0.05-0.02,0.1-0.03,0.15
                    c-0.02,0.1-0.05,0.19-0.08,0.27c-0.03,0.08-0.06,0.16-0.09,0.22c-0.03,0.07-0.06,0.12-0.08,0.17c-0.05,0.09-0.08,0.14-0.08,0.14
                    s0-0.06,0.01-0.16c0-0.05,0.01-0.11,0.02-0.18c0-0.07,0.02-0.15,0.02-0.23c0-0.08,0.01-0.17,0.02-0.27c0-0.05,0-0.1,0.01-0.14
                    c0-0.05,0-0.1,0-0.15c0-0.1-0.01-0.21-0.02-0.31c-0.01-0.11-0.02-0.21-0.04-0.32c-0.02-0.11-0.04-0.21-0.06-0.32
                    c-0.02-0.1-0.05-0.21-0.08-0.31c-0.06-0.2-0.11-0.39-0.15-0.55c-0.05-0.16-0.08-0.3-0.1-0.4
                    C138.79,115.74,138.78,115.68,138.78,115.68z"/>
                <path id="XMLID_42_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M85.71,108.73
                    c0.75,3.64,3,11.38,4.58,13.63c1.58,2.26,4.21,5.23,4.21,5.23s-5.6-4.04-7.53-8.41s-2.28-6.54-2.98-8.17
                    C83.29,109.38,85.02,105.35,85.71,108.73z"/>
                <path id="XMLID_41_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M96.95,130.74
                    c0,0,0.05,0.07,0.14,0.19c0.09,0.12,0.24,0.3,0.42,0.52c0.19,0.22,0.43,0.48,0.71,0.76c0.29,0.28,0.62,0.59,0.99,0.91
                    c0.38,0.31,0.81,0.63,1.27,0.95c0.47,0.3,0.99,0.61,1.51,0.95c0.53,0.34,1.07,0.68,1.61,1.04c0.54,0.36,1.09,0.73,1.64,1.11
                    c0.55,0.38,1.09,0.76,1.62,1.14c0.53,0.38,1.05,0.77,1.55,1.14c0.5,0.37,0.97,0.74,1.42,1.1c0.45,0.35,0.86,0.7,1.25,1.02
                    c0.19,0.16,0.37,0.32,0.54,0.47c0.17,0.15,0.33,0.3,0.48,0.43c0.3,0.27,0.55,0.52,0.76,0.73c0.05,0.05,0.1,0.1,0.15,0.15
                    c0.04,0.05,0.08,0.09,0.12,0.14c0.08,0.08,0.14,0.16,0.19,0.21c0.1,0.12,0.16,0.18,0.16,0.18s-0.06-0.05-0.18-0.15
                    c-0.12-0.1-0.29-0.25-0.52-0.43c-0.23-0.18-0.5-0.4-0.82-0.64c-0.32-0.25-0.69-0.51-1.09-0.81c-0.2-0.15-0.41-0.3-0.63-0.45
                    c-0.22-0.16-0.44-0.32-0.67-0.48c-0.46-0.33-0.95-0.67-1.46-1.03c-0.51-0.35-1.04-0.72-1.57-1.09c-0.53-0.37-1.08-0.75-1.63-1.12
                    c-0.54-0.38-1.09-0.75-1.63-1.12c-0.54-0.37-1.07-0.73-1.58-1.08c-0.51-0.35-1.01-0.68-1.48-1.02c-0.46-0.35-0.88-0.7-1.24-1.05
                    c-0.36-0.35-0.67-0.69-0.93-1c-0.26-0.31-0.47-0.6-0.63-0.84c-0.16-0.24-0.28-0.44-0.36-0.58
                    C96.98,130.82,96.95,130.74,96.95,130.74z"/>
                <path id="XMLID_40_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M94.5,150.01
                    c0,0,2.45,1.23,4.03,0s3.15-1.54,4.2,0.54c1.05,2.08,2.49,4.53,2.49,4.53s-2.46-3.25-3.53-3.02c-1.06,0.22-3.17-0.35-3.34,1.45
                    c-0.17,1.79,1.4,6.86,0.53,6.32C98,159.29,94.5,150.01,94.5,150.01z"/>
                <path id="XMLID_39_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M101.69,147.73
                    c2.26-0.53,4.01-2.1,5.77-2.98c1.75-0.88,4.35-2.1,6.03,0.7c0,0-2.75-1.04-2.2,0.27c0.55,1.31-0.67,2.18-1.55,2.01
                    c-0.88-0.18-1.84,1.75-2.15,2.28c-0.31,0.53-1.18-0.19-1.71-0.97c-0.53-0.78,0.07,2.02-0.66,2.37
                    C104.48,151.76,101.69,147.73,101.69,147.73z"/>
                <path id="XMLID_38_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M130.92,147.57
                    c0,0,6.94-2.2,8.81-4.74c0,0,5.31,2.98,4.79,3.84c-0.52,0.87-1.8,0.53-2.89,1.58c-1.09,1.05,1.19,1.93,0.75,4.03
                    c-0.44,2.1-0.78,1.23-1.92,0s-2.56-2.76-2.73-0.85c-0.17,1.9-1.57,5.41-3.49,6.98s-5.11,1.46-5.11,1.46s2.66-2.68,1.26-2.51
                    c-1.4,0.18-7.18,1.75-7.35,1.05c-0.18-0.7,3.58-1.4,4.85-2.45c1.28-1.05,4.4-1.05,5.9-2.1c1.5-1.05,4.2-3.69,3.29-4.82
                    c-0.91-1.13-1.26,1.01-2.49,2.39c-1.23,1.38-2.8,2.25-3.85,1.73c-1.05-0.53-1.93-2.1,0.18-3.15
                    C133.02,148.96,132.83,147.45,130.92,147.57z"/>
                <path id="XMLID_37_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M107.58,157.54
                    c0,0,0.16,0.08,0.42,0.25c0.26,0.17,0.63,0.42,1.05,0.77c0.21,0.17,0.43,0.36,0.65,0.58c0.22,0.22,0.45,0.45,0.67,0.72
                    c0.22,0.26,0.43,0.55,0.61,0.87c0.19,0.31,0.35,0.66,0.46,1.02c0.06,0.18,0.09,0.37,0.12,0.57c0.01,0.1,0.01,0.2,0.02,0.29
                    c0,0.1,0,0.18-0.01,0.27c-0.02,0.37-0.07,0.72-0.14,1.06c-0.07,0.34-0.17,0.65-0.27,0.94c-0.09,0.29-0.2,0.56-0.27,0.8
                    c-0.07,0.25-0.12,0.47-0.1,0.66c0.01,0.19,0.08,0.36,0.17,0.48c0.09,0.12,0.17,0.21,0.23,0.26c0.06,0.05,0.1,0.08,0.1,0.08
                    s-0.04-0.02-0.12-0.06c-0.07-0.04-0.18-0.1-0.3-0.21c-0.12-0.11-0.25-0.28-0.3-0.52c-0.06-0.23-0.05-0.5-0.01-0.77
                    c0.04-0.27,0.11-0.56,0.18-0.85c0.07-0.29,0.13-0.6,0.18-0.92c0.05-0.31,0.08-0.65,0.08-0.97c0-0.08,0-0.18,0-0.25
                    c-0.01-0.08-0.01-0.16-0.02-0.23c-0.02-0.16-0.05-0.31-0.1-0.47c-0.09-0.31-0.23-0.62-0.39-0.91c-0.16-0.29-0.34-0.57-0.53-0.83
                    c-0.19-0.26-0.38-0.51-0.57-0.74c-0.19-0.23-0.38-0.44-0.56-0.64c-0.36-0.39-0.67-0.7-0.89-0.92
                    C107.71,157.66,107.58,157.54,107.58,157.54z"/>
                <path id="XMLID_36_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M124.01,173.82
                    c0,0-1.84-2.1-2.96-2.28c-1.11-0.18-4.31-1.93-7.08-4.38c0,0-1.09,1.58,1.36,3.33c2.45,1.75,3.57,2.6,3.53,4.28
                    c-0.03,1.68,3.12,1.68,3.12,1.68s-4.18-2.8-2.56-3.85C121.05,171.55,124.01,173.82,124.01,173.82z"/>
                <path id="XMLID_35_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M130.57,182.05l-1.99,3.68
                    c-0.64-2.1-1.41-2.1,0.55-4.73c1.96-2.63,1.09-6.23,1.09-6.23s2.28,2.28,4.55,5.48c2.28,3.2,2.63,4.71,2.63,4.71
                    s-1.95-1.16-3.6-3.61s-2.53-1.4-2.88-0.35S131.62,181.35,130.57,182.05"/>
                <path id="XMLID_34_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M143.55,184.96
                    c0,0-1.07-5.71-2.47-8.51c-1.4-2.8-2.3-4.64-2.3-4.64s3.91,1.84,4.77,4.64c0.86,2.8,9.44,8.51,9.44,8.51s-6.69-2.83-8.43-4.71
                    C144.56,180.25,144.97,182.79,143.55,184.96z"/>
                <path id="XMLID_33_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M155.44,167.17
                    c0,0,0.08,0.03,0.24,0.09c0.15,0.06,0.38,0.15,0.66,0.28c0.28,0.14,0.62,0.3,1,0.52c0.19,0.1,0.39,0.23,0.6,0.35
                    c0.21,0.13,0.42,0.27,0.64,0.43c0.44,0.31,0.9,0.67,1.34,1.11c0.44,0.44,0.88,0.94,1.23,1.54c0.04,0.07,0.09,0.14,0.13,0.22
                    c0.04,0.07,0.08,0.15,0.13,0.22c0.04,0.07,0.08,0.15,0.13,0.22c0.04,0.07,0.09,0.14,0.13,0.2c0.09,0.14,0.18,0.27,0.28,0.41
                    c0.1,0.14,0.2,0.27,0.3,0.41c0.42,0.54,0.89,1.06,1.37,1.58c0.48,0.52,0.96,1.04,1.42,1.58c0.46,0.53,0.88,1.09,1.27,1.64
                    c0.19,0.28,0.37,0.55,0.55,0.81c0.18,0.26,0.36,0.51,0.54,0.75c0.37,0.48,0.72,0.93,1.07,1.33c0.68,0.81,1.28,1.46,1.71,1.91
                    c0.21,0.22,0.38,0.4,0.5,0.52c0.12,0.12,0.18,0.18,0.18,0.18s-0.07-0.06-0.2-0.16c-0.13-0.1-0.32-0.26-0.56-0.46
                    c-0.23-0.2-0.52-0.45-0.84-0.75c-0.16-0.15-0.32-0.31-0.5-0.48c-0.17-0.17-0.35-0.35-0.54-0.54c-0.37-0.38-0.77-0.81-1.16-1.28
                    c-0.2-0.23-0.39-0.48-0.59-0.74c-0.1-0.13-0.19-0.26-0.29-0.39c-0.09-0.13-0.19-0.27-0.29-0.4c-0.38-0.53-0.82-1.05-1.27-1.57
                    c-0.46-0.52-0.94-1.03-1.42-1.55c-0.48-0.53-0.95-1.06-1.39-1.63c-0.11-0.14-0.22-0.28-0.31-0.43c-0.1-0.15-0.2-0.29-0.29-0.45
                    c-0.05-0.08-0.09-0.15-0.14-0.23c-0.04-0.08-0.08-0.15-0.12-0.22c-0.04-0.07-0.08-0.15-0.12-0.22c-0.04-0.07-0.08-0.14-0.12-0.22
                    c-0.31-0.55-0.69-1.05-1.1-1.49c-0.1-0.11-0.21-0.21-0.31-0.32c-0.11-0.1-0.21-0.2-0.31-0.29c-0.1-0.1-0.21-0.19-0.31-0.28
                    c-0.1-0.09-0.2-0.18-0.31-0.26c-0.41-0.33-0.8-0.62-1.15-0.86c-0.35-0.24-0.67-0.44-0.94-0.6c-0.13-0.08-0.25-0.15-0.36-0.21
                    c-0.1-0.06-0.19-0.11-0.27-0.15C155.51,167.21,155.44,167.17,155.44,167.17z"/>
                <path id="XMLID_32_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M143.55,165.24
                    c0,0,0.05,0.08,0.15,0.23c0.09,0.15,0.26,0.35,0.47,0.6c0.22,0.24,0.51,0.53,0.86,0.82c0.35,0.29,0.77,0.59,1.25,0.9
                    c0.48,0.3,1.01,0.61,1.58,0.92c0.57,0.31,1.19,0.62,1.81,0.98c0.16,0.09,0.31,0.18,0.47,0.27c0.16,0.1,0.32,0.19,0.47,0.29
                    c0.31,0.2,0.63,0.41,0.93,0.63c0.61,0.45,1.21,0.95,1.76,1.5c0.54,0.56,1.04,1.17,1.44,1.83c0.21,0.33,0.38,0.67,0.53,1.02
                    c0.07,0.18,0.14,0.35,0.21,0.53c0.05,0.18,0.11,0.36,0.16,0.54c0.18,0.7,0.31,1.39,0.41,2.04c0.11,0.65,0.18,1.26,0.3,1.81
                    c0.12,0.55,0.28,1.04,0.47,1.45c0.02,0.05,0.05,0.1,0.07,0.15c0.03,0.05,0.05,0.1,0.08,0.14c0.05,0.09,0.1,0.19,0.15,0.27
                    c0.11,0.17,0.2,0.33,0.3,0.46c0.1,0.14,0.18,0.26,0.26,0.36c0.08,0.1,0.15,0.19,0.2,0.26c0.11,0.14,0.17,0.22,0.17,0.22
                    s-0.07-0.07-0.19-0.19c-0.06-0.06-0.14-0.14-0.23-0.23c-0.09-0.1-0.19-0.21-0.3-0.34c-0.11-0.13-0.22-0.28-0.35-0.44
                    c-0.06-0.08-0.12-0.17-0.18-0.26c-0.03-0.05-0.06-0.09-0.09-0.14c-0.03-0.05-0.06-0.1-0.09-0.15c-0.12-0.2-0.23-0.43-0.33-0.68
                    c-0.1-0.25-0.19-0.51-0.27-0.79c-0.16-0.56-0.27-1.17-0.4-1.81c-0.13-0.64-0.28-1.3-0.48-1.98c-0.18-0.66-0.5-1.31-0.88-1.92
                    c-0.38-0.61-0.86-1.19-1.38-1.72c-0.52-0.53-1.09-1.02-1.68-1.46c-0.59-0.44-1.19-0.85-1.8-1.21c-0.61-0.36-1.21-0.71-1.77-1.04
                    c-0.56-0.34-1.09-0.68-1.55-1.02c-0.23-0.17-0.45-0.34-0.66-0.51c-0.2-0.17-0.39-0.33-0.55-0.5c-0.34-0.32-0.6-0.64-0.8-0.91
                    c-0.2-0.28-0.34-0.5-0.41-0.66C143.59,165.33,143.55,165.24,143.55,165.24z"/>
                <path id="XMLID_31_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M139.73,182.23
                    c0,0,0.03,0.03,0.08,0.1c0.05,0.07,0.12,0.16,0.2,0.29c0.17,0.25,0.4,0.62,0.62,1.09c0.11,0.23,0.23,0.49,0.34,0.77
                    c0.11,0.28,0.22,0.58,0.31,0.89c0.1,0.31,0.18,0.64,0.25,0.98c0.07,0.34,0.12,0.68,0.16,1.03c0.03,0.35,0.06,0.7,0.05,1.04
                    c0,0.35-0.02,0.68-0.06,1.01c-0.04,0.33-0.09,0.64-0.16,0.93c-0.07,0.29-0.15,0.57-0.23,0.81c-0.04,0.12-0.08,0.24-0.13,0.35
                    c-0.05,0.11-0.09,0.21-0.13,0.31c-0.08,0.19-0.17,0.36-0.24,0.49c-0.07,0.13-0.14,0.23-0.18,0.3c-0.04,0.07-0.06,0.11-0.06,0.11
                    s0.05-0.17,0.13-0.45c0.04-0.15,0.09-0.32,0.14-0.52c0.02-0.1,0.05-0.21,0.07-0.32c0.02-0.11,0.05-0.23,0.07-0.36
                    c0.05-0.25,0.09-0.52,0.12-0.8c0.04-0.29,0.06-0.59,0.08-0.9c0-0.16,0.01-0.32,0.01-0.48c0-0.16,0-0.32-0.01-0.49
                    c-0.01-0.33-0.03-0.66-0.06-0.99c-0.04-0.33-0.08-0.66-0.13-0.99c-0.06-0.32-0.12-0.64-0.19-0.95c-0.07-0.31-0.15-0.6-0.23-0.88
                    c-0.08-0.28-0.16-0.54-0.24-0.78c-0.08-0.24-0.16-0.46-0.23-0.66c-0.07-0.19-0.13-0.36-0.19-0.5
                    C139.79,182.39,139.73,182.23,139.73,182.23z"/>
                <path id="XMLID_30_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M152.98,182.23
                    c0,0,0.14,0.13,0.36,0.36c0.11,0.12,0.26,0.25,0.41,0.42c0.16,0.16,0.34,0.34,0.54,0.54c0.19,0.2,0.41,0.41,0.62,0.65
                    c0.22,0.24,0.45,0.49,0.67,0.77c0.22,0.28,0.43,0.58,0.62,0.91c0.2,0.32,0.36,0.67,0.51,1.02c0.07,0.18,0.14,0.36,0.2,0.53
                    c0.06,0.18,0.12,0.36,0.17,0.54c0.1,0.36,0.19,0.72,0.27,1.05c0.08,0.34,0.15,0.67,0.21,0.98c0.07,0.31,0.13,0.61,0.18,0.88
                    c0.11,0.55,0.18,1.01,0.23,1.33c0.05,0.32,0.07,0.51,0.07,0.51s-0.07-0.17-0.19-0.48c-0.11-0.31-0.27-0.74-0.45-1.28
                    c-0.09-0.26-0.18-0.55-0.28-0.86c-0.09-0.31-0.2-0.63-0.29-0.96c-0.1-0.34-0.2-0.67-0.31-1.01c-0.06-0.17-0.12-0.34-0.18-0.51
                    c-0.07-0.17-0.13-0.34-0.2-0.5c-0.14-0.33-0.29-0.66-0.46-0.97c-0.17-0.31-0.36-0.6-0.55-0.89c-0.2-0.28-0.39-0.55-0.58-0.8
                    c-0.19-0.25-0.38-0.49-0.54-0.71c-0.17-0.22-0.32-0.43-0.45-0.61c-0.13-0.19-0.24-0.35-0.33-0.49
                    C153.08,182.39,152.98,182.23,152.98,182.23z"/>
                <path id="XMLID_29_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M117.09,159.83
                    c0,0,0.06,0.03,0.17,0.09c0.11,0.06,0.28,0.15,0.49,0.26c0.42,0.23,1.01,0.55,1.73,0.94c0.36,0.19,0.75,0.4,1.16,0.62
                    c0.41,0.22,0.85,0.44,1.3,0.67c0.23,0.11,0.46,0.23,0.69,0.35c0.23,0.12,0.47,0.23,0.71,0.35c0.24,0.11,0.48,0.23,0.72,0.34
                    c0.24,0.11,0.49,0.22,0.73,0.34c0.25,0.11,0.49,0.21,0.74,0.32c0.25,0.1,0.5,0.2,0.74,0.3c0.49,0.19,0.99,0.35,1.47,0.5
                    c0.48,0.15,0.95,0.28,1.4,0.41c0.45,0.12,0.88,0.24,1.27,0.36c0.39,0.12,0.75,0.25,1.06,0.4c0.31,0.14,0.55,0.32,0.73,0.48
                    c0.18,0.17,0.29,0.33,0.35,0.43c0.03,0.05,0.05,0.1,0.07,0.13c0.02,0.03,0.02,0.04,0.02,0.04s-0.04-0.06-0.12-0.15
                    c-0.08-0.1-0.21-0.23-0.4-0.36c-0.19-0.13-0.44-0.26-0.75-0.37c-0.3-0.11-0.66-0.2-1.06-0.28c-0.39-0.08-0.83-0.16-1.29-0.26
                    c-0.46-0.1-0.94-0.2-1.44-0.33c-0.5-0.13-1.01-0.28-1.52-0.47c-0.26-0.09-0.52-0.19-0.76-0.29c-0.25-0.11-0.5-0.22-0.76-0.32
                    c-0.25-0.12-0.5-0.23-0.74-0.35c-0.24-0.12-0.49-0.24-0.73-0.36c-0.48-0.25-0.94-0.5-1.39-0.75c-0.45-0.25-0.87-0.51-1.27-0.75
                    c-0.4-0.24-0.77-0.48-1.11-0.71c-0.34-0.22-0.65-0.44-0.92-0.63c-0.27-0.19-0.51-0.36-0.7-0.5
                    C117.3,160,117.09,159.83,117.09,159.83z"/>
                <path id="XMLID_28_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M116.03,146.15
                    c0,0,0.08,0.04,0.2,0.12c0.06,0.04,0.14,0.08,0.22,0.13c0.09,0.05,0.18,0.11,0.29,0.17c0.1,0.06,0.22,0.12,0.34,0.19
                    c0.12,0.06,0.25,0.13,0.38,0.19c0.13,0.07,0.27,0.12,0.41,0.19c0.07,0.03,0.14,0.06,0.22,0.09c0.07,0.03,0.14,0.06,0.22,0.08
                    l0.22,0.08c0.07,0.02,0.15,0.05,0.22,0.07c0.07,0.02,0.15,0.05,0.22,0.07c0.07,0.02,0.15,0.04,0.22,0.07
                    c0.14,0.05,0.28,0.08,0.41,0.12c0.13,0.04,0.26,0.08,0.38,0.11c0.12,0.03,0.22,0.07,0.32,0.1c0.1,0.03,0.18,0.05,0.25,0.07
                    c0.14,0.04,0.22,0.08,0.22,0.08s-0.08,0.02-0.23,0.04c-0.07,0.01-0.16,0.02-0.26,0.03c-0.1,0.01-0.22,0.01-0.34,0.01
                    c-0.06,0-0.13,0-0.2,0c-0.07,0-0.14-0.01-0.21-0.01c-0.14-0.01-0.3-0.02-0.45-0.05c-0.16-0.03-0.32-0.05-0.48-0.1
                    c-0.08-0.02-0.16-0.04-0.24-0.07c-0.08-0.03-0.16-0.06-0.24-0.09c-0.16-0.06-0.32-0.13-0.47-0.2c-0.15-0.08-0.29-0.16-0.43-0.25
                    c-0.14-0.09-0.26-0.18-0.37-0.28c-0.11-0.1-0.21-0.19-0.3-0.28c-0.09-0.09-0.16-0.18-0.23-0.26c-0.06-0.08-0.11-0.16-0.15-0.22
                    c-0.04-0.06-0.07-0.11-0.09-0.15C116.05,146.17,116.03,146.15,116.03,146.15z"/>
                <g id="XMLID_11_">
                    <path id="XMLID_27_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M56.29,108.73
                        c0,0-0.45-0.22-1.04-0.66C55.77,108.97,56.29,108.73,56.29,108.73z"/>
                    <path id="XMLID_12_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M124.01,195.15
                        c0,0-25.22-38.14-35.96-56.23s-8.64-20.2-7.71-26.4c0.93-6.2,5.6-7.91,5.6-7.91l1.72-1.88c0,0-1.72,1.88-7.18,1.88
                        c-5.46,0-4.03-8.36-4.03-8.36s0,0,0.7-1.23s0.53,0.71,0.88,1.99c0.35,1.28,2.1-0.58,1.93-3.03c-0.18-2.45,2.8-2.1,2.8-2.1
                        s-1.05,4.03,0.35,6.28c1.4,2.25,2.45,1.78,4.9,1.01c2.45-0.76,2.45-3.61,0.7-5.37c-1.75-1.75-2.63-3.5-1.58-4.93
                        c1.05-1.43,2.63,0.73,4.73-1.42c2.1-2.14,4.38-6.61,4.2-8.29c-0.18-1.68-3.5,0.8-3.5,0.8c3.85-3.68,4.38-9.28,4.38-9.28
                        c-2.45,8.06-7.53,8.48-7.53,8.48c2.28-1.5,2.45-3.93,3.15-5.85c0.7-1.93,4.9-7.88,4.03-8.93c-0.88-1.05-6.13,9.81-7.53,9.46
                        c-1.4-0.35,3.68-5.6,3.15-7c-0.53-1.4-3.85,5.95-5.95,5.95c-2.1,0,1.93-1.4,1.4-2.8c-0.53-1.4-2.1-1.23,0.35-2.28
                        c2.45-1.05,5.25-5.6,7.18-9.35c1.93-3.74,9.17-4.58,8.41-7.64c-0.7-2.8,5.43,0.53,9.88,0.35c4.45-0.18,5.01-2.98,8.68-5.08
                        c3.68-2.1,6.42,1.4,6.42,1.4c-3.57,1.23-7.47,3.5-13.34,8.06c-5.87,4.55-9.89,0-9.89,0s-0.7,2.45,3.59,2.91
                        c4.29,0.46,10.94-3.79,12.1-5.71c1.16-1.93,10.04-4.55,10.57-4.38c0.53,0.18-0.53,2.8-0.53,2.8c1.23-0.35,2.8-2.28,2.8-2.28
                        c2.28,3.85-2.1,5.78-2.1,5.78c5.78-1.75,2.8-5.96,5.29-7.54c2.49-1.58,1.02,0.88,5.16,8.24c4.14,7.35,7.68,7.17,7.68,7.17
                        c-5.14-2.33-7.54-10.51-7.54-10.51s2.17,0.47,5.67,1.4c3.5,0.93,4.44,6.3,5.37,8.87c0.93,2.57-1.87,5.6-1.79,8.87
                        c0.08,3.27-3.81,8.08-3.81,8.08c2.8,0.61,5.37-2.71,5.37-2.71c0.93,1.87-1.17,6.3-1.17,6.3s1.68,0.85,1.87,2.71
                        c0.19,1.85-0.7,8.73-1.17,13.7c-0.47,4.96-3.97,11.85-3.97,11.85c2.81,0.99,7.01-4.2,7.47-3.7c0.47,0.5,1.4,5.21,1.4,5.21
                        c3.5-2.7,4.67-14.37,4.67-14.37c1.87,1.62-0.7,12.27-0.7,12.27c10.27-5.03,16.11-21.55,16.11-21.55
                        c1.44,6.39-3.74,12.45-3.74,12.45s6.54-5.02,5.84-11.32c-0.7-6.3-7.01-10.83-7.01-10.83c5.84,2.24,8.64-3.88,8.64-3.88
                        c-3.74,3.04-7.24-1.17-7.24-1.17c7.47-2.34,5.14-9.81,5.14-9.81s-0.93,4.44-6.54,4.2s-3.97-12.14-3.97-12.14
                        c1.87,1.63,3.5,8.64,3.5,8.64c1.63-4.44-1.63-10.27-1.63-10.27c2.1,1.17,3.74,7.01,3.74,7.01c0.93-7.24-5.45-12.14-5.45-12.14
                        c5.29-7.94-0.38-14.24-0.38-14.24c2.1,8.64-5.14,9.57-5.14,9.57c3.27-3.27,3.27-4.9,1.4-10.27c-1.87-5.37-8.17-4.67-8.64-7.24
                        c-0.47-2.57,3.5-5.37,2.34-7.01c-1.17-1.63-4.9,1.17-4.9,1.17c-7.01-12.14-19.15-9.81-19.15-9.81c6.01-0.23,4.58,3.04,4.58,3.04
                        c-1.72-2.1-9.49-1.17-9.49-1.17s-2.57-4.9-7.24-5.4c-4.67-0.49-7.13,4.23-7.13,4.23s-1.51-3.74-6.29-4.2
                        c-4.79-0.47-8.08,5.6-8.08,5.6c-2.83-3.97-8.38-3.04-14.92,0c-6.54,3.04-9.81,11.21-11.91,15.18c-2.1,3.97-2.1,4.2-7.01,7.71
                        c-4.9,3.5-8.87,12.14-8.87,12.14s-1.4,0.47-3.04,5.84c-1.63,5.37,3.74,8.17,3.74,8.17c-11.44,3.97-7.24,9.34-7.24,9.34
                        s1.87-2.34,3.74-1.87c1.87,0.47-0.47,5.37-0.47,5.37c-2.34-3.5-4.44,0-4.44,0c2.34,0.7,1.17,5.37-0.47,6.33
                        c-1.63,0.96,0.47,6.78,0.47,6.78c-2.34-0.43-3.27-4.94-3.27-4.94s-2.34,5.14,0.23,7.47c2.57,2.34,8.87,3.62,8.87,3.62
                        s-2.57,1.28-3.74,6.3c-0.82,3.51,1.33,5.73,2.7,6.75c-0.33-0.57-0.65-1.61-0.83-3.46c-0.47-4.77,2.1-5.44,2.1-5.44
                        s0,2.51,1.17,8.14c1.17,5.64,7.24,7.2,7.24,7.2c0.47-3.04,6.77-3.5,6.77-3.5s-4.44,5.6-5.37,10.27c-0.93,4.67-2.57,6.54-7.24,8.87
                        c-4.67,2.33-3.04,7.94-7.24,10.97c-4.2,3.04-10.51,4.9-18.45,8.74c-7.94,3.84-11.48,9.23-11.48,9.23s-16.3,14.87-16.3,36.04
                        L124.01,195.15z M160.51,64.55c0,0-5.6-9-4.2-9c1.4,0,1.4,0,1.4,0s3.68,3.7,4.03,5.3C162.09,62.45,160.51,64.55,160.51,64.55z
                        M68.05,60.87c1.05-0.35,2.66,3.33,5.1,3.68c0,0-6.01-3.85-2.66-3.15c3.35,0.7,6.67,0.54,8.07,1.93c1.4,1.39,0.7,2.97-0.88,2.8
                        c-1.58-0.18-3.15,0.03-4.12,0.01c-0.96-0.01-2.01-0.89-2.01-0.89C69.8,63.85,67,61.22,68.05,60.87z M77.68,89.99
                        c-2.28,1.17-3.64,4.68-4.54,3.63c-0.89-1.05,0.68-1.4-0.89-1.05c-1.58,0.35-2.45,4.08-2.98,3.44c-0.53-0.64,0.15-3.96,2.27-5.02
                        c0,0-0.34-1.4-2.27-0.18c-1.6,1.02,1.93-2.33,4.29-1.95C75.93,89.24,79.96,88.82,77.68,89.99z M77.68,87.45
                        c-0.35-1.36,2.1-2.26-2.45-1.98c-4.55,0.27-8.93,1.02-9.98-3.38s2.98-6.5,3.33-7.55c0.35-1.05,0.07-2.8-0.75-2.63
                        c-0.82,0.18,4.96-3.15,2.16-2.45c-2.8,0.7-6.65,1.93-6.65,1.93c1.23-1.23,3.5-3.5,6.3-3.5c2.8,0,5.64,0.7,3.52,1.75
                        c-2.12,1.05-3.35,1.05-2.29,1.75c1.05,0.7,4.38-1.4,4.2,0c-0.18,1.4-3.33,2.45-4.2,4.03c-0.88,1.58-0.66,5.47,2.29,6.67
                        c2.96,1.21,4.64,1.18,6.16,3.38C80.84,87.67,78.03,88.8,77.68,87.45z M90.29,80.84c3.33-2.03,0.7,0,0.7,0s-3.97,3.5-7.07,3.68
                        c-3.09,0.18-4.16-2.81-4.16-2.81s1.65,2.28,4.16,1.58c0,0-2.05-1.98-0.1-1.58C85.56,82.06,86.96,82.86,90.29,80.84z M88.54,74.79
                        c0,0-0.75,2.91-2.47,3.16c0,0,2.98,0.25,3.43-1.15c0,0-0.41,3.83-5.67,2.35c-5.26-1.48-5.47-4.45-5.47-4.45s0.38,1.12,2.48,1.7
                        l-1.05-3.02c-0.02-0.05-0.03-0.08-0.03-0.08l0.03,0.08c0.17,0.47,1.2,2.9,4.03,2.9C86.97,76.28,88.54,74.79,88.54,74.79z
                        M93.09,58.95c-1.58,1.23-6.98,4.55-9.27,4.38c-2.29-0.18-5.44-0.53-6.14-6.3c0,0,1.23,2.63,2.28,3.15
                        c1.05,0.53,1.95,2.8,3.86,1.75c1.91-1.05-0.71-1.23-0.71-1.23c-0.88-1.23-0.7-2.1,1.93-3.15c2.63-1.05,5.25-3.15,6.83-3.85
                        c1.58-0.7,3.5-1.23,3.85,0C96.07,54.92,94.67,57.72,93.09,58.95z M108.95,42.31c-0.79,0.88-1.03,1.4-2.4,1.4
                        c-1.37,0-1.37-0.53-3.3-0.7c-1.93-0.18-4.14-1.93-5.22-1.05c-1.08,0.88-2.48,1.4-1.43,2.8c1.05,1.4,3.85-0.86,3.68,1.15
                        c-0.18,2.01-0.64,3.41-2.25,4.28c-1.61,0.88-4.76,1.93-6.16,2.1c-1.4,0.18-3.49-1.05-2.71-1.93c0.78-0.88,2.71-3.15,2.71-3.15
                        s-2.28,1.05-3.5,1.05c-1.23,0-1.05-0.86-0.7-2.36c0.35-1.5,1.93-2.72,4.2-2.9c2.28-0.18,4.87-0.18,5.43-2.45
                        c0,0-7.53,3.15-11.21-1.4c-0.47-0.58,6.48,0.7,8.41,0c1.93-0.7,6.26-1.52,6.37-1.11c0.11,0.41-1.97,3.73,0.82,2.51
                        c2.79-1.23,4.13-3.84,4.86-2.62c0.73,1.22,0.56,1,2.4,1.29C110.78,39.51,109.74,41.44,108.95,42.31z M119.45,32.33
                        c-1.66,0.35-0.09,2.1-0.02,2.42c0.07,0.32-1.99,0.21-3.22,0.98s-0.35,1.16-0.97,2.21c-0.62,1.05-4.81-5.25-8.49-6.3
                        c-3.68-1.05-4.9,2.63-8.58,4.09c-3.68,1.47-6.3-1.29-6.3-1.29c0.88,0.7,3.85-0.53,3.85-0.53s-1.4-1.93-1.23-2.28
                        c0.18-0.35,2.98,1.4,2.98,1.4s0.18-1.4,1.58-1.93c1.4-0.53,2.64-1.75,2.64-1.75c-1.39-0.53-3.87-6.48-1.76-3.94
                        c2.1,2.54,3.85,0.13,3.85,0.13s0.18-1.44-2.09-3.89c-2.26-2.45-0.82-5.43-0.82-5.43s0.63,2.98,3.26,4.9
                        c2.63,1.93,4.82,4.29,4.82,4.29c1.11-6.66,6.29-9.19,6.29-9.19s-3.06,3.5-4.11,8.41c-1.05,4.9,4.9,5.43,6.38,5.43
                        c1.48,0-0.08-1.05,0.8-1.75c0.88-0.7,2.28,1.4,2.74,2.1C121.51,31.1,121.11,31.98,119.45,32.33z M121.05,23.75
                        c-1.34-3.15-1.6-5.95-1.6-5.95s0.9,3.5,4.87,5.95c3.97,2.45,7.05,4.55,4.81,9.28c0,0,0.46-2.45-1.24-3.68
                        C126.19,28.13,122.39,26.9,121.05,23.75z M133.2,43.36c-2.28,2.28-2.28,2.28-2.28,2.28s0.11,0.88-1.79-0.53
                        c0,0-1.47-1.05-1.24-4.55c0.23-3.5,0.97-4.55,2-6.65s3.48-3.33,3.48-3.33s2.4-1.75,5.41-4.03s4.77,0,4.77,0s-6.55,3.15-7.84,7.71
                        c0,0,2.87-1.58,3.07-0.35c0.2,1.23-1.96,1.75-3.07,2.8c-1.11,1.05-3.56,3.85-4.44,4.03c-0.88,0.18-1.38-2.1-1.38-2.1
                        s-1.06,4.55-0.28,4.9c0.78,0.35,5.69-5.43,8.31-5.78c2.63-0.35-0.57,1.05,0.85,1.4c1.42,0.35,3.06-0.7,5.75,1.05
                        c2.68,1.75,2.97,3.15,7.12,2.1c4.15-1.05,6.42-5.95,6.77-4.2c0.35,1.75-3.37,9.11-11.76,5.95
                        C138.27,40.91,135.47,41.08,133.2,43.36z M150.01,52.96c-1.93-1.19-4.87-2.42-6.46-4.69c-1.59-2.28-0.39-3.3-0.39-3.3
                        s0.51,3.47,3.51,4.7l-2.13-3.76c0,0,2.86,2.88,4.26,3.23c1.4,0.35-1.05,1.23,0.88,1.75c1.93,0.53,4.38,1.33,5.95,2.07
                        c1.58,0.74-3.97,0-3.97,0s3.14,2.88,4.25,5.4c1.12,2.52,1.82,7.79,1.82,7.79S151.93,54.15,150.01,52.96z M167.66,76.4
                        c-0.39-1.46-4.69-0.29-3.99,0c0.7,0.29,1.93,1.28,3.33,2.76c1.4,1.48,0.35,1.15-1.05,1.33c-1.4,0.18-1.58-1.23-2.98-2.53
                        c-1.4-1.3-0.35,2.18-1.18,4.13c-0.82,1.95-0.93-4.4-1.74-7.37c-0.82-2.98-0.06,6.65-1.11,6.13c-1.05-0.53-0.35-3.5-0.88-2.88
                        c-0.53,0.62-1.05,5.33-1.05,5.33s-1.05-1.93-0.93-3.79c0.13-1.86,2.15-2.87,2.35-4.09c0.2-1.23-0.02-1.58,1.2-2.1
                        c1.23-0.53-0.7-4.03-0.7-4.03c3.15-0.7,4.03,3.5,4.96,4.03s2.92,0.18,4.5,1.4c1.58,1.23,3.33,3.25,3.33,3.25
                        S168.04,77.86,167.66,76.4z"/>
                </g>
                <path id="XMLID_10_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M126.69,86.88
                    c0,0,0.04,0.02,0.1,0.05c0.06,0.03,0.15,0.08,0.24,0.14c0.09,0.06,0.2,0.13,0.3,0.2c0.05,0.04,0.1,0.07,0.16,0.11
                    c0.05,0.04,0.1,0.08,0.16,0.11c0.05,0.04,0.1,0.07,0.15,0.11c0.05,0.03,0.1,0.07,0.15,0.1c0.05,0.03,0.1,0.06,0.14,0.09
                    c0.05,0.02,0.09,0.05,0.14,0.08c0.04,0.02,0.09,0.04,0.13,0.06c0.04,0.02,0.08,0.03,0.11,0.04c0.06,0.02,0.11,0.04,0.11,0.04
                    s-0.03,0.03-0.09,0.07c-0.03,0.02-0.07,0.05-0.12,0.07c-0.05,0.02-0.11,0.03-0.17,0.05c-0.06,0.01-0.13,0.01-0.21,0.01
                    c-0.07,0-0.15-0.02-0.23-0.04c-0.04-0.01-0.08-0.02-0.11-0.04c-0.04-0.01-0.08-0.03-0.11-0.05c-0.07-0.04-0.15-0.08-0.21-0.13
                    c-0.07-0.05-0.13-0.1-0.18-0.16c-0.06-0.05-0.11-0.11-0.15-0.17c-0.05-0.06-0.08-0.12-0.12-0.18c-0.03-0.06-0.06-0.12-0.08-0.17
                    c-0.04-0.11-0.07-0.21-0.08-0.28c-0.01-0.03-0.01-0.07-0.01-0.09C126.69,86.89,126.69,86.88,126.69,86.88z"/>
                <path id="XMLID_9_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M129.61,108.56
                    c0,0,0.02,0.01,0.05,0.02c0.03,0.01,0.08,0.03,0.14,0.05c0.06,0.02,0.13,0.04,0.21,0.07c0.08,0.03,0.17,0.06,0.27,0.1
                    c0.19,0.07,0.42,0.15,0.66,0.24c0.12,0.04,0.24,0.09,0.37,0.13c0.13,0.04,0.25,0.09,0.38,0.13c0.13,0.04,0.25,0.08,0.38,0.12
                    c0.06,0.02,0.13,0.04,0.19,0.06c0.06,0.02,0.12,0.03,0.18,0.05c0.06,0.02,0.12,0.03,0.18,0.05c0.06,0.01,0.12,0.03,0.17,0.04
                    c0.11,0.03,0.22,0.06,0.32,0.08c0.1,0.02,0.19,0.04,0.28,0.06c0.08,0.01,0.16,0.03,0.22,0.04c0.12,0.03,0.2,0.05,0.2,0.05
                    s-0.07,0.03-0.19,0.07c-0.06,0.02-0.14,0.04-0.22,0.07c-0.09,0.02-0.19,0.03-0.3,0.05c-0.11,0.02-0.23,0.02-0.35,0.02
                    c-0.06,0-0.13,0-0.19,0c-0.07,0-0.13-0.01-0.2-0.02c-0.07-0.01-0.14-0.02-0.21-0.02c-0.07-0.01-0.14-0.03-0.21-0.04
                    c-0.14-0.03-0.28-0.07-0.42-0.11c-0.14-0.04-0.27-0.1-0.41-0.16c-0.07-0.03-0.13-0.06-0.19-0.09c-0.06-0.03-0.13-0.06-0.18-0.1
                    c-0.12-0.07-0.23-0.13-0.33-0.21c-0.1-0.07-0.2-0.14-0.28-0.21c-0.17-0.14-0.29-0.27-0.38-0.37
                    C129.65,108.62,129.61,108.56,129.61,108.56z"/>
                <path id="XMLID_8_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M116.03,108.56
                    c0,0-0.12,0.08-0.34,0.21c-0.11,0.07-0.24,0.15-0.39,0.24c-0.15,0.09-0.32,0.2-0.51,0.31c-0.75,0.45-1.77,1.03-2.81,1.55
                    c-0.26,0.13-0.52,0.26-0.78,0.38c-0.26,0.12-0.52,0.24-0.77,0.35c-0.25,0.11-0.5,0.21-0.73,0.3c-0.12,0.05-0.23,0.09-0.34,0.13
                    c-0.11,0.04-0.22,0.08-0.33,0.11c-0.21,0.08-0.4,0.13-0.58,0.18c-0.17,0.05-0.33,0.08-0.45,0.11c-0.13,0.03-0.23,0.04-0.3,0.05
                    c-0.07,0.01-0.11,0.01-0.11,0.01s0.03-0.02,0.1-0.05c0.06-0.03,0.15-0.07,0.27-0.12c0.11-0.06,0.25-0.13,0.41-0.21
                    c0.16-0.08,0.33-0.18,0.52-0.28c0.38-0.21,0.83-0.45,1.31-0.71c0.24-0.13,0.49-0.26,0.74-0.39c0.25-0.13,0.51-0.26,0.77-0.4
                    c0.26-0.13,0.52-0.26,0.78-0.38c0.26-0.13,0.51-0.25,0.76-0.36c0.25-0.11,0.49-0.22,0.72-0.32c0.23-0.1,0.45-0.19,0.66-0.27
                    c0.41-0.16,0.76-0.28,1.01-0.36C115.89,108.6,116.03,108.56,116.03,108.56z"/>
                <path id="XMLID_7_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M125.39,132.61
                    c0,0,0.02,0.01,0.05,0.02c0.03,0.01,0.07,0.03,0.13,0.05c0.06,0.02,0.12,0.05,0.2,0.08c0.08,0.03,0.16,0.06,0.25,0.09
                    c0.37,0.13,0.86,0.28,1.34,0.33c0.12,0.02,0.24,0.02,0.35,0.02c0.06,0,0.11,0,0.17-0.01c0.06-0.01,0.11-0.01,0.16-0.02
                    c0.11-0.02,0.21-0.04,0.3-0.07c0.1-0.03,0.18-0.07,0.27-0.11c0.08-0.04,0.15-0.09,0.22-0.13c0.03-0.02,0.06-0.05,0.09-0.07
                    c0.03-0.02,0.05-0.04,0.07-0.06c0.05-0.04,0.08-0.07,0.1-0.09c0.02-0.02,0.04-0.03,0.04-0.03s0,0.02-0.01,0.05
                    c-0.01,0.03-0.01,0.08-0.04,0.14c-0.01,0.03-0.02,0.06-0.03,0.1c-0.01,0.04-0.03,0.07-0.05,0.11c-0.04,0.08-0.1,0.16-0.17,0.25
                    c-0.08,0.08-0.17,0.17-0.28,0.24c-0.11,0.07-0.24,0.14-0.38,0.18c-0.27,0.09-0.58,0.11-0.88,0.08c-0.15-0.02-0.29-0.04-0.43-0.09
                    c-0.14-0.04-0.27-0.09-0.39-0.15c-0.12-0.06-0.24-0.12-0.34-0.19c-0.1-0.07-0.2-0.14-0.28-0.2c-0.16-0.14-0.28-0.27-0.35-0.37
                    c-0.04-0.05-0.06-0.09-0.08-0.12C125.39,132.62,125.39,132.61,125.39,132.61z"/>
                <path id="XMLID_6_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M85.87,130.74
                    c0,0,0.13,0.17,0.35,0.49c0.1,0.16,0.23,0.35,0.37,0.58c0.14,0.23,0.29,0.48,0.45,0.77c0.16,0.29,0.33,0.6,0.49,0.93
                    c0.17,0.33,0.34,0.69,0.5,1.06c0.16,0.37,0.33,0.76,0.48,1.17c0.15,0.4,0.29,0.82,0.43,1.23c0.06,0.21,0.12,0.42,0.19,0.63
                    c0.06,0.21,0.11,0.42,0.16,0.63c0.1,0.42,0.19,0.84,0.25,1.24c0.07,0.41,0.11,0.8,0.15,1.18c0.04,0.37,0.05,0.73,0.05,1.06
                    c0,0.16,0,0.32-0.01,0.47c-0.01,0.15-0.02,0.29-0.03,0.42c-0.02,0.27-0.06,0.5-0.08,0.68c-0.03,0.19-0.06,0.33-0.08,0.43
                    c-0.02,0.1-0.03,0.15-0.03,0.15s0-0.22,0-0.6c0-0.09-0.01-0.2-0.01-0.31c0-0.11,0-0.24-0.02-0.37c-0.01-0.13-0.02-0.27-0.03-0.42
                    c-0.01-0.15-0.03-0.3-0.05-0.46c-0.01-0.16-0.04-0.32-0.06-0.5c-0.02-0.17-0.05-0.35-0.08-0.53c-0.03-0.18-0.07-0.37-0.1-0.56
                    c-0.04-0.19-0.08-0.38-0.12-0.58c-0.08-0.39-0.19-0.79-0.29-1.2c-0.05-0.2-0.12-0.41-0.17-0.61c-0.06-0.2-0.12-0.41-0.19-0.61
                    c-0.13-0.41-0.26-0.81-0.4-1.21c-0.14-0.4-0.28-0.79-0.42-1.16c-0.14-0.38-0.28-0.74-0.42-1.08c-0.14-0.34-0.27-0.67-0.4-0.96
                    c-0.25-0.6-0.47-1.09-0.62-1.44C85.96,130.94,85.87,130.74,85.87,130.74z"/>
                <path id="XMLID_5_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M141.46,168.92
                    c0,0,0.16,0.17,0.42,0.47c0.07,0.07,0.15,0.14,0.24,0.23c0.09,0.08,0.17,0.17,0.28,0.26c0.11,0.09,0.22,0.18,0.34,0.27
                    c0.13,0.09,0.26,0.18,0.39,0.27c0.14,0.09,0.3,0.17,0.45,0.26c0.16,0.07,0.33,0.16,0.5,0.24c0.09,0.03,0.18,0.07,0.27,0.1
                    c0.05,0.02,0.09,0.04,0.14,0.05c0.05,0.01,0.09,0.03,0.14,0.04c0.09,0.03,0.19,0.06,0.29,0.09c0.1,0.03,0.2,0.05,0.3,0.07
                    c0.2,0.05,0.41,0.08,0.62,0.13c0.11,0.01,0.21,0.03,0.32,0.04c0.11,0.02,0.21,0.03,0.32,0.04c0.43,0.04,0.88,0.06,1.33,0.05
                    c0.11,0,0.22,0,0.34,0l0.17,0l0.08,0l0.1,0c0.06,0,0.11,0,0.18,0c0.07,0,0.13,0.01,0.19,0.01l0.18,0.03
                    c0.06,0.01,0.12,0.03,0.18,0.04c0.48,0.11,0.92,0.34,1.28,0.62c0.37,0.28,0.66,0.61,0.9,0.94c0.24,0.33,0.41,0.68,0.54,1.01
                    c0.13,0.33,0.22,0.64,0.27,0.92c0.06,0.28,0.07,0.53,0.08,0.73c0.01,0.2,0,0.36-0.01,0.47c-0.01,0.11-0.01,0.16-0.01,0.16
                    s-0.01-0.06-0.02-0.16c-0.01-0.11-0.04-0.26-0.08-0.46c-0.04-0.19-0.1-0.43-0.18-0.69c-0.09-0.26-0.2-0.54-0.36-0.84
                    c-0.08-0.15-0.16-0.3-0.26-0.44c-0.1-0.15-0.2-0.3-0.32-0.44c-0.24-0.29-0.52-0.56-0.85-0.79c-0.33-0.23-0.7-0.4-1.1-0.48
                    l-0.15-0.03l-0.15-0.02l-0.14-0.01c-0.05,0-0.11,0-0.16,0l-0.08,0l-0.09,0l-0.17,0.01c-0.12,0.01-0.23,0.01-0.35,0.01
                    c-0.47,0-0.93-0.01-1.39-0.07c-0.23-0.02-0.46-0.07-0.68-0.1c-0.22-0.05-0.44-0.09-0.65-0.16c-0.11-0.03-0.21-0.06-0.31-0.1
                    c-0.1-0.04-0.2-0.08-0.3-0.12c-0.05-0.02-0.1-0.04-0.15-0.06c-0.05-0.02-0.1-0.05-0.14-0.07c-0.09-0.04-0.19-0.09-0.28-0.13
                    c-0.18-0.09-0.35-0.2-0.51-0.29c-0.16-0.11-0.31-0.22-0.45-0.32c-0.13-0.12-0.26-0.23-0.38-0.33c-0.11-0.11-0.22-0.23-0.31-0.33
                    c-0.1-0.1-0.18-0.21-0.25-0.31c-0.07-0.1-0.14-0.19-0.2-0.27c-0.11-0.17-0.19-0.31-0.24-0.4
                    C141.49,168.97,141.46,168.92,141.46,168.92z"/>
                <path id="XMLID_4_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M124.01,170.5
                    c0,0,0.18,0.08,0.48,0.24c0.16,0.07,0.34,0.16,0.55,0.25c0.11,0.05,0.22,0.09,0.34,0.13c0.06,0.02,0.12,0.04,0.19,0.07
                    c0.06,0.02,0.13,0.04,0.2,0.06c0.27,0.08,0.57,0.14,0.88,0.17c0.32,0.03,0.66,0.04,1.02,0.04c0.36,0,0.74,0,1.12,0.01
                    c0.19,0.01,0.39,0.02,0.58,0.04l0.15,0.01l0.07,0.01l0.04,0l0.03,0l0.02,0l0.14,0.02l0.07,0.01l0.08,0.02
                    c0.42,0.08,0.8,0.22,1.16,0.39c0.36,0.17,0.69,0.37,1,0.58c0.31,0.21,0.59,0.43,0.85,0.65c0.26,0.22,0.49,0.44,0.7,0.64
                    c0.42,0.41,0.73,0.79,0.94,1.05c0.21,0.27,0.32,0.43,0.32,0.43s-0.15-0.13-0.4-0.35c-0.26-0.22-0.63-0.53-1.08-0.88
                    c-0.23-0.18-0.48-0.36-0.74-0.55c-0.27-0.19-0.56-0.37-0.86-0.56c-0.3-0.18-0.62-0.35-0.96-0.5c-0.17-0.07-0.34-0.14-0.51-0.19
                    c-0.09-0.03-0.17-0.06-0.26-0.08c-0.09-0.03-0.17-0.04-0.26-0.06l-0.06-0.01l-0.07-0.01l-0.14-0.02l-0.02,0l0,0
                    c-0.01,0,0.01,0,0.01,0l-0.01,0l-0.04,0l-0.07-0.01l-0.14-0.01c-0.19-0.02-0.38-0.03-0.56-0.05c-0.38-0.02-0.75-0.04-1.11-0.06
                    c-0.36-0.02-0.73-0.06-1.07-0.12c-0.17-0.03-0.33-0.07-0.49-0.12c-0.15-0.06-0.3-0.1-0.44-0.17c-0.07-0.03-0.14-0.06-0.2-0.09
                    c-0.06-0.03-0.13-0.07-0.19-0.1c-0.12-0.06-0.23-0.13-0.33-0.19c-0.21-0.12-0.37-0.25-0.51-0.35c-0.14-0.1-0.24-0.19-0.31-0.25
                    C124.04,170.53,124.01,170.5,124.01,170.5z"/>
                <path id="XMLID_3_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M129.82,79.14
                    c0,0,0.03,0.1,0.08,0.27c0.03,0.09,0.05,0.19,0.07,0.31c0.01,0.06,0.02,0.13,0.04,0.2c0.01,0.07,0.02,0.14,0.03,0.22
                    c0.02,0.15,0.03,0.32,0.03,0.5c0.01,0.18,0,0.37-0.01,0.56c-0.02,0.19-0.03,0.39-0.08,0.59c-0.03,0.2-0.09,0.4-0.14,0.6
                    c-0.07,0.2-0.12,0.4-0.2,0.58c-0.07,0.19-0.17,0.37-0.25,0.55c-0.09,0.17-0.18,0.34-0.28,0.48c-0.1,0.15-0.19,0.29-0.29,0.41
                    c-0.05,0.06-0.09,0.12-0.14,0.17c-0.05,0.05-0.09,0.1-0.14,0.15c-0.08,0.09-0.16,0.17-0.23,0.23c-0.13,0.12-0.21,0.19-0.21,0.19
                    s0.05-0.09,0.15-0.23c0.05-0.07,0.11-0.16,0.17-0.27c0.07-0.1,0.14-0.22,0.21-0.35c0.08-0.13,0.15-0.27,0.23-0.42
                    c0.08-0.15,0.15-0.32,0.23-0.48c0.07-0.17,0.14-0.35,0.21-0.53c0.07-0.18,0.12-0.37,0.18-0.56c0.05-0.19,0.11-0.38,0.14-0.57
                    c0.04-0.19,0.07-0.38,0.11-0.56c0.02-0.18,0.05-0.36,0.06-0.53c0.01-0.17,0.03-0.33,0.03-0.48c0.01-0.15,0.01-0.29,0.01-0.41
                    c0-0.12,0-0.23,0-0.32C129.82,79.24,129.82,79.14,129.82,79.14z"/>
                <path id="XMLID_2_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M134.46,79.5
                    c0,0,0.01,0.11,0.03,0.29c0.02,0.19,0.04,0.45,0.07,0.77c0.02,0.16,0.03,0.33,0.05,0.51c0.02,0.18,0.05,0.37,0.07,0.57
                    c0.05,0.39,0.11,0.81,0.18,1.23c0.04,0.21,0.07,0.42,0.11,0.62c0.04,0.2,0.08,0.4,0.13,0.6c0.09,0.39,0.18,0.75,0.26,1.05
                    c0.08,0.31,0.15,0.57,0.21,0.75c0.05,0.18,0.08,0.29,0.08,0.29s-0.06-0.09-0.15-0.26c-0.05-0.08-0.1-0.18-0.16-0.3
                    c-0.06-0.12-0.12-0.25-0.18-0.4c-0.07-0.15-0.13-0.31-0.19-0.49c-0.06-0.17-0.13-0.36-0.18-0.55c-0.05-0.2-0.12-0.4-0.16-0.6
                    c-0.05-0.21-0.09-0.42-0.13-0.63c-0.03-0.21-0.07-0.43-0.09-0.64c-0.03-0.21-0.04-0.42-0.05-0.62c-0.02-0.4-0.02-0.78,0-1.11
                    c0.01-0.16,0.02-0.31,0.03-0.44c0.01-0.13,0.03-0.24,0.04-0.34C134.44,79.61,134.46,79.5,134.46,79.5z"/>
            </g>
            <rect x="194.49" y="3.16" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" width="191.98" height="191.98"/>
            <path id="XMLID_120_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M303.79,189.75
                c-0.37-2-30.97-38.88-32.5-52.53c-1.53-13.64-2.95-24.71-3.52-29.16c-0.57-4.45-0.99-5.67-6.91-8.91
                c-5.92-3.24-9.14-17.73-7.35-22.76s-0.97-14.68,1.8-18.26c2.77-3.58,24.07-28.39,26.97-34.1s11.26-10.84,14.92-10.84
                c3.66,0,15.23,4.04,22.42,4.59c7.19,0.55,19.71-0.59,22.58,5.97c2.87,6.56,7.14,5.96,10,11.96c2.85,6.01,2.24,9.43,3.69,15.42
                c1.45,5.99-0.84,12.94,1.45,16.74c2.28,3.8,8.94,6.5,7.46,11.28c-1.48,4.78-7.44,7.08-11.02,7.93c-3.58,0.84-4.45,1.74-5.7,7.93
                c-1.26,6.19-3.51,11.11-6.52,14.29c-3.01,3.18-4.49,15.67-5.04,18.2c-0.55,2.53-9.18,4.6-7.44,6.76c1.74,2.16,5.48,1.07,6.46,4.89
                c0.98,3.82,9.16,12.16,12.54,17.98c3.38,5.83,14.63,15.78,16.93,17.63c2.31,1.86,10.63,20.37,10.63,20.37h-69.68L303.79,189.75z"/>
            <g id="XMLID_60_">
                <path id="XMLID_114_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M386.47,195.15
                    c0,0-13.13-22.55-15.94-25.53c-2.8-2.98-4.2-0.35-7.18-1.4c-2.98-1.05-7.71-10.33-12.08-15.24c-4.38-4.9-13.45-9.87-12.16-10.34
                    c1.28-0.47,8.45-5.02,8.45-5.02s-0.63-0.58-2.5-2.22c-1.87-1.63-10.16-2.45-10.97-2.57c-0.82-0.12,0.23-1.63,1.87-2.8
                    c1.63-1.17,4.44-10.39,5.72-14.94c1.28-4.55,4.79-10.04,5.14-13.89c0.35-3.85,0-9.57,0-11.68c0-2.1,0.35-3.15,0.7-4.09
                    c0.35-0.93-1.28-1.52-3.5-2.69c-2.22-1.17-9.46-0.58-10.27,0c-0.82,0.58-2.8,1.52-4.9,1.52s-2.13,0.81-3.04,3.15
                    c-0.35,0.9-1.4,0.16-2.02,0.47c-0.62,0.31,0.7,1.09,1.63,1.87c0.93,0.78,0,8.87,0.16,10.35c0.16,1.48,1.01,1.01,1.63,2.02
                    c0.62,1.01,0.23,4.75-0.62,5.37c-0.86,0.62-2.1,1.63-2.72,2.26c-0.62,0.62-2.1,1.01-2.57,1.71c-0.47,0.7,4.75,2.02,5.76,2.49
                    c1.01,0.47,4.05-0.39,4.9-1.71c0.86-1.32,0.08-2.88-0.62-3.97c-0.7-1.09-1.63-3.97-1.71-5.76c-0.08-1.79-1.32-2.26-1.63-3.42
                    s0.54-1.25,1.63-0.95c1.09,0.29-0.16-2.86,0-3.87c0.16-1.01-0.16-1.17,1.32-0.86c1.48,0.31,2.26-0.16,1.71-0.16
                    s-3.19-1.71-2.41-2.65c0.78-0.93,2.1-1.01,2.1-1.01s0.08,2.88,3.04,2.88s2.65-2.1,2.96-2.26c0.31-0.16,1.17,0.62,0.86,1.63
                    c-0.31,1.01-3.19,1.87-3.19,1.87s1.25,0.31,2.18-0.16c0.93-0.47,1.4-0.23,0.62,0.7c-0.78,0.93-0.47,1.17-0.31,1.87
                    c0.16,0.7-0.86,1.95-0.86,2.72c0,0.78,2.18-0.78,2.72-1.09c0.54-0.31,1.17-2.26,1.87-3.66s1.17-0.85,1.35,2.48
                    c0.18,3.33,0,3.68-2.8,7.88c-2.8,4.2-4.2,9.46-4.9,11.73c-0.7,2.28-1.4,7.71-1.49,11.21c-0.09,3.5-4.81,3.33-7.44,3.5
                    c-2.63,0.18-2.63,2.1-2.1,4.2c0.53,2.1,0,1.75-2.28,2.63c-2.28,0.88-2.8-2.28-3.33-3.15c-0.53-0.88-1.26,1-2.8,2.63
                    c-0.76,0.81-3.06,2.33-6.04,1.39c-2.98-0.94-6.39-3.84-8.67-6.12c-2.28-2.28-5.78-1.05-7.53-1.93
                    c-1.75-0.88-12.08-12.26-11.91-11.38c0.18,0.88,10.68,12.08,11.56,13.48c0.88,1.4,7.88,4.55,9.28,4.55s4.73,2.63,7,3.68
                    c2.28,1.05,4.38,2.28,5.78,4.2c1.4,1.93-3.15,2.98-3.15,2.98s5.25,0.35,7,0.18c1.75-0.18,3.5-3.33,4.38-4.03
                    c0.88-0.7,6.3-3.5,7.71-4.38c1.4-0.88,2.8,1.23,1.05,1.93c-1.75,0.7-1.93,2.98,0.18,4.03c2.1,1.05,6.13,3.5,7.71,7
                    s-2.1,2.28,0,5.43c2.1,3.15,8.06,2.28,10.68,4.73c2.63,2.45,4.55,9.46,6.48,13.48c1.93,4.03,6.13,4.2,7.71,8.93
                    c1.58,4.73,0.7,9.77,0.7,9.77H386.47z M333.66,89.99c-0.31,0-0.56-0.25-0.56-0.56c0-0.31,0.25-0.56,0.56-0.56s0.56,0.25,0.56,0.56
                    C334.23,89.74,333.97,89.99,333.66,89.99z M342.77,87.97c-0.31,0.7-0.93,1.71-1.71,1.4c-0.78-0.31-3.82-1.61-5.92-1.79
                    c-1.56-0.13-2-1.09-0.77-1.25c1.23-0.16,4.19-0.23,4.74-0.54c0.54-0.31,2.02-0.54,3.27,0c1.25,0.54,1.25,0.54,1.25,0.54
                    S343.08,87.27,342.77,87.97z"/>
                <path id="XMLID_111_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M316.93,82.99
                    c-3.15,0-3.04-0.82-7.01-2.8s-4.79-0.58-6.3,0c-1.52,0.58-1.87,0.35-4.79,0.93c-2.92,0.58-6.3,2.45-5.95,3.04
                    c0.35,0.58,2.08,1.79,3.04,0.82c2.49-2.53,6.07-2.76,7.55-2.45c1.48,0.31-2.72,1.71-2.1,1.87c0.62,0.16,2.96-1.09,4.11-1.4
                    c1.15-0.31,5.39,2.02,2.2,1.87c-3.19-0.16-5.68,0.62-6.85,1.01c-1.17,0.39-1.87,0.62-3.35,0.08c-1.48-0.54-1.32,0.47-1.01,0.94
                    c0.31,0.47,1.87,0.39,1.01,1.13c-0.86,0.74-1.48-0.27-2.33,0.35c-0.86,0.62,1.79,0.62,1.71,1.07c-0.08,0.45,1.09,0.49,2.02,0
                    c0.93-0.49,2.33-0.91,2.33-0.91c1.87,2.65,3.58,2.65,3.58,2.65c-1.25-0.47-2.26-2.33-2.18-3.15c0.08-0.82,1.73-0.56,1.73-0.56
                    s0.53,2.94,3.41,2.94c2.88,0,3.27-2.94,3.27-2.94s1.48,0.99,1.17,1.98c-0.31,0.99-3.5,1.58-3.5,1.58s0.78,0.39,0.86,1.17
                    c0.08,0.78-3.46,0.86-3.5,1.56c-0.04,0.7,4.36,1.28,5.41,1.28c1.05,0,3.39-2.69,3.62-3.74c0.23-1.05,0-2.45,0.82-2.41
                    c0.82,0.04,2.1-0.51,3.04-1.42c0.93-0.91,2.69-3.99,2.69-5.37C321.6,80.71,320.08,82.99,316.93,82.99z M306.03,88.01
                    c-0.31,0-0.56-0.25-0.56-0.56c0-0.31,0.25-0.56,0.56-0.56c0.31,0,0.56,0.25,0.56,0.56C306.59,87.76,306.34,88.01,306.03,88.01z"/>
                <path id="XMLID_110_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M311.44,107.04
                    c1.17,0.7,1.75,0.93,2.33,0.7c0.58-0.23,0,0.12,0.58-0.23c0.58-0.35,2.45-0.64,3.74,0.32c1.28,0.96,3.15,2.01,2.22,2.36
                    c-0.93,0.35-1.87,0.23-2.92,0s-2.33,0.58-4.32-0.7C311.09,108.21,311.44,107.04,311.44,107.04z"/>
                <path id="XMLID_109_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M313.03,101.9
                    c0,0-4.4,3.5-6.44,4.75c-2.04,1.25,1.32,0.47,3.22-0.31S313.03,101.9,313.03,101.9z"/>
                <path id="XMLID_108_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M331.71,111.01
                    c0,0,1.32,0.99,2.51,3.74c1.2,2.75,1.3,6.54,1.3,6.54s0.12-1.01-1.3-1.48c-1.42-0.47-0.8-0.93-0.56-2.18
                    C333.89,116.38,332.18,111.01,331.71,111.01z"/>
                <path id="XMLID_107_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M320.12,112.8
                    c-0.5,1.16-0.08,1.48,0,2.26c0.08,0.78,0.12,1.79,0.45,1.32c0.33-0.47,0.18-1.25,0.49-2.1
                    C321.36,113.42,320.51,111.88,320.12,112.8z"/>
                <path id="XMLID_106_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M304.34,120.97
                    c-1.23-0.16-1.62-1.32,0-2.49c1.62-1.17,2.14-2.88,2.89-2.34c0.75,0.54,1.79,2.46,4.19,2.36c2.4-0.1,4.34-1.82,6.83-1.58
                    c2.49,0.23,2.65,1.87,4.44,1.01c1.79-0.86,2.65,0.47,4.13,0.93c1.48,0.47,4.59-0.54,4.36,0.31c-0.23,0.86-1.32,0.78-1.63,1.25
                    c-0.31,0.47,1.5,1.79,1.65,3.81c0.14,2.02,0.77,2.18-0.4,2.18s-1.87-0.54-2.33,0.39c-0.47,0.93-1.71,1.87-1.71,1.87
                    s-0.62-3.19-1.71-3.04c-1.09,0.16-3.11-0.16-3.42-0.47s-2.84-0.08-4.14,0.23c-1.31,0.31-6.29-0.93-4.89-1.48
                    c1.4-0.54,2.02-0.96,2.49-1.57s0.54-2.56,1.4-1.47c0.86,1.09,3.04,0.54,5.14,0.78c2.1,0.23,3.81-0.39,4.05-1.01
                    c0.23-0.62-0.16-1.32-0.16-1.32s-18.98-0.7-19.53,0C305.41,120.04,305.52,121.13,304.34,120.97z"/>
                <path id="XMLID_105_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M311.44,95.01
                    c0,0-0.03,0.03-0.08,0.09c-0.03,0.03-0.06,0.07-0.11,0.11c-0.04,0.04-0.09,0.09-0.15,0.14c-0.06,0.05-0.12,0.1-0.19,0.16
                    c-0.07,0.06-0.15,0.11-0.24,0.17c-0.09,0.06-0.18,0.12-0.28,0.18c-0.1,0.06-0.21,0.13-0.32,0.19c-0.12,0.06-0.24,0.12-0.36,0.19
                    c-0.13,0.06-0.26,0.11-0.4,0.18c-0.07,0.03-0.14,0.06-0.21,0.09c-0.07,0.03-0.15,0.05-0.22,0.08c-0.15,0.05-0.3,0.1-0.46,0.16
                    c-0.16,0.04-0.32,0.09-0.48,0.13c-0.08,0.02-0.16,0.04-0.25,0.07c-0.08,0.02-0.17,0.03-0.25,0.05c-0.34,0.06-0.68,0.14-1.02,0.17
                    c-0.17,0.02-0.35,0.04-0.52,0.06c-0.17,0.02-0.34,0.02-0.51,0.03c-0.34,0.01-0.68,0.03-1,0.01c-0.32-0.01-0.64-0.02-0.93-0.05
                    c-0.3-0.03-0.57-0.07-0.83-0.1c-0.13-0.02-0.25-0.03-0.37-0.05c-0.12-0.02-0.23-0.05-0.33-0.07c-0.21-0.04-0.38-0.08-0.53-0.11
                    c-0.29-0.07-0.46-0.11-0.46-0.11s0.17-0.01,0.47-0.01c0.15,0,0.33,0,0.54,0.01c0.1,0,0.21,0,0.33,0.01c0.12,0,0.24,0,0.37,0
                    c0.25,0,0.53-0.01,0.82,0c0.29,0,0.6-0.02,0.91-0.03c0.16-0.01,0.32,0,0.48-0.02c0.16-0.01,0.33-0.03,0.49-0.04
                    c0.16-0.01,0.33-0.02,0.5-0.04c0.17-0.02,0.33-0.04,0.5-0.06c0.33-0.03,0.66-0.09,0.99-0.14c0.16-0.02,0.32-0.06,0.48-0.09
                    c0.16-0.03,0.31-0.07,0.47-0.1c0.15-0.04,0.3-0.08,0.45-0.12c0.07-0.02,0.15-0.04,0.22-0.06c0.07-0.02,0.14-0.04,0.21-0.06
                    c0.14-0.04,0.27-0.08,0.4-0.12c0.13-0.05,0.25-0.09,0.37-0.13c0.12-0.04,0.23-0.09,0.34-0.13c0.11-0.04,0.21-0.08,0.3-0.13
                    c0.09-0.04,0.18-0.09,0.26-0.12c0.08-0.04,0.15-0.08,0.22-0.11c0.13-0.07,0.23-0.13,0.3-0.17
                    C311.4,95.04,311.44,95.01,311.44,95.01z"/>
                <path id="XMLID_104_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M329.07,97
                    c0,0,0.08,0.03,0.22,0.11c0.07,0.04,0.15,0.08,0.24,0.14c0.09,0.06,0.19,0.12,0.3,0.2c0.21,0.16,0.45,0.35,0.69,0.58
                    c0.06,0.06,0.12,0.12,0.18,0.18c0.06,0.06,0.12,0.12,0.18,0.19c0.12,0.13,0.23,0.26,0.34,0.4l0.16,0.21
                    c0.05,0.07,0.1,0.14,0.15,0.21c0.1,0.14,0.19,0.29,0.27,0.43c0.09,0.14,0.16,0.28,0.23,0.42c0.03,0.07,0.07,0.13,0.1,0.2
                    c0.03,0.07,0.05,0.13,0.08,0.19c0.05,0.12,0.09,0.24,0.13,0.34c0.03,0.1,0.05,0.2,0.07,0.27c0.04,0.15,0.05,0.24,0.05,0.24
                    s-0.06-0.07-0.16-0.19c-0.09-0.12-0.23-0.29-0.39-0.49c-0.08-0.1-0.16-0.21-0.25-0.33c-0.09-0.12-0.18-0.24-0.28-0.36
                    c-0.05-0.06-0.1-0.13-0.15-0.19c-0.05-0.06-0.1-0.13-0.15-0.19c-0.1-0.13-0.21-0.27-0.31-0.4c-0.1-0.13-0.21-0.26-0.31-0.4
                    c-0.1-0.13-0.21-0.26-0.31-0.38c-0.2-0.25-0.38-0.48-0.54-0.69c-0.16-0.21-0.29-0.38-0.38-0.5c-0.05-0.06-0.08-0.11-0.11-0.14
                    C329.08,97.02,329.07,97,329.07,97z"/>
                <path id="XMLID_103_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M334.37,104.6
                    c0,0,0.02,0.03,0.06,0.08c0.04,0.05,0.1,0.14,0.16,0.25c0.06,0.11,0.14,0.25,0.22,0.41c0.08,0.16,0.16,0.34,0.25,0.54
                    c0.17,0.4,0.35,0.87,0.54,1.37c0.09,0.25,0.19,0.51,0.29,0.77c0.1,0.26,0.2,0.53,0.31,0.79c0.1,0.26,0.21,0.52,0.33,0.77
                    c0.11,0.25,0.23,0.5,0.35,0.73c0.12,0.23,0.24,0.45,0.37,0.65c0.13,0.2,0.26,0.39,0.38,0.56c0.07,0.08,0.13,0.16,0.19,0.23
                    c0.06,0.07,0.13,0.14,0.18,0.2c0.12,0.12,0.23,0.23,0.33,0.3c0.09,0.08,0.17,0.14,0.23,0.18c0.05,0.04,0.08,0.06,0.08,0.06
                    s-0.03-0.01-0.1-0.03c-0.06-0.02-0.16-0.05-0.28-0.11c-0.06-0.03-0.12-0.06-0.19-0.1c-0.07-0.04-0.14-0.08-0.22-0.13
                    c-0.07-0.05-0.15-0.11-0.23-0.17c-0.08-0.06-0.16-0.13-0.24-0.21c-0.16-0.15-0.33-0.33-0.49-0.53c-0.16-0.2-0.32-0.42-0.46-0.65
                    c-0.14-0.24-0.29-0.49-0.41-0.75c-0.13-0.26-0.25-0.53-0.36-0.8c-0.22-0.54-0.4-1.1-0.55-1.62c-0.08-0.26-0.14-0.51-0.2-0.75
                    c-0.06-0.24-0.11-0.47-0.17-0.68c-0.05-0.21-0.1-0.4-0.14-0.57c-0.04-0.17-0.08-0.32-0.12-0.44c-0.04-0.12-0.06-0.22-0.09-0.28
                    C334.38,104.64,334.37,104.6,334.37,104.6z"/>
                <path id="XMLID_102_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M330.76,115.68
                    c0,0,0.04,0.05,0.1,0.13c0.03,0.04,0.07,0.09,0.1,0.15c0.04,0.06,0.08,0.13,0.12,0.2c0.09,0.15,0.18,0.33,0.26,0.53
                    c0.02,0.05,0.04,0.1,0.06,0.16c0.02,0.05,0.04,0.11,0.05,0.16c0.03,0.11,0.06,0.23,0.08,0.35c0.02,0.12,0.03,0.24,0.03,0.36
                    c0,0.06,0,0.12,0,0.18c0,0.06,0,0.11-0.01,0.17c-0.01,0.06-0.01,0.11-0.02,0.16c-0.01,0.05-0.02,0.1-0.03,0.15
                    c-0.02,0.1-0.05,0.19-0.08,0.27c-0.03,0.08-0.06,0.16-0.09,0.22c-0.03,0.07-0.06,0.12-0.08,0.17c-0.05,0.09-0.08,0.14-0.08,0.14
                    s0-0.06,0.01-0.16c0-0.05,0.01-0.11,0.02-0.18c0-0.07,0.02-0.15,0.02-0.23c0-0.08,0.01-0.17,0.01-0.27c0-0.05,0-0.1,0.01-0.14
                    c0-0.05,0-0.1,0-0.15c0-0.1-0.01-0.21-0.02-0.31c-0.01-0.11-0.02-0.21-0.04-0.32c-0.02-0.11-0.04-0.21-0.06-0.32
                    c-0.02-0.1-0.05-0.21-0.08-0.31c-0.06-0.2-0.11-0.39-0.15-0.55c-0.05-0.16-0.08-0.3-0.1-0.4
                    C330.77,115.74,330.76,115.68,330.76,115.68z"/>
                <path id="XMLID_101_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M277.7,108.73
                    c0.75,3.64,3,11.38,4.58,13.63s4.21,5.23,4.21,5.23s-5.6-4.04-7.53-8.41c-1.93-4.37-2.28-6.54-2.98-8.17
                    C275.27,109.38,277,105.35,277.7,108.73z"/>
                <path id="XMLID_100_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M288.93,130.74
                    c0,0,0.05,0.07,0.14,0.19c0.09,0.12,0.24,0.3,0.42,0.52c0.19,0.22,0.43,0.48,0.71,0.76c0.29,0.28,0.62,0.59,0.99,0.91
                    c0.38,0.31,0.81,0.63,1.27,0.95c0.47,0.3,0.99,0.61,1.51,0.95c0.53,0.34,1.07,0.68,1.61,1.04c0.54,0.36,1.09,0.73,1.64,1.11
                    c0.55,0.38,1.09,0.76,1.62,1.14c0.53,0.38,1.05,0.77,1.55,1.14c0.5,0.37,0.97,0.74,1.42,1.1c0.45,0.35,0.86,0.7,1.25,1.02
                    c0.19,0.16,0.37,0.32,0.54,0.47c0.17,0.15,0.33,0.3,0.48,0.43c0.3,0.27,0.55,0.52,0.76,0.73c0.05,0.05,0.1,0.1,0.15,0.15
                    c0.04,0.05,0.08,0.09,0.12,0.14c0.08,0.08,0.14,0.16,0.19,0.21c0.1,0.12,0.16,0.18,0.16,0.18s-0.06-0.05-0.18-0.15
                    c-0.12-0.1-0.29-0.25-0.52-0.43c-0.23-0.18-0.5-0.4-0.82-0.64c-0.32-0.25-0.69-0.51-1.09-0.81c-0.2-0.15-0.41-0.3-0.63-0.45
                    c-0.22-0.16-0.44-0.32-0.67-0.48c-0.46-0.33-0.95-0.67-1.46-1.03c-0.51-0.35-1.04-0.72-1.57-1.09c-0.53-0.37-1.08-0.75-1.63-1.12
                    c-0.54-0.38-1.09-0.75-1.63-1.12c-0.54-0.37-1.07-0.73-1.58-1.08c-0.51-0.35-1.01-0.68-1.48-1.02c-0.46-0.35-0.88-0.7-1.24-1.05
                    c-0.36-0.35-0.67-0.69-0.93-1c-0.26-0.31-0.47-0.6-0.63-0.84c-0.16-0.24-0.28-0.44-0.36-0.58
                    C288.97,130.82,288.93,130.74,288.93,130.74z"/>
                <path id="XMLID_99_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M286.48,150.01
                    c0,0,2.45,1.23,4.03,0s3.15-1.54,4.2,0.54s2.49,4.53,2.49,4.53s-2.46-3.25-3.53-3.02c-1.06,0.22-3.17-0.35-3.34,1.45
                    c-0.17,1.79,1.4,6.86,0.53,6.32C289.98,159.29,286.48,150.01,286.48,150.01z"/>
                <path id="XMLID_98_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M293.67,147.73
                    c2.26-0.53,4.01-2.1,5.77-2.98c1.75-0.88,4.35-2.1,6.03,0.7c0,0-2.75-1.04-2.2,0.27c0.55,1.31-0.67,2.18-1.55,2.01
                    c-0.88-0.18-1.84,1.75-2.15,2.28c-0.31,0.53-1.18-0.19-1.71-0.97c-0.53-0.78,0.07,2.02-0.66,2.37
                    C296.46,151.76,293.67,147.73,293.67,147.73z"/>
                <path id="XMLID_97_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M322.9,147.57
                    c0,0,6.94-2.2,8.81-4.74c0,0,5.31,2.98,4.79,3.84c-0.52,0.87-1.8,0.53-2.89,1.58c-1.09,1.05,1.19,1.93,0.75,4.03
                    c-0.44,2.1-0.78,1.23-1.92,0c-1.14-1.23-2.56-2.76-2.73-0.85c-0.17,1.9-1.57,5.41-3.49,6.98c-1.93,1.58-5.11,1.46-5.11,1.46
                    s2.66-2.68,1.26-2.51c-1.4,0.18-7.18,1.75-7.35,1.05s3.58-1.4,4.85-2.45c1.28-1.05,4.4-1.05,5.9-2.1c1.5-1.05,4.2-3.69,3.29-4.82
                    c-0.91-1.13-1.26,1.01-2.49,2.39c-1.23,1.38-2.8,2.25-3.85,1.73c-1.05-0.53-1.93-2.1,0.18-3.15
                    C325.01,148.96,324.82,147.45,322.9,147.57z"/>
                <path id="XMLID_96_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M299.57,157.54
                    c0,0,0.16,0.08,0.42,0.25c0.26,0.17,0.63,0.42,1.05,0.77c0.21,0.17,0.43,0.36,0.65,0.58c0.22,0.22,0.45,0.45,0.67,0.72
                    c0.22,0.26,0.43,0.55,0.61,0.87c0.19,0.31,0.35,0.66,0.46,1.02c0.06,0.18,0.09,0.37,0.12,0.57c0.01,0.1,0.01,0.2,0.02,0.29
                    c0,0.1,0,0.18-0.01,0.27c-0.02,0.37-0.07,0.72-0.14,1.06c-0.07,0.34-0.17,0.65-0.27,0.94c-0.09,0.29-0.2,0.56-0.27,0.8
                    c-0.07,0.25-0.12,0.47-0.1,0.66c0.01,0.19,0.08,0.36,0.17,0.48c0.09,0.12,0.17,0.21,0.23,0.26c0.06,0.05,0.1,0.08,0.1,0.08
                    s-0.04-0.02-0.12-0.06c-0.07-0.04-0.18-0.1-0.3-0.21c-0.12-0.11-0.25-0.28-0.3-0.52c-0.06-0.23-0.05-0.5-0.01-0.77
                    c0.04-0.27,0.11-0.56,0.18-0.85c0.07-0.29,0.13-0.6,0.18-0.92c0.05-0.31,0.08-0.65,0.08-0.97c0-0.08,0-0.18,0-0.25
                    c-0.01-0.08-0.01-0.16-0.02-0.23c-0.02-0.16-0.05-0.31-0.1-0.47c-0.09-0.31-0.23-0.62-0.39-0.91c-0.16-0.29-0.34-0.57-0.53-0.83
                    c-0.19-0.26-0.38-0.51-0.57-0.74c-0.19-0.23-0.38-0.44-0.56-0.64c-0.36-0.39-0.67-0.7-0.89-0.92
                    C299.7,157.66,299.57,157.54,299.57,157.54z"/>
                <path id="XMLID_95_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M315.99,173.82
                    c0,0-1.84-2.1-2.96-2.28s-4.31-1.93-7.08-4.38c0,0-1.09,1.58,1.36,3.33c2.45,1.75,3.57,2.6,3.53,4.28
                    c-0.03,1.68,3.12,1.68,3.12,1.68s-4.18-2.8-2.56-3.85C313.03,171.55,315.99,173.82,315.99,173.82z"/>
                <path id="XMLID_94_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M322.55,182.05l-1.99,3.68
                    c-0.64-2.1-1.41-2.1,0.55-4.73c1.96-2.63,1.09-6.23,1.09-6.23s2.28,2.28,4.55,5.48c2.28,3.2,2.63,4.71,2.63,4.71
                    s-1.95-1.16-3.6-3.61c-1.65-2.45-2.53-1.4-2.88-0.35C322.55,182.05,323.6,181.35,322.55,182.05"/>
                <path id="XMLID_93_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M335.53,184.96
                    c0,0-1.07-5.71-2.47-8.51c-1.4-2.8-2.3-4.64-2.3-4.64s3.91,1.84,4.77,4.64c0.86,2.8,9.44,8.51,9.44,8.51s-6.69-2.83-8.43-4.71
                    C336.54,180.25,336.95,182.79,335.53,184.96z"/>
                <path id="XMLID_92_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M347.42,167.17
                    c0,0,0.08,0.03,0.24,0.09c0.15,0.06,0.38,0.15,0.66,0.28c0.28,0.14,0.62,0.3,1,0.52c0.19,0.1,0.39,0.23,0.6,0.35
                    c0.21,0.13,0.42,0.27,0.64,0.43c0.44,0.31,0.9,0.67,1.34,1.11c0.44,0.44,0.88,0.94,1.23,1.54c0.04,0.07,0.09,0.14,0.13,0.22
                    c0.04,0.07,0.08,0.15,0.13,0.22c0.04,0.07,0.08,0.15,0.13,0.22c0.04,0.07,0.09,0.14,0.13,0.2c0.09,0.14,0.18,0.27,0.28,0.41
                    c0.1,0.14,0.2,0.27,0.3,0.41c0.42,0.54,0.89,1.06,1.37,1.58c0.48,0.52,0.96,1.04,1.42,1.58c0.46,0.53,0.88,1.09,1.27,1.64
                    c0.19,0.28,0.37,0.55,0.55,0.81c0.18,0.26,0.36,0.51,0.54,0.75c0.37,0.48,0.72,0.93,1.07,1.33c0.68,0.81,1.28,1.46,1.71,1.91
                    c0.21,0.22,0.38,0.4,0.5,0.52c0.12,0.12,0.18,0.18,0.18,0.18s-0.07-0.06-0.2-0.16c-0.13-0.1-0.32-0.26-0.56-0.46
                    c-0.23-0.2-0.52-0.45-0.84-0.75c-0.16-0.15-0.32-0.31-0.5-0.48c-0.17-0.17-0.35-0.35-0.54-0.54c-0.37-0.38-0.77-0.81-1.16-1.28
                    c-0.2-0.23-0.39-0.48-0.59-0.74c-0.1-0.13-0.19-0.26-0.29-0.39c-0.09-0.13-0.19-0.27-0.29-0.4c-0.38-0.53-0.82-1.05-1.27-1.57
                    c-0.46-0.52-0.94-1.03-1.42-1.55c-0.48-0.53-0.95-1.06-1.39-1.63c-0.11-0.14-0.22-0.28-0.31-0.43c-0.1-0.15-0.2-0.29-0.29-0.45
                    c-0.05-0.08-0.09-0.15-0.14-0.23c-0.04-0.08-0.08-0.15-0.12-0.22c-0.04-0.07-0.08-0.15-0.12-0.22c-0.04-0.07-0.08-0.14-0.12-0.22
                    c-0.31-0.55-0.69-1.05-1.1-1.49c-0.1-0.11-0.21-0.21-0.31-0.32c-0.11-0.1-0.21-0.2-0.31-0.29c-0.1-0.1-0.21-0.19-0.31-0.28
                    c-0.1-0.09-0.2-0.18-0.31-0.26c-0.41-0.33-0.8-0.62-1.15-0.86c-0.35-0.24-0.67-0.44-0.94-0.6c-0.13-0.08-0.25-0.15-0.36-0.21
                    c-0.1-0.06-0.19-0.11-0.27-0.15C347.5,167.21,347.42,167.17,347.42,167.17z"/>
                <path id="XMLID_91_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M335.53,165.24
                    c0,0,0.05,0.08,0.15,0.23c0.09,0.15,0.26,0.35,0.47,0.6c0.22,0.24,0.51,0.53,0.86,0.82c0.35,0.29,0.77,0.59,1.25,0.9
                    c0.48,0.3,1.01,0.61,1.58,0.92c0.57,0.31,1.19,0.62,1.81,0.98c0.16,0.09,0.31,0.18,0.47,0.27c0.16,0.1,0.32,0.19,0.47,0.29
                    c0.31,0.2,0.63,0.41,0.93,0.63c0.61,0.45,1.21,0.95,1.76,1.5c0.54,0.56,1.04,1.17,1.44,1.83c0.21,0.33,0.38,0.67,0.53,1.02
                    c0.07,0.18,0.14,0.35,0.21,0.53c0.05,0.18,0.11,0.36,0.16,0.54c0.18,0.7,0.31,1.39,0.41,2.04c0.11,0.65,0.18,1.26,0.3,1.81
                    c0.11,0.55,0.28,1.04,0.47,1.45c0.02,0.05,0.05,0.1,0.07,0.15c0.03,0.05,0.05,0.1,0.08,0.14c0.05,0.09,0.1,0.19,0.15,0.27
                    c0.11,0.17,0.2,0.33,0.3,0.46c0.1,0.14,0.18,0.26,0.26,0.36c0.08,0.1,0.15,0.19,0.2,0.26c0.11,0.14,0.17,0.22,0.17,0.22
                    s-0.07-0.07-0.19-0.19c-0.06-0.06-0.14-0.14-0.23-0.23c-0.09-0.1-0.19-0.21-0.3-0.34c-0.11-0.13-0.22-0.28-0.35-0.44
                    c-0.06-0.08-0.12-0.17-0.18-0.26c-0.03-0.05-0.06-0.09-0.09-0.14c-0.03-0.05-0.06-0.1-0.09-0.15c-0.12-0.2-0.23-0.43-0.33-0.68
                    c-0.1-0.25-0.19-0.51-0.27-0.79c-0.16-0.56-0.27-1.17-0.4-1.81c-0.13-0.64-0.28-1.3-0.48-1.98c-0.18-0.66-0.5-1.31-0.88-1.92
                    c-0.38-0.61-0.86-1.19-1.38-1.72c-0.52-0.53-1.09-1.02-1.68-1.46c-0.59-0.44-1.19-0.85-1.8-1.21c-0.61-0.36-1.21-0.71-1.77-1.04
                    c-0.56-0.34-1.09-0.68-1.55-1.02c-0.23-0.17-0.45-0.34-0.66-0.51c-0.2-0.17-0.39-0.33-0.55-0.5c-0.34-0.32-0.6-0.64-0.8-0.91
                    c-0.2-0.28-0.34-0.5-0.41-0.66C335.57,165.33,335.53,165.24,335.53,165.24z"/>
                <path id="XMLID_90_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M331.71,182.23
                    c0,0,0.03,0.03,0.08,0.1c0.05,0.07,0.12,0.16,0.2,0.29c0.17,0.25,0.4,0.62,0.62,1.09c0.11,0.23,0.23,0.49,0.34,0.77
                    c0.11,0.28,0.22,0.58,0.31,0.89c0.1,0.31,0.18,0.64,0.25,0.98c0.07,0.34,0.12,0.68,0.16,1.03c0.03,0.35,0.06,0.7,0.05,1.04
                    c0,0.35-0.02,0.68-0.06,1.01c-0.04,0.33-0.09,0.64-0.16,0.93c-0.07,0.29-0.15,0.57-0.23,0.81c-0.04,0.12-0.08,0.24-0.13,0.35
                    c-0.05,0.11-0.09,0.21-0.13,0.31c-0.08,0.19-0.17,0.36-0.24,0.49c-0.07,0.13-0.14,0.23-0.18,0.3c-0.04,0.07-0.06,0.11-0.06,0.11
                    s0.05-0.17,0.13-0.45c0.04-0.15,0.09-0.32,0.14-0.52c0.02-0.1,0.05-0.21,0.07-0.32c0.02-0.11,0.05-0.23,0.07-0.36
                    c0.05-0.25,0.09-0.52,0.12-0.8c0.04-0.29,0.06-0.59,0.08-0.9c0-0.16,0.01-0.32,0.01-0.48c0-0.16,0-0.32-0.01-0.49
                    c-0.01-0.33-0.03-0.66-0.06-0.99c-0.04-0.33-0.08-0.66-0.13-0.99c-0.06-0.32-0.12-0.64-0.19-0.95c-0.07-0.31-0.15-0.6-0.23-0.88
                    c-0.08-0.28-0.16-0.54-0.24-0.78c-0.08-0.24-0.16-0.46-0.23-0.66c-0.07-0.19-0.13-0.36-0.19-0.5
                    C331.78,182.39,331.71,182.23,331.71,182.23z"/>
                <path id="XMLID_89_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M344.97,182.23
                    c0,0,0.14,0.13,0.36,0.36c0.11,0.12,0.26,0.25,0.41,0.42c0.16,0.16,0.34,0.34,0.54,0.54c0.2,0.2,0.41,0.41,0.62,0.65
                    c0.22,0.24,0.45,0.49,0.67,0.77c0.22,0.28,0.43,0.58,0.62,0.91c0.2,0.32,0.36,0.67,0.51,1.02c0.07,0.18,0.14,0.36,0.2,0.53
                    c0.06,0.18,0.12,0.36,0.17,0.54c0.1,0.36,0.19,0.72,0.27,1.05c0.08,0.34,0.15,0.67,0.21,0.98c0.07,0.31,0.13,0.61,0.18,0.88
                    c0.11,0.55,0.18,1.01,0.23,1.33c0.05,0.32,0.07,0.51,0.07,0.51s-0.07-0.17-0.19-0.48c-0.11-0.31-0.27-0.74-0.45-1.28
                    c-0.09-0.26-0.18-0.55-0.28-0.86c-0.09-0.31-0.2-0.63-0.29-0.96c-0.1-0.34-0.2-0.67-0.31-1.01c-0.06-0.17-0.12-0.34-0.18-0.51
                    c-0.07-0.17-0.13-0.34-0.2-0.5c-0.14-0.33-0.29-0.66-0.46-0.97c-0.17-0.31-0.36-0.6-0.55-0.89c-0.2-0.28-0.39-0.55-0.58-0.8
                    c-0.19-0.25-0.38-0.49-0.54-0.71c-0.17-0.22-0.32-0.43-0.45-0.61c-0.13-0.19-0.24-0.35-0.33-0.49
                    C345.06,182.39,344.97,182.23,344.97,182.23z"/>
                <path id="XMLID_88_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M309.07,159.83
                    c0,0,0.06,0.03,0.17,0.09c0.11,0.06,0.28,0.15,0.49,0.26c0.42,0.23,1.01,0.55,1.73,0.94c0.36,0.19,0.75,0.4,1.16,0.62
                    c0.41,0.22,0.85,0.44,1.3,0.67c0.23,0.11,0.46,0.23,0.69,0.35c0.23,0.12,0.47,0.23,0.71,0.35c0.24,0.11,0.48,0.23,0.72,0.34
                    c0.24,0.11,0.49,0.22,0.73,0.34c0.25,0.11,0.49,0.21,0.74,0.32c0.25,0.1,0.5,0.2,0.74,0.3c0.49,0.19,0.99,0.35,1.47,0.5
                    c0.48,0.15,0.95,0.28,1.4,0.41c0.45,0.12,0.88,0.24,1.27,0.36c0.39,0.12,0.75,0.25,1.06,0.4c0.31,0.14,0.55,0.32,0.73,0.48
                    c0.18,0.17,0.29,0.33,0.35,0.43c0.03,0.05,0.05,0.1,0.07,0.13c0.02,0.03,0.02,0.04,0.02,0.04s-0.04-0.06-0.12-0.15
                    c-0.08-0.1-0.21-0.23-0.4-0.36c-0.19-0.13-0.44-0.26-0.75-0.37c-0.3-0.11-0.66-0.2-1.06-0.28c-0.39-0.08-0.83-0.16-1.29-0.26
                    c-0.46-0.1-0.94-0.2-1.44-0.33c-0.5-0.13-1.01-0.28-1.52-0.47c-0.26-0.09-0.52-0.19-0.76-0.29c-0.25-0.11-0.5-0.22-0.76-0.32
                    c-0.25-0.12-0.5-0.23-0.74-0.35c-0.24-0.12-0.49-0.24-0.73-0.36c-0.48-0.25-0.94-0.5-1.39-0.75c-0.45-0.25-0.87-0.51-1.27-0.75
                    c-0.4-0.24-0.77-0.48-1.11-0.71c-0.34-0.22-0.65-0.44-0.92-0.63c-0.27-0.19-0.51-0.36-0.7-0.5
                    C309.28,160,309.07,159.83,309.07,159.83z"/>
                <path id="XMLID_87_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M308.02,146.15
                    c0,0,0.08,0.04,0.2,0.12c0.06,0.04,0.14,0.08,0.22,0.13c0.09,0.05,0.18,0.11,0.29,0.17c0.1,0.06,0.22,0.12,0.34,0.19
                    c0.12,0.06,0.25,0.13,0.38,0.19c0.13,0.07,0.27,0.12,0.41,0.19c0.07,0.03,0.14,0.06,0.22,0.09c0.07,0.03,0.14,0.06,0.22,0.08
                    l0.22,0.08c0.07,0.02,0.15,0.05,0.22,0.07c0.07,0.02,0.15,0.05,0.22,0.07c0.07,0.02,0.15,0.04,0.22,0.07
                    c0.14,0.05,0.28,0.08,0.41,0.12c0.13,0.04,0.26,0.08,0.38,0.11c0.12,0.03,0.22,0.07,0.32,0.1c0.1,0.03,0.18,0.05,0.25,0.07
                    c0.14,0.04,0.22,0.08,0.22,0.08s-0.08,0.02-0.23,0.04c-0.07,0.01-0.16,0.02-0.26,0.03c-0.1,0.01-0.22,0.01-0.34,0.01
                    c-0.06,0-0.13,0-0.2,0c-0.07,0-0.14-0.01-0.21-0.01c-0.14-0.01-0.3-0.02-0.45-0.05c-0.16-0.03-0.32-0.05-0.48-0.1
                    c-0.08-0.02-0.16-0.04-0.24-0.07c-0.08-0.03-0.16-0.06-0.24-0.09c-0.16-0.06-0.32-0.13-0.47-0.2c-0.15-0.08-0.29-0.16-0.43-0.25
                    c-0.14-0.09-0.26-0.18-0.37-0.28c-0.11-0.1-0.21-0.19-0.3-0.28c-0.09-0.09-0.16-0.18-0.23-0.26c-0.06-0.08-0.11-0.16-0.15-0.22
                    c-0.04-0.06-0.07-0.11-0.09-0.15C308.03,146.17,308.02,146.15,308.02,146.15z"/>
                <g id="XMLID_70_">
                    <path id="XMLID_86_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M248.28,108.73
                        c0,0-0.45-0.22-1.04-0.66C247.75,108.97,248.28,108.73,248.28,108.73z"/>
                    <path id="XMLID_71_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M315.99,195.15
                        c0,0-25.22-38.14-35.96-56.23s-8.64-20.2-7.71-26.4c0.93-6.2,5.6-7.91,5.6-7.91l1.72-1.88c0,0-1.72,1.88-7.18,1.88
                        c-5.46,0-4.03-8.36-4.03-8.36s0,0,0.7-1.23c0.7-1.23,0.53,0.71,0.88,1.99c0.35,1.28,2.1-0.58,1.93-3.03
                        c-0.18-2.45,2.8-2.1,2.8-2.1s-1.05,4.03,0.35,6.28c1.4,2.25,2.45,1.78,4.9,1.01c2.45-0.76,2.45-3.61,0.7-5.37
                        c-1.75-1.75-2.63-3.5-1.58-4.93c1.05-1.43,2.63,0.73,4.73-1.42c2.1-2.14,4.38-6.61,4.2-8.29c-0.18-1.68-3.5,0.8-3.5,0.8
                        c3.85-3.68,4.38-9.28,4.38-9.28c-2.45,8.06-7.53,8.48-7.53,8.48c2.28-1.5,2.45-3.93,3.15-5.85c0.7-1.93,4.9-7.88,4.03-8.93
                        c-0.88-1.05-6.13,9.81-7.53,9.46c-1.4-0.35,3.68-5.6,3.15-7c-0.53-1.4-3.85,5.95-5.95,5.95s1.93-1.4,1.4-2.8
                        c-0.53-1.4-2.1-1.23,0.35-2.28c2.45-1.05,5.25-5.6,7.18-9.35c1.93-3.74,9.17-4.58,8.41-7.64c-0.7-2.8,5.43,0.53,9.88,0.35
                        c4.45-0.18,5.01-2.98,8.68-5.08c3.68-2.1,6.42,1.4,6.42,1.4c-3.57,1.23-7.47,3.5-13.34,8.06c-5.87,4.55-9.89,0-9.89,0
                        s-0.7,2.45,3.59,2.91c4.29,0.46,10.94-3.79,12.1-5.71c1.16-1.93,10.04-4.55,10.57-4.38c0.53,0.18-0.53,2.8-0.53,2.8
                        c1.23-0.35,2.8-2.28,2.8-2.28c2.28,3.85-2.1,5.78-2.1,5.78c5.78-1.75,2.8-5.96,5.29-7.54c2.49-1.58,1.02,0.88,5.16,8.24
                        c4.14,7.35,7.68,7.17,7.68,7.17c-5.14-2.33-7.54-10.51-7.54-10.51s2.17,0.47,5.67,1.4c3.5,0.93,4.44,6.3,5.37,8.87
                        c0.93,2.57-1.87,5.6-1.79,8.87c0.08,3.27-3.81,8.08-3.81,8.08c2.8,0.61,5.37-2.71,5.37-2.71c0.93,1.87-1.17,6.3-1.17,6.3
                        s1.68,0.85,1.87,2.71c0.19,1.85-0.7,8.73-1.17,13.7c-0.47,4.96-3.97,11.85-3.97,11.85c2.81,0.99,7.01-4.2,7.47-3.7
                        c0.47,0.5,1.4,5.21,1.4,5.21c3.5-2.7,4.67-14.37,4.67-14.37c1.87,1.62-0.7,12.27-0.7,12.27c10.27-5.03,16.11-21.55,16.11-21.55
                        c1.44,6.39-3.74,12.45-3.74,12.45s6.54-5.02,5.84-11.32c-0.7-6.3-7.01-10.83-7.01-10.83c5.84,2.24,8.64-3.88,8.64-3.88
                        c-3.74,3.04-7.24-1.17-7.24-1.17c7.47-2.34,5.14-9.81,5.14-9.81s-0.93,4.44-6.54,4.2s-3.97-12.14-3.97-12.14
                        c1.87,1.63,3.5,8.64,3.5,8.64c1.63-4.44-1.63-10.27-1.63-10.27c2.1,1.17,3.74,7.01,3.74,7.01c0.93-7.24-5.45-12.14-5.45-12.14
                        c5.29-7.94-0.38-14.24-0.38-14.24c2.1,8.64-5.14,9.57-5.14,9.57c3.27-3.27,3.27-4.9,1.4-10.27c-1.87-5.37-8.17-4.67-8.64-7.24
                        c-0.47-2.57,3.5-5.37,2.34-7.01c-1.17-1.63-4.9,1.17-4.9,1.17c-7.01-12.14-19.15-9.81-19.15-9.81c6.01-0.23,4.58,3.04,4.58,3.04
                        c-1.72-2.1-9.49-1.17-9.49-1.17s-2.57-4.9-7.24-5.4c-4.67-0.49-7.13,4.23-7.13,4.23s-1.51-3.74-6.29-4.2
                        c-4.79-0.47-8.08,5.6-8.08,5.6c-2.83-3.97-8.38-3.04-14.92,0c-6.54,3.04-9.81,11.21-11.91,15.18s-2.1,4.2-7.01,7.71
                        c-4.9,3.5-8.87,12.14-8.87,12.14s-1.4,0.47-3.04,5.84c-1.63,5.37,3.74,8.17,3.74,8.17c-11.44,3.97-7.24,9.34-7.24,9.34
                        s1.87-2.34,3.74-1.87c1.87,0.47-0.47,5.37-0.47,5.37c-2.34-3.5-4.44,0-4.44,0c2.34,0.7,1.17,5.37-0.47,6.33
                        c-1.63,0.96,0.47,6.78,0.47,6.78c-2.34-0.43-3.27-4.94-3.27-4.94s-2.34,5.14,0.23,7.47c2.57,2.34,8.87,3.62,8.87,3.62
                        s-2.57,1.28-3.74,6.3c-0.82,3.51,1.33,5.73,2.7,6.75c-0.33-0.57-0.65-1.61-0.83-3.46c-0.47-4.77,2.1-5.44,2.1-5.44
                        s0,2.51,1.17,8.14c1.17,5.64,7.24,7.2,7.24,7.2c0.47-3.04,6.77-3.5,6.77-3.5s-4.44,5.6-5.37,10.27c-0.93,4.67-2.57,6.54-7.24,8.87
                        c-4.67,2.33-3.04,7.94-7.24,10.97c-4.2,3.04-10.51,4.9-18.45,8.74c-7.94,3.84-11.48,9.23-11.48,9.23s-16.3,14.87-16.3,36.04
                        L315.99,195.15z M352.5,64.55c0,0-5.6-9-4.2-9s1.4,0,1.4,0s3.68,3.7,4.03,5.3C354.07,62.45,352.5,64.55,352.5,64.55z
                        M260.04,60.87c1.05-0.35,2.66,3.33,5.1,3.68c0,0-6.01-3.85-2.66-3.15c3.35,0.7,6.67,0.54,8.07,1.93c1.4,1.39,0.7,2.97-0.88,2.8
                        c-1.58-0.18-3.15,0.03-4.12,0.01c-0.96-0.01-2.01-0.89-2.01-0.89C261.79,63.85,258.99,61.22,260.04,60.87z M269.67,89.99
                        c-2.28,1.17-3.64,4.68-4.54,3.63c-0.89-1.05,0.68-1.4-0.89-1.05c-1.58,0.35-2.45,4.08-2.98,3.44c-0.53-0.64,0.15-3.96,2.27-5.02
                        c0,0-0.34-1.4-2.27-0.18c-1.6,1.02,1.93-2.33,4.29-1.95C267.92,89.24,271.94,88.82,269.67,89.99z M269.67,87.45
                        c-0.35-1.36,2.1-2.26-2.45-1.98c-4.55,0.27-8.93,1.02-9.98-3.38c-1.05-4.4,2.98-6.5,3.33-7.55c0.35-1.05,0.07-2.8-0.75-2.63
                        c-0.82,0.18,4.96-3.15,2.16-2.45c-2.8,0.7-6.65,1.93-6.65,1.93c1.23-1.23,3.5-3.5,6.3-3.5s5.64,0.7,3.52,1.75
                        c-2.12,1.05-3.35,1.05-2.29,1.75c1.05,0.7,4.38-1.4,4.2,0c-0.18,1.4-3.33,2.45-4.2,4.03c-0.88,1.58-0.66,5.47,2.29,6.67
                        c2.96,1.21,4.64,1.18,6.16,3.38C272.82,87.67,270.02,88.8,269.67,87.45z M282.27,80.84c3.33-2.03,0.7,0,0.7,0s-3.97,3.5-7.07,3.68
                        c-3.09,0.18-4.16-2.81-4.16-2.81s1.65,2.28,4.16,1.58c0,0-2.05-1.98-0.1-1.58C277.55,82.06,278.94,82.86,282.27,80.84z
                        M280.53,74.79c0,0-0.75,2.91-2.47,3.16c0,0,2.98,0.25,3.43-1.15c0,0-0.41,3.83-5.67,2.35c-5.26-1.48-5.47-4.45-5.47-4.45
                        s0.38,1.12,2.48,1.7l-1.05-3.02c-0.02-0.05-0.03-0.08-0.03-0.08l0.03,0.08c0.17,0.47,1.2,2.9,4.03,2.9
                        C278.95,76.28,280.53,74.79,280.53,74.79z M285.08,58.95c-1.58,1.23-6.98,4.55-9.27,4.38c-2.29-0.18-5.44-0.53-6.14-6.3
                        c0,0,1.23,2.63,2.28,3.15c1.05,0.53,1.95,2.8,3.86,1.75c1.91-1.05-0.71-1.23-0.71-1.23c-0.88-1.23-0.7-2.1,1.93-3.15
                        c2.63-1.05,5.25-3.15,6.83-3.85c1.58-0.7,3.5-1.23,3.85,0C288.06,54.92,286.65,57.72,285.08,58.95z M300.93,42.31
                        c-0.79,0.88-1.03,1.4-2.4,1.4c-1.37,0-1.37-0.53-3.3-0.7c-1.93-0.18-4.14-1.93-5.22-1.05c-1.08,0.88-2.48,1.4-1.43,2.8
                        c1.05,1.4,3.85-0.86,3.68,1.15c-0.18,2.01-0.64,3.41-2.25,4.28c-1.61,0.88-4.76,1.93-6.16,2.1c-1.4,0.18-3.49-1.05-2.71-1.93
                        c0.78-0.88,2.71-3.15,2.71-3.15s-2.28,1.05-3.5,1.05s-1.05-0.86-0.7-2.36c0.35-1.5,1.93-2.72,4.2-2.9
                        c2.28-0.18,4.87-0.18,5.43-2.45c0,0-7.53,3.15-11.21-1.4c-0.47-0.58,6.48,0.7,8.41,0c1.93-0.7,6.26-1.52,6.37-1.11
                        c0.11,0.41-1.97,3.73,0.82,2.51s4.13-3.84,4.86-2.62c0.73,1.22,0.56,1,2.4,1.29C302.77,39.51,301.72,41.44,300.93,42.31z
                        M311.44,32.33c-1.66,0.35-0.09,2.1-0.02,2.42c0.07,0.32-1.99,0.21-3.22,0.98c-1.23,0.77-0.35,1.16-0.97,2.21
                        c-0.62,1.05-4.81-5.25-8.49-6.3c-3.68-1.05-4.9,2.63-8.58,4.09c-3.68,1.47-6.3-1.29-6.3-1.29c0.88,0.7,3.85-0.53,3.85-0.53
                        s-1.4-1.93-1.23-2.28s2.98,1.4,2.98,1.4s0.18-1.4,1.58-1.93c1.4-0.53,2.64-1.75,2.64-1.75c-1.39-0.53-3.87-6.48-1.76-3.94
                        c2.1,2.54,3.85,0.13,3.85,0.13s0.18-1.44-2.09-3.89c-2.26-2.45-0.82-5.43-0.82-5.43s0.63,2.98,3.26,4.9s4.82,4.29,4.82,4.29
                        c1.11-6.66,6.29-9.19,6.29-9.19s-3.06,3.5-4.11,8.41c-1.05,4.9,4.9,5.43,6.38,5.43s-0.08-1.05,0.8-1.75
                        c0.88-0.7,2.28,1.4,2.74,2.1C313.5,31.1,313.1,31.98,311.44,32.33z M313.03,23.75c-1.34-3.15-1.6-5.95-1.6-5.95s0.9,3.5,4.87,5.95
                        c3.97,2.45,7.05,4.55,4.81,9.28c0,0,0.46-2.45-1.24-3.68C318.18,28.13,314.37,26.9,313.03,23.75z M325.18,43.36
                        c-2.28,2.28-2.28,2.28-2.28,2.28s0.11,0.88-1.79-0.53c0,0-1.47-1.05-1.24-4.55c0.23-3.5,0.97-4.55,2-6.65
                        c1.03-2.1,3.48-3.33,3.48-3.33s2.4-1.75,5.41-4.03c3-2.28,4.77,0,4.77,0s-6.55,3.15-7.84,7.71c0,0,2.87-1.58,3.07-0.35
                        c0.2,1.23-1.96,1.75-3.07,2.8c-1.11,1.05-3.56,3.85-4.44,4.03c-0.88,0.18-1.38-2.1-1.38-2.1s-1.06,4.55-0.28,4.9
                        c0.78,0.35,5.69-5.43,8.31-5.78c2.63-0.35-0.57,1.05,0.85,1.4c1.42,0.35,3.06-0.7,5.75,1.05c2.68,1.75,2.97,3.15,7.12,2.1
                        c4.15-1.05,6.42-5.95,6.77-4.2c0.35,1.75-3.37,9.11-11.76,5.95S327.46,41.08,325.18,43.36z M341.99,52.96
                        c-1.93-1.19-4.87-2.42-6.46-4.69c-1.59-2.28-0.39-3.3-0.39-3.3s0.51,3.47,3.51,4.7l-2.13-3.76c0,0,2.86,2.88,4.26,3.23
                        c1.4,0.35-1.05,1.23,0.88,1.75c1.93,0.53,4.38,1.33,5.95,2.07c1.58,0.74-3.97,0-3.97,0s3.14,2.88,4.25,5.4
                        c1.12,2.52,1.82,7.79,1.82,7.79S343.92,54.15,341.99,52.96z M359.64,76.4c-0.39-1.46-4.69-0.29-3.99,0
                        c0.7,0.29,1.93,1.28,3.33,2.76c1.4,1.48,0.35,1.15-1.05,1.33c-1.4,0.18-1.58-1.23-2.98-2.53c-1.4-1.3-0.35,2.18-1.18,4.13
                        c-0.82,1.95-0.93-4.4-1.74-7.37c-0.82-2.98-0.06,6.65-1.11,6.13c-1.05-0.53-0.35-3.5-0.88-2.88c-0.53,0.62-1.05,5.33-1.05,5.33
                        s-1.05-1.93-0.93-3.79c0.13-1.86,2.15-2.87,2.35-4.09c0.2-1.23-0.02-1.58,1.2-2.1s-0.7-4.03-0.7-4.03c3.15-0.7,4.03,3.5,4.96,4.03
                        c0.93,0.53,2.92,0.18,4.5,1.4c1.58,1.23,3.33,3.25,3.33,3.25S360.03,77.86,359.64,76.4z"/>
                </g>
                <path id="XMLID_69_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M318.68,86.88
                    c0,0,0.04,0.02,0.1,0.05c0.06,0.03,0.15,0.08,0.24,0.14c0.09,0.06,0.2,0.13,0.3,0.2c0.05,0.04,0.1,0.07,0.16,0.11
                    c0.05,0.04,0.1,0.08,0.16,0.11c0.05,0.04,0.1,0.07,0.15,0.11c0.05,0.03,0.1,0.07,0.15,0.1c0.05,0.03,0.1,0.06,0.14,0.09
                    c0.05,0.02,0.09,0.05,0.14,0.08c0.04,0.02,0.09,0.04,0.13,0.06c0.04,0.02,0.08,0.03,0.11,0.04c0.06,0.02,0.11,0.04,0.11,0.04
                    s-0.03,0.03-0.09,0.07c-0.03,0.02-0.07,0.05-0.12,0.07c-0.05,0.02-0.11,0.03-0.17,0.05c-0.06,0.01-0.13,0.01-0.21,0.01
                    c-0.07,0-0.15-0.02-0.23-0.04c-0.04-0.01-0.08-0.02-0.11-0.04c-0.04-0.01-0.08-0.03-0.11-0.05c-0.07-0.04-0.15-0.08-0.21-0.13
                    c-0.07-0.05-0.13-0.1-0.18-0.16c-0.06-0.05-0.11-0.11-0.15-0.17c-0.05-0.06-0.08-0.12-0.12-0.18c-0.03-0.06-0.06-0.12-0.08-0.17
                    c-0.04-0.11-0.07-0.21-0.08-0.28c-0.01-0.03-0.01-0.07-0.01-0.09C318.68,86.89,318.68,86.88,318.68,86.88z"/>
                <path id="XMLID_68_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M321.6,108.56
                    c0,0,0.02,0.01,0.05,0.02c0.03,0.01,0.08,0.03,0.14,0.05c0.06,0.02,0.13,0.04,0.21,0.07c0.08,0.03,0.17,0.06,0.27,0.1
                    c0.19,0.07,0.42,0.15,0.66,0.24c0.12,0.04,0.24,0.09,0.37,0.13c0.13,0.04,0.25,0.09,0.38,0.13c0.13,0.04,0.25,0.08,0.38,0.12
                    c0.06,0.02,0.13,0.04,0.19,0.06c0.06,0.02,0.12,0.03,0.18,0.05c0.06,0.02,0.12,0.03,0.18,0.05c0.06,0.01,0.12,0.03,0.17,0.04
                    c0.11,0.03,0.22,0.06,0.32,0.08c0.1,0.02,0.19,0.04,0.28,0.06c0.08,0.01,0.16,0.03,0.22,0.04c0.12,0.03,0.2,0.05,0.2,0.05
                    s-0.07,0.03-0.19,0.07c-0.06,0.02-0.14,0.04-0.22,0.07c-0.09,0.02-0.19,0.03-0.3,0.05c-0.11,0.02-0.23,0.02-0.35,0.02
                    c-0.06,0-0.13,0-0.19,0c-0.07,0-0.13-0.01-0.2-0.02c-0.07-0.01-0.14-0.02-0.21-0.02c-0.07-0.01-0.14-0.03-0.21-0.04
                    c-0.14-0.03-0.28-0.07-0.42-0.11c-0.14-0.04-0.27-0.1-0.41-0.16c-0.07-0.03-0.13-0.06-0.19-0.09c-0.06-0.03-0.13-0.06-0.18-0.1
                    c-0.12-0.07-0.23-0.13-0.33-0.21c-0.1-0.07-0.2-0.14-0.28-0.21c-0.17-0.14-0.29-0.27-0.38-0.37
                    C321.64,108.62,321.6,108.56,321.6,108.56z"/>
                <path id="XMLID_67_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M308.02,108.56
                    c0,0-0.12,0.08-0.34,0.21c-0.11,0.07-0.24,0.15-0.39,0.24c-0.15,0.09-0.32,0.2-0.51,0.31c-0.75,0.45-1.77,1.03-2.81,1.55
                    c-0.26,0.13-0.52,0.26-0.78,0.38c-0.26,0.12-0.52,0.24-0.77,0.35c-0.25,0.11-0.5,0.21-0.73,0.3c-0.12,0.05-0.23,0.09-0.34,0.13
                    c-0.11,0.04-0.22,0.08-0.33,0.11c-0.21,0.08-0.4,0.13-0.58,0.18c-0.17,0.05-0.33,0.08-0.45,0.11c-0.13,0.03-0.23,0.04-0.3,0.05
                    c-0.07,0.01-0.11,0.01-0.11,0.01s0.03-0.02,0.1-0.05c0.06-0.03,0.15-0.07,0.27-0.12c0.11-0.06,0.25-0.13,0.41-0.21
                    c0.16-0.08,0.33-0.18,0.52-0.28c0.38-0.21,0.83-0.45,1.31-0.71c0.24-0.13,0.49-0.26,0.74-0.39c0.25-0.13,0.51-0.26,0.77-0.4
                    c0.26-0.13,0.52-0.26,0.78-0.38c0.26-0.13,0.51-0.25,0.76-0.36c0.25-0.11,0.49-0.22,0.72-0.32c0.23-0.1,0.45-0.19,0.66-0.27
                    c0.41-0.16,0.76-0.28,1.01-0.36C307.88,108.6,308.02,108.56,308.02,108.56z"/>
                <path id="XMLID_66_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M317.37,132.61
                    c0,0,0.02,0.01,0.05,0.02c0.03,0.01,0.07,0.03,0.13,0.05c0.06,0.02,0.12,0.05,0.2,0.08c0.08,0.03,0.16,0.06,0.25,0.09
                    c0.37,0.13,0.86,0.28,1.34,0.33c0.12,0.02,0.24,0.02,0.35,0.02c0.06,0,0.11,0,0.17-0.01c0.06-0.01,0.11-0.01,0.16-0.02
                    c0.11-0.02,0.21-0.04,0.3-0.07c0.1-0.03,0.18-0.07,0.27-0.11c0.08-0.04,0.15-0.09,0.22-0.13c0.03-0.02,0.06-0.05,0.09-0.07
                    c0.03-0.02,0.05-0.04,0.07-0.06c0.05-0.04,0.08-0.07,0.1-0.09c0.02-0.02,0.04-0.03,0.04-0.03s0,0.02-0.01,0.05
                    c-0.01,0.03-0.01,0.08-0.04,0.14c-0.01,0.03-0.02,0.06-0.03,0.1c-0.01,0.04-0.03,0.07-0.05,0.11c-0.04,0.08-0.1,0.16-0.17,0.25
                    c-0.08,0.08-0.17,0.17-0.28,0.24c-0.11,0.07-0.24,0.14-0.38,0.18c-0.27,0.09-0.58,0.11-0.88,0.08c-0.15-0.02-0.29-0.04-0.43-0.09
                    c-0.14-0.04-0.27-0.09-0.39-0.15c-0.12-0.06-0.24-0.12-0.34-0.19c-0.1-0.07-0.2-0.14-0.28-0.2c-0.16-0.14-0.28-0.27-0.35-0.37
                    c-0.04-0.05-0.06-0.09-0.08-0.12C317.38,132.62,317.37,132.61,317.37,132.61z"/>
                <path id="XMLID_65_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M277.86,130.74
                    c0,0,0.13,0.17,0.35,0.49c0.1,0.16,0.23,0.35,0.37,0.58c0.14,0.23,0.29,0.48,0.45,0.77c0.16,0.29,0.33,0.6,0.49,0.93
                    c0.17,0.33,0.34,0.69,0.5,1.06c0.16,0.37,0.33,0.76,0.48,1.17c0.15,0.4,0.29,0.82,0.43,1.23c0.06,0.21,0.12,0.42,0.19,0.63
                    c0.06,0.21,0.11,0.42,0.16,0.63c0.1,0.42,0.19,0.84,0.25,1.24c0.07,0.41,0.11,0.8,0.15,1.18c0.04,0.37,0.05,0.73,0.05,1.06
                    c0,0.16,0,0.32-0.01,0.47c-0.01,0.15-0.02,0.29-0.03,0.42c-0.02,0.27-0.06,0.5-0.08,0.68c-0.03,0.19-0.06,0.33-0.08,0.43
                    c-0.02,0.1-0.03,0.15-0.03,0.15s0-0.22,0-0.6c0-0.09-0.01-0.2-0.01-0.31c0-0.11,0-0.24-0.02-0.37c-0.01-0.13-0.02-0.27-0.03-0.42
                    c-0.01-0.15-0.03-0.3-0.05-0.46c-0.01-0.16-0.04-0.32-0.06-0.5c-0.02-0.17-0.05-0.35-0.08-0.53c-0.03-0.18-0.07-0.37-0.1-0.56
                    c-0.04-0.19-0.08-0.38-0.12-0.58c-0.08-0.39-0.19-0.79-0.29-1.2c-0.05-0.2-0.12-0.41-0.17-0.61c-0.06-0.2-0.12-0.41-0.19-0.61
                    c-0.13-0.41-0.26-0.81-0.4-1.21c-0.14-0.4-0.28-0.79-0.42-1.16c-0.14-0.38-0.28-0.74-0.42-1.08c-0.14-0.34-0.27-0.67-0.4-0.96
                    c-0.25-0.6-0.47-1.09-0.62-1.44C277.94,130.94,277.86,130.74,277.86,130.74z"/>
                <path id="XMLID_64_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M333.44,168.92
                    c0,0,0.16,0.17,0.42,0.47c0.07,0.07,0.15,0.14,0.24,0.23c0.09,0.08,0.17,0.17,0.28,0.26c0.11,0.09,0.22,0.18,0.34,0.27
                    c0.13,0.09,0.26,0.18,0.39,0.27c0.14,0.09,0.3,0.17,0.45,0.26c0.16,0.07,0.33,0.16,0.5,0.24c0.09,0.03,0.18,0.07,0.27,0.1
                    c0.05,0.02,0.09,0.04,0.14,0.05c0.05,0.01,0.09,0.03,0.14,0.04c0.09,0.03,0.19,0.06,0.29,0.09c0.1,0.03,0.2,0.05,0.3,0.07
                    c0.2,0.05,0.41,0.08,0.62,0.13c0.11,0.01,0.21,0.03,0.32,0.04c0.11,0.02,0.21,0.03,0.32,0.04c0.43,0.04,0.88,0.06,1.33,0.05
                    c0.11,0,0.22,0,0.34,0l0.17,0l0.08,0l0.1,0c0.06,0,0.11,0,0.18,0c0.07,0,0.13,0.01,0.19,0.01l0.18,0.03
                    c0.06,0.01,0.12,0.03,0.18,0.04c0.48,0.11,0.92,0.34,1.28,0.62c0.37,0.28,0.66,0.61,0.9,0.94c0.24,0.33,0.41,0.68,0.54,1.01
                    c0.13,0.33,0.22,0.64,0.27,0.92c0.06,0.28,0.07,0.53,0.08,0.73c0.01,0.2,0,0.36-0.01,0.47c-0.01,0.11-0.01,0.16-0.01,0.16
                    s-0.01-0.06-0.02-0.16c-0.01-0.11-0.04-0.26-0.08-0.46c-0.04-0.19-0.1-0.43-0.18-0.69c-0.09-0.26-0.2-0.54-0.36-0.84
                    c-0.08-0.15-0.16-0.3-0.26-0.44c-0.1-0.15-0.2-0.3-0.32-0.44c-0.24-0.29-0.52-0.56-0.85-0.79c-0.33-0.23-0.7-0.4-1.1-0.48
                    l-0.15-0.03l-0.15-0.02l-0.14-0.01c-0.05,0-0.11,0-0.16,0l-0.08,0l-0.09,0l-0.17,0.01c-0.12,0.01-0.23,0.01-0.35,0.01
                    c-0.47,0-0.93-0.01-1.39-0.07c-0.23-0.02-0.46-0.07-0.68-0.1c-0.22-0.05-0.44-0.09-0.65-0.16c-0.11-0.03-0.21-0.06-0.31-0.1
                    c-0.1-0.04-0.2-0.08-0.3-0.12c-0.05-0.02-0.1-0.04-0.15-0.06c-0.05-0.02-0.1-0.05-0.14-0.07c-0.09-0.04-0.19-0.09-0.28-0.13
                    c-0.18-0.09-0.35-0.2-0.51-0.29c-0.16-0.11-0.31-0.22-0.45-0.32c-0.13-0.12-0.26-0.23-0.38-0.33c-0.11-0.11-0.22-0.23-0.31-0.33
                    c-0.1-0.1-0.18-0.21-0.25-0.31c-0.07-0.1-0.14-0.19-0.2-0.27c-0.11-0.17-0.19-0.31-0.24-0.4
                    C333.47,168.97,333.44,168.92,333.44,168.92z"/>
                <path id="XMLID_63_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M315.99,170.5
                    c0,0,0.18,0.08,0.48,0.24c0.16,0.07,0.34,0.16,0.55,0.25c0.11,0.05,0.22,0.09,0.34,0.13c0.06,0.02,0.12,0.04,0.19,0.07
                    c0.06,0.02,0.13,0.04,0.2,0.06c0.27,0.08,0.57,0.14,0.88,0.17c0.32,0.03,0.66,0.04,1.02,0.04c0.36,0,0.74,0,1.12,0.01
                    c0.19,0.01,0.39,0.02,0.58,0.04l0.15,0.01l0.07,0.01l0.04,0l0.03,0l0.02,0l0.14,0.02l0.07,0.01l0.08,0.02
                    c0.42,0.08,0.8,0.22,1.16,0.39c0.36,0.17,0.69,0.37,1,0.58c0.31,0.21,0.59,0.43,0.85,0.65c0.26,0.22,0.49,0.44,0.7,0.64
                    c0.42,0.41,0.73,0.79,0.94,1.05c0.21,0.27,0.32,0.43,0.32,0.43s-0.15-0.13-0.4-0.35c-0.26-0.22-0.63-0.53-1.08-0.88
                    c-0.23-0.18-0.48-0.36-0.74-0.55c-0.27-0.19-0.56-0.37-0.86-0.56c-0.3-0.18-0.62-0.35-0.96-0.5c-0.17-0.07-0.34-0.14-0.51-0.19
                    c-0.09-0.03-0.17-0.06-0.26-0.08c-0.09-0.03-0.17-0.04-0.26-0.06l-0.06-0.01l-0.07-0.01l-0.14-0.02l-0.02,0l0,0
                    c-0.01,0,0.01,0,0.01,0l-0.01,0l-0.04,0l-0.07-0.01l-0.14-0.01c-0.19-0.02-0.38-0.03-0.56-0.05c-0.38-0.02-0.75-0.04-1.11-0.06
                    c-0.36-0.02-0.73-0.06-1.07-0.12c-0.17-0.03-0.33-0.07-0.49-0.12c-0.15-0.06-0.3-0.1-0.44-0.17c-0.07-0.03-0.14-0.06-0.2-0.09
                    c-0.06-0.03-0.13-0.07-0.19-0.1c-0.12-0.06-0.23-0.13-0.33-0.19c-0.21-0.12-0.37-0.25-0.51-0.35c-0.14-0.1-0.24-0.19-0.31-0.25
                    C316.03,170.53,315.99,170.5,315.99,170.5z"/>
                <path id="XMLID_62_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M321.8,79.14
                    c0,0,0.03,0.1,0.08,0.27c0.03,0.09,0.05,0.19,0.07,0.31c0.01,0.06,0.02,0.13,0.04,0.2c0.01,0.07,0.02,0.14,0.03,0.22
                    c0.02,0.15,0.03,0.32,0.03,0.5c0.01,0.18,0,0.37-0.01,0.56c-0.02,0.19-0.03,0.39-0.08,0.59c-0.03,0.2-0.09,0.4-0.14,0.6
                    c-0.07,0.2-0.12,0.4-0.2,0.58c-0.07,0.19-0.17,0.37-0.25,0.55c-0.09,0.17-0.18,0.34-0.28,0.48c-0.1,0.15-0.19,0.29-0.29,0.41
                    c-0.05,0.06-0.09,0.12-0.14,0.17c-0.05,0.05-0.09,0.1-0.14,0.15c-0.08,0.09-0.16,0.17-0.23,0.23c-0.13,0.12-0.21,0.19-0.21,0.19
                    s0.05-0.09,0.15-0.23c0.05-0.07,0.11-0.16,0.17-0.27c0.07-0.1,0.14-0.22,0.21-0.35c0.08-0.13,0.15-0.27,0.23-0.42
                    c0.08-0.15,0.15-0.32,0.23-0.48c0.07-0.17,0.14-0.35,0.21-0.53c0.07-0.18,0.12-0.37,0.18-0.56c0.05-0.19,0.11-0.38,0.14-0.57
                    c0.04-0.19,0.07-0.38,0.11-0.56c0.02-0.18,0.05-0.36,0.06-0.53c0.01-0.17,0.03-0.33,0.03-0.48c0.01-0.15,0.01-0.29,0.01-0.41
                    c0-0.12,0-0.23,0-0.32C321.8,79.24,321.8,79.14,321.8,79.14z"/>
                <path id="XMLID_61_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M326.45,79.5
                    c0,0,0.01,0.11,0.03,0.29c0.02,0.19,0.04,0.45,0.07,0.77c0.02,0.16,0.03,0.33,0.05,0.51c0.02,0.18,0.05,0.37,0.07,0.57
                    c0.05,0.39,0.11,0.81,0.18,1.23c0.04,0.21,0.07,0.42,0.11,0.62c0.04,0.2,0.08,0.4,0.13,0.6c0.09,0.39,0.18,0.75,0.26,1.05
                    c0.08,0.31,0.15,0.57,0.21,0.75c0.05,0.18,0.08,0.29,0.08,0.29s-0.06-0.09-0.15-0.26c-0.05-0.08-0.1-0.18-0.16-0.3
                    c-0.06-0.12-0.12-0.25-0.18-0.4c-0.07-0.15-0.13-0.31-0.19-0.49c-0.06-0.17-0.13-0.36-0.18-0.55c-0.05-0.2-0.12-0.4-0.16-0.6
                    c-0.05-0.21-0.09-0.42-0.13-0.63c-0.03-0.21-0.07-0.43-0.09-0.64c-0.03-0.21-0.04-0.42-0.05-0.62c-0.02-0.4-0.02-0.78,0-1.11
                    c0.01-0.16,0.02-0.31,0.03-0.44c0.01-0.13,0.03-0.24,0.04-0.34C326.43,79.61,326.45,79.5,326.45,79.5z"/>
            </g>
            <rect x="386.47" y="3.16" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" width="191.98" height="191.98"/>
            <path id="XMLID_257_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M495.78,189.75
                c-0.37-2-30.97-38.88-32.5-52.53c-1.53-13.64-2.95-24.71-3.52-29.16c-0.57-4.45-0.99-5.67-6.91-8.91
                c-5.92-3.24-9.14-17.73-7.35-22.76c1.8-5.02-0.97-14.68,1.8-18.26c2.77-3.58,24.07-28.39,26.97-34.1s11.26-10.84,14.92-10.84
                c3.66,0,15.23,4.04,22.42,4.59c7.19,0.55,19.71-0.59,22.58,5.97c2.87,6.56,7.14,5.96,10,11.96s2.24,9.43,3.69,15.42
                c1.45,5.99-0.84,12.94,1.45,16.74c2.28,3.8,8.94,6.5,7.46,11.28c-1.48,4.78-7.44,7.08-11.02,7.93c-3.58,0.84-4.45,1.74-5.7,7.93
                c-1.26,6.19-3.51,11.11-6.52,14.29c-3.01,3.18-4.49,15.67-5.04,18.2c-0.55,2.53-9.18,4.6-7.44,6.76c1.74,2.16,5.48,1.07,6.46,4.89
                c0.98,3.82,9.16,12.16,12.54,17.98c3.38,5.83,14.63,15.78,16.93,17.63c2.31,1.86,10.63,20.37,10.63,20.37h-69.68L495.78,189.75z"/>
            <g id="XMLID_122_">
                <path id="XMLID_253_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M578.46,195.15
                    c0,0-13.13-22.55-15.94-25.53c-2.8-2.98-4.2-0.35-7.18-1.4c-2.98-1.05-7.71-10.33-12.08-15.24c-4.38-4.9-13.45-9.87-12.16-10.34
                    c1.28-0.47,8.45-5.02,8.45-5.02s-0.63-0.58-2.5-2.22c-1.87-1.63-10.16-2.45-10.97-2.57c-0.82-0.12,0.23-1.63,1.87-2.8
                    c1.63-1.17,4.44-10.39,5.72-14.94c1.28-4.55,4.79-10.04,5.14-13.89c0.35-3.85,0-9.57,0-11.68c0-2.1,0.35-3.15,0.7-4.09
                    c0.35-0.93-1.28-1.52-3.5-2.69s-9.46-0.58-10.27,0c-0.82,0.58-2.8,1.52-4.9,1.52c-2.1,0-2.13,0.81-3.04,3.15
                    c-0.35,0.9-1.4,0.16-2.02,0.47c-0.62,0.31,0.7,1.09,1.63,1.87c0.93,0.78,0,8.87,0.16,10.35c0.16,1.48,1.01,1.01,1.63,2.02
                    c0.62,1.01,0.23,4.75-0.62,5.37c-0.86,0.62-2.1,1.63-2.72,2.26c-0.62,0.62-2.1,1.01-2.57,1.71s4.75,2.02,5.76,2.49
                    c1.01,0.47,4.05-0.39,4.9-1.71c0.86-1.32,0.08-2.88-0.62-3.97c-0.7-1.09-1.63-3.97-1.71-5.76c-0.08-1.79-1.32-2.26-1.63-3.42
                    s0.54-1.25,1.63-0.95c1.09,0.29-0.16-2.86,0-3.87c0.16-1.01-0.16-1.17,1.32-0.86c1.48,0.31,2.26-0.16,1.71-0.16
                    c-0.54,0-3.19-1.71-2.41-2.65c0.78-0.93,2.1-1.01,2.1-1.01s0.08,2.88,3.04,2.88c2.96,0,2.65-2.1,2.96-2.26
                    c0.31-0.16,1.17,0.62,0.86,1.63c-0.31,1.01-3.19,1.87-3.19,1.87s1.25,0.31,2.18-0.16c0.93-0.47,1.4-0.23,0.62,0.7
                    c-0.78,0.93-0.47,1.17-0.31,1.87c0.16,0.7-0.86,1.95-0.86,2.72c0,0.78,2.18-0.78,2.72-1.09c0.54-0.31,1.17-2.26,1.87-3.66
                    s1.17-0.85,1.35,2.48c0.18,3.33,0,3.68-2.8,7.88c-2.8,4.2-4.2,9.46-4.9,11.73c-0.7,2.28-1.4,7.71-1.49,11.21
                    c-0.09,3.5-4.81,3.33-7.44,3.5c-2.63,0.18-2.63,2.1-2.1,4.2c0.53,2.1,0,1.75-2.28,2.63c-2.28,0.88-2.8-2.28-3.33-3.15
                    c-0.53-0.88-1.26,1-2.8,2.63c-0.76,0.81-3.06,2.33-6.04,1.39c-2.98-0.94-6.39-3.84-8.67-6.12c-2.28-2.28-5.78-1.05-7.53-1.93
                    c-1.75-0.88-12.08-12.26-11.91-11.38c0.18,0.88,10.68,12.08,11.56,13.48c0.88,1.4,7.88,4.55,9.28,4.55s4.73,2.63,7,3.68
                    c2.28,1.05,4.38,2.28,5.78,4.2c1.4,1.93-3.15,2.98-3.15,2.98s5.25,0.35,7,0.18c1.75-0.18,3.5-3.33,4.38-4.03
                    c0.88-0.7,6.3-3.5,7.71-4.38c1.4-0.88,2.8,1.23,1.05,1.93c-1.75,0.7-1.93,2.98,0.18,4.03c2.1,1.05,6.13,3.5,7.71,7
                    s-2.1,2.28,0,5.43c2.1,3.15,8.06,2.28,10.68,4.73c2.63,2.45,4.55,9.46,6.48,13.48c1.93,4.03,6.13,4.2,7.71,8.93
                    c1.58,4.73,0.7,9.77,0.7,9.77H578.46z M525.65,89.99c-0.31,0-0.56-0.25-0.56-0.56c0-0.31,0.25-0.56,0.56-0.56s0.56,0.25,0.56,0.56
                    C526.21,89.74,525.96,89.99,525.65,89.99z M534.75,87.97c-0.31,0.7-0.93,1.71-1.71,1.4c-0.78-0.31-3.82-1.61-5.92-1.79
                    c-1.56-0.13-2-1.09-0.77-1.25c1.23-0.16,4.19-0.23,4.74-0.54c0.54-0.31,2.02-0.54,3.27,0c1.25,0.54,1.25,0.54,1.25,0.54
                    S535.06,87.27,534.75,87.97z"/>
                <path id="XMLID_250_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M508.91,82.99
                    c-3.15,0-3.04-0.82-7.01-2.8s-4.79-0.58-6.3,0c-1.52,0.58-1.87,0.35-4.79,0.93c-2.92,0.58-6.3,2.45-5.95,3.04
                    c0.35,0.58,2.08,1.79,3.04,0.82c2.49-2.53,6.07-2.76,7.55-2.45c1.48,0.31-2.72,1.71-2.1,1.87c0.62,0.16,2.96-1.09,4.11-1.4
                    c1.15-0.31,5.39,2.02,2.2,1.87c-3.19-0.16-5.68,0.62-6.85,1.01c-1.17,0.39-1.87,0.62-3.35,0.08c-1.48-0.54-1.32,0.47-1.01,0.94
                    c0.31,0.47,1.87,0.39,1.01,1.13c-0.86,0.74-1.48-0.27-2.33,0.35c-0.86,0.62,1.79,0.62,1.71,1.07c-0.08,0.45,1.09,0.49,2.02,0
                    c0.93-0.49,2.33-0.91,2.33-0.91c1.87,2.65,3.58,2.65,3.58,2.65c-1.25-0.47-2.26-2.33-2.18-3.15c0.08-0.82,1.73-0.56,1.73-0.56
                    s0.53,2.94,3.41,2.94c2.88,0,3.27-2.94,3.27-2.94s1.48,0.99,1.17,1.98c-0.31,0.99-3.5,1.58-3.5,1.58s0.78,0.39,0.86,1.17
                    c0.08,0.78-3.46,0.86-3.5,1.56c-0.04,0.7,4.36,1.28,5.41,1.28c1.05,0,3.39-2.69,3.62-3.74c0.23-1.05,0-2.45,0.82-2.41
                    c0.82,0.04,2.1-0.51,3.04-1.42c0.93-0.91,2.69-3.99,2.69-5.37C513.58,80.71,512.06,82.99,508.91,82.99z M498.01,88.01
                    c-0.31,0-0.56-0.25-0.56-0.56c0-0.31,0.25-0.56,0.56-0.56c0.31,0,0.56,0.25,0.56,0.56C498.58,87.76,498.32,88.01,498.01,88.01z"/>
                <path id="XMLID_249_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M503.42,107.04
                    c1.17,0.7,1.75,0.93,2.33,0.7c0.58-0.23,0,0.12,0.58-0.23c0.58-0.35,2.45-0.64,3.74,0.32c1.28,0.96,3.15,2.01,2.22,2.36
                    c-0.93,0.35-1.87,0.23-2.92,0s-2.33,0.58-4.32-0.7C503.07,108.21,503.42,107.04,503.42,107.04z"/>
                <path id="XMLID_248_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M505.02,101.9
                    c0,0-4.4,3.5-6.44,4.75c-2.04,1.25,1.32,0.47,3.22-0.31S505.02,101.9,505.02,101.9z"/>
                <path id="XMLID_247_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M523.7,111.01
                    c0,0,1.32,0.99,2.51,3.74c1.2,2.75,1.3,6.54,1.3,6.54s0.12-1.01-1.3-1.48c-1.42-0.47-0.8-0.93-0.56-2.18
                    C525.88,116.38,524.17,111.01,523.7,111.01z"/>
                <path id="XMLID_246_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M512.1,112.8
                    c-0.5,1.16-0.08,1.48,0,2.26c0.08,0.78,0.12,1.79,0.45,1.32c0.33-0.47,0.18-1.25,0.49-2.1C513.35,113.42,512.5,111.88,512.1,112.8z
                    "/>
                <path id="XMLID_245_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M496.32,120.97
                    c-1.23-0.16-1.62-1.32,0-2.49c1.62-1.17,2.14-2.88,2.89-2.34c0.75,0.54,1.79,2.46,4.19,2.36c2.4-0.1,4.34-1.82,6.83-1.58
                    c2.49,0.23,2.65,1.87,4.44,1.01c1.79-0.86,2.65,0.47,4.13,0.93c1.48,0.47,4.59-0.54,4.36,0.31c-0.23,0.86-1.32,0.78-1.63,1.25
                    c-0.31,0.47,1.5,1.79,1.65,3.81c0.14,2.02,0.77,2.18-0.4,2.18c-1.17,0-1.87-0.54-2.34,0.39c-0.47,0.93-1.71,1.87-1.71,1.87
                    s-0.62-3.19-1.71-3.04c-1.09,0.16-3.11-0.16-3.42-0.47c-0.31-0.31-2.84-0.08-4.14,0.23c-1.31,0.31-6.29-0.93-4.89-1.48
                    c1.4-0.54,2.02-0.96,2.49-1.57s0.54-2.56,1.4-1.47c0.86,1.09,3.04,0.54,5.14,0.78c2.1,0.23,3.81-0.39,4.05-1.01
                    c0.23-0.62-0.16-1.32-0.16-1.32s-18.98-0.7-19.53,0C497.39,120.04,497.51,121.13,496.32,120.97z"/>
                <path id="XMLID_244_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M503.42,95.01
                    c0,0-0.03,0.03-0.08,0.09c-0.03,0.03-0.06,0.07-0.11,0.11c-0.04,0.04-0.09,0.09-0.15,0.14c-0.06,0.05-0.12,0.1-0.19,0.16
                    c-0.07,0.06-0.15,0.11-0.24,0.17c-0.09,0.06-0.18,0.12-0.28,0.18c-0.1,0.06-0.21,0.13-0.32,0.19c-0.12,0.06-0.24,0.12-0.36,0.19
                    c-0.13,0.06-0.26,0.11-0.4,0.18c-0.07,0.03-0.14,0.06-0.21,0.09c-0.07,0.03-0.15,0.05-0.22,0.08c-0.15,0.05-0.3,0.1-0.46,0.16
                    c-0.16,0.04-0.32,0.09-0.48,0.13c-0.08,0.02-0.16,0.04-0.25,0.07c-0.08,0.02-0.17,0.03-0.25,0.05c-0.34,0.06-0.68,0.14-1.02,0.17
                    c-0.17,0.02-0.35,0.04-0.52,0.06c-0.17,0.02-0.34,0.02-0.51,0.03c-0.34,0.01-0.68,0.03-1,0.01c-0.32-0.01-0.64-0.02-0.93-0.05
                    c-0.3-0.03-0.57-0.07-0.83-0.1c-0.13-0.02-0.25-0.03-0.37-0.05c-0.12-0.02-0.23-0.05-0.33-0.07c-0.21-0.04-0.38-0.08-0.53-0.11
                    c-0.29-0.07-0.46-0.11-0.46-0.11s0.17-0.01,0.47-0.01c0.15,0,0.33,0,0.54,0.01c0.1,0,0.21,0,0.33,0.01c0.12,0,0.24,0,0.37,0
                    c0.25,0,0.53-0.01,0.82,0c0.29,0,0.6-0.02,0.91-0.03c0.16-0.01,0.32,0,0.48-0.02c0.16-0.01,0.33-0.03,0.49-0.04
                    c0.16-0.01,0.33-0.02,0.5-0.04c0.17-0.02,0.33-0.04,0.5-0.06c0.33-0.03,0.66-0.09,0.99-0.14c0.16-0.02,0.32-0.06,0.48-0.09
                    c0.16-0.03,0.31-0.07,0.47-0.1c0.15-0.04,0.3-0.08,0.45-0.12c0.07-0.02,0.15-0.04,0.22-0.06c0.07-0.02,0.14-0.04,0.21-0.06
                    c0.14-0.04,0.27-0.08,0.4-0.12c0.13-0.05,0.25-0.09,0.37-0.13c0.12-0.04,0.23-0.09,0.34-0.13c0.11-0.04,0.21-0.08,0.3-0.13
                    c0.09-0.04,0.18-0.09,0.26-0.12c0.08-0.04,0.15-0.08,0.22-0.11c0.13-0.07,0.23-0.13,0.3-0.17
                    C503.39,95.04,503.42,95.01,503.42,95.01z"/>
                <path id="XMLID_243_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M521.05,97
                    c0,0,0.08,0.03,0.22,0.11c0.07,0.04,0.15,0.08,0.24,0.14c0.09,0.06,0.19,0.12,0.3,0.2c0.21,0.16,0.45,0.35,0.69,0.58
                    c0.06,0.06,0.12,0.12,0.18,0.18c0.06,0.06,0.12,0.12,0.18,0.19c0.12,0.13,0.23,0.26,0.34,0.4l0.16,0.21
                    c0.05,0.07,0.1,0.14,0.15,0.21c0.1,0.14,0.19,0.29,0.27,0.43c0.09,0.14,0.16,0.28,0.23,0.42c0.03,0.07,0.07,0.13,0.1,0.2
                    c0.03,0.07,0.05,0.13,0.08,0.19c0.05,0.12,0.09,0.24,0.13,0.34c0.03,0.1,0.05,0.2,0.07,0.27c0.04,0.15,0.05,0.24,0.05,0.24
                    s-0.06-0.07-0.16-0.19c-0.09-0.12-0.23-0.29-0.39-0.49c-0.08-0.1-0.16-0.21-0.25-0.33c-0.09-0.12-0.18-0.24-0.28-0.36
                    c-0.05-0.06-0.1-0.13-0.15-0.19c-0.05-0.06-0.1-0.13-0.15-0.19c-0.1-0.13-0.21-0.27-0.31-0.4c-0.1-0.13-0.21-0.26-0.31-0.4
                    c-0.1-0.13-0.21-0.26-0.31-0.38c-0.2-0.25-0.38-0.48-0.54-0.69c-0.16-0.21-0.29-0.38-0.38-0.5c-0.05-0.06-0.08-0.11-0.11-0.14
                    C521.06,97.02,521.05,97,521.05,97z"/>
                <path id="XMLID_242_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M526.36,104.6
                    c0,0,0.02,0.03,0.06,0.08c0.04,0.05,0.1,0.14,0.16,0.25c0.06,0.11,0.14,0.25,0.22,0.41c0.08,0.16,0.16,0.34,0.25,0.54
                    c0.17,0.4,0.35,0.87,0.54,1.37c0.09,0.25,0.19,0.51,0.29,0.77c0.1,0.26,0.2,0.53,0.31,0.79c0.1,0.26,0.21,0.52,0.33,0.77
                    c0.11,0.25,0.23,0.5,0.35,0.73c0.12,0.23,0.24,0.45,0.37,0.65c0.13,0.2,0.26,0.39,0.38,0.56c0.07,0.08,0.13,0.16,0.19,0.23
                    c0.06,0.07,0.13,0.14,0.18,0.2c0.12,0.12,0.23,0.23,0.33,0.3c0.09,0.08,0.17,0.14,0.23,0.18c0.05,0.04,0.08,0.06,0.08,0.06
                    s-0.03-0.01-0.1-0.03c-0.06-0.02-0.16-0.05-0.28-0.11c-0.06-0.03-0.12-0.06-0.19-0.1c-0.07-0.04-0.14-0.08-0.22-0.13
                    c-0.07-0.05-0.15-0.11-0.23-0.17c-0.08-0.06-0.16-0.13-0.24-0.21c-0.16-0.15-0.33-0.33-0.49-0.53c-0.16-0.2-0.32-0.42-0.46-0.65
                    c-0.14-0.24-0.29-0.49-0.41-0.75c-0.13-0.26-0.25-0.53-0.36-0.8c-0.22-0.54-0.4-1.1-0.55-1.62c-0.08-0.26-0.14-0.51-0.2-0.75
                    c-0.06-0.24-0.11-0.47-0.17-0.68c-0.05-0.21-0.1-0.4-0.14-0.57c-0.04-0.17-0.08-0.32-0.12-0.44c-0.04-0.12-0.06-0.22-0.09-0.28
                    C526.37,104.64,526.36,104.6,526.36,104.6z"/>
                <path id="XMLID_241_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M522.74,115.68
                    c0,0,0.04,0.05,0.1,0.13c0.03,0.04,0.07,0.09,0.1,0.15c0.04,0.06,0.08,0.13,0.12,0.2c0.09,0.15,0.18,0.33,0.26,0.53
                    c0.02,0.05,0.04,0.1,0.06,0.16c0.02,0.05,0.04,0.11,0.05,0.16c0.03,0.11,0.06,0.23,0.08,0.35c0.02,0.12,0.03,0.24,0.03,0.36
                    c0,0.06,0,0.12,0,0.18c0,0.06,0,0.11-0.01,0.17c-0.01,0.06-0.01,0.11-0.02,0.16c-0.01,0.05-0.02,0.1-0.03,0.15
                    c-0.02,0.1-0.05,0.19-0.08,0.27c-0.03,0.08-0.06,0.16-0.09,0.22c-0.03,0.07-0.06,0.12-0.08,0.17c-0.05,0.09-0.08,0.14-0.08,0.14
                    s0-0.06,0.01-0.16c0.01-0.05,0.01-0.11,0.02-0.18c0-0.07,0.02-0.15,0.02-0.23c0-0.08,0.01-0.17,0.02-0.27c0-0.05,0-0.1,0.01-0.14
                    c0-0.05,0-0.1,0-0.15c0-0.1-0.01-0.21-0.02-0.31c-0.01-0.11-0.02-0.21-0.04-0.32c-0.02-0.11-0.04-0.21-0.06-0.32
                    c-0.02-0.1-0.05-0.21-0.08-0.31c-0.06-0.2-0.11-0.39-0.15-0.55c-0.05-0.16-0.08-0.3-0.1-0.4
                    C522.75,115.74,522.74,115.68,522.74,115.68z"/>
                <path id="XMLID_240_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M469.68,108.73
                    c0.75,3.64,3,11.38,4.58,13.63s4.21,5.23,4.21,5.23s-5.6-4.04-7.53-8.41c-1.93-4.37-2.28-6.54-2.98-8.17
                    C467.26,109.38,468.99,105.35,469.68,108.73z"/>
                <path id="XMLID_239_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M480.92,130.74
                    c0,0,0.05,0.07,0.14,0.19c0.09,0.12,0.24,0.3,0.42,0.52c0.19,0.22,0.43,0.48,0.71,0.76c0.29,0.28,0.62,0.59,0.99,0.91
                    c0.38,0.31,0.81,0.63,1.27,0.95c0.47,0.3,0.99,0.61,1.51,0.95c0.53,0.34,1.07,0.68,1.61,1.04c0.54,0.36,1.09,0.73,1.64,1.11
                    c0.55,0.38,1.09,0.76,1.62,1.14c0.53,0.38,1.05,0.77,1.55,1.14c0.5,0.37,0.97,0.74,1.42,1.1c0.45,0.35,0.86,0.7,1.25,1.02
                    c0.19,0.16,0.37,0.32,0.54,0.47c0.17,0.15,0.33,0.3,0.48,0.43c0.3,0.27,0.55,0.52,0.76,0.73c0.05,0.05,0.1,0.1,0.15,0.15
                    c0.04,0.05,0.08,0.09,0.12,0.14c0.08,0.08,0.14,0.16,0.19,0.21c0.1,0.12,0.16,0.18,0.16,0.18s-0.06-0.05-0.18-0.15
                    c-0.12-0.1-0.29-0.25-0.52-0.43c-0.23-0.18-0.5-0.4-0.82-0.64c-0.32-0.25-0.69-0.51-1.09-0.81c-0.2-0.15-0.41-0.3-0.63-0.45
                    c-0.22-0.16-0.44-0.32-0.67-0.48c-0.46-0.33-0.95-0.67-1.46-1.03c-0.51-0.35-1.04-0.72-1.57-1.09c-0.53-0.37-1.08-0.75-1.63-1.12
                    c-0.54-0.38-1.09-0.75-1.63-1.12c-0.54-0.37-1.07-0.73-1.58-1.08c-0.51-0.35-1.01-0.68-1.48-1.02c-0.46-0.35-0.88-0.7-1.24-1.05
                    c-0.36-0.35-0.67-0.69-0.93-1c-0.26-0.31-0.47-0.6-0.63-0.84c-0.16-0.24-0.28-0.44-0.36-0.58
                    C480.95,130.82,480.92,130.74,480.92,130.74z"/>
                <path id="XMLID_238_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M478.46,150.01
                    c0,0,2.45,1.23,4.03,0s3.15-1.54,4.2,0.54s2.49,4.53,2.49,4.53s-2.46-3.25-3.53-3.02c-1.06,0.22-3.17-0.35-3.34,1.45
                    c-0.17,1.79,1.4,6.86,0.53,6.32C481.97,159.29,478.46,150.01,478.46,150.01z"/>
                <path id="XMLID_237_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M485.66,147.73
                    c2.26-0.53,4.01-2.1,5.77-2.98c1.75-0.88,4.35-2.1,6.03,0.7c0,0-2.75-1.04-2.2,0.27c0.55,1.31-0.67,2.18-1.55,2.01
                    c-0.88-0.18-1.84,1.75-2.15,2.28c-0.31,0.53-1.18-0.19-1.71-0.97c-0.53-0.78,0.07,2.02-0.66,2.37
                    C488.45,151.76,485.66,147.73,485.66,147.73z"/>
                <path id="XMLID_236_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M514.89,147.57
                    c0,0,6.94-2.2,8.81-4.74c0,0,5.31,2.98,4.79,3.84c-0.52,0.87-1.8,0.53-2.89,1.58c-1.09,1.05,1.19,1.93,0.75,4.03
                    c-0.44,2.1-0.78,1.23-1.92,0c-1.14-1.23-2.56-2.76-2.73-0.85c-0.17,1.9-1.57,5.41-3.49,6.98c-1.93,1.58-5.11,1.46-5.11,1.46
                    s2.66-2.68,1.26-2.51c-1.4,0.18-7.18,1.75-7.35,1.05s3.58-1.4,4.85-2.45c1.28-1.05,4.4-1.05,5.9-2.1c1.5-1.05,4.2-3.69,3.29-4.82
                    c-0.91-1.13-1.26,1.01-2.49,2.39c-1.23,1.38-2.8,2.25-3.85,1.73c-1.05-0.53-1.93-2.1,0.18-3.15
                    C516.99,148.96,516.8,147.45,514.89,147.57z"/>
                <path id="XMLID_235_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M491.55,157.54
                    c0,0,0.16,0.08,0.42,0.25c0.26,0.17,0.63,0.42,1.05,0.77c0.21,0.17,0.43,0.36,0.65,0.58c0.22,0.22,0.45,0.45,0.67,0.72
                    c0.22,0.26,0.43,0.55,0.61,0.87c0.19,0.31,0.35,0.66,0.46,1.02c0.06,0.18,0.09,0.37,0.12,0.57c0.01,0.1,0.01,0.2,0.02,0.29
                    c0,0.1,0,0.18-0.01,0.27c-0.02,0.37-0.07,0.72-0.14,1.06c-0.07,0.34-0.17,0.65-0.27,0.94c-0.09,0.29-0.2,0.56-0.27,0.8
                    c-0.07,0.25-0.12,0.47-0.1,0.66c0.01,0.19,0.08,0.36,0.17,0.48c0.09,0.12,0.17,0.21,0.23,0.26c0.06,0.05,0.1,0.08,0.1,0.08
                    s-0.04-0.02-0.12-0.06c-0.07-0.04-0.18-0.1-0.3-0.21c-0.12-0.11-0.25-0.28-0.3-0.52c-0.06-0.23-0.05-0.5-0.01-0.77
                    c0.04-0.27,0.11-0.56,0.18-0.85c0.07-0.29,0.13-0.6,0.18-0.92c0.05-0.31,0.08-0.65,0.08-0.97c0-0.08,0-0.18,0-0.25
                    c-0.01-0.08-0.01-0.16-0.02-0.23c-0.02-0.16-0.05-0.31-0.1-0.47c-0.09-0.31-0.23-0.62-0.39-0.91c-0.16-0.29-0.34-0.57-0.53-0.83
                    c-0.19-0.26-0.38-0.51-0.57-0.74c-0.19-0.23-0.38-0.44-0.56-0.64c-0.36-0.39-0.67-0.7-0.89-0.92
                    C491.68,157.66,491.55,157.54,491.55,157.54z"/>
                <path id="XMLID_234_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M507.97,173.82
                    c0,0-1.84-2.1-2.96-2.28s-4.31-1.93-7.08-4.38c0,0-1.09,1.58,1.36,3.33c2.45,1.75,3.57,2.6,3.53,4.28
                    c-0.03,1.68,3.12,1.68,3.12,1.68s-4.18-2.8-2.56-3.85C505.02,171.55,507.97,173.82,507.97,173.82z"/>
                <path id="XMLID_233_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M514.54,182.05l-1.99,3.68
                    c-0.64-2.1-1.41-2.1,0.55-4.73c1.96-2.63,1.09-6.23,1.09-6.23s2.28,2.28,4.55,5.48c2.28,3.2,2.63,4.71,2.63,4.71
                    s-1.95-1.16-3.6-3.61s-2.53-1.4-2.88-0.35C514.54,182.05,515.59,181.35,514.54,182.05"/>
                <path id="XMLID_232_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M527.51,184.96
                    c0,0-1.07-5.71-2.47-8.51c-1.4-2.8-2.3-4.64-2.3-4.64s3.91,1.84,4.77,4.64c0.86,2.8,9.44,8.51,9.44,8.51s-6.69-2.83-8.43-4.71
                    C528.53,180.25,528.93,182.79,527.51,184.96z"/>
                <path id="XMLID_231_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M539.4,167.17
                    c0,0,0.08,0.03,0.24,0.09c0.15,0.06,0.38,0.15,0.66,0.28c0.28,0.14,0.62,0.3,1,0.52c0.19,0.1,0.39,0.23,0.6,0.35
                    c0.21,0.13,0.42,0.27,0.64,0.43c0.44,0.31,0.9,0.67,1.34,1.11c0.44,0.44,0.88,0.94,1.23,1.54c0.04,0.07,0.09,0.14,0.13,0.22
                    c0.04,0.07,0.08,0.15,0.13,0.22c0.04,0.07,0.08,0.15,0.13,0.22c0.04,0.07,0.09,0.14,0.13,0.2c0.09,0.14,0.18,0.27,0.28,0.41
                    c0.1,0.14,0.2,0.27,0.3,0.41c0.42,0.54,0.89,1.06,1.37,1.58c0.48,0.52,0.96,1.04,1.42,1.58c0.46,0.53,0.88,1.09,1.27,1.64
                    c0.19,0.28,0.37,0.55,0.55,0.81c0.18,0.26,0.36,0.51,0.54,0.75c0.37,0.48,0.72,0.93,1.07,1.33c0.68,0.81,1.28,1.46,1.71,1.91
                    c0.21,0.22,0.38,0.4,0.5,0.52c0.12,0.12,0.18,0.18,0.18,0.18s-0.07-0.06-0.2-0.16c-0.13-0.1-0.32-0.26-0.56-0.46
                    c-0.23-0.2-0.52-0.45-0.84-0.75c-0.16-0.15-0.32-0.31-0.5-0.48c-0.17-0.17-0.35-0.35-0.54-0.54c-0.37-0.38-0.77-0.81-1.16-1.28
                    c-0.2-0.23-0.39-0.48-0.59-0.74c-0.1-0.13-0.19-0.26-0.29-0.39c-0.09-0.13-0.19-0.27-0.29-0.4c-0.38-0.53-0.82-1.05-1.27-1.57
                    c-0.46-0.52-0.94-1.03-1.42-1.55c-0.48-0.53-0.95-1.06-1.39-1.63c-0.11-0.14-0.22-0.28-0.31-0.43c-0.1-0.15-0.2-0.29-0.29-0.45
                    c-0.05-0.08-0.09-0.15-0.14-0.23c-0.04-0.08-0.08-0.15-0.12-0.22c-0.04-0.07-0.08-0.15-0.12-0.22c-0.04-0.07-0.08-0.14-0.12-0.22
                    c-0.31-0.55-0.69-1.05-1.1-1.49c-0.1-0.11-0.21-0.21-0.31-0.32c-0.11-0.1-0.21-0.2-0.31-0.29c-0.1-0.1-0.21-0.19-0.31-0.28
                    c-0.1-0.09-0.2-0.18-0.31-0.26c-0.41-0.33-0.8-0.62-1.15-0.86c-0.35-0.24-0.67-0.44-0.94-0.6c-0.13-0.08-0.25-0.15-0.36-0.21
                    c-0.1-0.06-0.19-0.11-0.27-0.15C539.48,167.21,539.4,167.17,539.4,167.17z"/>
                <path id="XMLID_230_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M527.51,165.24
                    c0,0,0.05,0.08,0.15,0.23c0.09,0.15,0.26,0.35,0.47,0.6c0.22,0.24,0.51,0.53,0.86,0.82c0.35,0.29,0.77,0.59,1.25,0.9
                    c0.48,0.3,1.01,0.61,1.58,0.92c0.57,0.31,1.19,0.62,1.81,0.98c0.16,0.09,0.31,0.18,0.47,0.27c0.16,0.1,0.32,0.19,0.47,0.29
                    c0.31,0.2,0.63,0.41,0.93,0.63c0.61,0.45,1.21,0.95,1.76,1.5c0.54,0.56,1.04,1.17,1.44,1.83c0.21,0.33,0.38,0.67,0.53,1.02
                    c0.07,0.18,0.14,0.35,0.21,0.53c0.05,0.18,0.11,0.36,0.16,0.54c0.18,0.7,0.31,1.39,0.41,2.04c0.11,0.65,0.18,1.26,0.3,1.81
                    c0.12,0.55,0.28,1.04,0.47,1.45c0.02,0.05,0.05,0.1,0.07,0.15c0.03,0.05,0.05,0.1,0.08,0.14c0.05,0.09,0.1,0.19,0.15,0.27
                    c0.11,0.17,0.2,0.33,0.3,0.46c0.1,0.14,0.18,0.26,0.26,0.36c0.08,0.1,0.15,0.19,0.2,0.26c0.11,0.14,0.17,0.22,0.17,0.22
                    s-0.07-0.07-0.19-0.19c-0.06-0.06-0.14-0.14-0.23-0.23c-0.09-0.1-0.19-0.21-0.3-0.34c-0.11-0.13-0.22-0.28-0.34-0.44
                    c-0.06-0.08-0.12-0.17-0.18-0.26c-0.03-0.05-0.06-0.09-0.09-0.14c-0.03-0.05-0.06-0.1-0.09-0.15c-0.12-0.2-0.23-0.43-0.33-0.68
                    c-0.1-0.25-0.19-0.51-0.27-0.79c-0.16-0.56-0.27-1.17-0.4-1.81c-0.13-0.64-0.28-1.3-0.48-1.98c-0.18-0.66-0.5-1.31-0.88-1.92
                    c-0.38-0.61-0.86-1.19-1.38-1.72c-0.52-0.53-1.09-1.02-1.68-1.46c-0.59-0.44-1.19-0.85-1.8-1.21c-0.61-0.36-1.21-0.71-1.77-1.04
                    c-0.56-0.34-1.09-0.68-1.55-1.02c-0.23-0.17-0.45-0.34-0.66-0.51c-0.2-0.17-0.39-0.33-0.55-0.5c-0.34-0.32-0.6-0.64-0.8-0.91
                    c-0.2-0.28-0.34-0.5-0.41-0.66C527.56,165.33,527.51,165.24,527.51,165.24z"/>
                <path id="XMLID_229_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M523.7,182.23
                    c0,0,0.03,0.03,0.08,0.1c0.05,0.07,0.12,0.16,0.2,0.29c0.17,0.25,0.4,0.62,0.62,1.09c0.11,0.23,0.23,0.49,0.34,0.77
                    c0.11,0.28,0.22,0.58,0.31,0.89c0.1,0.31,0.18,0.64,0.25,0.98c0.07,0.34,0.12,0.68,0.16,1.03c0.03,0.35,0.06,0.7,0.05,1.04
                    c0,0.35-0.02,0.68-0.06,1.01c-0.04,0.33-0.09,0.64-0.16,0.93c-0.07,0.29-0.15,0.57-0.23,0.81c-0.04,0.12-0.08,0.24-0.13,0.35
                    c-0.05,0.11-0.09,0.21-0.13,0.31c-0.08,0.19-0.17,0.36-0.24,0.49c-0.07,0.13-0.14,0.23-0.18,0.3c-0.04,0.07-0.06,0.11-0.06,0.11
                    s0.05-0.17,0.13-0.45c0.04-0.15,0.09-0.32,0.14-0.52c0.02-0.1,0.05-0.21,0.07-0.32c0.02-0.11,0.05-0.23,0.07-0.36
                    c0.05-0.25,0.09-0.52,0.12-0.8c0.04-0.29,0.06-0.59,0.08-0.9c0-0.16,0.01-0.32,0.01-0.48c0-0.16,0-0.32-0.01-0.49
                    c-0.01-0.33-0.03-0.66-0.06-0.99c-0.04-0.33-0.08-0.66-0.13-0.99c-0.06-0.32-0.12-0.64-0.19-0.95c-0.07-0.31-0.15-0.6-0.23-0.88
                    c-0.08-0.28-0.16-0.54-0.24-0.78c-0.08-0.24-0.16-0.46-0.23-0.66c-0.07-0.19-0.13-0.36-0.19-0.5
                    C523.76,182.39,523.7,182.23,523.7,182.23z"/>
                <path id="XMLID_228_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M536.95,182.23
                    c0,0,0.14,0.13,0.36,0.36c0.11,0.12,0.26,0.25,0.41,0.42c0.16,0.16,0.34,0.34,0.54,0.54c0.2,0.2,0.41,0.41,0.62,0.65
                    c0.22,0.24,0.45,0.49,0.67,0.77c0.22,0.28,0.43,0.58,0.62,0.91c0.2,0.32,0.36,0.67,0.51,1.02c0.07,0.18,0.14,0.36,0.2,0.53
                    c0.06,0.18,0.12,0.36,0.17,0.54c0.1,0.36,0.19,0.72,0.27,1.05c0.08,0.34,0.15,0.67,0.21,0.98c0.07,0.31,0.13,0.61,0.18,0.88
                    c0.11,0.55,0.18,1.01,0.23,1.33c0.05,0.32,0.07,0.51,0.07,0.51s-0.07-0.17-0.19-0.48c-0.11-0.31-0.27-0.74-0.45-1.28
                    c-0.09-0.26-0.18-0.55-0.28-0.86c-0.09-0.31-0.2-0.63-0.29-0.96c-0.1-0.34-0.2-0.67-0.31-1.01c-0.06-0.17-0.12-0.34-0.18-0.51
                    c-0.07-0.17-0.13-0.34-0.2-0.5c-0.14-0.33-0.29-0.66-0.46-0.97c-0.17-0.31-0.36-0.6-0.55-0.89c-0.2-0.28-0.39-0.55-0.58-0.8
                    c-0.19-0.25-0.38-0.49-0.54-0.71c-0.17-0.22-0.32-0.43-0.45-0.61c-0.13-0.19-0.24-0.35-0.33-0.49
                    C537.05,182.39,536.95,182.23,536.95,182.23z"/>
                <path id="XMLID_227_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M501.05,159.83
                    c0,0,0.06,0.03,0.17,0.09c0.11,0.06,0.28,0.15,0.49,0.26c0.42,0.23,1.01,0.55,1.73,0.94c0.36,0.19,0.75,0.4,1.16,0.62
                    c0.41,0.22,0.85,0.44,1.3,0.67c0.23,0.11,0.46,0.23,0.69,0.35c0.23,0.12,0.47,0.23,0.71,0.35c0.24,0.11,0.48,0.23,0.72,0.34
                    c0.24,0.11,0.49,0.22,0.73,0.34c0.25,0.11,0.49,0.21,0.74,0.32c0.25,0.1,0.5,0.2,0.74,0.3c0.49,0.19,0.99,0.35,1.47,0.5
                    c0.48,0.15,0.95,0.28,1.4,0.41c0.45,0.12,0.88,0.24,1.27,0.36c0.39,0.12,0.75,0.25,1.06,0.4c0.31,0.14,0.55,0.32,0.73,0.48
                    c0.18,0.17,0.29,0.33,0.35,0.43c0.03,0.05,0.05,0.1,0.07,0.13c0.02,0.03,0.02,0.04,0.02,0.04s-0.04-0.06-0.12-0.15
                    c-0.08-0.1-0.21-0.23-0.4-0.36c-0.19-0.13-0.44-0.26-0.75-0.37c-0.3-0.11-0.66-0.2-1.06-0.28c-0.39-0.08-0.83-0.16-1.29-0.26
                    c-0.46-0.1-0.94-0.2-1.44-0.33c-0.5-0.13-1.01-0.28-1.52-0.47c-0.26-0.09-0.52-0.19-0.76-0.29c-0.25-0.11-0.5-0.22-0.76-0.32
                    c-0.25-0.12-0.5-0.23-0.74-0.35c-0.24-0.12-0.49-0.24-0.73-0.36c-0.48-0.25-0.94-0.5-1.39-0.75c-0.45-0.25-0.87-0.51-1.27-0.75
                    c-0.4-0.24-0.77-0.48-1.11-0.71c-0.34-0.22-0.65-0.44-0.92-0.63c-0.27-0.19-0.51-0.36-0.7-0.5
                    C501.27,160,501.05,159.83,501.05,159.83z"/>
                <path id="XMLID_226_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M500,146.15
                    c0,0,0.08,0.04,0.2,0.12c0.06,0.04,0.14,0.08,0.22,0.13c0.09,0.05,0.18,0.11,0.29,0.17c0.1,0.06,0.22,0.12,0.34,0.19
                    c0.12,0.06,0.25,0.13,0.38,0.19c0.13,0.07,0.27,0.12,0.41,0.19c0.07,0.03,0.14,0.06,0.22,0.09c0.07,0.03,0.14,0.06,0.22,0.08
                    l0.22,0.08c0.07,0.02,0.15,0.05,0.22,0.07c0.07,0.02,0.15,0.05,0.22,0.07c0.07,0.02,0.15,0.04,0.22,0.07
                    c0.14,0.05,0.28,0.08,0.41,0.12c0.13,0.04,0.26,0.08,0.38,0.11c0.12,0.03,0.22,0.07,0.32,0.1c0.1,0.03,0.18,0.05,0.25,0.07
                    c0.14,0.04,0.22,0.08,0.22,0.08s-0.08,0.02-0.23,0.04c-0.07,0.01-0.16,0.02-0.26,0.03c-0.1,0.01-0.22,0.01-0.34,0.01
                    c-0.06,0-0.13,0-0.2,0c-0.07,0-0.14-0.01-0.21-0.01c-0.14-0.01-0.3-0.02-0.45-0.05c-0.16-0.03-0.32-0.05-0.48-0.1
                    c-0.08-0.02-0.16-0.04-0.24-0.07c-0.08-0.03-0.16-0.06-0.24-0.09c-0.16-0.06-0.32-0.13-0.47-0.2c-0.15-0.08-0.29-0.16-0.43-0.25
                    c-0.14-0.09-0.26-0.18-0.37-0.28c-0.11-0.1-0.21-0.19-0.3-0.28c-0.09-0.09-0.16-0.18-0.23-0.26c-0.06-0.08-0.11-0.16-0.15-0.22
                    c-0.04-0.06-0.07-0.11-0.09-0.15C500.01,146.17,500,146.15,500,146.15z"/>
                <g id="XMLID_154_">
                    <path id="XMLID_225_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M440.26,108.73
                        c0,0-0.45-0.22-1.04-0.66C439.74,108.97,440.26,108.73,440.26,108.73z"/>
                    <path id="XMLID_169_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M507.97,195.15
                        c0,0-25.22-38.14-35.96-56.23s-8.64-20.2-7.71-26.4c0.93-6.2,5.6-7.91,5.6-7.91l1.72-1.88c0,0-1.72,1.88-7.18,1.88
                        c-5.46,0-4.03-8.36-4.03-8.36s0,0,0.7-1.23c0.7-1.23,0.53,0.71,0.88,1.99c0.35,1.28,2.1-0.58,1.93-3.03
                        c-0.18-2.45,2.8-2.1,2.8-2.1s-1.05,4.03,0.35,6.28c1.4,2.25,2.45,1.78,4.9,1.01c2.45-0.76,2.45-3.61,0.7-5.37
                        c-1.75-1.75-2.63-3.5-1.58-4.93c1.05-1.43,2.63,0.73,4.73-1.42c2.1-2.14,4.38-6.61,4.2-8.29c-0.18-1.68-3.5,0.8-3.5,0.8
                        c3.85-3.68,4.38-9.28,4.38-9.28c-2.45,8.06-7.53,8.48-7.53,8.48c2.28-1.5,2.45-3.93,3.15-5.85c0.7-1.93,4.9-7.88,4.03-8.93
                        c-0.88-1.05-6.13,9.81-7.53,9.46c-1.4-0.35,3.68-5.6,3.15-7c-0.53-1.4-3.85,5.95-5.95,5.95s1.93-1.4,1.4-2.8
                        c-0.53-1.4-2.1-1.23,0.35-2.28c2.45-1.05,5.25-5.6,7.18-9.35c1.93-3.74,9.17-4.58,8.41-7.64c-0.7-2.8,5.43,0.53,9.88,0.35
                        c4.45-0.18,5.01-2.98,8.68-5.08c3.68-2.1,6.42,1.4,6.42,1.4c-3.57,1.23-7.47,3.5-13.34,8.06c-5.87,4.55-9.89,0-9.89,0
                        s-0.7,2.45,3.59,2.91c4.29,0.46,10.94-3.79,12.1-5.71c1.16-1.93,10.04-4.55,10.57-4.38c0.53,0.18-0.53,2.8-0.53,2.8
                        c1.23-0.35,2.8-2.28,2.8-2.28c2.28,3.85-2.1,5.78-2.1,5.78c5.78-1.75,2.8-5.96,5.29-7.54c2.49-1.58,1.02,0.88,5.16,8.24
                        c4.14,7.35,7.68,7.17,7.68,7.17c-5.14-2.33-7.54-10.51-7.54-10.51s2.17,0.47,5.67,1.4c3.5,0.93,4.44,6.3,5.37,8.87
                        c0.93,2.57-1.87,5.6-1.79,8.87c0.08,3.27-3.81,8.08-3.81,8.08c2.8,0.61,5.37-2.71,5.37-2.71c0.93,1.87-1.17,6.3-1.17,6.3
                        s1.68,0.85,1.87,2.71c0.19,1.85-0.7,8.73-1.17,13.7c-0.47,4.96-3.97,11.85-3.97,11.85c2.81,0.99,7.01-4.2,7.47-3.7
                        c0.47,0.5,1.4,5.21,1.4,5.21c3.5-2.7,4.67-14.37,4.67-14.37c1.87,1.62-0.7,12.27-0.7,12.27c10.27-5.03,16.11-21.55,16.11-21.55
                        c1.44,6.39-3.74,12.45-3.74,12.45s6.54-5.02,5.84-11.32c-0.7-6.3-7.01-10.83-7.01-10.83c5.84,2.24,8.64-3.88,8.64-3.88
                        c-3.74,3.04-7.24-1.17-7.24-1.17c7.47-2.34,5.14-9.81,5.14-9.81s-0.93,4.44-6.54,4.2s-3.97-12.14-3.97-12.14
                        c1.87,1.63,3.5,8.64,3.5,8.64c1.63-4.44-1.63-10.27-1.63-10.27c2.1,1.17,3.74,7.01,3.74,7.01c0.93-7.24-5.45-12.14-5.45-12.14
                        c5.29-7.94-0.38-14.24-0.38-14.24c2.1,8.64-5.14,9.57-5.14,9.57c3.27-3.27,3.27-4.9,1.4-10.27c-1.87-5.37-8.17-4.67-8.64-7.24
                        c-0.47-2.57,3.5-5.37,2.34-7.01c-1.17-1.63-4.9,1.17-4.9,1.17c-7.01-12.14-19.15-9.81-19.15-9.81c6.01-0.23,4.58,3.04,4.58,3.04
                        c-1.72-2.1-9.48-1.17-9.48-1.17s-2.57-4.9-7.24-5.4c-4.67-0.49-7.13,4.23-7.13,4.23s-1.51-3.74-6.29-4.2
                        c-4.79-0.47-8.08,5.6-8.08,5.6c-2.83-3.97-8.38-3.04-14.92,0c-6.54,3.04-9.81,11.21-11.91,15.18s-2.1,4.2-7.01,7.71
                        c-4.9,3.5-8.87,12.14-8.87,12.14s-1.4,0.47-3.04,5.84c-1.63,5.37,3.74,8.17,3.74,8.17c-11.44,3.97-7.24,9.34-7.24,9.34
                        s1.87-2.34,3.74-1.87c1.87,0.47-0.47,5.37-0.47,5.37c-2.34-3.5-4.44,0-4.44,0c2.34,0.7,1.17,5.37-0.47,6.33
                        c-1.63,0.96,0.47,6.78,0.47,6.78c-2.33-0.43-3.27-4.94-3.27-4.94s-2.34,5.14,0.23,7.47c2.57,2.34,8.87,3.62,8.87,3.62
                        s-2.57,1.28-3.74,6.3c-0.82,3.51,1.33,5.73,2.7,6.75c-0.33-0.57-0.65-1.61-0.83-3.46c-0.47-4.77,2.1-5.44,2.1-5.44
                        s0,2.51,1.17,8.14c1.17,5.64,7.24,7.2,7.24,7.2c0.47-3.04,6.77-3.5,6.77-3.5s-4.44,5.6-5.37,10.27c-0.93,4.67-2.57,6.54-7.24,8.87
                        c-4.67,2.33-3.04,7.94-7.24,10.97c-4.2,3.04-10.51,4.9-18.45,8.74c-7.94,3.84-11.48,9.23-11.48,9.23s-16.3,14.87-16.3,36.04
                        L507.97,195.15z M544.48,64.55c0,0-5.6-9-4.2-9c1.4,0,1.4,0,1.4,0s3.68,3.7,4.03,5.3C546.06,62.45,544.48,64.55,544.48,64.55z
                        M452.02,60.87c1.05-0.35,2.66,3.33,5.1,3.68c0,0-6.01-3.85-2.66-3.15c3.35,0.7,6.67,0.54,8.07,1.93c1.4,1.39,0.7,2.97-0.88,2.8
                        c-1.58-0.18-3.15,0.03-4.12,0.01c-0.96-0.01-2.01-0.89-2.01-0.89C453.77,63.85,450.97,61.22,452.02,60.87z M461.65,89.99
                        c-2.28,1.17-3.64,4.68-4.54,3.63c-0.89-1.05,0.68-1.4-0.89-1.05c-1.58,0.35-2.45,4.08-2.98,3.44c-0.53-0.64,0.15-3.96,2.27-5.02
                        c0,0-0.34-1.4-2.27-0.18c-1.6,1.02,1.93-2.33,4.29-1.95C459.9,89.24,463.93,88.82,461.65,89.99z M461.65,87.45
                        c-0.35-1.36,2.1-2.26-2.45-1.98c-4.55,0.27-8.93,1.02-9.98-3.38c-1.05-4.4,2.98-6.5,3.33-7.55c0.35-1.05,0.07-2.8-0.75-2.63
                        c-0.82,0.18,4.96-3.15,2.16-2.45c-2.8,0.7-6.65,1.93-6.65,1.93c1.23-1.23,3.5-3.5,6.3-3.5s5.64,0.7,3.52,1.75
                        c-2.12,1.05-3.35,1.05-2.29,1.75c1.05,0.7,4.38-1.4,4.2,0c-0.18,1.4-3.33,2.45-4.2,4.03c-0.88,1.58-0.66,5.47,2.29,6.67
                        c2.96,1.21,4.64,1.18,6.16,3.38C464.8,87.67,462,88.8,461.65,87.45z M474.26,80.84c3.33-2.03,0.7,0,0.7,0s-3.97,3.5-7.07,3.68
                        c-3.09,0.18-4.16-2.81-4.16-2.81s1.65,2.28,4.16,1.58c0,0-2.05-1.98-0.1-1.58C469.53,82.06,470.92,82.86,474.26,80.84z
                        M472.51,74.79c0,0-0.75,2.91-2.47,3.16c0,0,2.98,0.25,3.43-1.15c0,0-0.41,3.83-5.67,2.35c-5.26-1.48-5.47-4.45-5.47-4.45
                        s0.38,1.12,2.48,1.7l-1.05-3.02c-0.02-0.05-0.03-0.08-0.03-0.08l0.03,0.08c0.17,0.47,1.2,2.9,4.03,2.9
                        C470.93,76.28,472.51,74.79,472.51,74.79z M477.06,58.95c-1.58,1.23-6.98,4.55-9.27,4.38c-2.29-0.18-5.44-0.53-6.14-6.3
                        c0,0,1.23,2.63,2.28,3.15c1.05,0.53,1.95,2.8,3.86,1.75c1.91-1.05-0.71-1.23-0.71-1.23c-0.88-1.23-0.7-2.1,1.93-3.15
                        c2.63-1.05,5.25-3.15,6.83-3.85c1.58-0.7,3.5-1.23,3.85,0C480.04,54.92,478.64,57.72,477.06,58.95z M492.91,42.31
                        c-0.79,0.88-1.03,1.4-2.4,1.4c-1.37,0-1.37-0.53-3.3-0.7c-1.93-0.18-4.14-1.93-5.22-1.05c-1.08,0.88-2.48,1.4-1.43,2.8
                        c1.05,1.4,3.85-0.86,3.68,1.15c-0.18,2.01-0.64,3.41-2.25,4.28c-1.61,0.88-4.76,1.93-6.16,2.1c-1.4,0.18-3.49-1.05-2.71-1.93
                        c0.78-0.88,2.71-3.15,2.71-3.15s-2.28,1.05-3.5,1.05s-1.05-0.86-0.7-2.36c0.35-1.5,1.93-2.72,4.2-2.9
                        c2.28-0.18,4.87-0.18,5.43-2.45c0,0-7.53,3.15-11.21-1.4c-0.47-0.58,6.48,0.7,8.41,0c1.93-0.7,6.26-1.52,6.37-1.11
                        c0.11,0.41-1.97,3.73,0.82,2.51s4.13-3.84,4.86-2.62c0.73,1.22,0.56,1,2.4,1.29C494.75,39.51,493.71,41.44,492.91,42.31z
                        M503.42,32.33c-1.66,0.35-0.09,2.1-0.02,2.42c0.07,0.32-1.99,0.21-3.22,0.98c-1.23,0.77-0.35,1.16-0.97,2.21
                        c-0.62,1.05-4.81-5.25-8.49-6.3c-3.68-1.05-4.9,2.63-8.58,4.09c-3.68,1.47-6.3-1.29-6.3-1.29c0.88,0.7,3.85-0.53,3.85-0.53
                        s-1.4-1.93-1.23-2.28s2.98,1.4,2.98,1.4s0.18-1.4,1.58-1.93c1.4-0.53,2.64-1.75,2.64-1.75c-1.39-0.53-3.87-6.48-1.76-3.94
                        c2.1,2.54,3.85,0.13,3.85,0.13s0.18-1.44-2.09-3.89c-2.26-2.45-0.82-5.43-0.82-5.43s0.63,2.98,3.26,4.9s4.82,4.29,4.82,4.29
                        c1.11-6.66,6.29-9.19,6.29-9.19s-3.06,3.5-4.11,8.41c-1.05,4.9,4.9,5.43,6.38,5.43s-0.08-1.05,0.8-1.75
                        c0.88-0.7,2.28,1.4,2.74,2.1C505.48,31.1,505.08,31.98,503.42,32.33z M505.02,23.75c-1.34-3.15-1.6-5.95-1.6-5.95
                        s0.9,3.5,4.87,5.95c3.97,2.45,7.05,4.55,4.81,9.28c0,0,0.46-2.45-1.24-3.68C510.16,28.13,506.36,26.9,505.02,23.75z M517.16,43.36
                        c-2.28,2.28-2.28,2.28-2.28,2.28s0.11,0.88-1.79-0.53c0,0-1.47-1.05-1.24-4.55s0.97-4.55,2-6.65s3.48-3.33,3.48-3.33
                        s2.4-1.75,5.41-4.03s4.77,0,4.77,0s-6.55,3.15-7.84,7.71c0,0,2.87-1.58,3.07-0.35c0.2,1.23-1.96,1.75-3.07,2.8
                        c-1.11,1.05-3.56,3.85-4.44,4.03c-0.88,0.18-1.38-2.1-1.38-2.1s-1.06,4.55-0.28,4.9c0.78,0.35,5.69-5.43,8.31-5.78
                        c2.63-0.35-0.57,1.05,0.85,1.4c1.42,0.35,3.06-0.7,5.75,1.05c2.68,1.75,2.97,3.15,7.12,2.1c4.15-1.05,6.42-5.95,6.77-4.2
                        c0.35,1.75-3.37,9.11-11.76,5.95C522.24,40.91,519.44,41.08,517.16,43.36z M533.98,52.96c-1.93-1.19-4.87-2.42-6.46-4.69
                        c-1.59-2.28-0.39-3.3-0.39-3.3s0.51,3.47,3.51,4.7l-2.13-3.76c0,0,2.86,2.88,4.26,3.23c1.4,0.35-1.05,1.23,0.88,1.75
                        s4.38,1.33,5.95,2.07c1.58,0.74-3.97,0-3.97,0s3.14,2.88,4.25,5.4c1.12,2.52,1.82,7.79,1.82,7.79S535.9,54.15,533.98,52.96z
                        M551.62,76.4c-0.39-1.46-4.69-0.29-3.99,0c0.7,0.29,1.93,1.28,3.33,2.76c1.4,1.48,0.35,1.15-1.05,1.33
                        c-1.4,0.18-1.58-1.23-2.98-2.53c-1.4-1.3-0.35,2.18-1.18,4.13c-0.82,1.95-0.93-4.4-1.74-7.37c-0.82-2.98-0.06,6.65-1.11,6.13
                        c-1.05-0.53-0.35-3.5-0.88-2.88c-0.53,0.62-1.05,5.33-1.05,5.33s-1.05-1.93-0.93-3.79c0.13-1.86,2.15-2.87,2.35-4.09
                        c0.2-1.23-0.02-1.58,1.2-2.1c1.23-0.53-0.7-4.03-0.7-4.03c3.15-0.7,4.03,3.5,4.96,4.03c0.93,0.53,2.92,0.18,4.5,1.4
                        c1.58,1.23,3.33,3.25,3.33,3.25S552.01,77.86,551.62,76.4z"/>
                </g>
                <path id="XMLID_135_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M510.66,86.88
                    c0,0,0.04,0.02,0.1,0.05c0.06,0.03,0.15,0.08,0.24,0.14c0.09,0.06,0.2,0.13,0.3,0.2c0.05,0.04,0.1,0.07,0.16,0.11
                    c0.05,0.04,0.1,0.08,0.16,0.11c0.05,0.04,0.1,0.07,0.15,0.11c0.05,0.03,0.1,0.07,0.15,0.1c0.05,0.03,0.1,0.06,0.14,0.09
                    c0.05,0.02,0.09,0.05,0.14,0.08c0.04,0.02,0.09,0.04,0.13,0.06c0.04,0.02,0.08,0.03,0.11,0.04c0.06,0.02,0.11,0.04,0.11,0.04
                    s-0.03,0.03-0.09,0.07c-0.03,0.02-0.07,0.05-0.12,0.07c-0.05,0.02-0.11,0.03-0.17,0.05c-0.06,0.01-0.13,0.01-0.21,0.01
                    c-0.07,0-0.15-0.02-0.23-0.04c-0.04-0.01-0.08-0.02-0.11-0.04c-0.04-0.01-0.08-0.03-0.11-0.05c-0.07-0.04-0.15-0.08-0.21-0.13
                    c-0.07-0.05-0.13-0.1-0.18-0.16c-0.06-0.05-0.11-0.11-0.15-0.17c-0.05-0.06-0.08-0.12-0.12-0.18c-0.03-0.06-0.06-0.12-0.08-0.17
                    c-0.04-0.11-0.07-0.21-0.08-0.28c-0.01-0.03-0.01-0.07-0.01-0.09C510.66,86.89,510.66,86.88,510.66,86.88z"/>
                <path id="XMLID_134_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M513.58,108.56
                    c0,0,0.02,0.01,0.05,0.02c0.03,0.01,0.08,0.03,0.14,0.05c0.06,0.02,0.13,0.04,0.21,0.07c0.08,0.03,0.17,0.06,0.27,0.1
                    c0.19,0.07,0.42,0.15,0.66,0.24c0.12,0.04,0.24,0.09,0.37,0.13c0.13,0.04,0.25,0.09,0.38,0.13c0.13,0.04,0.25,0.08,0.38,0.12
                    c0.06,0.02,0.13,0.04,0.19,0.06c0.06,0.02,0.12,0.03,0.18,0.05c0.06,0.02,0.12,0.03,0.18,0.05c0.06,0.01,0.12,0.03,0.17,0.04
                    c0.11,0.03,0.22,0.06,0.32,0.08c0.1,0.02,0.19,0.04,0.28,0.06c0.08,0.01,0.16,0.03,0.22,0.04c0.12,0.03,0.2,0.05,0.2,0.05
                    s-0.07,0.03-0.19,0.07c-0.06,0.02-0.14,0.04-0.22,0.07c-0.09,0.02-0.19,0.03-0.3,0.05c-0.11,0.02-0.23,0.02-0.35,0.02
                    c-0.06,0-0.13,0-0.19,0c-0.07,0-0.13-0.01-0.2-0.02c-0.07-0.01-0.14-0.02-0.21-0.02c-0.07-0.01-0.14-0.03-0.21-0.04
                    c-0.14-0.03-0.28-0.07-0.42-0.11c-0.14-0.04-0.27-0.1-0.41-0.16c-0.07-0.03-0.13-0.06-0.19-0.09c-0.06-0.03-0.13-0.06-0.18-0.1
                    c-0.12-0.07-0.23-0.13-0.33-0.21c-0.1-0.07-0.2-0.14-0.28-0.21c-0.17-0.14-0.29-0.27-0.38-0.37
                    C513.62,108.62,513.58,108.56,513.58,108.56z"/>
                <path id="XMLID_132_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M500,108.56
                    c0,0-0.12,0.08-0.34,0.21c-0.11,0.07-0.24,0.15-0.39,0.24c-0.15,0.09-0.32,0.2-0.51,0.31c-0.75,0.45-1.77,1.03-2.81,1.55
                    c-0.26,0.13-0.52,0.26-0.78,0.38c-0.26,0.12-0.52,0.24-0.77,0.35c-0.25,0.11-0.5,0.21-0.73,0.3c-0.12,0.05-0.23,0.09-0.34,0.13
                    c-0.11,0.04-0.22,0.08-0.33,0.11c-0.21,0.08-0.4,0.13-0.58,0.18c-0.17,0.05-0.33,0.08-0.45,0.11c-0.13,0.03-0.23,0.04-0.3,0.05
                    c-0.07,0.01-0.11,0.01-0.11,0.01s0.03-0.02,0.1-0.05c0.06-0.03,0.15-0.07,0.27-0.12c0.11-0.06,0.25-0.13,0.41-0.21
                    c0.16-0.08,0.33-0.18,0.52-0.28c0.38-0.21,0.83-0.45,1.31-0.71c0.24-0.13,0.49-0.26,0.74-0.39c0.25-0.13,0.51-0.26,0.77-0.4
                    c0.26-0.13,0.52-0.26,0.78-0.38c0.26-0.13,0.51-0.25,0.76-0.36c0.25-0.11,0.49-0.22,0.72-0.32c0.23-0.1,0.45-0.19,0.66-0.27
                    c0.41-0.16,0.76-0.28,1.01-0.36C499.86,108.6,500,108.56,500,108.56z"/>
                <path id="XMLID_131_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M509.35,132.61
                    c0,0,0.02,0.01,0.05,0.02c0.03,0.01,0.07,0.03,0.13,0.05c0.06,0.02,0.12,0.05,0.2,0.08c0.08,0.03,0.16,0.06,0.25,0.09
                    c0.37,0.13,0.86,0.28,1.34,0.33c0.12,0.02,0.24,0.02,0.35,0.02c0.06,0,0.11,0,0.17-0.01c0.06-0.01,0.11-0.01,0.16-0.02
                    c0.11-0.02,0.21-0.04,0.3-0.07c0.1-0.03,0.18-0.07,0.27-0.11c0.08-0.04,0.15-0.09,0.22-0.13c0.03-0.02,0.06-0.05,0.09-0.07
                    c0.03-0.02,0.05-0.04,0.07-0.06c0.05-0.04,0.08-0.07,0.1-0.09c0.02-0.02,0.04-0.03,0.04-0.03s0,0.02-0.01,0.05
                    c-0.01,0.03-0.01,0.08-0.04,0.14c-0.01,0.03-0.02,0.06-0.03,0.1c-0.01,0.04-0.03,0.07-0.05,0.11c-0.04,0.08-0.1,0.16-0.17,0.25
                    c-0.08,0.08-0.17,0.17-0.28,0.24c-0.11,0.07-0.24,0.14-0.38,0.18c-0.27,0.09-0.58,0.11-0.88,0.08c-0.15-0.02-0.29-0.04-0.43-0.09
                    c-0.14-0.04-0.27-0.09-0.39-0.15c-0.12-0.06-0.24-0.12-0.34-0.19c-0.1-0.07-0.2-0.14-0.28-0.2c-0.16-0.14-0.28-0.27-0.35-0.37
                    c-0.04-0.05-0.06-0.09-0.08-0.12C509.36,132.62,509.35,132.61,509.35,132.61z"/>
                <path id="XMLID_129_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M469.84,130.74
                    c0,0,0.13,0.17,0.35,0.49c0.1,0.16,0.23,0.35,0.37,0.58c0.14,0.23,0.29,0.48,0.45,0.77c0.16,0.29,0.33,0.6,0.49,0.93
                    c0.17,0.33,0.34,0.69,0.5,1.06c0.16,0.37,0.33,0.76,0.48,1.17c0.15,0.4,0.29,0.82,0.43,1.23c0.06,0.21,0.12,0.42,0.19,0.63
                    c0.06,0.21,0.11,0.42,0.16,0.63c0.1,0.42,0.19,0.84,0.25,1.24c0.07,0.41,0.11,0.8,0.15,1.18c0.04,0.37,0.05,0.73,0.05,1.06
                    c0,0.16,0,0.32-0.01,0.47c-0.01,0.15-0.02,0.29-0.03,0.42c-0.02,0.27-0.06,0.5-0.08,0.68c-0.03,0.19-0.06,0.33-0.08,0.43
                    c-0.02,0.1-0.03,0.15-0.03,0.15s0-0.22,0-0.6c0-0.09-0.01-0.2-0.01-0.31c0-0.11,0-0.24-0.02-0.37c-0.01-0.13-0.02-0.27-0.03-0.42
                    c-0.01-0.15-0.03-0.3-0.05-0.46c-0.01-0.16-0.04-0.32-0.06-0.5c-0.02-0.17-0.05-0.35-0.08-0.53c-0.03-0.18-0.07-0.37-0.1-0.56
                    c-0.04-0.19-0.08-0.38-0.12-0.58c-0.08-0.39-0.19-0.79-0.29-1.2c-0.05-0.2-0.12-0.41-0.17-0.61c-0.06-0.2-0.12-0.41-0.19-0.61
                    c-0.13-0.41-0.26-0.81-0.4-1.21c-0.14-0.4-0.28-0.79-0.42-1.16c-0.14-0.38-0.28-0.74-0.42-1.08c-0.14-0.34-0.27-0.67-0.4-0.96
                    c-0.25-0.6-0.47-1.09-0.62-1.44C469.93,130.94,469.84,130.74,469.84,130.74z"/>
                <path id="XMLID_128_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M525.43,168.92
                    c0,0,0.16,0.17,0.42,0.47c0.07,0.07,0.15,0.14,0.24,0.23c0.09,0.08,0.17,0.17,0.28,0.26c0.11,0.09,0.22,0.18,0.34,0.27
                    c0.13,0.09,0.26,0.18,0.39,0.27c0.14,0.09,0.3,0.17,0.45,0.26c0.16,0.07,0.33,0.16,0.5,0.24c0.09,0.03,0.18,0.07,0.27,0.1
                    c0.05,0.02,0.09,0.04,0.14,0.05c0.05,0.01,0.09,0.03,0.14,0.04c0.09,0.03,0.19,0.06,0.29,0.09c0.1,0.03,0.2,0.05,0.3,0.07
                    c0.2,0.05,0.41,0.08,0.62,0.13c0.11,0.01,0.21,0.03,0.32,0.04c0.11,0.02,0.21,0.03,0.32,0.04c0.43,0.04,0.88,0.06,1.33,0.05
                    c0.11,0,0.22,0,0.34,0l0.17,0l0.08,0l0.1,0c0.06,0,0.11,0,0.18,0c0.07,0,0.13,0.01,0.2,0.01l0.18,0.03
                    c0.06,0.01,0.12,0.03,0.18,0.04c0.48,0.11,0.92,0.34,1.28,0.62c0.37,0.28,0.66,0.61,0.9,0.94c0.24,0.33,0.41,0.68,0.54,1.01
                    c0.13,0.33,0.22,0.64,0.27,0.92c0.06,0.28,0.07,0.53,0.08,0.73c0.01,0.2,0,0.36-0.01,0.47c-0.01,0.11-0.01,0.16-0.01,0.16
                    s-0.01-0.06-0.02-0.16c-0.01-0.11-0.04-0.26-0.08-0.46c-0.04-0.19-0.1-0.43-0.18-0.69c-0.09-0.26-0.2-0.54-0.36-0.84
                    c-0.08-0.15-0.16-0.3-0.26-0.44c-0.1-0.15-0.2-0.3-0.32-0.44c-0.24-0.29-0.52-0.56-0.85-0.79c-0.33-0.23-0.7-0.4-1.1-0.48
                    l-0.15-0.03l-0.15-0.02l-0.14-0.01c-0.05,0-0.11,0-0.16,0l-0.08,0l-0.09,0l-0.17,0.01c-0.12,0.01-0.23,0.01-0.35,0.01
                    c-0.47,0-0.93-0.01-1.39-0.07c-0.23-0.02-0.46-0.07-0.68-0.1c-0.22-0.05-0.44-0.09-0.65-0.16c-0.11-0.03-0.21-0.06-0.31-0.1
                    c-0.1-0.04-0.2-0.08-0.3-0.12c-0.05-0.02-0.1-0.04-0.15-0.06c-0.05-0.02-0.1-0.05-0.14-0.07c-0.09-0.04-0.19-0.09-0.28-0.13
                    c-0.18-0.09-0.35-0.2-0.51-0.29c-0.16-0.11-0.31-0.22-0.45-0.32c-0.13-0.12-0.26-0.23-0.38-0.33c-0.11-0.11-0.22-0.23-0.31-0.33
                    c-0.1-0.1-0.18-0.21-0.25-0.31c-0.07-0.1-0.14-0.19-0.2-0.27c-0.11-0.17-0.19-0.31-0.24-0.4
                    C525.45,168.97,525.43,168.92,525.43,168.92z"/>
                <path id="XMLID_126_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M507.97,170.5
                    c0,0,0.18,0.08,0.48,0.24c0.16,0.07,0.34,0.16,0.55,0.25c0.11,0.05,0.22,0.09,0.34,0.13c0.06,0.02,0.12,0.04,0.19,0.07
                    c0.06,0.02,0.13,0.04,0.2,0.06c0.27,0.08,0.57,0.14,0.88,0.17c0.32,0.03,0.66,0.04,1.02,0.04c0.36,0,0.74,0,1.12,0.01
                    c0.19,0.01,0.39,0.02,0.58,0.04l0.15,0.01l0.07,0.01l0.04,0l0.03,0l0.02,0l0.14,0.02l0.07,0.01l0.08,0.02
                    c0.42,0.08,0.8,0.22,1.16,0.39c0.36,0.17,0.69,0.37,1,0.58c0.31,0.21,0.59,0.43,0.85,0.65c0.26,0.22,0.49,0.44,0.7,0.64
                    c0.42,0.41,0.73,0.79,0.94,1.05c0.21,0.27,0.32,0.43,0.32,0.43s-0.15-0.13-0.4-0.35c-0.26-0.22-0.63-0.53-1.08-0.88
                    c-0.23-0.18-0.48-0.36-0.74-0.55c-0.27-0.19-0.56-0.37-0.86-0.56c-0.3-0.18-0.62-0.35-0.96-0.5c-0.17-0.07-0.34-0.14-0.51-0.19
                    c-0.09-0.03-0.17-0.06-0.26-0.08c-0.09-0.03-0.17-0.04-0.26-0.06l-0.06-0.01l-0.07-0.01l-0.14-0.02l-0.02,0l0,0
                    c-0.01,0,0.01,0,0.01,0l-0.01,0l-0.04,0l-0.07-0.01l-0.14-0.01c-0.19-0.02-0.38-0.03-0.56-0.05c-0.38-0.02-0.75-0.04-1.11-0.06
                    c-0.36-0.02-0.73-0.06-1.07-0.12c-0.17-0.03-0.33-0.07-0.49-0.12c-0.15-0.06-0.3-0.1-0.44-0.17c-0.07-0.03-0.14-0.06-0.2-0.09
                    c-0.06-0.03-0.13-0.07-0.19-0.1c-0.12-0.06-0.23-0.13-0.33-0.19c-0.21-0.12-0.37-0.25-0.51-0.35c-0.14-0.1-0.24-0.19-0.31-0.25
                    C508.01,170.53,507.97,170.5,507.97,170.5z"/>
                <path id="XMLID_125_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M513.79,79.14
                    c0,0,0.03,0.1,0.08,0.27c0.03,0.09,0.05,0.19,0.07,0.31c0.01,0.06,0.02,0.13,0.04,0.2c0.01,0.07,0.02,0.14,0.03,0.22
                    c0.02,0.15,0.03,0.32,0.03,0.5c0.01,0.18,0,0.37-0.01,0.56c-0.02,0.19-0.03,0.39-0.08,0.59c-0.03,0.2-0.09,0.4-0.14,0.6
                    c-0.07,0.2-0.12,0.4-0.2,0.58c-0.07,0.19-0.17,0.37-0.25,0.55c-0.09,0.17-0.18,0.34-0.28,0.48c-0.1,0.15-0.19,0.29-0.29,0.41
                    c-0.05,0.06-0.09,0.12-0.14,0.17c-0.05,0.05-0.09,0.1-0.14,0.15c-0.08,0.09-0.16,0.17-0.23,0.23c-0.13,0.12-0.21,0.19-0.21,0.19
                    s0.05-0.09,0.15-0.23c0.05-0.07,0.11-0.16,0.17-0.27c0.07-0.1,0.14-0.22,0.21-0.35c0.08-0.13,0.15-0.27,0.23-0.42
                    c0.08-0.15,0.15-0.32,0.23-0.48c0.07-0.17,0.14-0.35,0.21-0.53c0.07-0.18,0.12-0.37,0.18-0.56c0.05-0.19,0.11-0.38,0.14-0.57
                    c0.04-0.19,0.07-0.38,0.11-0.56c0.02-0.18,0.05-0.36,0.06-0.53c0.01-0.17,0.03-0.33,0.03-0.48c0.01-0.15,0.01-0.29,0.01-0.41
                    c0-0.12,0-0.23,0-0.32C513.79,79.24,513.79,79.14,513.79,79.14z"/>
                <path id="XMLID_123_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M518.43,79.5
                    c0,0,0.01,0.11,0.03,0.29c0.02,0.19,0.04,0.45,0.07,0.77c0.02,0.16,0.03,0.33,0.05,0.51c0.02,0.18,0.05,0.37,0.07,0.57
                    c0.05,0.39,0.11,0.81,0.18,1.23c0.04,0.21,0.07,0.42,0.11,0.62c0.04,0.2,0.08,0.4,0.13,0.6c0.09,0.39,0.18,0.75,0.26,1.05
                    c0.08,0.31,0.15,0.57,0.21,0.75c0.05,0.18,0.08,0.29,0.08,0.29s-0.06-0.09-0.15-0.26c-0.05-0.08-0.1-0.18-0.16-0.3
                    c-0.06-0.12-0.12-0.25-0.18-0.4c-0.07-0.15-0.13-0.31-0.19-0.49c-0.06-0.17-0.13-0.36-0.18-0.55c-0.05-0.2-0.12-0.4-0.16-0.6
                    c-0.05-0.21-0.09-0.42-0.13-0.63c-0.03-0.21-0.07-0.43-0.09-0.64c-0.03-0.21-0.04-0.42-0.05-0.62c-0.02-0.4-0.02-0.78,0-1.11
                    c0.01-0.16,0.02-0.31,0.03-0.44c0.01-0.13,0.03-0.24,0.04-0.34C518.41,79.61,518.43,79.5,518.43,79.5z"/>
            </g>
            <rect x="2.5" y="194.89" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" width="191.98" height="191.98"/>
            <path id="XMLID_469_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M111.81,381.48
                c-0.37-2-30.97-38.88-32.5-52.53c-1.53-13.64-2.95-24.71-3.52-29.16c-0.57-4.45-0.99-5.67-6.91-8.91
                c-5.92-3.24-9.14-17.73-7.35-22.76c1.8-5.02-0.97-14.68,1.8-18.26c2.77-3.58,24.07-28.39,26.97-34.1
                c2.9-5.7,11.26-10.84,14.92-10.84c3.66,0,15.23,4.04,22.42,4.59c7.19,0.55,19.71-0.59,22.58,5.97c2.87,6.56,7.14,5.96,10,11.96
                c2.85,6.01,2.24,9.43,3.69,15.42c1.45,5.99-0.84,12.94,1.45,16.74c2.28,3.8,8.94,6.5,7.46,11.28c-1.48,4.78-7.44,7.08-11.02,7.93
                c-3.58,0.84-4.45,1.74-5.7,7.93c-1.26,6.19-3.51,11.11-6.52,14.29c-3.01,3.18-4.49,15.67-5.04,18.2c-0.55,2.53-9.18,4.6-7.44,6.76
                c1.74,2.16,5.48,1.07,6.46,4.89c0.98,3.82,9.16,12.16,12.54,17.98c3.38,5.83,14.63,15.78,16.93,17.63
                c2.31,1.86,10.63,20.37,10.63,20.37h-69.68L111.81,381.48z"/>
            <g id="XMLID_411_">
                <path id="XMLID_465_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M194.49,386.88
                    c0,0-13.13-22.55-15.94-25.53s-4.2-0.35-7.18-1.4c-2.98-1.05-7.71-10.33-12.08-15.24c-4.38-4.9-13.45-9.87-12.16-10.34
                    s8.45-5.02,8.45-5.02s-0.63-0.58-2.5-2.22c-1.87-1.63-10.16-2.45-10.97-2.57c-0.82-0.12,0.23-1.63,1.87-2.8
                    c1.63-1.17,4.44-10.39,5.72-14.94c1.28-4.55,4.79-10.04,5.14-13.89c0.35-3.85,0-9.57,0-11.68s0.35-3.15,0.7-4.09
                    c0.35-0.93-1.28-1.52-3.5-2.69c-2.22-1.17-9.46-0.58-10.27,0c-0.82,0.58-2.8,1.52-4.9,1.52c-2.1,0-2.13,0.81-3.04,3.15
                    c-0.35,0.9-1.4,0.16-2.02,0.47c-0.62,0.31,0.7,1.09,1.63,1.87c0.93,0.78,0,8.87,0.16,10.35c0.16,1.48,1.01,1.01,1.63,2.02
                    c0.62,1.01,0.23,4.75-0.62,5.37c-0.86,0.62-2.1,1.63-2.72,2.26c-0.62,0.62-2.1,1.01-2.57,1.71c-0.47,0.7,4.75,2.02,5.76,2.49
                    c1.01,0.47,4.05-0.39,4.9-1.71c0.86-1.32,0.08-2.88-0.62-3.97c-0.7-1.09-1.63-3.97-1.71-5.76c-0.08-1.79-1.32-2.26-1.63-3.42
                    c-0.31-1.17,0.54-1.25,1.63-0.95c1.09,0.29-0.16-2.86,0-3.87c0.16-1.01-0.16-1.17,1.32-0.86c1.48,0.31,2.26-0.16,1.71-0.16
                    c-0.54,0-3.19-1.71-2.41-2.65c0.78-0.93,2.1-1.01,2.1-1.01s0.08,2.88,3.04,2.88s2.65-2.1,2.96-2.26c0.31-0.16,1.17,0.62,0.86,1.63
                    c-0.31,1.01-3.19,1.87-3.19,1.87s1.25,0.31,2.18-0.16c0.93-0.47,1.4-0.23,0.62,0.7c-0.78,0.93-0.47,1.17-0.31,1.87
                    c0.16,0.7-0.86,1.95-0.86,2.72c0,0.78,2.18-0.78,2.72-1.09c0.54-0.31,1.17-2.26,1.87-3.66c0.7-1.4,1.17-0.85,1.35,2.48
                    c0.18,3.33,0,3.68-2.8,7.88c-2.8,4.2-4.2,9.46-4.9,11.73c-0.7,2.28-1.4,7.71-1.49,11.21c-0.09,3.5-4.81,3.33-7.44,3.5
                    c-2.63,0.18-2.63,2.1-2.1,4.2c0.53,2.1,0,1.75-2.28,2.63s-2.8-2.28-3.33-3.15c-0.53-0.88-1.26,1-2.8,2.63
                    c-0.76,0.81-3.06,2.33-6.04,1.39c-2.98-0.94-6.39-3.84-8.67-6.12c-2.28-2.28-5.78-1.05-7.53-1.93
                    c-1.75-0.88-12.08-12.26-11.91-11.38c0.18,0.88,10.68,12.08,11.56,13.48c0.88,1.4,7.88,4.55,9.28,4.55c1.4,0,4.73,2.63,7,3.68
                    c2.28,1.05,4.38,2.28,5.78,4.2c1.4,1.93-3.15,2.98-3.15,2.98s5.25,0.35,7,0.18s3.5-3.33,4.38-4.03c0.88-0.7,6.3-3.5,7.71-4.38
                    c1.4-0.88,2.8,1.23,1.05,1.93c-1.75,0.7-1.93,2.98,0.18,4.03c2.1,1.05,6.13,3.5,7.71,7c1.58,3.5-2.1,2.28,0,5.43
                    s8.06,2.28,10.68,4.73c2.63,2.45,4.55,9.46,6.48,13.48c1.93,4.03,6.13,4.2,7.71,8.93s0.7,9.77,0.7,9.77H194.49z M141.68,281.72
                    c-0.31,0-0.56-0.25-0.56-0.56c0-0.31,0.25-0.56,0.56-0.56s0.56,0.25,0.56,0.56C142.24,281.47,141.99,281.72,141.68,281.72z
                    M150.78,279.7c-0.31,0.7-0.93,1.71-1.71,1.4c-0.78-0.31-3.82-1.61-5.92-1.79c-1.56-0.13-2-1.09-0.77-1.25
                    c1.23-0.16,4.19-0.23,4.74-0.54c0.54-0.31,2.02-0.54,3.27,0c1.25,0.54,1.25,0.54,1.25,0.54S151.09,279,150.78,279.7z"/>
                <path id="XMLID_462_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M124.94,274.72
                    c-3.15,0-3.04-0.82-7.01-2.8c-3.97-1.98-4.79-0.58-6.3,0c-1.52,0.58-1.87,0.35-4.79,0.93c-2.92,0.58-6.3,2.45-5.95,3.04
                    c0.35,0.58,2.08,1.79,3.04,0.82c2.49-2.53,6.07-2.76,7.55-2.45c1.48,0.31-2.72,1.71-2.1,1.87c0.62,0.16,2.96-1.09,4.11-1.4
                    c1.15-0.31,5.39,2.02,2.2,1.87c-3.19-0.16-5.68,0.62-6.85,1.01c-1.17,0.39-1.87,0.62-3.35,0.08c-1.48-0.54-1.32,0.47-1.01,0.94
                    c0.31,0.47,1.87,0.39,1.01,1.13c-0.86,0.74-1.48-0.27-2.33,0.35c-0.86,0.62,1.79,0.62,1.71,1.07s1.09,0.49,2.02,0
                    c0.93-0.49,2.33-0.91,2.33-0.91c1.87,2.65,3.58,2.65,3.58,2.65c-1.25-0.47-2.26-2.33-2.18-3.15c0.08-0.82,1.73-0.56,1.73-0.56
                    s0.53,2.94,3.41,2.94c2.88,0,3.27-2.94,3.27-2.94s1.48,0.99,1.17,1.98s-3.5,1.58-3.5,1.58s0.78,0.39,0.86,1.17
                    c0.08,0.78-3.46,0.86-3.5,1.56c-0.04,0.7,4.36,1.28,5.41,1.28c1.05,0,3.39-2.69,3.62-3.74s0-2.45,0.82-2.41
                    c0.82,0.04,2.1-0.51,3.04-1.42c0.93-0.91,2.69-3.99,2.69-5.37C129.61,272.44,128.09,274.72,124.94,274.72z M114.04,279.74
                    c-0.31,0-0.56-0.25-0.56-0.56s0.25-0.56,0.56-0.56s0.56,0.25,0.56,0.56S114.36,279.74,114.04,279.74z"/>
                <path id="XMLID_461_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M119.45,298.77
                    c1.17,0.7,1.75,0.93,2.33,0.7c0.58-0.23,0,0.12,0.58-0.23c0.58-0.35,2.45-0.64,3.74,0.32c1.28,0.96,3.15,2.01,2.22,2.36
                    c-0.93,0.35-1.87,0.23-2.92,0c-1.05-0.23-2.33,0.58-4.32-0.7S119.45,298.77,119.45,298.77z"/>
                <path id="XMLID_460_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M121.05,293.63
                    c0,0-4.4,3.5-6.44,4.75c-2.04,1.25,1.32,0.47,3.22-0.31C119.73,297.29,121.05,293.63,121.05,293.63z"/>
                <path id="XMLID_459_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M139.73,302.74
                    c0,0,1.32,0.99,2.51,3.74c1.2,2.75,1.3,6.54,1.3,6.54s0.12-1.01-1.3-1.48c-1.42-0.47-0.8-0.93-0.56-2.18
                    C141.91,308.11,140.2,302.74,139.73,302.74z"/>
                <path id="XMLID_458_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M128.13,304.53
                    c-0.5,1.16-0.08,1.48,0,2.26c0.08,0.78,0.12,1.79,0.45,1.32c0.33-0.47,0.18-1.25,0.49-2.1
                    C129.38,305.15,128.53,303.61,128.13,304.53z"/>
                <path id="XMLID_457_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M112.35,312.7
                    c-1.23-0.16-1.62-1.32,0-2.49c1.62-1.17,2.14-2.88,2.89-2.34c0.75,0.54,1.79,2.46,4.19,2.36s4.34-1.82,6.83-1.58
                    c2.49,0.23,2.65,1.87,4.44,1.01c1.79-0.86,2.65,0.47,4.13,0.93c1.48,0.47,4.59-0.54,4.36,0.31s-1.32,0.78-1.63,1.25
                    c-0.31,0.47,1.5,1.79,1.65,3.81s0.77,2.18-0.4,2.18c-1.17,0-1.87-0.54-2.33,0.39c-0.47,0.93-1.71,1.87-1.71,1.87
                    s-0.62-3.19-1.71-3.04c-1.09,0.16-3.11-0.16-3.42-0.47c-0.31-0.31-2.84-0.08-4.14,0.23c-1.31,0.31-6.29-0.93-4.89-1.48
                    c1.4-0.54,2.02-0.96,2.49-1.57c0.47-0.61,0.54-2.56,1.4-1.47c0.86,1.09,3.04,0.54,5.14,0.78c2.1,0.23,3.81-0.39,4.05-1.01
                    c0.23-0.62-0.16-1.32-0.16-1.32s-18.98-0.7-19.53,0S113.54,312.86,112.35,312.7z"/>
                <path id="XMLID_456_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M119.45,286.74
                    c0,0-0.03,0.03-0.08,0.09c-0.03,0.03-0.06,0.07-0.11,0.11c-0.04,0.04-0.09,0.09-0.15,0.14c-0.06,0.05-0.12,0.1-0.19,0.16
                    c-0.07,0.06-0.15,0.11-0.24,0.17c-0.09,0.06-0.18,0.12-0.28,0.18c-0.1,0.06-0.21,0.13-0.32,0.19c-0.12,0.06-0.24,0.12-0.36,0.19
                    c-0.13,0.06-0.26,0.11-0.4,0.18c-0.07,0.03-0.14,0.06-0.21,0.09c-0.07,0.03-0.15,0.05-0.22,0.08c-0.15,0.05-0.3,0.1-0.46,0.16
                    c-0.16,0.04-0.32,0.09-0.48,0.13c-0.08,0.02-0.16,0.04-0.25,0.07c-0.08,0.02-0.17,0.03-0.25,0.05c-0.34,0.06-0.68,0.14-1.02,0.17
                    c-0.17,0.02-0.35,0.04-0.52,0.06c-0.17,0.02-0.34,0.02-0.51,0.03c-0.34,0.01-0.68,0.03-1,0.01c-0.32-0.01-0.64-0.02-0.93-0.05
                    c-0.3-0.03-0.57-0.07-0.83-0.1c-0.13-0.02-0.25-0.03-0.37-0.05c-0.12-0.02-0.23-0.05-0.33-0.07c-0.21-0.04-0.38-0.08-0.53-0.11
                    c-0.29-0.07-0.46-0.11-0.46-0.11s0.17-0.01,0.47-0.01c0.15,0,0.33,0,0.54,0.01c0.1,0,0.21,0,0.33,0.01c0.12,0,0.24,0,0.37,0
                    c0.25,0,0.53-0.01,0.82,0c0.29,0,0.6-0.02,0.91-0.03c0.16-0.01,0.32,0,0.48-0.02c0.16-0.01,0.33-0.03,0.49-0.04
                    c0.16-0.01,0.33-0.02,0.5-0.04c0.17-0.02,0.33-0.04,0.5-0.06c0.33-0.03,0.66-0.09,0.99-0.14c0.16-0.02,0.32-0.06,0.48-0.09
                    c0.16-0.03,0.31-0.07,0.47-0.1c0.15-0.04,0.3-0.08,0.45-0.12c0.07-0.02,0.15-0.04,0.22-0.06c0.07-0.02,0.14-0.04,0.21-0.06
                    c0.14-0.04,0.27-0.08,0.4-0.12c0.13-0.05,0.25-0.09,0.37-0.13c0.12-0.04,0.23-0.09,0.34-0.13c0.11-0.04,0.21-0.08,0.3-0.13
                    c0.09-0.04,0.18-0.09,0.26-0.12c0.08-0.04,0.15-0.08,0.22-0.11c0.13-0.07,0.23-0.13,0.3-0.17
                    C119.42,286.77,119.45,286.74,119.45,286.74z"/>
                <path id="XMLID_455_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M137.08,288.73
                    c0,0,0.08,0.03,0.22,0.11c0.07,0.04,0.15,0.08,0.24,0.14c0.09,0.06,0.19,0.12,0.3,0.2c0.21,0.16,0.45,0.35,0.69,0.58
                    c0.06,0.06,0.12,0.12,0.18,0.18c0.06,0.06,0.12,0.12,0.18,0.19c0.12,0.13,0.23,0.26,0.34,0.4l0.16,0.21
                    c0.05,0.07,0.1,0.14,0.15,0.21c0.1,0.14,0.19,0.29,0.27,0.43c0.09,0.14,0.16,0.28,0.23,0.42c0.03,0.07,0.07,0.13,0.1,0.2
                    c0.03,0.07,0.05,0.13,0.08,0.19c0.05,0.12,0.09,0.24,0.13,0.34c0.03,0.1,0.05,0.2,0.07,0.27c0.04,0.15,0.05,0.24,0.05,0.24
                    s-0.06-0.07-0.16-0.19c-0.09-0.12-0.23-0.29-0.39-0.49c-0.08-0.1-0.16-0.21-0.25-0.33c-0.09-0.12-0.18-0.24-0.28-0.36
                    c-0.05-0.06-0.1-0.13-0.15-0.19c-0.05-0.06-0.1-0.13-0.15-0.19c-0.1-0.13-0.21-0.27-0.31-0.4c-0.1-0.13-0.21-0.26-0.31-0.4
                    c-0.1-0.13-0.21-0.26-0.31-0.38c-0.2-0.25-0.38-0.48-0.54-0.69c-0.16-0.21-0.29-0.38-0.38-0.5c-0.05-0.06-0.08-0.11-0.11-0.14
                    C137.1,288.75,137.08,288.73,137.08,288.73z"/>
                <path id="XMLID_454_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M142.39,296.33
                    c0,0,0.02,0.03,0.06,0.08c0.04,0.05,0.1,0.14,0.16,0.25c0.06,0.11,0.14,0.25,0.22,0.41c0.08,0.16,0.16,0.34,0.25,0.54
                    c0.17,0.4,0.35,0.87,0.54,1.37c0.09,0.25,0.19,0.51,0.29,0.77c0.1,0.26,0.2,0.53,0.31,0.79c0.1,0.26,0.21,0.52,0.33,0.77
                    c0.11,0.25,0.23,0.5,0.35,0.73c0.12,0.23,0.24,0.45,0.37,0.65c0.13,0.2,0.26,0.39,0.38,0.56c0.07,0.08,0.13,0.16,0.19,0.23
                    c0.06,0.07,0.13,0.14,0.18,0.2c0.12,0.12,0.23,0.23,0.33,0.3c0.09,0.08,0.17,0.14,0.23,0.18c0.05,0.04,0.08,0.06,0.08,0.06
                    s-0.03-0.01-0.1-0.03c-0.06-0.02-0.16-0.05-0.28-0.11c-0.06-0.03-0.12-0.06-0.19-0.1c-0.07-0.04-0.14-0.08-0.22-0.13
                    c-0.07-0.05-0.15-0.11-0.23-0.17c-0.08-0.06-0.16-0.13-0.24-0.21c-0.16-0.15-0.33-0.33-0.49-0.53c-0.16-0.2-0.32-0.42-0.46-0.65
                    c-0.14-0.24-0.29-0.49-0.41-0.75c-0.13-0.26-0.25-0.53-0.36-0.8c-0.22-0.54-0.4-1.1-0.55-1.62c-0.08-0.26-0.14-0.51-0.2-0.75
                    c-0.06-0.24-0.11-0.47-0.17-0.68c-0.05-0.21-0.1-0.4-0.14-0.57c-0.04-0.17-0.08-0.32-0.12-0.44c-0.04-0.12-0.06-0.22-0.09-0.28
                    C142.4,296.37,142.39,296.33,142.39,296.33z"/>
                <path id="XMLID_453_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M138.78,307.41
                    c0,0,0.04,0.05,0.1,0.13c0.03,0.04,0.07,0.09,0.1,0.15c0.04,0.06,0.08,0.13,0.12,0.2c0.09,0.15,0.18,0.33,0.26,0.53
                    c0.02,0.05,0.04,0.1,0.06,0.16c0.02,0.05,0.04,0.11,0.05,0.16c0.03,0.11,0.06,0.23,0.08,0.35c0.02,0.12,0.03,0.24,0.03,0.36
                    c0,0.06,0,0.12,0,0.18c0,0.06,0,0.11-0.01,0.17c-0.01,0.06-0.01,0.11-0.02,0.16c-0.01,0.05-0.02,0.1-0.03,0.15
                    c-0.02,0.1-0.05,0.19-0.08,0.27c-0.03,0.08-0.06,0.16-0.09,0.22c-0.03,0.07-0.06,0.12-0.08,0.17c-0.05,0.09-0.08,0.14-0.08,0.14
                    s0-0.06,0.01-0.16c0-0.05,0.01-0.11,0.02-0.18c0-0.07,0.02-0.15,0.02-0.23c0-0.08,0.01-0.17,0.02-0.27c0-0.05,0-0.1,0.01-0.14
                    c0-0.05,0-0.1,0-0.15c0-0.1-0.01-0.21-0.02-0.31c-0.01-0.11-0.02-0.21-0.04-0.32c-0.02-0.11-0.04-0.21-0.06-0.32
                    c-0.02-0.1-0.05-0.21-0.08-0.31c-0.06-0.2-0.11-0.39-0.15-0.55c-0.05-0.16-0.08-0.3-0.1-0.4
                    C138.79,307.47,138.78,307.41,138.78,307.41z"/>
                <path id="XMLID_452_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M85.71,300.46
                    c0.75,3.64,3,11.38,4.58,13.63c1.58,2.26,4.21,5.23,4.21,5.23s-5.6-4.04-7.53-8.41s-2.28-6.54-2.98-8.17
                    C83.29,301.11,85.02,297.08,85.71,300.46z"/>
                <path id="XMLID_451_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M96.95,322.47
                    c0,0,0.05,0.07,0.14,0.19c0.09,0.12,0.24,0.3,0.42,0.52c0.19,0.22,0.43,0.48,0.71,0.76c0.29,0.28,0.62,0.59,0.99,0.91
                    c0.38,0.31,0.81,0.63,1.27,0.95c0.47,0.3,0.99,0.61,1.51,0.95c0.53,0.34,1.07,0.68,1.61,1.04c0.54,0.36,1.09,0.73,1.64,1.11
                    c0.55,0.38,1.09,0.76,1.62,1.14c0.53,0.38,1.05,0.77,1.55,1.14c0.5,0.37,0.97,0.74,1.42,1.1c0.45,0.35,0.86,0.7,1.25,1.02
                    c0.19,0.16,0.37,0.32,0.54,0.47c0.17,0.15,0.33,0.3,0.48,0.43c0.3,0.27,0.55,0.52,0.76,0.73c0.05,0.05,0.1,0.1,0.15,0.15
                    c0.04,0.05,0.08,0.09,0.12,0.14c0.08,0.08,0.14,0.16,0.19,0.21c0.1,0.12,0.16,0.18,0.16,0.18s-0.06-0.05-0.18-0.15
                    c-0.12-0.1-0.29-0.25-0.52-0.43c-0.23-0.18-0.5-0.4-0.82-0.64c-0.32-0.25-0.69-0.51-1.09-0.81c-0.2-0.15-0.41-0.3-0.63-0.45
                    c-0.22-0.16-0.44-0.32-0.67-0.48c-0.46-0.33-0.95-0.67-1.46-1.03c-0.51-0.35-1.04-0.72-1.57-1.09c-0.53-0.37-1.08-0.75-1.63-1.12
                    c-0.54-0.38-1.09-0.75-1.63-1.12c-0.54-0.37-1.07-0.73-1.58-1.08c-0.51-0.35-1.01-0.68-1.48-1.02c-0.46-0.35-0.88-0.7-1.24-1.05
                    c-0.36-0.35-0.67-0.69-0.93-1c-0.26-0.31-0.47-0.6-0.63-0.84c-0.16-0.24-0.28-0.44-0.36-0.58
                    C96.98,322.55,96.95,322.47,96.95,322.47z"/>
                <path id="XMLID_450_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M94.5,341.74
                    c0,0,2.45,1.23,4.03,0c1.58-1.23,3.15-1.54,4.2,0.54c1.05,2.08,2.49,4.53,2.49,4.53s-2.46-3.25-3.53-3.02
                    c-1.06,0.22-3.17-0.35-3.34,1.45c-0.17,1.79,1.4,6.86,0.53,6.32C98,351.02,94.5,341.74,94.5,341.74z"/>
                <path id="XMLID_449_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M101.69,339.46
                    c2.26-0.53,4.01-2.1,5.77-2.98c1.75-0.88,4.35-2.1,6.03,0.7c0,0-2.75-1.04-2.2,0.27c0.55,1.31-0.67,2.18-1.55,2.01
                    c-0.88-0.18-1.84,1.75-2.15,2.28c-0.31,0.53-1.18-0.19-1.71-0.97c-0.53-0.78,0.07,2.02-0.66,2.37
                    C104.48,343.49,101.69,339.46,101.69,339.46z"/>
                <path id="XMLID_448_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M130.92,339.3
                    c0,0,6.94-2.2,8.81-4.74c0,0,5.31,2.98,4.79,3.84s-1.8,0.53-2.89,1.58c-1.09,1.05,1.19,1.93,0.75,4.03c-0.44,2.1-0.78,1.23-1.92,0
                    c-1.14-1.23-2.56-2.76-2.73-0.85c-0.17,1.9-1.57,5.41-3.49,6.98s-5.11,1.46-5.11,1.46s2.66-2.68,1.26-2.51s-7.18,1.75-7.35,1.05
                    c-0.18-0.7,3.58-1.4,4.85-2.45s4.4-1.05,5.9-2.1c1.5-1.05,4.2-3.69,3.29-4.82c-0.91-1.13-1.26,1.01-2.49,2.39
                    c-1.23,1.38-2.8,2.25-3.85,1.73c-1.05-0.53-1.93-2.1,0.18-3.15C133.02,340.69,132.83,339.18,130.92,339.3z"/>
                <path id="XMLID_447_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M107.58,349.27
                    c0,0,0.16,0.08,0.42,0.25c0.26,0.17,0.63,0.42,1.05,0.77c0.21,0.17,0.43,0.36,0.65,0.58c0.22,0.22,0.45,0.45,0.67,0.72
                    c0.22,0.26,0.43,0.55,0.61,0.87c0.19,0.31,0.35,0.66,0.46,1.02c0.06,0.18,0.09,0.37,0.12,0.57c0.01,0.1,0.01,0.2,0.02,0.29
                    c0,0.1,0,0.18-0.01,0.27c-0.02,0.37-0.07,0.72-0.14,1.06c-0.07,0.34-0.17,0.65-0.27,0.94c-0.09,0.29-0.2,0.56-0.27,0.8
                    c-0.07,0.25-0.12,0.47-0.1,0.66c0.01,0.19,0.08,0.36,0.17,0.48c0.09,0.12,0.17,0.21,0.23,0.26c0.06,0.05,0.1,0.08,0.1,0.08
                    s-0.04-0.02-0.12-0.06c-0.07-0.04-0.18-0.1-0.3-0.21c-0.12-0.11-0.25-0.28-0.3-0.52c-0.06-0.23-0.05-0.5-0.01-0.77
                    c0.04-0.27,0.11-0.56,0.18-0.85c0.07-0.29,0.13-0.6,0.18-0.92c0.05-0.31,0.08-0.65,0.08-0.97c0-0.08,0-0.18,0-0.25
                    c-0.01-0.08-0.01-0.16-0.02-0.23c-0.02-0.16-0.05-0.31-0.1-0.47c-0.09-0.31-0.23-0.62-0.39-0.91c-0.16-0.29-0.34-0.57-0.53-0.83
                    c-0.19-0.26-0.38-0.51-0.57-0.74c-0.19-0.23-0.38-0.44-0.56-0.64c-0.36-0.39-0.67-0.7-0.89-0.92
                    C107.71,349.39,107.58,349.27,107.58,349.27z"/>
                <path id="XMLID_446_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M124.01,365.55
                    c0,0-1.84-2.1-2.96-2.28c-1.11-0.18-4.31-1.93-7.08-4.38c0,0-1.09,1.58,1.36,3.33c2.45,1.75,3.57,2.6,3.53,4.28
                    c-0.03,1.68,3.12,1.68,3.12,1.68s-4.18-2.8-2.56-3.85S124.01,365.55,124.01,365.55z"/>
                <path id="XMLID_445_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M130.57,373.78l-1.99,3.68
                    c-0.64-2.1-1.41-2.1,0.55-4.73c1.96-2.63,1.09-6.23,1.09-6.23s2.28,2.28,4.55,5.48c2.28,3.2,2.63,4.71,2.63,4.71
                    s-1.95-1.16-3.6-3.61s-2.53-1.4-2.88-0.35S131.62,373.08,130.57,373.78"/>
                <path id="XMLID_444_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M143.55,376.69
                    c0,0-1.07-5.71-2.47-8.51c-1.4-2.8-2.3-4.64-2.3-4.64s3.91,1.84,4.77,4.64s9.44,8.51,9.44,8.51s-6.69-2.83-8.43-4.71
                    C144.56,371.98,144.97,374.52,143.55,376.69z"/>
                <path id="XMLID_443_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M155.44,358.9
                    c0,0,0.08,0.03,0.24,0.09c0.15,0.06,0.38,0.15,0.66,0.28c0.28,0.14,0.62,0.3,1,0.52c0.19,0.1,0.39,0.23,0.6,0.35
                    c0.21,0.13,0.42,0.27,0.64,0.43c0.44,0.31,0.9,0.67,1.34,1.11c0.44,0.44,0.88,0.94,1.23,1.54c0.04,0.07,0.09,0.14,0.13,0.22
                    c0.04,0.07,0.08,0.15,0.13,0.22c0.04,0.07,0.08,0.15,0.13,0.22c0.04,0.07,0.09,0.14,0.13,0.2c0.09,0.14,0.18,0.27,0.28,0.41
                    c0.1,0.14,0.2,0.27,0.3,0.41c0.42,0.54,0.89,1.06,1.37,1.58c0.48,0.52,0.96,1.04,1.42,1.58c0.46,0.53,0.88,1.09,1.27,1.64
                    c0.19,0.28,0.37,0.55,0.55,0.81c0.18,0.26,0.36,0.51,0.54,0.75c0.37,0.48,0.72,0.93,1.07,1.33c0.68,0.81,1.28,1.46,1.71,1.91
                    c0.21,0.22,0.38,0.4,0.5,0.52c0.12,0.12,0.18,0.18,0.18,0.18s-0.07-0.06-0.2-0.16c-0.13-0.1-0.32-0.26-0.56-0.46
                    c-0.23-0.2-0.52-0.45-0.84-0.75c-0.16-0.15-0.32-0.31-0.5-0.48c-0.17-0.17-0.35-0.35-0.54-0.54c-0.37-0.38-0.77-0.81-1.16-1.28
                    c-0.2-0.23-0.39-0.48-0.59-0.74c-0.1-0.13-0.19-0.26-0.29-0.39c-0.09-0.13-0.19-0.27-0.29-0.4c-0.38-0.53-0.82-1.05-1.27-1.57
                    c-0.46-0.52-0.94-1.03-1.42-1.55c-0.48-0.53-0.95-1.06-1.39-1.63c-0.11-0.14-0.22-0.28-0.31-0.43c-0.1-0.15-0.2-0.29-0.29-0.45
                    c-0.05-0.08-0.09-0.15-0.14-0.23c-0.04-0.08-0.08-0.15-0.12-0.22c-0.04-0.07-0.08-0.15-0.12-0.22c-0.04-0.07-0.08-0.14-0.12-0.22
                    c-0.31-0.55-0.69-1.05-1.1-1.49c-0.1-0.11-0.21-0.21-0.31-0.32c-0.11-0.1-0.21-0.2-0.31-0.29c-0.1-0.1-0.21-0.19-0.31-0.28
                    c-0.1-0.09-0.2-0.18-0.31-0.26c-0.41-0.33-0.8-0.62-1.15-0.86c-0.35-0.24-0.67-0.44-0.94-0.6c-0.13-0.08-0.25-0.15-0.36-0.21
                    c-0.1-0.06-0.19-0.11-0.27-0.15C155.51,358.94,155.44,358.9,155.44,358.9z"/>
                <path id="XMLID_442_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M143.55,356.97
                    c0,0,0.05,0.08,0.15,0.23c0.09,0.15,0.26,0.35,0.47,0.6c0.22,0.24,0.51,0.53,0.86,0.82c0.35,0.29,0.77,0.59,1.25,0.9
                    c0.48,0.3,1.01,0.61,1.58,0.92c0.57,0.31,1.19,0.62,1.81,0.98c0.16,0.09,0.31,0.18,0.47,0.27c0.16,0.1,0.32,0.19,0.47,0.29
                    c0.31,0.2,0.63,0.41,0.93,0.63c0.61,0.45,1.21,0.95,1.76,1.5c0.54,0.56,1.04,1.17,1.44,1.83c0.21,0.33,0.38,0.67,0.53,1.02
                    c0.07,0.18,0.14,0.35,0.21,0.53c0.05,0.18,0.11,0.36,0.16,0.54c0.18,0.7,0.31,1.39,0.41,2.04c0.11,0.65,0.18,1.26,0.3,1.81
                    c0.12,0.55,0.28,1.04,0.47,1.45c0.02,0.05,0.05,0.1,0.07,0.15c0.03,0.05,0.05,0.1,0.08,0.14c0.05,0.09,0.1,0.19,0.15,0.27
                    c0.11,0.17,0.2,0.33,0.3,0.46c0.1,0.14,0.18,0.26,0.26,0.36c0.08,0.1,0.15,0.19,0.2,0.26c0.11,0.14,0.17,0.22,0.17,0.22
                    s-0.07-0.07-0.19-0.19c-0.06-0.06-0.14-0.14-0.23-0.23c-0.09-0.1-0.19-0.21-0.3-0.34c-0.11-0.13-0.22-0.28-0.35-0.44
                    c-0.06-0.08-0.12-0.17-0.18-0.26c-0.03-0.05-0.06-0.09-0.09-0.14c-0.03-0.05-0.06-0.1-0.09-0.15c-0.12-0.2-0.23-0.43-0.33-0.68
                    c-0.1-0.25-0.19-0.51-0.27-0.79c-0.16-0.56-0.27-1.17-0.4-1.81c-0.13-0.64-0.28-1.3-0.48-1.98c-0.18-0.66-0.5-1.31-0.88-1.92
                    c-0.38-0.61-0.86-1.19-1.38-1.72c-0.52-0.53-1.09-1.02-1.68-1.46c-0.59-0.44-1.19-0.85-1.8-1.21c-0.61-0.36-1.21-0.71-1.77-1.04
                    c-0.56-0.34-1.09-0.68-1.55-1.02c-0.23-0.17-0.45-0.34-0.66-0.51c-0.2-0.17-0.39-0.33-0.55-0.5c-0.34-0.32-0.6-0.64-0.8-0.91
                    c-0.2-0.28-0.34-0.5-0.41-0.66C143.59,357.06,143.55,356.97,143.55,356.97z"/>
                <path id="XMLID_441_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M139.73,373.96
                    c0,0,0.03,0.03,0.08,0.1c0.05,0.07,0.12,0.16,0.2,0.29c0.17,0.25,0.4,0.62,0.62,1.09c0.11,0.23,0.23,0.49,0.34,0.77
                    c0.11,0.28,0.22,0.58,0.31,0.89c0.1,0.31,0.18,0.64,0.25,0.98c0.07,0.34,0.12,0.68,0.16,1.03c0.03,0.35,0.06,0.7,0.05,1.04
                    c0,0.35-0.02,0.68-0.06,1.01c-0.04,0.33-0.09,0.64-0.16,0.93c-0.07,0.29-0.15,0.57-0.23,0.81c-0.04,0.12-0.08,0.24-0.13,0.35
                    c-0.05,0.11-0.09,0.21-0.13,0.31c-0.08,0.19-0.17,0.36-0.24,0.49c-0.07,0.13-0.14,0.23-0.18,0.3c-0.04,0.07-0.06,0.11-0.06,0.11
                    s0.05-0.17,0.13-0.45c0.04-0.15,0.09-0.32,0.14-0.52c0.02-0.1,0.05-0.21,0.07-0.32c0.02-0.11,0.05-0.23,0.07-0.36
                    c0.05-0.25,0.09-0.52,0.12-0.8c0.04-0.29,0.06-0.59,0.08-0.9c0-0.16,0.01-0.32,0.01-0.48c0-0.16,0-0.32-0.01-0.49
                    c-0.01-0.33-0.03-0.66-0.06-0.99c-0.04-0.33-0.08-0.66-0.13-0.99c-0.06-0.32-0.12-0.64-0.19-0.95c-0.07-0.31-0.15-0.6-0.23-0.88
                    c-0.08-0.28-0.16-0.54-0.24-0.78c-0.08-0.24-0.16-0.46-0.23-0.66c-0.07-0.19-0.13-0.36-0.19-0.5
                    C139.79,374.12,139.73,373.96,139.73,373.96z"/>
                <path id="XMLID_440_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M152.98,373.96
                    c0,0,0.14,0.13,0.36,0.36c0.11,0.12,0.26,0.25,0.41,0.42c0.16,0.16,0.34,0.34,0.54,0.54c0.19,0.2,0.41,0.41,0.62,0.65
                    c0.22,0.24,0.45,0.49,0.67,0.77c0.22,0.28,0.43,0.58,0.62,0.91c0.2,0.32,0.36,0.67,0.51,1.02c0.07,0.18,0.14,0.36,0.2,0.53
                    c0.06,0.18,0.12,0.36,0.17,0.54c0.1,0.36,0.19,0.72,0.27,1.05c0.08,0.34,0.15,0.67,0.21,0.98c0.07,0.31,0.13,0.61,0.18,0.88
                    c0.11,0.55,0.18,1.01,0.23,1.33c0.05,0.32,0.07,0.51,0.07,0.51s-0.07-0.17-0.19-0.48c-0.11-0.31-0.27-0.74-0.45-1.28
                    c-0.09-0.26-0.18-0.55-0.28-0.86c-0.09-0.31-0.2-0.63-0.29-0.96c-0.1-0.34-0.2-0.67-0.31-1.01c-0.06-0.17-0.12-0.34-0.18-0.51
                    c-0.07-0.17-0.13-0.34-0.2-0.5c-0.14-0.33-0.29-0.66-0.46-0.97c-0.17-0.31-0.36-0.6-0.55-0.89c-0.2-0.28-0.39-0.55-0.58-0.8
                    c-0.19-0.25-0.38-0.49-0.54-0.71c-0.17-0.22-0.32-0.43-0.45-0.61c-0.13-0.19-0.24-0.35-0.33-0.49
                    C153.08,374.12,152.98,373.96,152.98,373.96z"/>
                <path id="XMLID_439_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M117.09,351.56
                    c0,0,0.06,0.03,0.17,0.09c0.11,0.06,0.28,0.15,0.49,0.26c0.42,0.23,1.01,0.55,1.73,0.94c0.36,0.19,0.75,0.4,1.16,0.62
                    c0.41,0.22,0.85,0.44,1.3,0.67c0.23,0.11,0.46,0.23,0.69,0.35c0.23,0.12,0.47,0.23,0.71,0.35c0.24,0.11,0.48,0.23,0.72,0.34
                    c0.24,0.11,0.49,0.22,0.73,0.34c0.25,0.11,0.49,0.21,0.74,0.32c0.25,0.1,0.5,0.2,0.74,0.3c0.49,0.19,0.99,0.35,1.47,0.5
                    c0.48,0.15,0.95,0.28,1.4,0.41c0.45,0.12,0.88,0.24,1.27,0.36c0.39,0.12,0.75,0.25,1.06,0.4c0.31,0.14,0.55,0.32,0.73,0.48
                    c0.18,0.17,0.29,0.33,0.35,0.43c0.03,0.05,0.05,0.1,0.07,0.13c0.02,0.03,0.02,0.04,0.02,0.04s-0.04-0.06-0.12-0.15
                    c-0.08-0.1-0.21-0.23-0.4-0.36c-0.19-0.13-0.44-0.26-0.75-0.37c-0.3-0.11-0.66-0.2-1.06-0.28c-0.39-0.08-0.83-0.16-1.29-0.26
                    c-0.46-0.1-0.94-0.2-1.44-0.33c-0.5-0.13-1.01-0.28-1.52-0.47c-0.26-0.09-0.52-0.19-0.76-0.29c-0.25-0.11-0.5-0.22-0.76-0.32
                    c-0.25-0.12-0.5-0.23-0.74-0.35c-0.24-0.12-0.49-0.24-0.73-0.36c-0.48-0.25-0.94-0.5-1.39-0.75c-0.45-0.25-0.87-0.51-1.27-0.75
                    c-0.4-0.24-0.77-0.48-1.11-0.71c-0.34-0.22-0.65-0.44-0.92-0.63c-0.27-0.19-0.51-0.36-0.7-0.5
                    C117.3,351.73,117.09,351.56,117.09,351.56z"/>
                <path id="XMLID_438_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M116.03,337.88
                    c0,0,0.08,0.04,0.2,0.12c0.06,0.04,0.14,0.08,0.22,0.13c0.09,0.05,0.18,0.11,0.29,0.17c0.1,0.06,0.22,0.12,0.34,0.19
                    c0.12,0.06,0.25,0.13,0.38,0.19c0.13,0.07,0.27,0.12,0.41,0.19c0.07,0.03,0.14,0.06,0.22,0.09c0.07,0.03,0.14,0.06,0.22,0.08
                    l0.22,0.08c0.07,0.02,0.15,0.05,0.22,0.07c0.07,0.02,0.15,0.05,0.22,0.07c0.07,0.02,0.15,0.04,0.22,0.07
                    c0.14,0.05,0.28,0.08,0.41,0.12c0.13,0.04,0.26,0.08,0.38,0.11c0.12,0.03,0.22,0.07,0.32,0.1c0.1,0.03,0.18,0.05,0.25,0.07
                    c0.14,0.04,0.22,0.08,0.22,0.08s-0.08,0.02-0.23,0.04c-0.07,0.01-0.16,0.02-0.26,0.03c-0.1,0.01-0.22,0.01-0.34,0.01
                    c-0.06,0-0.13,0-0.2,0c-0.07,0-0.14-0.01-0.21-0.01c-0.14-0.01-0.3-0.02-0.45-0.05c-0.16-0.03-0.32-0.05-0.48-0.1
                    c-0.08-0.02-0.16-0.04-0.24-0.07c-0.08-0.03-0.16-0.06-0.24-0.09c-0.16-0.06-0.32-0.13-0.47-0.2c-0.15-0.08-0.29-0.16-0.43-0.25
                    c-0.14-0.09-0.26-0.18-0.37-0.28c-0.11-0.1-0.21-0.19-0.3-0.28c-0.09-0.09-0.16-0.18-0.23-0.26c-0.06-0.08-0.11-0.16-0.15-0.22
                    c-0.04-0.06-0.07-0.11-0.09-0.15C116.05,337.9,116.03,337.88,116.03,337.88z"/>
                <g id="XMLID_421_">
                    <path id="XMLID_437_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M56.29,300.46
                        c0,0-0.45-0.22-1.04-0.66C55.77,300.7,56.29,300.46,56.29,300.46z"/>
                    <path id="XMLID_422_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M124.01,386.88
                        c0,0-25.22-38.14-35.96-56.23c-10.74-18.1-8.64-20.2-7.71-26.4c0.93-6.2,5.6-7.91,5.6-7.91l1.72-1.88c0,0-1.72,1.88-7.18,1.88
                        c-5.46,0-4.03-8.36-4.03-8.36s0,0,0.7-1.23s0.53,0.71,0.88,1.99s2.1-0.58,1.93-3.03s2.8-2.1,2.8-2.1s-1.05,4.03,0.35,6.28
                        c1.4,2.25,2.45,1.78,4.9,1.01c2.45-0.76,2.45-3.61,0.7-5.37c-1.75-1.75-2.63-3.5-1.58-4.93c1.05-1.43,2.63,0.73,4.73-1.42
                        c2.1-2.14,4.38-6.61,4.2-8.29c-0.18-1.68-3.5,0.8-3.5,0.8c3.85-3.68,4.38-9.28,4.38-9.28c-2.45,8.06-7.53,8.48-7.53,8.48
                        c2.28-1.5,2.45-3.93,3.15-5.85c0.7-1.93,4.9-7.88,4.03-8.93c-0.88-1.05-6.13,9.81-7.53,9.46c-1.4-0.35,3.68-5.6,3.15-7
                        s-3.85,5.95-5.95,5.95c-2.1,0,1.93-1.4,1.4-2.8c-0.53-1.4-2.1-1.23,0.35-2.28c2.45-1.05,5.25-5.6,7.18-9.35
                        c1.93-3.74,9.17-4.58,8.41-7.64c-0.7-2.8,5.43,0.53,9.88,0.35c4.45-0.18,5.01-2.98,8.68-5.08c3.68-2.1,6.42,1.4,6.42,1.4
                        c-3.57,1.23-7.47,3.5-13.34,8.06c-5.87,4.55-9.89,0-9.89,0s-0.7,2.45,3.59,2.91c4.29,0.46,10.94-3.79,12.1-5.71
                        c1.16-1.93,10.04-4.55,10.57-4.38c0.53,0.18-0.53,2.8-0.53,2.8c1.23-0.35,2.8-2.28,2.8-2.28c2.28,3.85-2.1,5.78-2.1,5.78
                        c5.78-1.75,2.8-5.96,5.29-7.54c2.49-1.58,1.02,0.88,5.16,8.24c4.14,7.35,7.68,7.17,7.68,7.17c-5.14-2.33-7.54-10.51-7.54-10.51
                        s2.17,0.47,5.67,1.4c3.5,0.93,4.44,6.3,5.37,8.87c0.93,2.57-1.87,5.6-1.79,8.87c0.08,3.27-3.81,8.08-3.81,8.08
                        c2.8,0.61,5.37-2.71,5.37-2.71c0.93,1.87-1.17,6.3-1.17,6.3s1.68,0.85,1.87,2.71s-0.7,8.73-1.17,13.7
                        c-0.47,4.96-3.97,11.85-3.97,11.85c2.81,0.99,7.01-4.2,7.47-3.7c0.47,0.5,1.4,5.21,1.4,5.21c3.5-2.7,4.67-14.37,4.67-14.37
                        c1.87,1.62-0.7,12.27-0.7,12.27c10.27-5.03,16.11-21.55,16.11-21.55c1.44,6.39-3.74,12.45-3.74,12.45s6.54-5.02,5.84-11.32
                        c-0.7-6.3-7.01-10.83-7.01-10.83c5.84,2.24,8.64-3.88,8.64-3.88c-3.74,3.04-7.24-1.17-7.24-1.17c7.47-2.33,5.14-9.81,5.14-9.81
                        s-0.93,4.44-6.54,4.2c-5.6-0.23-3.97-12.14-3.97-12.14c1.87,1.63,3.5,8.64,3.5,8.64c1.63-4.44-1.63-10.27-1.63-10.27
                        c2.1,1.17,3.74,7.01,3.74,7.01c0.93-7.24-5.45-12.14-5.45-12.14c5.29-7.94-0.38-14.24-0.38-14.24c2.1,8.64-5.14,9.57-5.14,9.57
                        c3.27-3.27,3.27-4.9,1.4-10.27c-1.87-5.37-8.17-4.67-8.64-7.24c-0.47-2.57,3.5-5.37,2.34-7.01c-1.17-1.63-4.9,1.17-4.9,1.17
                        c-7.01-12.14-19.15-9.81-19.15-9.81c6.01-0.23,4.58,3.04,4.58,3.04c-1.72-2.1-9.49-1.17-9.49-1.17s-2.57-4.9-7.24-5.4
                        c-4.67-0.49-7.13,4.23-7.13,4.23s-1.51-3.74-6.29-4.2c-4.79-0.47-8.08,5.6-8.08,5.6c-2.83-3.97-8.38-3.04-14.92,0
                        c-6.54,3.04-9.81,11.21-11.91,15.18c-2.1,3.97-2.1,4.2-7.01,7.71c-4.9,3.5-8.87,12.14-8.87,12.14s-1.4,0.47-3.04,5.84
                        c-1.63,5.37,3.74,8.17,3.74,8.17c-11.44,3.97-7.24,9.34-7.24,9.34s1.87-2.34,3.74-1.87c1.87,0.47-0.47,5.37-0.47,5.37
                        c-2.34-3.5-4.44,0-4.44,0c2.34,0.7,1.17,5.37-0.47,6.33s0.47,6.78,0.47,6.78c-2.34-0.43-3.27-4.94-3.27-4.94s-2.34,5.14,0.23,7.47
                        c2.57,2.33,8.87,3.62,8.87,3.62s-2.57,1.28-3.74,6.3c-0.82,3.51,1.33,5.73,2.7,6.75c-0.33-0.57-0.65-1.61-0.83-3.46
                        c-0.47-4.77,2.1-5.44,2.1-5.44s0,2.51,1.17,8.14c1.17,5.64,7.24,7.2,7.24,7.2c0.47-3.04,6.77-3.5,6.77-3.5s-4.44,5.6-5.37,10.27
                        c-0.93,4.67-2.57,6.54-7.24,8.87c-4.67,2.33-3.04,7.94-7.24,10.97c-4.2,3.04-10.51,4.9-18.45,8.74
                        c-7.94,3.84-11.48,9.23-11.48,9.23s-16.3,14.87-16.3,36.04L124.01,386.88z M160.51,256.28c0,0-5.6-9-4.2-9c1.4,0,1.4,0,1.4,0
                        s3.68,3.7,4.03,5.3C162.09,254.18,160.51,256.28,160.51,256.28z M68.05,252.6c1.05-0.35,2.66,3.33,5.1,3.68
                        c0,0-6.01-3.85-2.66-3.15c3.35,0.7,6.67,0.54,8.07,1.93c1.4,1.39,0.7,2.97-0.88,2.8c-1.58-0.18-3.15,0.03-4.12,0.01
                        c-0.96-0.01-2.01-0.89-2.01-0.89C69.8,255.58,67,252.95,68.05,252.6z M77.68,281.72c-2.28,1.17-3.64,4.68-4.54,3.63
                        c-0.89-1.05,0.68-1.4-0.89-1.05c-1.58,0.35-2.45,4.08-2.98,3.44c-0.53-0.64,0.15-3.96,2.27-5.02c0,0-0.34-1.4-2.27-0.18
                        c-1.6,1.02,1.93-2.33,4.29-1.95C75.93,280.97,79.96,280.55,77.68,281.72z M77.68,279.18c-0.35-1.36,2.1-2.26-2.45-1.98
                        c-4.55,0.27-8.93,1.02-9.98-3.38c-1.05-4.4,2.98-6.5,3.33-7.55c0.35-1.05,0.07-2.8-0.75-2.63c-0.82,0.18,4.96-3.15,2.16-2.45
                        s-6.65,1.93-6.65,1.93c1.23-1.23,3.5-3.5,6.3-3.5c2.8,0,5.64,0.7,3.52,1.75c-2.12,1.05-3.35,1.05-2.29,1.75
                        c1.05,0.7,4.38-1.4,4.2,0c-0.18,1.4-3.33,2.45-4.2,4.03c-0.88,1.58-0.66,5.47,2.29,6.67c2.96,1.21,4.64,1.18,6.16,3.38
                        C80.84,279.4,78.03,280.53,77.68,279.18z M90.29,272.57c3.33-2.03,0.7,0,0.7,0s-3.97,3.5-7.07,3.68
                        c-3.09,0.18-4.16-2.81-4.16-2.81s1.65,2.28,4.16,1.58c0,0-2.05-1.98-0.1-1.58C85.56,273.79,86.96,274.59,90.29,272.57z
                        M88.54,266.52c0,0-0.75,2.91-2.47,3.16c0,0,2.98,0.25,3.43-1.15c0,0-0.41,3.83-5.67,2.35c-5.26-1.48-5.47-4.45-5.47-4.45
                        s0.38,1.12,2.48,1.7l-1.05-3.02c-0.02-0.05-0.03-0.08-0.03-0.08l0.03,0.08c0.17,0.47,1.2,2.9,4.03,2.9
                        C86.97,268.01,88.54,266.52,88.54,266.52z M93.09,250.68c-1.58,1.23-6.98,4.55-9.27,4.38c-2.29-0.18-5.44-0.53-6.14-6.3
                        c0,0,1.23,2.63,2.28,3.15c1.05,0.53,1.95,2.8,3.86,1.75c1.91-1.05-0.71-1.23-0.71-1.23c-0.88-1.23-0.7-2.1,1.93-3.15
                        c2.63-1.05,5.25-3.15,6.83-3.85c1.58-0.7,3.5-1.23,3.85,0C96.07,246.65,94.67,249.45,93.09,250.68z M108.95,234.04
                        c-0.79,0.88-1.03,1.4-2.4,1.4c-1.37,0-1.37-0.53-3.3-0.7c-1.93-0.18-4.14-1.93-5.22-1.05c-1.08,0.88-2.48,1.4-1.43,2.8
                        c1.05,1.4,3.85-0.86,3.68,1.15c-0.18,2.01-0.64,3.41-2.25,4.28c-1.61,0.88-4.76,1.93-6.16,2.1c-1.4,0.18-3.49-1.05-2.71-1.93
                        c0.78-0.88,2.71-3.15,2.71-3.15s-2.28,1.05-3.5,1.05c-1.23,0-1.05-0.86-0.7-2.36c0.35-1.5,1.93-2.72,4.2-2.9
                        c2.28-0.18,4.87-0.18,5.43-2.45c0,0-7.53,3.15-11.21-1.4c-0.47-0.58,6.48,0.7,8.41,0c1.93-0.7,6.26-1.52,6.37-1.11
                        c0.11,0.41-1.97,3.73,0.82,2.51c2.79-1.23,4.13-3.84,4.86-2.62c0.73,1.22,0.56,1,2.4,1.29
                        C110.78,231.24,109.74,233.16,108.95,234.04z M119.45,224.06c-1.66,0.35-0.09,2.1-0.02,2.42c0.07,0.32-1.99,0.21-3.22,0.98
                        c-1.23,0.77-0.35,1.16-0.97,2.21c-0.62,1.05-4.81-5.25-8.49-6.3c-3.68-1.05-4.9,2.63-8.58,4.09c-3.68,1.47-6.3-1.29-6.3-1.29
                        c0.88,0.7,3.85-0.53,3.85-0.53s-1.4-1.93-1.23-2.28c0.18-0.35,2.98,1.4,2.98,1.4s0.18-1.4,1.58-1.93c1.4-0.53,2.64-1.75,2.64-1.75
                        c-1.39-0.53-3.87-6.48-1.76-3.94c2.1,2.54,3.85,0.13,3.85,0.13s0.18-1.44-2.09-3.89c-2.26-2.45-0.82-5.43-0.82-5.43
                        s0.63,2.98,3.26,4.9c2.63,1.93,4.82,4.29,4.82,4.29c1.11-6.66,6.29-9.19,6.29-9.19s-3.06,3.5-4.11,8.41
                        c-1.05,4.9,4.9,5.43,6.38,5.43c1.48,0-0.08-1.05,0.8-1.75c0.88-0.7,2.28,1.4,2.74,2.1C121.51,222.83,121.11,223.71,119.45,224.06z
                        M121.05,215.48c-1.34-3.15-1.6-5.95-1.6-5.95s0.9,3.5,4.87,5.95c3.97,2.45,7.05,4.55,4.81,9.28c0,0,0.46-2.45-1.24-3.68
                        C126.19,219.86,122.39,218.63,121.05,215.48z M133.2,235.09c-2.28,2.28-2.28,2.28-2.28,2.28s0.11,0.88-1.79-0.53
                        c0,0-1.47-1.05-1.24-4.55c0.23-3.5,0.97-4.55,2-6.65c1.03-2.1,3.48-3.33,3.48-3.33s2.4-1.75,5.41-4.03s4.77,0,4.77,0
                        s-6.55,3.15-7.84,7.71c0,0,2.87-1.58,3.07-0.35c0.2,1.23-1.96,1.75-3.07,2.8c-1.11,1.05-3.56,3.85-4.44,4.03
                        c-0.88,0.18-1.38-2.1-1.38-2.1s-1.06,4.55-0.28,4.9c0.78,0.35,5.69-5.43,8.31-5.78c2.63-0.35-0.57,1.05,0.85,1.4
                        c1.42,0.35,3.06-0.7,5.75,1.05c2.68,1.75,2.97,3.15,7.12,2.1c4.15-1.05,6.42-5.95,6.77-4.2c0.35,1.75-3.37,9.11-11.76,5.95
                        C138.27,232.64,135.47,232.81,133.2,235.09z M150.01,244.69c-1.93-1.19-4.87-2.42-6.46-4.69c-1.59-2.28-0.39-3.3-0.39-3.3
                        s0.51,3.47,3.51,4.7l-2.13-3.76c0,0,2.86,2.88,4.26,3.23c1.4,0.35-1.05,1.23,0.88,1.75c1.93,0.53,4.38,1.33,5.95,2.07
                        c1.58,0.74-3.97,0-3.97,0s3.14,2.88,4.25,5.4c1.12,2.52,1.82,7.79,1.82,7.79S151.93,245.88,150.01,244.69z M167.66,268.13
                        c-0.39-1.46-4.69-0.29-3.99,0c0.7,0.29,1.93,1.28,3.33,2.76c1.4,1.48,0.35,1.15-1.05,1.33c-1.4,0.18-1.58-1.23-2.98-2.53
                        c-1.4-1.3-0.35,2.18-1.18,4.13c-0.82,1.95-0.93-4.4-1.74-7.37c-0.82-2.98-0.06,6.65-1.11,6.13c-1.05-0.53-0.35-3.5-0.88-2.88
                        c-0.53,0.62-1.05,5.33-1.05,5.33s-1.05-1.93-0.93-3.79c0.13-1.86,2.15-2.87,2.35-4.09c0.2-1.23-0.02-1.58,1.2-2.1
                        c1.23-0.53-0.7-4.03-0.7-4.03c3.15-0.7,4.03,3.5,4.96,4.03c0.93,0.53,2.92,0.18,4.5,1.4c1.58,1.23,3.33,3.25,3.33,3.25
                        S168.04,269.59,167.66,268.13z"/>
                </g>
                <path id="XMLID_420_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M126.69,278.61
                    c0,0,0.04,0.02,0.1,0.05c0.06,0.03,0.15,0.08,0.24,0.14c0.09,0.06,0.2,0.13,0.3,0.2c0.05,0.04,0.1,0.07,0.16,0.11
                    c0.05,0.04,0.1,0.08,0.16,0.11c0.05,0.04,0.1,0.07,0.15,0.11c0.05,0.03,0.1,0.07,0.15,0.1c0.05,0.03,0.1,0.06,0.14,0.09
                    c0.05,0.02,0.09,0.05,0.14,0.08c0.04,0.02,0.09,0.04,0.13,0.06c0.04,0.02,0.08,0.03,0.11,0.04c0.06,0.02,0.11,0.04,0.11,0.04
                    s-0.03,0.03-0.09,0.07c-0.03,0.02-0.07,0.05-0.12,0.07c-0.05,0.02-0.11,0.03-0.17,0.05c-0.06,0.01-0.13,0.01-0.21,0.01
                    c-0.07,0-0.15-0.02-0.23-0.04c-0.04-0.01-0.08-0.02-0.11-0.04c-0.04-0.01-0.08-0.03-0.11-0.05c-0.07-0.04-0.15-0.08-0.21-0.13
                    c-0.07-0.05-0.13-0.1-0.18-0.16c-0.06-0.05-0.11-0.11-0.15-0.17c-0.05-0.06-0.08-0.12-0.12-0.18c-0.03-0.06-0.06-0.12-0.08-0.17
                    c-0.04-0.11-0.07-0.21-0.08-0.28c-0.01-0.03-0.01-0.07-0.01-0.09C126.69,278.62,126.69,278.61,126.69,278.61z"/>
                <path id="XMLID_419_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M129.61,300.29
                    c0,0,0.02,0.01,0.05,0.02c0.03,0.01,0.08,0.03,0.14,0.05c0.06,0.02,0.13,0.04,0.21,0.07c0.08,0.03,0.17,0.06,0.27,0.1
                    c0.19,0.07,0.42,0.15,0.66,0.24c0.12,0.04,0.24,0.09,0.37,0.13c0.13,0.04,0.25,0.09,0.38,0.13c0.13,0.04,0.25,0.08,0.38,0.12
                    c0.06,0.02,0.13,0.04,0.19,0.06c0.06,0.02,0.12,0.03,0.18,0.05c0.06,0.02,0.12,0.03,0.18,0.05c0.06,0.01,0.12,0.03,0.17,0.04
                    c0.11,0.03,0.22,0.06,0.32,0.08c0.1,0.02,0.19,0.04,0.28,0.06c0.08,0.01,0.16,0.03,0.22,0.04c0.12,0.03,0.2,0.05,0.2,0.05
                    s-0.07,0.03-0.19,0.07c-0.06,0.02-0.14,0.04-0.22,0.07c-0.09,0.02-0.19,0.03-0.3,0.05c-0.11,0.02-0.23,0.02-0.35,0.02
                    c-0.06,0-0.13,0-0.19,0c-0.07,0-0.13-0.01-0.2-0.02c-0.07-0.01-0.14-0.02-0.21-0.02c-0.07-0.01-0.14-0.03-0.21-0.04
                    c-0.14-0.03-0.28-0.07-0.42-0.11c-0.14-0.04-0.27-0.1-0.41-0.16c-0.07-0.03-0.13-0.06-0.19-0.09c-0.06-0.03-0.13-0.06-0.18-0.1
                    c-0.12-0.07-0.23-0.13-0.33-0.21c-0.1-0.07-0.2-0.14-0.28-0.21c-0.17-0.14-0.29-0.27-0.38-0.37
                    C129.65,300.35,129.61,300.29,129.61,300.29z"/>
                <path id="XMLID_418_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M116.03,300.29
                    c0,0-0.12,0.08-0.34,0.21c-0.11,0.07-0.24,0.15-0.39,0.24c-0.15,0.09-0.32,0.2-0.51,0.31c-0.75,0.45-1.77,1.03-2.81,1.55
                    c-0.26,0.13-0.52,0.26-0.78,0.38c-0.26,0.12-0.52,0.24-0.77,0.35c-0.25,0.11-0.5,0.21-0.73,0.3c-0.12,0.05-0.23,0.09-0.34,0.13
                    c-0.11,0.04-0.22,0.08-0.33,0.11c-0.21,0.08-0.4,0.13-0.58,0.18c-0.17,0.05-0.33,0.08-0.45,0.11c-0.13,0.03-0.23,0.04-0.3,0.05
                    c-0.07,0.01-0.11,0.01-0.11,0.01s0.03-0.02,0.1-0.05c0.06-0.03,0.15-0.07,0.27-0.12c0.11-0.06,0.25-0.13,0.41-0.21
                    c0.16-0.08,0.33-0.18,0.52-0.28c0.38-0.21,0.83-0.45,1.31-0.71c0.24-0.13,0.49-0.26,0.74-0.39c0.25-0.13,0.51-0.26,0.77-0.4
                    c0.26-0.13,0.52-0.26,0.78-0.38c0.26-0.13,0.51-0.25,0.76-0.36c0.25-0.11,0.49-0.22,0.72-0.32c0.23-0.1,0.45-0.19,0.66-0.27
                    c0.41-0.16,0.76-0.28,1.01-0.36C115.89,300.33,116.03,300.29,116.03,300.29z"/>
                <path id="XMLID_417_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M125.39,324.34
                    c0,0,0.02,0.01,0.05,0.02c0.03,0.01,0.07,0.03,0.13,0.05c0.06,0.02,0.12,0.05,0.2,0.08c0.08,0.03,0.16,0.06,0.25,0.09
                    c0.37,0.13,0.86,0.28,1.34,0.33c0.12,0.02,0.24,0.02,0.35,0.02c0.06,0,0.11,0,0.17-0.01c0.06-0.01,0.11-0.01,0.16-0.02
                    c0.11-0.02,0.21-0.04,0.3-0.07c0.1-0.03,0.18-0.07,0.27-0.11c0.08-0.04,0.15-0.09,0.22-0.13c0.03-0.02,0.06-0.05,0.09-0.07
                    c0.03-0.02,0.05-0.04,0.07-0.06c0.05-0.04,0.08-0.07,0.1-0.09c0.02-0.02,0.04-0.03,0.04-0.03s0,0.02-0.01,0.05
                    c-0.01,0.03-0.01,0.08-0.04,0.14c-0.01,0.03-0.02,0.06-0.03,0.1c-0.01,0.04-0.03,0.07-0.05,0.11c-0.04,0.08-0.1,0.16-0.17,0.25
                    c-0.08,0.08-0.17,0.17-0.28,0.24c-0.11,0.07-0.24,0.14-0.38,0.18c-0.27,0.09-0.58,0.11-0.88,0.08c-0.15-0.02-0.29-0.04-0.43-0.09
                    c-0.14-0.04-0.27-0.09-0.39-0.15c-0.12-0.06-0.24-0.12-0.34-0.19c-0.1-0.07-0.2-0.14-0.28-0.2c-0.16-0.14-0.28-0.27-0.35-0.37
                    c-0.04-0.05-0.06-0.09-0.08-0.12C125.39,324.35,125.39,324.34,125.39,324.34z"/>
                <path id="XMLID_416_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M85.87,322.47
                    c0,0,0.13,0.17,0.35,0.49c0.1,0.16,0.23,0.35,0.37,0.58c0.14,0.23,0.29,0.48,0.45,0.77c0.16,0.29,0.33,0.6,0.49,0.93
                    c0.17,0.33,0.34,0.69,0.5,1.06c0.16,0.37,0.33,0.76,0.48,1.17c0.15,0.4,0.29,0.82,0.43,1.23c0.06,0.21,0.12,0.42,0.19,0.63
                    c0.06,0.21,0.11,0.42,0.16,0.63c0.1,0.42,0.19,0.84,0.25,1.24c0.07,0.41,0.11,0.8,0.15,1.18c0.04,0.37,0.05,0.73,0.05,1.06
                    c0,0.16,0,0.32-0.01,0.47c-0.01,0.15-0.02,0.29-0.03,0.42c-0.02,0.27-0.06,0.5-0.08,0.68c-0.03,0.19-0.06,0.33-0.08,0.43
                    c-0.02,0.1-0.03,0.15-0.03,0.15s0-0.22,0-0.6c0-0.09-0.01-0.2-0.01-0.31c0-0.11,0-0.24-0.02-0.37c-0.01-0.13-0.02-0.27-0.03-0.42
                    c-0.01-0.15-0.03-0.3-0.05-0.46c-0.01-0.16-0.04-0.32-0.06-0.5c-0.02-0.17-0.05-0.35-0.08-0.53c-0.03-0.18-0.07-0.37-0.1-0.56
                    c-0.04-0.19-0.08-0.38-0.12-0.58c-0.08-0.39-0.19-0.79-0.29-1.2c-0.05-0.2-0.12-0.41-0.17-0.61c-0.06-0.2-0.12-0.41-0.19-0.61
                    c-0.13-0.41-0.26-0.81-0.4-1.21c-0.14-0.4-0.28-0.79-0.42-1.16c-0.14-0.38-0.28-0.74-0.42-1.08c-0.14-0.34-0.27-0.67-0.4-0.96
                    c-0.25-0.6-0.47-1.09-0.62-1.44C85.96,322.67,85.87,322.47,85.87,322.47z"/>
                <path id="XMLID_415_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M141.46,360.65
                    c0,0,0.16,0.17,0.42,0.47c0.07,0.07,0.15,0.14,0.24,0.23c0.09,0.08,0.17,0.17,0.28,0.26c0.11,0.09,0.22,0.18,0.34,0.27
                    c0.13,0.09,0.26,0.18,0.39,0.27c0.14,0.09,0.3,0.17,0.45,0.26c0.16,0.07,0.33,0.16,0.5,0.24c0.09,0.03,0.18,0.07,0.27,0.1
                    c0.05,0.02,0.09,0.04,0.14,0.05c0.05,0.01,0.09,0.03,0.14,0.04c0.09,0.03,0.19,0.06,0.29,0.09c0.1,0.03,0.2,0.05,0.3,0.07
                    c0.2,0.05,0.41,0.08,0.62,0.13c0.11,0.01,0.21,0.03,0.32,0.04c0.11,0.02,0.21,0.03,0.32,0.04c0.43,0.04,0.88,0.06,1.33,0.05
                    c0.11,0,0.22,0,0.34,0l0.17,0l0.08,0l0.1,0c0.06,0,0.11,0,0.18,0c0.07,0,0.13,0.01,0.19,0.01l0.18,0.03
                    c0.06,0.01,0.12,0.02,0.18,0.04c0.48,0.11,0.92,0.34,1.28,0.62c0.37,0.28,0.66,0.61,0.9,0.94c0.24,0.33,0.41,0.68,0.54,1.01
                    c0.13,0.33,0.22,0.64,0.27,0.92c0.06,0.28,0.07,0.53,0.08,0.73c0.01,0.2,0,0.36-0.01,0.47c-0.01,0.11-0.01,0.16-0.01,0.16
                    s-0.01-0.06-0.02-0.16c-0.01-0.11-0.04-0.26-0.08-0.46c-0.04-0.19-0.1-0.43-0.18-0.69c-0.09-0.26-0.2-0.54-0.36-0.84
                    c-0.08-0.15-0.16-0.3-0.26-0.44c-0.1-0.15-0.2-0.3-0.32-0.44c-0.24-0.29-0.52-0.56-0.85-0.79c-0.33-0.23-0.7-0.4-1.1-0.48
                    l-0.15-0.03l-0.15-0.02l-0.14-0.01c-0.05,0-0.11,0-0.16,0l-0.08,0l-0.09,0l-0.17,0.01c-0.12,0.01-0.23,0.01-0.35,0.01
                    c-0.47,0-0.93-0.01-1.39-0.07c-0.23-0.02-0.46-0.07-0.68-0.1c-0.22-0.05-0.44-0.09-0.65-0.16c-0.11-0.03-0.21-0.06-0.31-0.1
                    c-0.1-0.04-0.2-0.08-0.3-0.12c-0.05-0.02-0.1-0.04-0.15-0.06c-0.05-0.02-0.1-0.05-0.14-0.07c-0.09-0.04-0.19-0.09-0.28-0.13
                    c-0.18-0.09-0.35-0.2-0.51-0.29c-0.16-0.11-0.31-0.22-0.45-0.32c-0.13-0.12-0.26-0.23-0.38-0.33c-0.11-0.11-0.22-0.23-0.31-0.33
                    c-0.1-0.1-0.18-0.21-0.25-0.31c-0.07-0.1-0.14-0.19-0.2-0.27c-0.11-0.17-0.19-0.31-0.24-0.4
                    C141.49,360.7,141.46,360.65,141.46,360.65z"/>
                <path id="XMLID_414_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M124.01,362.23
                    c0,0,0.18,0.08,0.48,0.24c0.16,0.07,0.34,0.16,0.55,0.25c0.11,0.05,0.22,0.09,0.34,0.13c0.06,0.02,0.12,0.04,0.19,0.07
                    c0.06,0.02,0.13,0.04,0.2,0.06c0.27,0.08,0.57,0.14,0.88,0.17c0.32,0.03,0.66,0.04,1.02,0.04c0.36,0,0.74,0,1.12,0.01
                    c0.19,0.01,0.39,0.02,0.58,0.04l0.15,0.01l0.07,0.01l0.04,0l0.03,0l0.02,0l0.14,0.02l0.07,0.01l0.08,0.02
                    c0.42,0.08,0.8,0.22,1.16,0.39c0.36,0.17,0.69,0.37,1,0.58c0.31,0.21,0.59,0.43,0.85,0.65c0.26,0.22,0.49,0.44,0.7,0.64
                    c0.42,0.41,0.73,0.79,0.94,1.05c0.21,0.27,0.32,0.43,0.32,0.43s-0.15-0.13-0.4-0.35c-0.26-0.22-0.63-0.53-1.08-0.88
                    c-0.23-0.18-0.48-0.36-0.74-0.55c-0.27-0.19-0.56-0.37-0.86-0.56c-0.3-0.18-0.62-0.35-0.96-0.5c-0.17-0.07-0.34-0.14-0.51-0.19
                    c-0.09-0.03-0.17-0.06-0.26-0.08c-0.09-0.03-0.17-0.04-0.26-0.06l-0.06-0.01l-0.07-0.01l-0.14-0.02l-0.02,0l0,0
                    c-0.01,0,0.01,0,0.01,0l-0.01,0l-0.04,0l-0.07-0.01l-0.14-0.01c-0.19-0.02-0.38-0.03-0.56-0.05c-0.38-0.02-0.75-0.04-1.11-0.06
                    c-0.36-0.02-0.73-0.06-1.07-0.12c-0.17-0.03-0.33-0.07-0.49-0.12c-0.15-0.06-0.3-0.1-0.44-0.17c-0.07-0.03-0.14-0.06-0.2-0.09
                    c-0.06-0.03-0.13-0.07-0.19-0.1c-0.12-0.06-0.23-0.13-0.33-0.19c-0.21-0.12-0.37-0.25-0.51-0.35c-0.14-0.1-0.24-0.19-0.31-0.25
                    C124.04,362.26,124.01,362.23,124.01,362.23z"/>
                <path id="XMLID_413_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M129.82,270.87
                    c0,0,0.03,0.1,0.08,0.27c0.03,0.09,0.05,0.19,0.07,0.31c0.01,0.06,0.02,0.13,0.04,0.2c0.01,0.07,0.02,0.14,0.03,0.22
                    c0.02,0.15,0.03,0.32,0.03,0.5c0.01,0.18,0,0.37-0.01,0.56c-0.02,0.19-0.03,0.39-0.08,0.59c-0.03,0.2-0.09,0.4-0.14,0.6
                    c-0.07,0.2-0.12,0.4-0.2,0.58c-0.07,0.19-0.17,0.37-0.25,0.55c-0.09,0.17-0.18,0.34-0.28,0.48c-0.1,0.15-0.19,0.29-0.29,0.41
                    c-0.05,0.06-0.09,0.12-0.14,0.17c-0.05,0.05-0.09,0.1-0.14,0.15c-0.08,0.09-0.16,0.17-0.23,0.23c-0.13,0.12-0.21,0.19-0.21,0.19
                    s0.05-0.09,0.15-0.23c0.05-0.07,0.11-0.16,0.17-0.27c0.07-0.1,0.14-0.22,0.21-0.35c0.08-0.13,0.15-0.27,0.23-0.42
                    c0.08-0.15,0.15-0.32,0.23-0.48c0.07-0.17,0.14-0.35,0.21-0.53c0.07-0.18,0.12-0.37,0.18-0.56c0.05-0.19,0.11-0.38,0.14-0.57
                    c0.04-0.19,0.07-0.38,0.11-0.56c0.02-0.18,0.05-0.36,0.06-0.53c0.01-0.17,0.03-0.33,0.03-0.48c0.01-0.15,0.01-0.29,0.01-0.41
                    c0-0.12,0-0.23,0-0.32C129.82,270.97,129.82,270.87,129.82,270.87z"/>
                <path id="XMLID_412_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M134.46,271.23
                    c0,0,0.01,0.11,0.03,0.29c0.02,0.19,0.04,0.45,0.07,0.77c0.02,0.16,0.03,0.33,0.05,0.51c0.02,0.18,0.05,0.37,0.07,0.57
                    c0.05,0.39,0.11,0.81,0.18,1.23c0.04,0.21,0.07,0.42,0.11,0.62c0.04,0.2,0.08,0.4,0.13,0.6c0.09,0.39,0.18,0.75,0.26,1.05
                    c0.08,0.31,0.15,0.57,0.21,0.75c0.05,0.18,0.08,0.29,0.08,0.29s-0.06-0.09-0.15-0.26c-0.05-0.08-0.1-0.18-0.16-0.3
                    c-0.06-0.12-0.12-0.25-0.18-0.4c-0.07-0.15-0.13-0.31-0.19-0.49c-0.06-0.17-0.13-0.36-0.18-0.55c-0.05-0.2-0.12-0.4-0.16-0.6
                    c-0.05-0.21-0.09-0.42-0.13-0.63c-0.03-0.21-0.07-0.43-0.09-0.64c-0.03-0.21-0.04-0.42-0.05-0.62c-0.02-0.4-0.02-0.78,0-1.11
                    c0.01-0.16,0.02-0.31,0.03-0.44c0.01-0.13,0.03-0.24,0.04-0.34C134.44,271.34,134.46,271.23,134.46,271.23z"/>
            </g>
            <rect x="194.49" y="194.89" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" width="191.98" height="191.98"/>
            <path id="XMLID_410_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M303.79,381.48
                c-0.37-2-30.97-38.88-32.5-52.53c-1.53-13.64-2.95-24.71-3.52-29.16c-0.57-4.45-0.99-5.67-6.91-8.91
                c-5.92-3.24-9.14-17.73-7.35-22.76c1.8-5.02-0.97-14.68,1.8-18.26c2.77-3.58,24.07-28.39,26.97-34.1
                c2.9-5.7,11.26-10.84,14.92-10.84c3.66,0,15.23,4.04,22.42,4.59c7.19,0.55,19.71-0.59,22.58,5.97c2.87,6.56,7.14,5.96,10,11.96
                c2.85,6.01,2.24,9.43,3.69,15.42c1.45,5.99-0.84,12.94,1.45,16.74c2.28,3.8,8.94,6.5,7.46,11.28c-1.48,4.78-7.44,7.08-11.02,7.93
                c-3.58,0.84-4.45,1.74-5.7,7.93s-3.51,11.11-6.52,14.29c-3.01,3.18-4.49,15.67-5.04,18.2c-0.55,2.53-9.18,4.6-7.44,6.76
                c1.74,2.16,5.48,1.07,6.46,4.89c0.98,3.82,9.16,12.16,12.54,17.98c3.38,5.83,14.63,15.78,16.93,17.63
                c2.31,1.86,10.63,20.37,10.63,20.37h-69.68L303.79,381.48z"/>
            <g id="XMLID_317_">
                <path id="XMLID_406_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M386.47,386.88
                    c0,0-13.13-22.55-15.94-25.53c-2.8-2.98-4.2-0.35-7.18-1.4c-2.98-1.05-7.71-10.33-12.08-15.24c-4.38-4.9-13.45-9.87-12.16-10.34
                    c1.28-0.47,8.45-5.02,8.45-5.02s-0.63-0.58-2.5-2.22c-1.87-1.63-10.16-2.45-10.97-2.57c-0.82-0.12,0.23-1.63,1.87-2.8
                    c1.63-1.17,4.44-10.39,5.72-14.94c1.28-4.55,4.79-10.04,5.14-13.89c0.35-3.85,0-9.57,0-11.68s0.35-3.15,0.7-4.09
                    c0.35-0.93-1.28-1.52-3.5-2.69c-2.22-1.17-9.46-0.58-10.27,0c-0.82,0.58-2.8,1.52-4.9,1.52s-2.13,0.81-3.04,3.15
                    c-0.35,0.9-1.4,0.16-2.02,0.47c-0.62,0.31,0.7,1.09,1.63,1.87c0.93,0.78,0,8.87,0.16,10.35c0.16,1.48,1.01,1.01,1.63,2.02
                    c0.62,1.01,0.23,4.75-0.62,5.37c-0.86,0.62-2.1,1.63-2.72,2.26c-0.62,0.62-2.1,1.01-2.57,1.71c-0.47,0.7,4.75,2.02,5.76,2.49
                    c1.01,0.47,4.05-0.39,4.9-1.71c0.86-1.32,0.08-2.88-0.62-3.97c-0.7-1.09-1.63-3.97-1.71-5.76c-0.08-1.79-1.32-2.26-1.63-3.42
                    c-0.31-1.17,0.54-1.25,1.63-0.95c1.09,0.29-0.16-2.86,0-3.87c0.16-1.01-0.16-1.17,1.32-0.86c1.48,0.31,2.26-0.16,1.71-0.16
                    s-3.19-1.71-2.41-2.65c0.78-0.93,2.1-1.01,2.1-1.01s0.08,2.88,3.04,2.88s2.65-2.1,2.96-2.26c0.31-0.16,1.17,0.62,0.86,1.63
                    c-0.31,1.01-3.19,1.87-3.19,1.87s1.25,0.31,2.18-0.16c0.93-0.47,1.4-0.23,0.62,0.7c-0.78,0.93-0.47,1.17-0.31,1.87
                    c0.16,0.7-0.86,1.95-0.86,2.72c0,0.78,2.18-0.78,2.72-1.09c0.54-0.31,1.17-2.26,1.87-3.66s1.17-0.85,1.35,2.48s0,3.68-2.8,7.88
                    c-2.8,4.2-4.2,9.46-4.9,11.73c-0.7,2.28-1.4,7.71-1.49,11.21s-4.81,3.33-7.44,3.5c-2.63,0.18-2.63,2.1-2.1,4.2s0,1.75-2.28,2.63
                    s-2.8-2.28-3.33-3.15s-1.26,1-2.8,2.63c-0.76,0.81-3.06,2.33-6.04,1.39c-2.98-0.94-6.39-3.84-8.67-6.12
                    c-2.28-2.28-5.78-1.05-7.53-1.93c-1.75-0.88-12.08-12.26-11.91-11.38c0.18,0.88,10.68,12.08,11.56,13.48
                    c0.88,1.4,7.88,4.55,9.28,4.55s4.73,2.63,7,3.68c2.28,1.05,4.38,2.28,5.78,4.2s-3.15,2.98-3.15,2.98s5.25,0.35,7,0.18
                    c1.75-0.18,3.5-3.33,4.38-4.03c0.88-0.7,6.3-3.5,7.71-4.38s2.8,1.23,1.05,1.93c-1.75,0.7-1.93,2.98,0.18,4.03
                    c2.1,1.05,6.13,3.5,7.71,7c1.58,3.5-2.1,2.28,0,5.43c2.1,3.15,8.06,2.28,10.68,4.73c2.63,2.45,4.55,9.46,6.48,13.48
                    c1.93,4.03,6.13,4.2,7.71,8.93s0.7,9.77,0.7,9.77H386.47z M333.66,281.72c-0.31,0-0.56-0.25-0.56-0.56c0-0.31,0.25-0.56,0.56-0.56
                    s0.56,0.25,0.56,0.56C334.23,281.47,333.97,281.72,333.66,281.72z M342.77,279.7c-0.31,0.7-0.93,1.71-1.71,1.4
                    c-0.78-0.31-3.82-1.61-5.92-1.79c-1.56-0.13-2-1.09-0.77-1.25c1.23-0.16,4.19-0.23,4.74-0.54c0.54-0.31,2.02-0.54,3.27,0
                    s1.25,0.54,1.25,0.54S343.08,279,342.77,279.7z"/>
                <path id="XMLID_403_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M316.93,274.72
                    c-3.15,0-3.04-0.82-7.01-2.8c-3.97-1.98-4.79-0.58-6.3,0c-1.52,0.58-1.87,0.35-4.79,0.93c-2.92,0.58-6.3,2.45-5.95,3.04
                    c0.35,0.58,2.08,1.79,3.04,0.82c2.49-2.53,6.07-2.76,7.55-2.45c1.48,0.31-2.72,1.71-2.1,1.87s2.96-1.09,4.11-1.4
                    c1.15-0.31,5.39,2.02,2.2,1.87s-5.68,0.62-6.85,1.01c-1.17,0.39-1.87,0.62-3.35,0.08c-1.48-0.54-1.32,0.47-1.01,0.94
                    c0.31,0.47,1.87,0.39,1.01,1.13c-0.86,0.74-1.48-0.27-2.33,0.35s1.79,0.62,1.71,1.07c-0.08,0.45,1.09,0.49,2.02,0
                    c0.93-0.49,2.33-0.91,2.33-0.91c1.87,2.65,3.58,2.65,3.58,2.65c-1.25-0.47-2.26-2.33-2.18-3.15s1.73-0.56,1.73-0.56
                    s0.53,2.94,3.41,2.94c2.88,0,3.27-2.94,3.27-2.94s1.48,0.99,1.17,1.98s-3.5,1.58-3.5,1.58s0.78,0.39,0.86,1.17s-3.46,0.86-3.5,1.56
                    c-0.04,0.7,4.36,1.28,5.41,1.28c1.05,0,3.39-2.69,3.62-3.74c0.23-1.05,0-2.45,0.82-2.41c0.82,0.04,2.1-0.51,3.04-1.42
                    c0.93-0.91,2.69-3.99,2.69-5.37C321.6,272.44,320.08,274.72,316.93,274.72z M306.03,279.74c-0.31,0-0.56-0.25-0.56-0.56
                    s0.25-0.56,0.56-0.56c0.31,0,0.56,0.25,0.56,0.56S306.34,279.74,306.03,279.74z"/>
                <path id="XMLID_402_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M311.44,298.77
                    c1.17,0.7,1.75,0.93,2.33,0.7s0,0.12,0.58-0.23c0.58-0.35,2.45-0.64,3.74,0.32c1.28,0.96,3.15,2.01,2.22,2.36
                    c-0.93,0.35-1.87,0.23-2.92,0c-1.05-0.23-2.33,0.58-4.32-0.7C311.09,299.94,311.44,298.77,311.44,298.77z"/>
                <path id="XMLID_401_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M313.03,293.63
                    c0,0-4.4,3.5-6.44,4.75c-2.04,1.25,1.32,0.47,3.22-0.31S313.03,293.63,313.03,293.63z"/>
                <path id="XMLID_400_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M331.71,302.74
                    c0,0,1.32,0.99,2.51,3.74c1.2,2.75,1.3,6.54,1.3,6.54s0.12-1.01-1.3-1.48c-1.42-0.47-0.8-0.93-0.56-2.18
                    C333.89,308.11,332.18,302.74,331.71,302.74z"/>
                <path id="XMLID_399_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M320.12,304.53
                    c-0.5,1.16-0.08,1.48,0,2.26c0.08,0.78,0.12,1.79,0.45,1.32c0.33-0.47,0.18-1.25,0.49-2.1
                    C321.36,305.15,320.51,303.61,320.12,304.53z"/>
                <path id="XMLID_398_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M304.34,312.7
                    c-1.23-0.16-1.62-1.32,0-2.49c1.62-1.17,2.14-2.88,2.89-2.34s1.79,2.46,4.19,2.36c2.4-0.1,4.34-1.82,6.83-1.58
                    c2.49,0.23,2.65,1.87,4.44,1.01c1.79-0.86,2.65,0.47,4.13,0.93c1.48,0.47,4.59-0.54,4.36,0.31c-0.23,0.86-1.32,0.78-1.63,1.25
                    c-0.31,0.47,1.5,1.79,1.65,3.81s0.77,2.18-0.4,2.18s-1.87-0.54-2.33,0.39c-0.47,0.93-1.71,1.87-1.71,1.87s-0.62-3.19-1.71-3.04
                    s-3.11-0.16-3.42-0.47c-0.31-0.31-2.84-0.08-4.14,0.23c-1.31,0.31-6.29-0.93-4.89-1.48c1.4-0.54,2.02-0.96,2.49-1.57
                    c0.47-0.61,0.54-2.56,1.4-1.47c0.86,1.09,3.04,0.54,5.14,0.78c2.1,0.23,3.81-0.39,4.05-1.01c0.23-0.62-0.16-1.32-0.16-1.32
                    s-18.98-0.7-19.53,0S305.52,312.86,304.34,312.7z"/>
                <path id="XMLID_397_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M311.44,286.74
                    c0,0-0.03,0.03-0.08,0.09c-0.03,0.03-0.06,0.07-0.11,0.11c-0.04,0.04-0.09,0.09-0.15,0.14c-0.06,0.05-0.12,0.1-0.19,0.16
                    c-0.07,0.06-0.15,0.11-0.24,0.17c-0.09,0.06-0.18,0.12-0.28,0.18c-0.1,0.06-0.21,0.13-0.32,0.19c-0.12,0.06-0.24,0.12-0.36,0.19
                    c-0.13,0.06-0.26,0.11-0.4,0.18c-0.07,0.03-0.14,0.06-0.21,0.09c-0.07,0.03-0.15,0.05-0.22,0.08c-0.15,0.05-0.3,0.1-0.46,0.16
                    c-0.16,0.04-0.32,0.09-0.48,0.13c-0.08,0.02-0.16,0.04-0.25,0.07c-0.08,0.02-0.17,0.03-0.25,0.05c-0.34,0.06-0.68,0.14-1.02,0.17
                    c-0.17,0.02-0.35,0.04-0.52,0.06c-0.17,0.02-0.34,0.02-0.51,0.03c-0.34,0.01-0.68,0.03-1,0.01c-0.32-0.01-0.64-0.02-0.93-0.05
                    c-0.3-0.03-0.57-0.07-0.83-0.1c-0.13-0.02-0.25-0.03-0.37-0.05c-0.12-0.02-0.23-0.05-0.33-0.07c-0.21-0.04-0.38-0.08-0.53-0.11
                    c-0.29-0.07-0.46-0.11-0.46-0.11s0.17-0.01,0.47-0.01c0.15,0,0.33,0,0.54,0.01c0.1,0,0.21,0,0.33,0.01c0.12,0,0.24,0,0.37,0
                    c0.25,0,0.53-0.01,0.82,0c0.29,0,0.6-0.02,0.91-0.03c0.16-0.01,0.32,0,0.48-0.02c0.16-0.01,0.33-0.03,0.49-0.04
                    c0.16-0.01,0.33-0.02,0.5-0.04c0.17-0.02,0.33-0.04,0.5-0.06c0.33-0.03,0.66-0.09,0.99-0.14c0.16-0.02,0.32-0.06,0.48-0.09
                    c0.16-0.03,0.31-0.07,0.47-0.1c0.15-0.04,0.3-0.08,0.45-0.12c0.07-0.02,0.15-0.04,0.22-0.06c0.07-0.02,0.14-0.04,0.21-0.06
                    c0.14-0.04,0.27-0.08,0.4-0.12c0.13-0.05,0.25-0.09,0.37-0.13c0.12-0.04,0.23-0.09,0.34-0.13c0.11-0.04,0.21-0.08,0.3-0.13
                    c0.09-0.04,0.18-0.09,0.26-0.12c0.08-0.04,0.15-0.08,0.22-0.11c0.13-0.07,0.23-0.13,0.3-0.17
                    C311.4,286.77,311.44,286.74,311.44,286.74z"/>
                <path id="XMLID_396_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M329.07,288.73
                    c0,0,0.08,0.03,0.22,0.11c0.07,0.04,0.15,0.08,0.24,0.14c0.09,0.06,0.19,0.12,0.3,0.2c0.21,0.16,0.45,0.35,0.69,0.58
                    c0.06,0.06,0.12,0.12,0.18,0.18c0.06,0.06,0.12,0.12,0.18,0.19c0.12,0.13,0.23,0.26,0.34,0.4l0.16,0.21
                    c0.05,0.07,0.1,0.14,0.15,0.21c0.1,0.14,0.19,0.29,0.27,0.43c0.09,0.14,0.16,0.28,0.23,0.42c0.03,0.07,0.07,0.13,0.1,0.2
                    c0.03,0.07,0.05,0.13,0.08,0.19c0.05,0.12,0.09,0.24,0.13,0.34c0.03,0.1,0.05,0.2,0.07,0.27c0.04,0.15,0.05,0.24,0.05,0.24
                    s-0.06-0.07-0.16-0.19c-0.09-0.12-0.23-0.29-0.39-0.49c-0.08-0.1-0.16-0.21-0.25-0.33c-0.09-0.12-0.18-0.24-0.28-0.36
                    c-0.05-0.06-0.1-0.13-0.15-0.19c-0.05-0.06-0.1-0.13-0.15-0.19c-0.1-0.13-0.21-0.27-0.31-0.4c-0.1-0.13-0.21-0.26-0.31-0.4
                    c-0.1-0.13-0.21-0.26-0.31-0.38c-0.2-0.25-0.38-0.48-0.54-0.69c-0.16-0.21-0.29-0.38-0.38-0.5c-0.05-0.06-0.08-0.11-0.11-0.14
                    C329.08,288.75,329.07,288.73,329.07,288.73z"/>
                <path id="XMLID_395_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M334.37,296.33
                    c0,0,0.02,0.03,0.06,0.08c0.04,0.05,0.1,0.14,0.16,0.25c0.06,0.11,0.14,0.25,0.22,0.41c0.08,0.16,0.16,0.34,0.25,0.54
                    c0.17,0.4,0.35,0.87,0.54,1.37c0.09,0.25,0.19,0.51,0.29,0.77c0.1,0.26,0.2,0.53,0.31,0.79c0.1,0.26,0.21,0.52,0.33,0.77
                    c0.11,0.25,0.23,0.5,0.35,0.73c0.12,0.23,0.24,0.45,0.37,0.65c0.13,0.2,0.26,0.39,0.38,0.56c0.07,0.08,0.13,0.16,0.19,0.23
                    c0.06,0.07,0.13,0.14,0.18,0.2c0.12,0.12,0.23,0.23,0.33,0.3c0.09,0.08,0.17,0.14,0.23,0.18c0.05,0.04,0.08,0.06,0.08,0.06
                    s-0.03-0.01-0.1-0.03c-0.06-0.02-0.16-0.05-0.28-0.11c-0.06-0.03-0.12-0.06-0.19-0.1c-0.07-0.04-0.14-0.08-0.22-0.13
                    c-0.07-0.05-0.15-0.11-0.23-0.17c-0.08-0.06-0.16-0.13-0.24-0.21c-0.16-0.15-0.33-0.33-0.49-0.53c-0.16-0.2-0.32-0.42-0.46-0.65
                    c-0.14-0.24-0.29-0.49-0.41-0.75c-0.13-0.26-0.25-0.53-0.36-0.8c-0.22-0.54-0.4-1.1-0.55-1.62c-0.08-0.26-0.14-0.51-0.2-0.75
                    c-0.06-0.24-0.11-0.47-0.17-0.68c-0.05-0.21-0.1-0.4-0.14-0.57c-0.04-0.17-0.08-0.32-0.12-0.44c-0.04-0.12-0.06-0.22-0.09-0.28
                    C334.38,296.37,334.37,296.33,334.37,296.33z"/>
                <path id="XMLID_394_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M330.76,307.41
                    c0,0,0.04,0.05,0.1,0.13c0.03,0.04,0.07,0.09,0.1,0.15c0.04,0.06,0.08,0.13,0.12,0.2c0.09,0.15,0.18,0.33,0.26,0.53
                    c0.02,0.05,0.04,0.1,0.06,0.16c0.02,0.05,0.04,0.11,0.05,0.16c0.03,0.11,0.06,0.23,0.08,0.35c0.02,0.12,0.03,0.24,0.03,0.36
                    c0,0.06,0,0.12,0,0.18c0,0.06,0,0.11-0.01,0.17c-0.01,0.06-0.01,0.11-0.02,0.16c-0.01,0.05-0.02,0.1-0.03,0.15
                    c-0.02,0.1-0.05,0.19-0.08,0.27c-0.03,0.08-0.06,0.16-0.09,0.22c-0.03,0.07-0.06,0.12-0.08,0.17c-0.05,0.09-0.08,0.14-0.08,0.14
                    s0-0.06,0.01-0.16c0-0.05,0.01-0.11,0.02-0.18c0-0.07,0.02-0.15,0.02-0.23c0-0.08,0.01-0.17,0.01-0.27c0-0.05,0-0.1,0.01-0.14
                    c0-0.05,0-0.1,0-0.15c0-0.1-0.01-0.21-0.02-0.31c-0.01-0.11-0.02-0.21-0.04-0.32c-0.02-0.11-0.04-0.21-0.06-0.32
                    c-0.02-0.1-0.05-0.21-0.08-0.31c-0.06-0.2-0.11-0.39-0.15-0.55c-0.05-0.16-0.08-0.3-0.1-0.4
                    C330.77,307.47,330.76,307.41,330.76,307.41z"/>
                <path id="XMLID_393_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M277.7,300.46
                    c0.75,3.64,3,11.38,4.58,13.63s4.21,5.23,4.21,5.23s-5.6-4.04-7.53-8.41c-1.93-4.37-2.28-6.54-2.98-8.17
                    C275.27,301.11,277,297.08,277.7,300.46z"/>
                <path id="XMLID_392_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M288.93,322.47
                    c0,0,0.05,0.07,0.14,0.19c0.09,0.12,0.24,0.3,0.42,0.52c0.19,0.22,0.43,0.48,0.71,0.76c0.29,0.28,0.62,0.59,0.99,0.91
                    c0.38,0.31,0.81,0.63,1.27,0.95c0.47,0.3,0.99,0.61,1.51,0.95c0.53,0.34,1.07,0.68,1.61,1.04c0.54,0.36,1.09,0.73,1.64,1.11
                    c0.55,0.38,1.09,0.76,1.62,1.14c0.53,0.38,1.05,0.77,1.55,1.14c0.5,0.37,0.97,0.74,1.42,1.1c0.45,0.35,0.86,0.7,1.25,1.02
                    c0.19,0.16,0.37,0.32,0.54,0.47c0.17,0.15,0.33,0.3,0.48,0.43c0.3,0.27,0.55,0.52,0.76,0.73c0.05,0.05,0.1,0.1,0.15,0.15
                    c0.04,0.05,0.08,0.09,0.12,0.14c0.08,0.08,0.14,0.16,0.19,0.21c0.1,0.12,0.16,0.18,0.16,0.18s-0.06-0.05-0.18-0.15
                    c-0.12-0.1-0.29-0.25-0.52-0.43c-0.23-0.18-0.5-0.4-0.82-0.64c-0.32-0.25-0.69-0.51-1.09-0.81c-0.2-0.15-0.41-0.3-0.63-0.45
                    c-0.22-0.16-0.44-0.32-0.67-0.48c-0.46-0.33-0.95-0.67-1.46-1.03c-0.51-0.35-1.04-0.72-1.57-1.09c-0.53-0.37-1.08-0.75-1.63-1.12
                    c-0.54-0.38-1.09-0.75-1.63-1.12c-0.54-0.37-1.07-0.73-1.58-1.08c-0.51-0.35-1.01-0.68-1.48-1.02c-0.46-0.35-0.88-0.7-1.24-1.05
                    c-0.36-0.35-0.67-0.69-0.93-1c-0.26-0.31-0.47-0.6-0.63-0.84c-0.16-0.24-0.28-0.44-0.36-0.58
                    C288.97,322.55,288.93,322.47,288.93,322.47z"/>
                <path id="XMLID_391_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M286.48,341.74
                    c0,0,2.45,1.23,4.03,0c1.58-1.23,3.15-1.54,4.2,0.54s2.49,4.53,2.49,4.53s-2.46-3.25-3.53-3.02c-1.06,0.22-3.17-0.35-3.34,1.45
                    c-0.17,1.79,1.4,6.86,0.53,6.32C289.98,351.02,286.48,341.74,286.48,341.74z"/>
                <path id="XMLID_390_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M293.67,339.46
                    c2.26-0.53,4.01-2.1,5.77-2.98s4.35-2.1,6.03,0.7c0,0-2.75-1.04-2.2,0.27c0.55,1.31-0.67,2.18-1.55,2.01
                    c-0.88-0.18-1.84,1.75-2.15,2.28c-0.31,0.53-1.18-0.19-1.71-0.97s0.07,2.02-0.66,2.37C296.46,343.49,293.67,339.46,293.67,339.46z"
                    />
                <path id="XMLID_389_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M322.9,339.3
                    c0,0,6.94-2.2,8.81-4.74c0,0,5.31,2.98,4.79,3.84c-0.52,0.87-1.8,0.53-2.89,1.58c-1.09,1.05,1.19,1.93,0.75,4.03
                    c-0.44,2.1-0.78,1.23-1.92,0c-1.14-1.23-2.56-2.76-2.73-0.85c-0.17,1.9-1.57,5.41-3.49,6.98c-1.93,1.58-5.11,1.46-5.11,1.46
                    s2.66-2.68,1.26-2.51s-7.18,1.75-7.35,1.05s3.58-1.4,4.85-2.45s4.4-1.05,5.9-2.1c1.5-1.05,4.2-3.69,3.29-4.82s-1.26,1.01-2.49,2.39
                    c-1.23,1.38-2.8,2.25-3.85,1.73c-1.05-0.53-1.93-2.1,0.18-3.15C325.01,340.69,324.82,339.18,322.9,339.3z"/>
                <path id="XMLID_388_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M299.57,349.27
                    c0,0,0.16,0.08,0.42,0.25c0.26,0.17,0.63,0.42,1.05,0.77c0.21,0.17,0.43,0.36,0.65,0.58c0.22,0.22,0.45,0.45,0.67,0.72
                    c0.22,0.26,0.43,0.55,0.61,0.87c0.19,0.31,0.35,0.66,0.46,1.02c0.06,0.18,0.09,0.37,0.12,0.57c0.01,0.1,0.01,0.2,0.02,0.29
                    c0,0.1,0,0.18-0.01,0.27c-0.02,0.37-0.07,0.72-0.14,1.06c-0.07,0.34-0.17,0.65-0.27,0.94c-0.09,0.29-0.2,0.56-0.27,0.8
                    c-0.07,0.25-0.12,0.47-0.1,0.66c0.01,0.19,0.08,0.36,0.17,0.48c0.09,0.12,0.17,0.21,0.23,0.26c0.06,0.05,0.1,0.08,0.1,0.08
                    s-0.04-0.02-0.12-0.06c-0.07-0.04-0.18-0.1-0.3-0.21c-0.12-0.11-0.25-0.28-0.3-0.52c-0.06-0.23-0.05-0.5-0.01-0.77
                    c0.04-0.27,0.11-0.56,0.18-0.85c0.07-0.29,0.13-0.6,0.18-0.92c0.05-0.31,0.08-0.65,0.08-0.97c0-0.08,0-0.18,0-0.25
                    c-0.01-0.08-0.01-0.16-0.02-0.23c-0.02-0.16-0.05-0.31-0.1-0.47c-0.09-0.31-0.23-0.62-0.39-0.91c-0.16-0.29-0.34-0.57-0.53-0.83
                    c-0.19-0.26-0.38-0.51-0.57-0.74c-0.19-0.23-0.38-0.44-0.56-0.64c-0.36-0.39-0.67-0.7-0.89-0.92
                    C299.7,349.39,299.57,349.27,299.57,349.27z"/>
                <path id="XMLID_387_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M315.99,365.55
                    c0,0-1.84-2.1-2.96-2.28s-4.31-1.93-7.08-4.38c0,0-1.09,1.58,1.36,3.33c2.45,1.75,3.57,2.6,3.53,4.28
                    c-0.03,1.68,3.12,1.68,3.12,1.68s-4.18-2.8-2.56-3.85C313.03,363.28,315.99,365.55,315.99,365.55z"/>
                <path id="XMLID_386_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M322.55,373.78l-1.99,3.68
                    c-0.64-2.1-1.41-2.1,0.55-4.73c1.96-2.63,1.09-6.23,1.09-6.23s2.28,2.28,4.55,5.48c2.28,3.2,2.63,4.71,2.63,4.71
                    s-1.95-1.16-3.6-3.61c-1.65-2.45-2.53-1.4-2.88-0.35C322.55,373.78,323.6,373.08,322.55,373.78"/>
                <path id="XMLID_385_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M335.53,376.69
                    c0,0-1.07-5.71-2.47-8.51c-1.4-2.8-2.3-4.64-2.3-4.64s3.91,1.84,4.77,4.64c0.86,2.8,9.44,8.51,9.44,8.51s-6.69-2.83-8.43-4.71
                    C336.54,371.98,336.95,374.52,335.53,376.69z"/>
                <path id="XMLID_384_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M347.42,358.9
                    c0,0,0.08,0.03,0.24,0.09c0.15,0.06,0.38,0.15,0.66,0.28c0.28,0.14,0.62,0.3,1,0.52c0.19,0.1,0.39,0.23,0.6,0.35
                    c0.21,0.13,0.42,0.27,0.64,0.43c0.44,0.31,0.9,0.67,1.34,1.11c0.44,0.44,0.88,0.94,1.23,1.54c0.04,0.07,0.09,0.14,0.13,0.22
                    c0.04,0.07,0.08,0.15,0.13,0.22c0.04,0.07,0.08,0.15,0.13,0.22c0.04,0.07,0.09,0.14,0.13,0.2c0.09,0.14,0.18,0.27,0.28,0.41
                    c0.1,0.14,0.2,0.27,0.3,0.41c0.42,0.54,0.89,1.06,1.37,1.58c0.48,0.52,0.96,1.04,1.42,1.58c0.46,0.53,0.88,1.09,1.27,1.64
                    c0.19,0.28,0.37,0.55,0.55,0.81c0.18,0.26,0.36,0.51,0.54,0.75c0.37,0.48,0.72,0.93,1.07,1.33c0.68,0.81,1.28,1.46,1.71,1.91
                    c0.21,0.22,0.38,0.4,0.5,0.52c0.12,0.12,0.18,0.18,0.18,0.18s-0.07-0.06-0.2-0.16c-0.13-0.1-0.32-0.26-0.56-0.46
                    c-0.23-0.2-0.52-0.45-0.84-0.75c-0.16-0.15-0.32-0.31-0.5-0.48c-0.17-0.17-0.35-0.35-0.54-0.54c-0.37-0.38-0.77-0.81-1.16-1.28
                    c-0.2-0.23-0.39-0.48-0.59-0.74c-0.1-0.13-0.19-0.26-0.29-0.39c-0.09-0.13-0.19-0.27-0.29-0.4c-0.38-0.53-0.82-1.05-1.27-1.57
                    c-0.46-0.52-0.94-1.03-1.42-1.55c-0.48-0.53-0.95-1.06-1.39-1.63c-0.11-0.14-0.22-0.28-0.31-0.43c-0.1-0.15-0.2-0.29-0.29-0.45
                    c-0.05-0.08-0.09-0.15-0.14-0.23c-0.04-0.08-0.08-0.15-0.12-0.22c-0.04-0.07-0.08-0.15-0.12-0.22c-0.04-0.07-0.08-0.14-0.12-0.22
                    c-0.31-0.55-0.69-1.05-1.1-1.49c-0.1-0.11-0.21-0.21-0.31-0.32c-0.11-0.1-0.21-0.2-0.31-0.29c-0.1-0.1-0.21-0.19-0.31-0.28
                    c-0.1-0.09-0.2-0.18-0.31-0.26c-0.41-0.33-0.8-0.62-1.15-0.86c-0.35-0.24-0.67-0.44-0.94-0.6c-0.13-0.08-0.25-0.15-0.36-0.21
                    c-0.1-0.06-0.19-0.11-0.27-0.15C347.5,358.94,347.42,358.9,347.42,358.9z"/>
                <path id="XMLID_383_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M335.53,356.97
                    c0,0,0.05,0.08,0.15,0.23c0.09,0.15,0.26,0.35,0.47,0.6c0.22,0.24,0.51,0.53,0.86,0.82c0.35,0.29,0.77,0.59,1.25,0.9
                    c0.48,0.3,1.01,0.61,1.58,0.92c0.57,0.31,1.19,0.62,1.81,0.98c0.16,0.09,0.31,0.18,0.47,0.27c0.16,0.1,0.32,0.19,0.47,0.29
                    c0.31,0.2,0.63,0.41,0.93,0.63c0.61,0.45,1.21,0.95,1.76,1.5c0.54,0.56,1.04,1.17,1.44,1.83c0.21,0.33,0.38,0.67,0.53,1.02
                    c0.07,0.18,0.14,0.35,0.21,0.53c0.05,0.18,0.11,0.36,0.16,0.54c0.18,0.7,0.31,1.39,0.41,2.04c0.11,0.65,0.18,1.26,0.3,1.81
                    c0.11,0.55,0.28,1.04,0.47,1.45c0.02,0.05,0.05,0.1,0.07,0.15c0.03,0.05,0.05,0.1,0.08,0.14c0.05,0.09,0.1,0.19,0.15,0.27
                    c0.11,0.17,0.2,0.33,0.3,0.46c0.1,0.14,0.18,0.26,0.26,0.36c0.08,0.1,0.15,0.19,0.2,0.26c0.11,0.14,0.17,0.22,0.17,0.22
                    s-0.07-0.07-0.19-0.19c-0.06-0.06-0.14-0.14-0.23-0.23c-0.09-0.1-0.19-0.21-0.3-0.34c-0.11-0.13-0.22-0.28-0.35-0.44
                    c-0.06-0.08-0.12-0.17-0.18-0.26c-0.03-0.05-0.06-0.09-0.09-0.14c-0.03-0.05-0.06-0.1-0.09-0.15c-0.12-0.2-0.23-0.43-0.33-0.68
                    c-0.1-0.25-0.19-0.51-0.27-0.79c-0.16-0.56-0.27-1.17-0.4-1.81c-0.13-0.64-0.28-1.3-0.48-1.98c-0.18-0.66-0.5-1.31-0.88-1.92
                    c-0.38-0.61-0.86-1.19-1.38-1.72c-0.52-0.53-1.09-1.02-1.68-1.46c-0.59-0.44-1.19-0.85-1.8-1.21c-0.61-0.36-1.21-0.71-1.77-1.04
                    c-0.56-0.34-1.09-0.68-1.55-1.02c-0.23-0.17-0.45-0.34-0.66-0.51c-0.2-0.17-0.39-0.33-0.55-0.5c-0.34-0.32-0.6-0.64-0.8-0.91
                    c-0.2-0.28-0.34-0.5-0.41-0.66C335.57,357.06,335.53,356.97,335.53,356.97z"/>
                <path id="XMLID_382_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M331.71,373.96
                    c0,0,0.03,0.03,0.08,0.1c0.05,0.07,0.12,0.16,0.2,0.29c0.17,0.25,0.4,0.62,0.62,1.09c0.11,0.23,0.23,0.49,0.34,0.77
                    c0.11,0.28,0.22,0.58,0.31,0.89c0.1,0.31,0.18,0.64,0.25,0.98c0.07,0.34,0.12,0.68,0.16,1.03c0.03,0.35,0.06,0.7,0.05,1.04
                    c0,0.35-0.02,0.68-0.06,1.01c-0.04,0.33-0.09,0.64-0.16,0.93c-0.07,0.29-0.15,0.57-0.23,0.81c-0.04,0.12-0.08,0.24-0.13,0.35
                    c-0.05,0.11-0.09,0.21-0.13,0.31c-0.08,0.19-0.17,0.36-0.24,0.49c-0.07,0.13-0.14,0.23-0.18,0.3c-0.04,0.07-0.06,0.11-0.06,0.11
                    s0.05-0.17,0.13-0.45c0.04-0.15,0.09-0.32,0.14-0.52c0.02-0.1,0.05-0.21,0.07-0.32c0.02-0.11,0.05-0.23,0.07-0.36
                    c0.05-0.25,0.09-0.52,0.12-0.8c0.04-0.29,0.06-0.59,0.08-0.9c0-0.16,0.01-0.32,0.01-0.48c0-0.16,0-0.32-0.01-0.49
                    c-0.01-0.33-0.03-0.66-0.06-0.99c-0.04-0.33-0.08-0.66-0.13-0.99c-0.06-0.32-0.12-0.64-0.19-0.95c-0.07-0.31-0.15-0.6-0.23-0.88
                    c-0.08-0.28-0.16-0.54-0.24-0.78c-0.08-0.24-0.16-0.46-0.23-0.66c-0.07-0.19-0.13-0.36-0.19-0.5
                    C331.78,374.12,331.71,373.96,331.71,373.96z"/>
                <path id="XMLID_346_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M344.97,373.96
                    c0,0,0.14,0.13,0.36,0.36c0.11,0.12,0.26,0.25,0.41,0.42c0.16,0.16,0.34,0.34,0.54,0.54c0.2,0.2,0.41,0.41,0.62,0.65
                    c0.22,0.24,0.45,0.49,0.67,0.77c0.22,0.28,0.43,0.58,0.62,0.91c0.2,0.32,0.36,0.67,0.51,1.02c0.07,0.18,0.14,0.36,0.2,0.53
                    c0.06,0.18,0.12,0.36,0.17,0.54c0.1,0.36,0.19,0.72,0.27,1.05c0.08,0.34,0.15,0.67,0.21,0.98c0.07,0.31,0.13,0.61,0.18,0.88
                    c0.11,0.55,0.18,1.01,0.23,1.33c0.05,0.32,0.07,0.51,0.07,0.51s-0.07-0.17-0.19-0.48c-0.11-0.31-0.27-0.74-0.45-1.28
                    c-0.09-0.26-0.18-0.55-0.28-0.86c-0.09-0.31-0.2-0.63-0.29-0.96c-0.1-0.34-0.2-0.67-0.31-1.01c-0.06-0.17-0.12-0.34-0.18-0.51
                    c-0.07-0.17-0.13-0.34-0.2-0.5c-0.14-0.33-0.29-0.66-0.46-0.97c-0.17-0.31-0.36-0.6-0.55-0.89c-0.2-0.28-0.39-0.55-0.58-0.8
                    c-0.19-0.25-0.38-0.49-0.54-0.71c-0.17-0.22-0.32-0.43-0.45-0.61c-0.13-0.19-0.24-0.35-0.33-0.49
                    C345.06,374.12,344.97,373.96,344.97,373.96z"/>
                <path id="XMLID_345_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M309.07,351.56
                    c0,0,0.06,0.03,0.17,0.09c0.11,0.06,0.28,0.15,0.49,0.26c0.42,0.23,1.01,0.55,1.73,0.94c0.36,0.19,0.75,0.4,1.16,0.62
                    c0.41,0.22,0.85,0.44,1.3,0.67c0.23,0.11,0.46,0.23,0.69,0.35c0.23,0.12,0.47,0.23,0.71,0.35c0.24,0.11,0.48,0.23,0.72,0.34
                    c0.24,0.11,0.49,0.22,0.73,0.34c0.25,0.11,0.49,0.21,0.74,0.32c0.25,0.1,0.5,0.2,0.74,0.3c0.49,0.19,0.99,0.35,1.47,0.5
                    c0.48,0.15,0.95,0.28,1.4,0.41c0.45,0.12,0.88,0.24,1.27,0.36c0.39,0.12,0.75,0.25,1.06,0.4c0.31,0.14,0.55,0.32,0.73,0.48
                    c0.18,0.17,0.29,0.33,0.35,0.43c0.03,0.05,0.05,0.1,0.07,0.13c0.02,0.03,0.02,0.04,0.02,0.04s-0.04-0.06-0.12-0.15
                    c-0.08-0.1-0.21-0.23-0.4-0.36c-0.19-0.13-0.44-0.26-0.75-0.37c-0.3-0.11-0.66-0.2-1.06-0.28c-0.39-0.08-0.83-0.16-1.29-0.26
                    c-0.46-0.1-0.94-0.2-1.44-0.33c-0.5-0.13-1.01-0.28-1.52-0.47c-0.26-0.09-0.52-0.19-0.76-0.29c-0.25-0.11-0.5-0.22-0.76-0.32
                    c-0.25-0.12-0.5-0.23-0.74-0.35c-0.24-0.12-0.49-0.24-0.73-0.36c-0.48-0.25-0.94-0.5-1.39-0.75c-0.45-0.25-0.87-0.51-1.27-0.75
                    c-0.4-0.24-0.77-0.48-1.11-0.71c-0.34-0.22-0.65-0.44-0.92-0.63c-0.27-0.19-0.51-0.36-0.7-0.5
                    C309.28,351.73,309.07,351.56,309.07,351.56z"/>
                <path id="XMLID_344_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M308.02,337.88
                    c0,0,0.08,0.04,0.2,0.12c0.06,0.04,0.14,0.08,0.22,0.13c0.09,0.05,0.18,0.11,0.29,0.17c0.1,0.06,0.22,0.12,0.34,0.19
                    c0.12,0.06,0.25,0.13,0.38,0.19c0.13,0.07,0.27,0.12,0.41,0.19c0.07,0.03,0.14,0.06,0.22,0.09c0.07,0.03,0.14,0.06,0.22,0.08
                    l0.22,0.08c0.07,0.02,0.15,0.05,0.22,0.07c0.07,0.02,0.15,0.05,0.22,0.07c0.07,0.02,0.15,0.04,0.22,0.07
                    c0.14,0.05,0.28,0.08,0.41,0.12c0.13,0.04,0.26,0.08,0.38,0.11c0.12,0.03,0.22,0.07,0.32,0.1c0.1,0.03,0.18,0.05,0.25,0.07
                    c0.14,0.04,0.22,0.08,0.22,0.08s-0.08,0.02-0.23,0.04c-0.07,0.01-0.16,0.02-0.26,0.03c-0.1,0.01-0.22,0.01-0.34,0.01
                    c-0.06,0-0.13,0-0.2,0c-0.07,0-0.14-0.01-0.21-0.01c-0.14-0.01-0.3-0.02-0.45-0.05c-0.16-0.03-0.32-0.05-0.48-0.1
                    c-0.08-0.02-0.16-0.04-0.24-0.07c-0.08-0.03-0.16-0.06-0.24-0.09c-0.16-0.06-0.32-0.13-0.47-0.2c-0.15-0.08-0.29-0.16-0.43-0.25
                    c-0.14-0.09-0.26-0.18-0.37-0.28c-0.11-0.1-0.21-0.19-0.3-0.28c-0.09-0.09-0.16-0.18-0.23-0.26c-0.06-0.08-0.11-0.16-0.15-0.22
                    c-0.04-0.06-0.07-0.11-0.09-0.15C308.03,337.9,308.02,337.88,308.02,337.88z"/>
                <g id="XMLID_327_">
                    <path id="XMLID_343_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M248.28,300.46
                        c0,0-0.45-0.22-1.04-0.66C247.75,300.7,248.28,300.46,248.28,300.46z"/>
                    <path id="XMLID_328_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M315.99,386.88
                        c0,0-25.22-38.14-35.96-56.23c-10.74-18.1-8.64-20.2-7.71-26.4c0.93-6.2,5.6-7.91,5.6-7.91l1.72-1.88c0,0-1.72,1.88-7.18,1.88
                        c-5.46,0-4.03-8.36-4.03-8.36s0,0,0.7-1.23c0.7-1.23,0.53,0.71,0.88,1.99c0.35,1.28,2.1-0.58,1.93-3.03s2.8-2.1,2.8-2.1
                        s-1.05,4.03,0.35,6.28c1.4,2.25,2.45,1.78,4.9,1.01c2.45-0.76,2.45-3.61,0.7-5.37c-1.75-1.75-2.63-3.5-1.58-4.93
                        c1.05-1.43,2.63,0.73,4.73-1.42c2.1-2.14,4.38-6.61,4.2-8.29c-0.18-1.68-3.5,0.8-3.5,0.8c3.85-3.68,4.38-9.28,4.38-9.28
                        c-2.45,8.06-7.53,8.48-7.53,8.48c2.28-1.5,2.45-3.93,3.15-5.85s4.9-7.88,4.03-8.93c-0.88-1.05-6.13,9.81-7.53,9.46
                        c-1.4-0.35,3.68-5.6,3.15-7c-0.53-1.4-3.85,5.95-5.95,5.95s1.93-1.4,1.4-2.8c-0.53-1.4-2.1-1.23,0.35-2.28
                        c2.45-1.05,5.25-5.6,7.18-9.35c1.93-3.74,9.17-4.58,8.41-7.64c-0.7-2.8,5.43,0.53,9.88,0.35c4.45-0.18,5.01-2.98,8.68-5.08
                        c3.68-2.1,6.42,1.4,6.42,1.4c-3.57,1.23-7.47,3.5-13.34,8.06c-5.87,4.55-9.89,0-9.89,0s-0.7,2.45,3.59,2.91
                        c4.29,0.46,10.94-3.79,12.1-5.71c1.16-1.93,10.04-4.55,10.57-4.38c0.53,0.18-0.53,2.8-0.53,2.8c1.23-0.35,2.8-2.28,2.8-2.28
                        c2.28,3.85-2.1,5.78-2.1,5.78c5.78-1.75,2.8-5.96,5.29-7.54c2.49-1.58,1.02,0.88,5.16,8.24c4.14,7.35,7.68,7.17,7.68,7.17
                        c-5.14-2.33-7.54-10.51-7.54-10.51s2.17,0.47,5.67,1.4c3.5,0.93,4.44,6.3,5.37,8.87c0.93,2.57-1.87,5.6-1.79,8.87
                        c0.08,3.27-3.81,8.08-3.81,8.08c2.8,0.61,5.37-2.71,5.37-2.71c0.93,1.87-1.17,6.3-1.17,6.3s1.68,0.85,1.87,2.71
                        s-0.7,8.73-1.17,13.7c-0.47,4.96-3.97,11.85-3.97,11.85c2.81,0.99,7.01-4.2,7.47-3.7c0.47,0.5,1.4,5.21,1.4,5.21
                        c3.5-2.7,4.67-14.37,4.67-14.37c1.87,1.62-0.7,12.27-0.7,12.27c10.27-5.03,16.11-21.55,16.11-21.55
                        c1.44,6.39-3.74,12.45-3.74,12.45s6.54-5.02,5.84-11.32c-0.7-6.3-7.01-10.83-7.01-10.83c5.84,2.24,8.64-3.88,8.64-3.88
                        c-3.74,3.04-7.24-1.17-7.24-1.17c7.47-2.33,5.14-9.81,5.14-9.81s-0.93,4.44-6.54,4.2c-5.6-0.23-3.97-12.14-3.97-12.14
                        c1.87,1.63,3.5,8.64,3.5,8.64c1.63-4.44-1.63-10.27-1.63-10.27c2.1,1.17,3.74,7.01,3.74,7.01c0.93-7.24-5.45-12.14-5.45-12.14
                        c5.29-7.94-0.38-14.24-0.38-14.24c2.1,8.64-5.14,9.57-5.14,9.57c3.27-3.27,3.27-4.9,1.4-10.27c-1.87-5.37-8.17-4.67-8.64-7.24
                        c-0.47-2.57,3.5-5.37,2.34-7.01c-1.17-1.63-4.9,1.17-4.9,1.17c-7.01-12.14-19.15-9.81-19.15-9.81c6.01-0.23,4.58,3.04,4.58,3.04
                        c-1.72-2.1-9.49-1.17-9.49-1.17s-2.57-4.9-7.24-5.4c-4.67-0.49-7.13,4.23-7.13,4.23s-1.51-3.74-6.29-4.2
                        c-4.79-0.47-8.08,5.6-8.08,5.6c-2.83-3.97-8.38-3.04-14.92,0c-6.54,3.04-9.81,11.21-11.91,15.18s-2.1,4.2-7.01,7.71
                        s-8.87,12.14-8.87,12.14s-1.4,0.47-3.04,5.84c-1.63,5.37,3.74,8.17,3.74,8.17c-11.44,3.97-7.24,9.34-7.24,9.34
                        s1.87-2.34,3.74-1.87c1.87,0.47-0.47,5.37-0.47,5.37c-2.34-3.5-4.44,0-4.44,0c2.34,0.7,1.17,5.37-0.47,6.33
                        c-1.63,0.96,0.47,6.78,0.47,6.78c-2.34-0.43-3.27-4.94-3.27-4.94s-2.34,5.14,0.23,7.47c2.57,2.33,8.87,3.62,8.87,3.62
                        s-2.57,1.28-3.74,6.3c-0.82,3.51,1.33,5.73,2.7,6.75c-0.33-0.57-0.65-1.61-0.83-3.46c-0.47-4.77,2.1-5.44,2.1-5.44
                        s0,2.51,1.17,8.14c1.17,5.64,7.24,7.2,7.24,7.2c0.47-3.04,6.77-3.5,6.77-3.5s-4.44,5.6-5.37,10.27c-0.93,4.67-2.57,6.54-7.24,8.87
                        s-3.04,7.94-7.24,10.97c-4.2,3.04-10.51,4.9-18.45,8.74c-7.94,3.84-11.48,9.23-11.48,9.23s-16.3,14.87-16.3,36.04L315.99,386.88z
                        M352.5,256.28c0,0-5.6-9-4.2-9s1.4,0,1.4,0s3.68,3.7,4.03,5.3C354.07,254.18,352.5,256.28,352.5,256.28z M260.04,252.6
                        c1.05-0.35,2.66,3.33,5.1,3.68c0,0-6.01-3.85-2.66-3.15c3.35,0.7,6.67,0.54,8.07,1.93c1.4,1.39,0.7,2.97-0.88,2.8
                        c-1.58-0.18-3.15,0.03-4.12,0.01s-2.01-0.89-2.01-0.89C261.79,255.58,258.99,252.95,260.04,252.6z M269.67,281.72
                        c-2.28,1.17-3.64,4.68-4.54,3.63s0.68-1.4-0.89-1.05c-1.58,0.35-2.45,4.08-2.98,3.44c-0.53-0.64,0.15-3.96,2.27-5.02
                        c0,0-0.34-1.4-2.27-0.18c-1.6,1.02,1.93-2.33,4.29-1.95S271.94,280.55,269.67,281.72z M269.67,279.18
                        c-0.35-1.36,2.1-2.26-2.45-1.98s-8.93,1.02-9.98-3.38c-1.05-4.4,2.98-6.5,3.33-7.55c0.35-1.05,0.07-2.8-0.75-2.63
                        c-0.82,0.18,4.96-3.15,2.16-2.45c-2.8,0.7-6.65,1.93-6.65,1.93c1.23-1.23,3.5-3.5,6.3-3.5s5.64,0.7,3.52,1.75
                        c-2.12,1.05-3.35,1.05-2.29,1.75c1.05,0.7,4.38-1.4,4.2,0c-0.18,1.4-3.33,2.45-4.2,4.03c-0.88,1.58-0.66,5.47,2.29,6.67
                        c2.96,1.21,4.64,1.18,6.16,3.38S270.02,280.53,269.67,279.18z M282.27,272.57c3.33-2.03,0.7,0,0.7,0s-3.97,3.5-7.07,3.68
                        c-3.09,0.18-4.16-2.81-4.16-2.81s1.65,2.28,4.16,1.58c0,0-2.05-1.98-0.1-1.58C277.55,273.79,278.94,274.59,282.27,272.57z
                        M280.53,266.52c0,0-0.75,2.91-2.47,3.16c0,0,2.98,0.25,3.43-1.15c0,0-0.41,3.83-5.67,2.35c-5.26-1.48-5.47-4.45-5.47-4.45
                        s0.38,1.12,2.48,1.7l-1.05-3.02c-0.02-0.05-0.03-0.08-0.03-0.08l0.03,0.08c0.17,0.47,1.2,2.9,4.03,2.9
                        C278.95,268.01,280.53,266.52,280.53,266.52z M285.08,250.68c-1.58,1.23-6.98,4.55-9.27,4.38c-2.29-0.18-5.44-0.53-6.14-6.3
                        c0,0,1.23,2.63,2.28,3.15c1.05,0.53,1.95,2.8,3.86,1.75c1.91-1.05-0.71-1.23-0.71-1.23c-0.88-1.23-0.7-2.1,1.93-3.15
                        c2.63-1.05,5.25-3.15,6.83-3.85c1.58-0.7,3.5-1.23,3.85,0C288.06,246.65,286.65,249.45,285.08,250.68z M300.93,234.04
                        c-0.79,0.88-1.03,1.4-2.4,1.4c-1.37,0-1.37-0.53-3.3-0.7c-1.93-0.18-4.14-1.93-5.22-1.05c-1.08,0.88-2.48,1.4-1.43,2.8
                        s3.85-0.86,3.68,1.15c-0.18,2.01-0.64,3.41-2.25,4.28c-1.61,0.88-4.76,1.93-6.16,2.1c-1.4,0.18-3.49-1.05-2.71-1.93
                        c0.78-0.88,2.71-3.15,2.71-3.15s-2.28,1.05-3.5,1.05s-1.05-0.86-0.7-2.36c0.35-1.5,1.93-2.72,4.2-2.9
                        c2.28-0.18,4.87-0.18,5.43-2.45c0,0-7.53,3.15-11.21-1.4c-0.47-0.58,6.48,0.7,8.41,0c1.93-0.7,6.26-1.52,6.37-1.11
                        c0.11,0.41-1.97,3.73,0.82,2.51s4.13-3.84,4.86-2.62c0.73,1.22,0.56,1,2.4,1.29C302.77,231.24,301.72,233.16,300.93,234.04z
                        M311.44,224.06c-1.66,0.35-0.09,2.1-0.02,2.42c0.07,0.32-1.99,0.21-3.22,0.98c-1.23,0.77-0.35,1.16-0.97,2.21
                        c-0.62,1.05-4.81-5.25-8.49-6.3c-3.68-1.05-4.9,2.63-8.58,4.09c-3.68,1.47-6.3-1.29-6.3-1.29c0.88,0.7,3.85-0.53,3.85-0.53
                        s-1.4-1.93-1.23-2.28s2.98,1.4,2.98,1.4s0.18-1.4,1.58-1.93c1.4-0.53,2.64-1.75,2.64-1.75c-1.39-0.53-3.87-6.48-1.76-3.94
                        c2.1,2.54,3.85,0.13,3.85,0.13s0.18-1.44-2.09-3.89c-2.26-2.45-0.82-5.43-0.82-5.43s0.63,2.98,3.26,4.9
                        c2.63,1.93,4.82,4.29,4.82,4.29c1.11-6.66,6.29-9.19,6.29-9.19s-3.06,3.5-4.11,8.41c-1.05,4.9,4.9,5.43,6.38,5.43
                        s-0.08-1.05,0.8-1.75c0.88-0.7,2.28,1.4,2.74,2.1C313.5,222.83,313.1,223.71,311.44,224.06z M313.03,215.48
                        c-1.34-3.15-1.6-5.95-1.6-5.95s0.9,3.5,4.87,5.95c3.97,2.45,7.05,4.55,4.81,9.28c0,0,0.46-2.45-1.24-3.68
                        C318.18,219.86,314.37,218.63,313.03,215.48z M325.18,235.09c-2.28,2.28-2.28,2.28-2.28,2.28s0.11,0.88-1.79-0.53
                        c0,0-1.47-1.05-1.24-4.55c0.23-3.5,0.97-4.55,2-6.65c1.03-2.1,3.48-3.33,3.48-3.33s2.4-1.75,5.41-4.03c3-2.28,4.77,0,4.77,0
                        s-6.55,3.15-7.84,7.71c0,0,2.87-1.58,3.07-0.35c0.2,1.23-1.96,1.75-3.07,2.8c-1.11,1.05-3.56,3.85-4.44,4.03
                        c-0.88,0.18-1.38-2.1-1.38-2.1s-1.06,4.55-0.28,4.9c0.78,0.35,5.69-5.43,8.31-5.78c2.63-0.35-0.57,1.05,0.85,1.4
                        c1.42,0.35,3.06-0.7,5.75,1.05c2.68,1.75,2.97,3.15,7.12,2.1c4.15-1.05,6.42-5.95,6.77-4.2c0.35,1.75-3.37,9.11-11.76,5.95
                        S327.46,232.81,325.18,235.09z M341.99,244.69c-1.93-1.19-4.87-2.42-6.46-4.69c-1.59-2.28-0.39-3.3-0.39-3.3s0.51,3.47,3.51,4.7
                        l-2.13-3.76c0,0,2.86,2.88,4.26,3.23c1.4,0.35-1.05,1.23,0.88,1.75c1.93,0.53,4.38,1.33,5.95,2.07c1.58,0.74-3.97,0-3.97,0
                        s3.14,2.88,4.25,5.4c1.12,2.52,1.82,7.79,1.82,7.79S343.92,245.88,341.99,244.69z M359.64,268.13c-0.39-1.46-4.69-0.29-3.99,0
                        s1.93,1.28,3.33,2.76c1.4,1.48,0.35,1.15-1.05,1.33c-1.4,0.18-1.58-1.23-2.98-2.53c-1.4-1.3-0.35,2.18-1.18,4.13
                        c-0.82,1.95-0.93-4.4-1.74-7.37c-0.82-2.98-0.06,6.65-1.11,6.13c-1.05-0.53-0.35-3.5-0.88-2.88c-0.53,0.62-1.05,5.33-1.05,5.33
                        s-1.05-1.93-0.93-3.79c0.13-1.86,2.15-2.87,2.35-4.09c0.2-1.23-0.02-1.58,1.2-2.1c1.23-0.53-0.7-4.03-0.7-4.03
                        c3.15-0.7,4.03,3.5,4.96,4.03c0.93,0.53,2.92,0.18,4.5,1.4c1.58,1.23,3.33,3.25,3.33,3.25S360.03,269.59,359.64,268.13z"/>
                </g>
                <path id="XMLID_326_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M318.68,278.61
                    c0,0,0.04,0.02,0.1,0.05c0.06,0.03,0.15,0.08,0.24,0.14c0.09,0.06,0.2,0.13,0.3,0.2c0.05,0.04,0.1,0.07,0.16,0.11
                    c0.05,0.04,0.1,0.08,0.16,0.11c0.05,0.04,0.1,0.07,0.15,0.11c0.05,0.03,0.1,0.07,0.15,0.1c0.05,0.03,0.1,0.06,0.14,0.09
                    c0.05,0.02,0.09,0.05,0.14,0.08c0.04,0.02,0.09,0.04,0.13,0.06c0.04,0.02,0.08,0.03,0.11,0.04c0.06,0.02,0.11,0.04,0.11,0.04
                    s-0.03,0.03-0.09,0.07c-0.03,0.02-0.07,0.05-0.12,0.07c-0.05,0.02-0.11,0.03-0.17,0.05c-0.06,0.01-0.13,0.01-0.21,0.01
                    c-0.07,0-0.15-0.02-0.23-0.04c-0.04-0.01-0.08-0.02-0.11-0.04c-0.04-0.01-0.08-0.03-0.11-0.05c-0.07-0.04-0.15-0.08-0.21-0.13
                    c-0.07-0.05-0.13-0.1-0.18-0.16c-0.06-0.05-0.11-0.11-0.15-0.17c-0.05-0.06-0.08-0.12-0.12-0.18c-0.03-0.06-0.06-0.12-0.08-0.17
                    c-0.04-0.11-0.07-0.21-0.08-0.28c-0.01-0.03-0.01-0.07-0.01-0.09C318.68,278.62,318.68,278.61,318.68,278.61z"/>
                <path id="XMLID_325_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M321.6,300.29
                    c0,0,0.02,0.01,0.05,0.02c0.03,0.01,0.08,0.03,0.14,0.05c0.06,0.02,0.13,0.04,0.21,0.07c0.08,0.03,0.17,0.06,0.27,0.1
                    c0.19,0.07,0.42,0.15,0.66,0.24c0.12,0.04,0.24,0.09,0.37,0.13c0.13,0.04,0.25,0.09,0.38,0.13c0.13,0.04,0.25,0.08,0.38,0.12
                    c0.06,0.02,0.13,0.04,0.19,0.06c0.06,0.02,0.12,0.03,0.18,0.05c0.06,0.02,0.12,0.03,0.18,0.05c0.06,0.01,0.12,0.03,0.17,0.04
                    c0.11,0.03,0.22,0.06,0.32,0.08c0.1,0.02,0.19,0.04,0.28,0.06c0.08,0.01,0.16,0.03,0.22,0.04c0.12,0.03,0.2,0.05,0.2,0.05
                    s-0.07,0.03-0.19,0.07c-0.06,0.02-0.14,0.04-0.22,0.07c-0.09,0.02-0.19,0.03-0.3,0.05c-0.11,0.02-0.23,0.02-0.35,0.02
                    c-0.06,0-0.13,0-0.19,0c-0.07,0-0.13-0.01-0.2-0.02c-0.07-0.01-0.14-0.02-0.21-0.02c-0.07-0.01-0.14-0.03-0.21-0.04
                    c-0.14-0.03-0.28-0.07-0.42-0.11c-0.14-0.04-0.27-0.1-0.41-0.16c-0.07-0.03-0.13-0.06-0.19-0.09c-0.06-0.03-0.13-0.06-0.18-0.1
                    c-0.12-0.07-0.23-0.13-0.33-0.21c-0.1-0.07-0.2-0.14-0.28-0.21c-0.17-0.14-0.29-0.27-0.38-0.37
                    C321.64,300.35,321.6,300.29,321.6,300.29z"/>
                <path id="XMLID_324_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M308.02,300.29
                    c0,0-0.12,0.08-0.34,0.21c-0.11,0.07-0.24,0.15-0.39,0.24c-0.15,0.09-0.32,0.2-0.51,0.31c-0.75,0.45-1.77,1.03-2.81,1.55
                    c-0.26,0.13-0.52,0.26-0.78,0.38c-0.26,0.12-0.52,0.24-0.77,0.35c-0.25,0.11-0.5,0.21-0.73,0.3c-0.12,0.05-0.23,0.09-0.34,0.13
                    c-0.11,0.04-0.22,0.08-0.33,0.11c-0.21,0.08-0.4,0.13-0.58,0.18c-0.17,0.05-0.33,0.08-0.45,0.11c-0.13,0.03-0.23,0.04-0.3,0.05
                    c-0.07,0.01-0.11,0.01-0.11,0.01s0.03-0.02,0.1-0.05c0.06-0.03,0.15-0.07,0.27-0.12c0.11-0.06,0.25-0.13,0.41-0.21
                    c0.16-0.08,0.33-0.18,0.52-0.28c0.38-0.21,0.83-0.45,1.31-0.71c0.24-0.13,0.49-0.26,0.74-0.39c0.25-0.13,0.51-0.26,0.77-0.4
                    c0.26-0.13,0.52-0.26,0.78-0.38c0.26-0.13,0.51-0.25,0.76-0.36c0.25-0.11,0.49-0.22,0.72-0.32c0.23-0.1,0.45-0.19,0.66-0.27
                    c0.41-0.16,0.76-0.28,1.01-0.36C307.88,300.33,308.02,300.29,308.02,300.29z"/>
                <path id="XMLID_323_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M317.37,324.34
                    c0,0,0.02,0.01,0.05,0.02c0.03,0.01,0.07,0.03,0.13,0.05c0.06,0.02,0.12,0.05,0.2,0.08c0.08,0.03,0.16,0.06,0.25,0.09
                    c0.37,0.13,0.86,0.28,1.34,0.33c0.12,0.02,0.24,0.02,0.35,0.02c0.06,0,0.11,0,0.17-0.01c0.06-0.01,0.11-0.01,0.16-0.02
                    c0.11-0.02,0.21-0.04,0.3-0.07c0.1-0.03,0.18-0.07,0.27-0.11c0.08-0.04,0.15-0.09,0.22-0.13c0.03-0.02,0.06-0.05,0.09-0.07
                    c0.03-0.02,0.05-0.04,0.07-0.06c0.05-0.04,0.08-0.07,0.1-0.09c0.02-0.02,0.04-0.03,0.04-0.03s0,0.02-0.01,0.05
                    c-0.01,0.03-0.01,0.08-0.04,0.14c-0.01,0.03-0.02,0.06-0.03,0.1c-0.01,0.04-0.03,0.07-0.05,0.11c-0.04,0.08-0.1,0.16-0.17,0.25
                    c-0.08,0.08-0.17,0.17-0.28,0.24c-0.11,0.07-0.24,0.14-0.38,0.18c-0.27,0.09-0.58,0.11-0.88,0.08c-0.15-0.02-0.29-0.04-0.43-0.09
                    c-0.14-0.04-0.27-0.09-0.39-0.15c-0.12-0.06-0.24-0.12-0.34-0.19c-0.1-0.07-0.2-0.14-0.28-0.2c-0.16-0.14-0.28-0.27-0.35-0.37
                    c-0.04-0.05-0.06-0.09-0.08-0.12C317.38,324.35,317.37,324.34,317.37,324.34z"/>
                <path id="XMLID_322_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M277.86,322.47
                    c0,0,0.13,0.17,0.35,0.49c0.1,0.16,0.23,0.35,0.37,0.58c0.14,0.23,0.29,0.48,0.45,0.77c0.16,0.29,0.33,0.6,0.49,0.93
                    c0.17,0.33,0.34,0.69,0.5,1.06c0.16,0.37,0.33,0.76,0.48,1.17c0.15,0.4,0.29,0.82,0.43,1.23c0.06,0.21,0.12,0.42,0.19,0.63
                    c0.06,0.21,0.11,0.42,0.16,0.63c0.1,0.42,0.19,0.84,0.25,1.24c0.07,0.41,0.11,0.8,0.15,1.18c0.04,0.37,0.05,0.73,0.05,1.06
                    c0,0.16,0,0.32-0.01,0.47c-0.01,0.15-0.02,0.29-0.03,0.42c-0.02,0.27-0.06,0.5-0.08,0.68c-0.03,0.19-0.06,0.33-0.08,0.43
                    c-0.02,0.1-0.03,0.15-0.03,0.15s0-0.22,0-0.6c0-0.09-0.01-0.2-0.01-0.31c0-0.11,0-0.24-0.02-0.37c-0.01-0.13-0.02-0.27-0.03-0.42
                    c-0.01-0.15-0.03-0.3-0.05-0.46c-0.01-0.16-0.04-0.32-0.06-0.5c-0.02-0.17-0.05-0.35-0.08-0.53c-0.03-0.18-0.07-0.37-0.1-0.56
                    c-0.04-0.19-0.08-0.38-0.12-0.58c-0.08-0.39-0.19-0.79-0.29-1.2c-0.05-0.2-0.12-0.41-0.17-0.61c-0.06-0.2-0.12-0.41-0.19-0.61
                    c-0.13-0.41-0.26-0.81-0.4-1.21c-0.14-0.4-0.28-0.79-0.42-1.16c-0.14-0.38-0.28-0.74-0.42-1.08c-0.14-0.34-0.27-0.67-0.4-0.96
                    c-0.25-0.6-0.47-1.09-0.62-1.44C277.94,322.67,277.86,322.47,277.86,322.47z"/>
                <path id="XMLID_321_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M333.44,360.65
                    c0,0,0.16,0.17,0.42,0.47c0.07,0.07,0.15,0.14,0.24,0.23c0.09,0.08,0.17,0.17,0.28,0.26c0.11,0.09,0.22,0.18,0.34,0.27
                    c0.13,0.09,0.26,0.18,0.39,0.27c0.14,0.09,0.3,0.17,0.45,0.26c0.16,0.07,0.33,0.16,0.5,0.24c0.09,0.03,0.18,0.07,0.27,0.1
                    c0.05,0.02,0.09,0.04,0.14,0.05c0.05,0.01,0.09,0.03,0.14,0.04c0.09,0.03,0.19,0.06,0.29,0.09c0.1,0.03,0.2,0.05,0.3,0.07
                    c0.2,0.05,0.41,0.08,0.62,0.13c0.11,0.01,0.21,0.03,0.32,0.04c0.11,0.02,0.21,0.03,0.32,0.04c0.43,0.04,0.88,0.06,1.33,0.05
                    c0.11,0,0.22,0,0.34,0l0.17,0l0.08,0l0.1,0c0.06,0,0.11,0,0.18,0c0.07,0,0.13,0.01,0.19,0.01l0.18,0.03
                    c0.06,0.01,0.12,0.02,0.18,0.04c0.48,0.11,0.92,0.34,1.28,0.62c0.37,0.28,0.66,0.61,0.9,0.94c0.24,0.33,0.41,0.68,0.54,1.01
                    c0.13,0.33,0.22,0.64,0.27,0.92c0.06,0.28,0.07,0.53,0.08,0.73c0.01,0.2,0,0.36-0.01,0.47c-0.01,0.11-0.01,0.16-0.01,0.16
                    s-0.01-0.06-0.02-0.16c-0.01-0.11-0.04-0.26-0.08-0.46c-0.04-0.19-0.1-0.43-0.18-0.69c-0.09-0.26-0.2-0.54-0.36-0.84
                    c-0.08-0.15-0.16-0.3-0.26-0.44c-0.1-0.15-0.2-0.3-0.32-0.44c-0.24-0.29-0.52-0.56-0.85-0.79c-0.33-0.23-0.7-0.4-1.1-0.48
                    l-0.15-0.03l-0.15-0.02l-0.14-0.01c-0.05,0-0.11,0-0.16,0l-0.08,0l-0.09,0l-0.17,0.01c-0.12,0.01-0.23,0.01-0.35,0.01
                    c-0.47,0-0.93-0.01-1.39-0.07c-0.23-0.02-0.46-0.07-0.68-0.1c-0.22-0.05-0.44-0.09-0.65-0.16c-0.11-0.03-0.21-0.06-0.31-0.1
                    c-0.1-0.04-0.2-0.08-0.3-0.12c-0.05-0.02-0.1-0.04-0.15-0.06c-0.05-0.02-0.1-0.05-0.14-0.07c-0.09-0.04-0.19-0.09-0.28-0.13
                    c-0.18-0.09-0.35-0.2-0.51-0.29c-0.16-0.11-0.31-0.22-0.45-0.32c-0.13-0.12-0.26-0.23-0.38-0.33c-0.11-0.11-0.22-0.23-0.31-0.33
                    c-0.1-0.1-0.18-0.21-0.25-0.31c-0.07-0.1-0.14-0.19-0.2-0.27c-0.11-0.17-0.19-0.31-0.24-0.4
                    C333.47,360.7,333.44,360.65,333.44,360.65z"/>
                <path id="XMLID_320_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M315.99,362.23
                    c0,0,0.18,0.08,0.48,0.24c0.16,0.07,0.34,0.16,0.55,0.25c0.11,0.05,0.22,0.09,0.34,0.13c0.06,0.02,0.12,0.04,0.19,0.07
                    c0.06,0.02,0.13,0.04,0.2,0.06c0.27,0.08,0.57,0.14,0.88,0.17c0.32,0.03,0.66,0.04,1.02,0.04c0.36,0,0.74,0,1.12,0.01
                    c0.19,0.01,0.39,0.02,0.58,0.04l0.15,0.01l0.07,0.01l0.04,0l0.03,0l0.02,0l0.14,0.02l0.07,0.01l0.08,0.02
                    c0.42,0.08,0.8,0.22,1.16,0.39c0.36,0.17,0.69,0.37,1,0.58c0.31,0.21,0.59,0.43,0.85,0.65c0.26,0.22,0.49,0.44,0.7,0.64
                    c0.42,0.41,0.73,0.79,0.94,1.05c0.21,0.27,0.32,0.43,0.32,0.43s-0.15-0.13-0.4-0.35c-0.26-0.22-0.63-0.53-1.08-0.88
                    c-0.23-0.18-0.48-0.36-0.74-0.55c-0.27-0.19-0.56-0.37-0.86-0.56c-0.3-0.18-0.62-0.35-0.96-0.5c-0.17-0.07-0.34-0.14-0.51-0.19
                    c-0.09-0.03-0.17-0.06-0.26-0.08c-0.09-0.03-0.17-0.04-0.26-0.06l-0.06-0.01l-0.07-0.01l-0.14-0.02l-0.02,0l0,0
                    c-0.01,0,0.01,0,0.01,0l-0.01,0l-0.04,0l-0.07-0.01l-0.14-0.01c-0.19-0.02-0.38-0.03-0.56-0.05c-0.38-0.02-0.75-0.04-1.11-0.06
                    c-0.36-0.02-0.73-0.06-1.07-0.12c-0.17-0.03-0.33-0.07-0.49-0.12c-0.15-0.06-0.3-0.1-0.44-0.17c-0.07-0.03-0.14-0.06-0.2-0.09
                    c-0.06-0.03-0.13-0.07-0.19-0.1c-0.12-0.06-0.23-0.13-0.33-0.19c-0.21-0.12-0.37-0.25-0.51-0.35c-0.14-0.1-0.24-0.19-0.31-0.25
                    C316.03,362.26,315.99,362.23,315.99,362.23z"/>
                <path id="XMLID_319_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M321.8,270.87
                    c0,0,0.03,0.1,0.08,0.27c0.03,0.09,0.05,0.19,0.07,0.31c0.01,0.06,0.02,0.13,0.04,0.2c0.01,0.07,0.02,0.14,0.03,0.22
                    c0.02,0.15,0.03,0.32,0.03,0.5c0.01,0.18,0,0.37-0.01,0.56c-0.02,0.19-0.03,0.39-0.08,0.59c-0.03,0.2-0.09,0.4-0.14,0.6
                    c-0.07,0.2-0.12,0.4-0.2,0.58c-0.07,0.19-0.17,0.37-0.25,0.55c-0.09,0.17-0.18,0.34-0.28,0.48c-0.1,0.15-0.19,0.29-0.29,0.41
                    c-0.05,0.06-0.09,0.12-0.14,0.17c-0.05,0.05-0.09,0.1-0.14,0.15c-0.08,0.09-0.16,0.17-0.23,0.23c-0.13,0.12-0.21,0.19-0.21,0.19
                    s0.05-0.09,0.15-0.23c0.05-0.07,0.11-0.16,0.17-0.27c0.07-0.1,0.14-0.22,0.21-0.35c0.08-0.13,0.15-0.27,0.23-0.42
                    c0.08-0.15,0.15-0.32,0.23-0.48c0.07-0.17,0.14-0.35,0.21-0.53c0.07-0.18,0.12-0.37,0.18-0.56c0.05-0.19,0.11-0.38,0.14-0.57
                    c0.04-0.19,0.07-0.38,0.11-0.56c0.02-0.18,0.05-0.36,0.06-0.53c0.01-0.17,0.03-0.33,0.03-0.48c0.01-0.15,0.01-0.29,0.01-0.41
                    c0-0.12,0-0.23,0-0.32C321.8,270.97,321.8,270.87,321.8,270.87z"/>
                <path id="XMLID_318_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M326.45,271.23
                    c0,0,0.01,0.11,0.03,0.29c0.02,0.19,0.04,0.45,0.07,0.77c0.02,0.16,0.03,0.33,0.05,0.51c0.02,0.18,0.05,0.37,0.07,0.57
                    c0.05,0.39,0.11,0.81,0.18,1.23c0.04,0.21,0.07,0.42,0.11,0.62c0.04,0.2,0.08,0.4,0.13,0.6c0.09,0.39,0.18,0.75,0.26,1.05
                    c0.08,0.31,0.15,0.57,0.21,0.75c0.05,0.18,0.08,0.29,0.08,0.29s-0.06-0.09-0.15-0.26c-0.05-0.08-0.1-0.18-0.16-0.3
                    c-0.06-0.12-0.12-0.25-0.18-0.4c-0.07-0.15-0.13-0.31-0.19-0.49c-0.06-0.17-0.13-0.36-0.18-0.55c-0.05-0.2-0.12-0.4-0.16-0.6
                    c-0.05-0.21-0.09-0.42-0.13-0.63c-0.03-0.21-0.07-0.43-0.09-0.64c-0.03-0.21-0.04-0.42-0.05-0.62c-0.02-0.4-0.02-0.78,0-1.11
                    c0.01-0.16,0.02-0.31,0.03-0.44c0.01-0.13,0.03-0.24,0.04-0.34C326.43,271.34,326.45,271.23,326.45,271.23z"/>
            </g>
            <rect x="386.47" y="194.89" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" width="191.98" height="191.98"/>
            <path id="XMLID_316_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M495.78,381.48
                c-0.37-2-30.97-38.88-32.5-52.53c-1.53-13.64-2.95-24.71-3.52-29.16c-0.57-4.45-0.99-5.67-6.91-8.91
                c-5.92-3.24-9.14-17.73-7.35-22.76c1.8-5.02-0.97-14.68,1.8-18.26c2.77-3.58,24.07-28.39,26.97-34.1
                c2.9-5.7,11.26-10.84,14.92-10.84c3.66,0,15.23,4.04,22.42,4.59c7.19,0.55,19.71-0.59,22.58,5.97c2.87,6.56,7.14,5.96,10,11.96
                s2.24,9.43,3.69,15.42c1.45,5.99-0.84,12.94,1.45,16.74c2.28,3.8,8.94,6.5,7.46,11.28c-1.48,4.78-7.44,7.08-11.02,7.93
                c-3.58,0.84-4.45,1.74-5.7,7.93c-1.26,6.19-3.51,11.11-6.52,14.29c-3.01,3.18-4.49,15.67-5.04,18.2c-0.55,2.53-9.18,4.6-7.44,6.76
                c1.74,2.16,5.48,1.07,6.46,4.89s9.16,12.16,12.54,17.98c3.38,5.83,14.63,15.78,16.93,17.63s10.63,20.37,10.63,20.37h-69.68
                L495.78,381.48z"/>
            <g id="XMLID_258_">
                <path id="XMLID_312_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M578.46,386.88
                    c0,0-13.13-22.55-15.94-25.53c-2.8-2.98-4.2-0.35-7.18-1.4c-2.98-1.05-7.71-10.33-12.08-15.24c-4.38-4.9-13.45-9.87-12.16-10.34
                    s8.45-5.02,8.45-5.02s-0.63-0.58-2.5-2.22c-1.87-1.63-10.16-2.45-10.97-2.57c-0.82-0.12,0.23-1.63,1.87-2.8
                    c1.63-1.17,4.44-10.39,5.72-14.94c1.28-4.55,4.79-10.04,5.14-13.89c0.35-3.85,0-9.57,0-11.68s0.35-3.15,0.7-4.09
                    c0.35-0.93-1.28-1.52-3.5-2.69c-2.22-1.17-9.46-0.58-10.27,0c-0.82,0.58-2.8,1.52-4.9,1.52c-2.1,0-2.13,0.81-3.04,3.15
                    c-0.35,0.9-1.4,0.16-2.02,0.47c-0.62,0.31,0.7,1.09,1.63,1.87c0.93,0.78,0,8.87,0.16,10.35c0.16,1.48,1.01,1.01,1.63,2.02
                    c0.62,1.01,0.23,4.75-0.62,5.37c-0.86,0.62-2.1,1.63-2.72,2.26c-0.62,0.62-2.1,1.01-2.57,1.71c-0.47,0.7,4.75,2.02,5.76,2.49
                    s4.05-0.39,4.9-1.71c0.86-1.32,0.08-2.88-0.62-3.97c-0.7-1.09-1.63-3.97-1.71-5.76c-0.08-1.79-1.32-2.26-1.63-3.42
                    c-0.31-1.17,0.54-1.25,1.63-0.95s-0.16-2.86,0-3.87c0.16-1.01-0.16-1.17,1.32-0.86c1.48,0.31,2.26-0.16,1.71-0.16
                    c-0.54,0-3.19-1.71-2.41-2.65c0.78-0.93,2.1-1.01,2.1-1.01s0.08,2.88,3.04,2.88c2.96,0,2.65-2.1,2.96-2.26
                    c0.31-0.16,1.17,0.62,0.86,1.63c-0.31,1.01-3.19,1.87-3.19,1.87s1.25,0.31,2.18-0.16c0.93-0.47,1.4-0.23,0.62,0.7
                    c-0.78,0.93-0.47,1.17-0.31,1.87c0.16,0.7-0.86,1.95-0.86,2.72c0,0.78,2.18-0.78,2.72-1.09c0.54-0.31,1.17-2.26,1.87-3.66
                    s1.17-0.85,1.35,2.48c0.18,3.33,0,3.68-2.8,7.88c-2.8,4.2-4.2,9.46-4.9,11.73c-0.7,2.28-1.4,7.71-1.49,11.21s-4.81,3.33-7.44,3.5
                    c-2.63,0.18-2.63,2.1-2.1,4.2c0.53,2.1,0,1.75-2.28,2.63c-2.28,0.88-2.8-2.28-3.33-3.15s-1.26,1-2.8,2.63
                    c-0.76,0.81-3.06,2.33-6.04,1.39c-2.98-0.94-6.39-3.84-8.67-6.12c-2.28-2.28-5.78-1.05-7.53-1.93
                    c-1.75-0.88-12.08-12.26-11.91-11.38c0.18,0.88,10.68,12.08,11.56,13.48c0.88,1.4,7.88,4.55,9.28,4.55s4.73,2.63,7,3.68
                    c2.28,1.05,4.38,2.28,5.78,4.2s-3.15,2.98-3.15,2.98s5.25,0.35,7,0.18s3.5-3.33,4.38-4.03c0.88-0.7,6.3-3.5,7.71-4.38
                    s2.8,1.23,1.05,1.93s-1.93,2.98,0.18,4.03c2.1,1.05,6.13,3.5,7.71,7c1.58,3.5-2.1,2.28,0,5.43c2.1,3.15,8.06,2.28,10.68,4.73
                    c2.63,2.45,4.55,9.46,6.48,13.48c1.93,4.03,6.13,4.2,7.71,8.93s0.7,9.77,0.7,9.77H578.46z M525.65,281.72
                    c-0.31,0-0.56-0.25-0.56-0.56c0-0.31,0.25-0.56,0.56-0.56s0.56,0.25,0.56,0.56C526.21,281.47,525.96,281.72,525.65,281.72z
                    M534.75,279.7c-0.31,0.7-0.93,1.71-1.71,1.4c-0.78-0.31-3.82-1.61-5.92-1.79c-1.56-0.13-2-1.09-0.77-1.25
                    c1.23-0.16,4.19-0.23,4.74-0.54c0.54-0.31,2.02-0.54,3.27,0c1.25,0.54,1.25,0.54,1.25,0.54S535.06,279,534.75,279.7z"/>
                <path id="XMLID_309_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M508.91,274.72
                    c-3.15,0-3.04-0.82-7.01-2.8c-3.97-1.98-4.79-0.58-6.3,0c-1.52,0.58-1.87,0.35-4.79,0.93c-2.92,0.58-6.3,2.45-5.95,3.04
                    c0.35,0.58,2.08,1.79,3.04,0.82c2.49-2.53,6.07-2.76,7.55-2.45c1.48,0.31-2.72,1.71-2.1,1.87s2.96-1.09,4.11-1.4
                    c1.15-0.31,5.39,2.02,2.2,1.87s-5.68,0.62-6.85,1.01c-1.17,0.39-1.87,0.62-3.35,0.08c-1.48-0.54-1.32,0.47-1.01,0.94
                    c0.31,0.47,1.87,0.39,1.01,1.13c-0.86,0.74-1.48-0.27-2.33,0.35s1.79,0.62,1.71,1.07c-0.08,0.45,1.09,0.49,2.02,0
                    c0.93-0.49,2.33-0.91,2.33-0.91c1.87,2.65,3.58,2.65,3.58,2.65c-1.25-0.47-2.26-2.33-2.18-3.15s1.73-0.56,1.73-0.56
                    s0.53,2.94,3.41,2.94c2.88,0,3.27-2.94,3.27-2.94s1.48,0.99,1.17,1.98s-3.5,1.58-3.5,1.58s0.78,0.39,0.86,1.17s-3.46,0.86-3.5,1.56
                    c-0.04,0.7,4.36,1.28,5.41,1.28c1.05,0,3.39-2.69,3.62-3.74c0.23-1.05,0-2.45,0.82-2.41c0.82,0.04,2.1-0.51,3.04-1.42
                    c0.93-0.91,2.69-3.99,2.69-5.37C513.58,272.44,512.06,274.72,508.91,274.72z M498.01,279.74c-0.31,0-0.56-0.25-0.56-0.56
                    s0.25-0.56,0.56-0.56c0.31,0,0.56,0.25,0.56,0.56S498.32,279.74,498.01,279.74z"/>
                <path id="XMLID_308_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M503.42,298.77
                    c1.17,0.7,1.75,0.93,2.33,0.7s0,0.12,0.58-0.23c0.58-0.35,2.45-0.64,3.74,0.32c1.28,0.96,3.15,2.01,2.22,2.36
                    c-0.93,0.35-1.87,0.23-2.92,0c-1.05-0.23-2.33,0.58-4.32-0.7C503.07,299.94,503.42,298.77,503.42,298.77z"/>
                <path id="XMLID_307_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M505.02,293.63
                    c0,0-4.4,3.5-6.44,4.75c-2.04,1.25,1.32,0.47,3.22-0.31S505.02,293.63,505.02,293.63z"/>
                <path id="XMLID_306_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M523.7,302.74
                    c0,0,1.32,0.99,2.51,3.74c1.2,2.75,1.3,6.54,1.3,6.54s0.12-1.01-1.3-1.48c-1.42-0.47-0.8-0.93-0.56-2.18
                    S524.17,302.74,523.7,302.74z"/>
                <path id="XMLID_305_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M512.1,304.53
                    c-0.5,1.16-0.08,1.48,0,2.26c0.08,0.78,0.12,1.79,0.45,1.32c0.33-0.47,0.18-1.25,0.49-2.1C513.35,305.15,512.5,303.61,512.1,304.53
                    z"/>
                <path id="XMLID_304_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M496.32,312.7
                    c-1.23-0.16-1.62-1.32,0-2.49c1.62-1.17,2.14-2.88,2.89-2.34c0.75,0.54,1.79,2.46,4.19,2.36c2.4-0.1,4.34-1.82,6.83-1.58
                    c2.49,0.23,2.65,1.87,4.44,1.01c1.79-0.86,2.65,0.47,4.13,0.93c1.48,0.47,4.59-0.54,4.36,0.31c-0.23,0.86-1.32,0.78-1.63,1.25
                    c-0.31,0.47,1.5,1.79,1.65,3.81s0.77,2.18-0.4,2.18c-1.17,0-1.87-0.54-2.34,0.39c-0.47,0.93-1.71,1.87-1.71,1.87
                    s-0.62-3.19-1.71-3.04c-1.09,0.16-3.11-0.16-3.42-0.47c-0.31-0.31-2.84-0.08-4.14,0.23c-1.31,0.31-6.29-0.93-4.89-1.48
                    c1.4-0.54,2.02-0.96,2.49-1.57c0.47-0.61,0.54-2.56,1.4-1.47c0.86,1.09,3.04,0.54,5.14,0.78c2.1,0.23,3.81-0.39,4.05-1.01
                    c0.23-0.62-0.16-1.32-0.16-1.32s-18.98-0.7-19.53,0S497.51,312.86,496.32,312.7z"/>
                <path id="XMLID_303_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M503.42,286.74
                    c0,0-0.03,0.03-0.08,0.09c-0.03,0.03-0.06,0.07-0.11,0.11c-0.04,0.04-0.09,0.09-0.15,0.14c-0.06,0.05-0.12,0.1-0.19,0.16
                    c-0.07,0.06-0.15,0.11-0.24,0.17c-0.09,0.06-0.18,0.12-0.28,0.18c-0.1,0.06-0.21,0.13-0.32,0.19c-0.12,0.06-0.24,0.12-0.36,0.19
                    c-0.13,0.06-0.26,0.11-0.4,0.18c-0.07,0.03-0.14,0.06-0.21,0.09c-0.07,0.03-0.15,0.05-0.22,0.08c-0.15,0.05-0.3,0.1-0.46,0.16
                    c-0.16,0.04-0.32,0.09-0.48,0.13c-0.08,0.02-0.16,0.04-0.25,0.07c-0.08,0.02-0.17,0.03-0.25,0.05c-0.34,0.06-0.68,0.14-1.02,0.17
                    c-0.17,0.02-0.35,0.04-0.52,0.06c-0.17,0.02-0.34,0.02-0.51,0.03c-0.34,0.01-0.68,0.03-1,0.01c-0.32-0.01-0.64-0.02-0.93-0.05
                    c-0.3-0.03-0.57-0.07-0.83-0.1c-0.13-0.02-0.25-0.03-0.37-0.05c-0.12-0.02-0.23-0.05-0.33-0.07c-0.21-0.04-0.38-0.08-0.53-0.11
                    c-0.29-0.07-0.46-0.11-0.46-0.11s0.17-0.01,0.47-0.01c0.15,0,0.33,0,0.54,0.01c0.1,0,0.21,0,0.33,0.01c0.12,0,0.24,0,0.37,0
                    c0.25,0,0.53-0.01,0.82,0c0.29,0,0.6-0.02,0.91-0.03c0.16-0.01,0.32,0,0.48-0.02c0.16-0.01,0.33-0.03,0.49-0.04
                    c0.16-0.01,0.33-0.02,0.5-0.04c0.17-0.02,0.33-0.04,0.5-0.06c0.33-0.03,0.66-0.09,0.99-0.14c0.16-0.02,0.32-0.06,0.48-0.09
                    c0.16-0.03,0.31-0.07,0.47-0.1c0.15-0.04,0.3-0.08,0.45-0.12c0.07-0.02,0.15-0.04,0.22-0.06c0.07-0.02,0.14-0.04,0.21-0.06
                    c0.14-0.04,0.27-0.08,0.4-0.12c0.13-0.05,0.25-0.09,0.37-0.13c0.12-0.04,0.23-0.09,0.34-0.13c0.11-0.04,0.21-0.08,0.3-0.13
                    c0.09-0.04,0.18-0.09,0.26-0.12c0.08-0.04,0.15-0.08,0.22-0.11c0.13-0.07,0.23-0.13,0.3-0.17
                    C503.39,286.77,503.42,286.74,503.42,286.74z"/>
                <path id="XMLID_302_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M521.05,288.73
                    c0,0,0.08,0.03,0.22,0.11c0.07,0.04,0.15,0.08,0.24,0.14c0.09,0.06,0.19,0.12,0.3,0.2c0.21,0.16,0.45,0.35,0.69,0.58
                    c0.06,0.06,0.12,0.12,0.18,0.18c0.06,0.06,0.12,0.12,0.18,0.19c0.12,0.13,0.23,0.26,0.34,0.4l0.16,0.21
                    c0.05,0.07,0.1,0.14,0.15,0.21c0.1,0.14,0.19,0.29,0.27,0.43c0.09,0.14,0.16,0.28,0.23,0.42c0.03,0.07,0.07,0.13,0.1,0.2
                    c0.03,0.07,0.05,0.13,0.08,0.19c0.05,0.12,0.09,0.24,0.13,0.34c0.03,0.1,0.05,0.2,0.07,0.27c0.04,0.15,0.05,0.24,0.05,0.24
                    s-0.06-0.07-0.16-0.19c-0.09-0.12-0.23-0.29-0.39-0.49c-0.08-0.1-0.16-0.21-0.25-0.33c-0.09-0.12-0.18-0.24-0.28-0.36
                    c-0.05-0.06-0.1-0.13-0.15-0.19c-0.05-0.06-0.1-0.13-0.15-0.19c-0.1-0.13-0.21-0.27-0.31-0.4c-0.1-0.13-0.21-0.26-0.31-0.4
                    c-0.1-0.13-0.21-0.26-0.31-0.38c-0.2-0.25-0.38-0.48-0.54-0.69c-0.16-0.21-0.29-0.38-0.38-0.5c-0.05-0.06-0.08-0.11-0.11-0.14
                    C521.06,288.75,521.05,288.73,521.05,288.73z"/>
                <path id="XMLID_301_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M526.36,296.33
                    c0,0,0.02,0.03,0.06,0.08c0.04,0.05,0.1,0.14,0.16,0.25c0.06,0.11,0.14,0.25,0.22,0.41c0.08,0.16,0.16,0.34,0.25,0.54
                    c0.17,0.4,0.35,0.87,0.54,1.37c0.09,0.25,0.19,0.51,0.29,0.77c0.1,0.26,0.2,0.53,0.31,0.79c0.1,0.26,0.21,0.52,0.33,0.77
                    c0.11,0.25,0.23,0.5,0.35,0.73c0.12,0.23,0.24,0.45,0.37,0.65c0.13,0.2,0.26,0.39,0.38,0.56c0.07,0.08,0.13,0.16,0.19,0.23
                    c0.06,0.07,0.13,0.14,0.18,0.2c0.12,0.12,0.23,0.23,0.33,0.3c0.09,0.08,0.17,0.14,0.23,0.18c0.05,0.04,0.08,0.06,0.08,0.06
                    s-0.03-0.01-0.1-0.03c-0.06-0.02-0.16-0.05-0.28-0.11c-0.06-0.03-0.12-0.06-0.19-0.1c-0.07-0.04-0.14-0.08-0.22-0.13
                    c-0.07-0.05-0.15-0.11-0.23-0.17c-0.08-0.06-0.16-0.13-0.24-0.21c-0.16-0.15-0.33-0.33-0.49-0.53c-0.16-0.2-0.32-0.42-0.46-0.65
                    c-0.14-0.24-0.29-0.49-0.41-0.75c-0.13-0.26-0.25-0.53-0.36-0.8c-0.22-0.54-0.4-1.1-0.55-1.62c-0.08-0.26-0.14-0.51-0.2-0.75
                    c-0.06-0.24-0.11-0.47-0.17-0.68c-0.05-0.21-0.1-0.4-0.14-0.57c-0.04-0.17-0.08-0.32-0.12-0.44c-0.04-0.12-0.06-0.22-0.09-0.28
                    C526.37,296.37,526.36,296.33,526.36,296.33z"/>
                <path id="XMLID_300_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M522.74,307.41
                    c0,0,0.04,0.05,0.1,0.13c0.03,0.04,0.07,0.09,0.1,0.15c0.04,0.06,0.08,0.13,0.12,0.2c0.09,0.15,0.18,0.33,0.26,0.53
                    c0.02,0.05,0.04,0.1,0.06,0.16c0.02,0.05,0.04,0.11,0.05,0.16c0.03,0.11,0.06,0.23,0.08,0.35c0.02,0.12,0.03,0.24,0.03,0.36
                    c0,0.06,0,0.12,0,0.18c0,0.06,0,0.11-0.01,0.17c-0.01,0.06-0.01,0.11-0.02,0.16c-0.01,0.05-0.02,0.1-0.03,0.15
                    c-0.02,0.1-0.05,0.19-0.08,0.27c-0.03,0.08-0.06,0.16-0.09,0.22c-0.03,0.07-0.06,0.12-0.08,0.17c-0.05,0.09-0.08,0.14-0.08,0.14
                    s0-0.06,0.01-0.16c0.01-0.05,0.01-0.11,0.02-0.18c0-0.07,0.02-0.15,0.02-0.23c0-0.08,0.01-0.17,0.02-0.27c0-0.05,0-0.1,0.01-0.14
                    c0-0.05,0-0.1,0-0.15c0-0.1-0.01-0.21-0.02-0.31c-0.01-0.11-0.02-0.21-0.04-0.32c-0.02-0.11-0.04-0.21-0.06-0.32
                    c-0.02-0.1-0.05-0.21-0.08-0.31c-0.06-0.2-0.11-0.39-0.15-0.55c-0.05-0.16-0.08-0.3-0.1-0.4
                    C522.75,307.47,522.74,307.41,522.74,307.41z"/>
                <path id="XMLID_299_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M469.68,300.46
                    c0.75,3.64,3,11.38,4.58,13.63s4.21,5.23,4.21,5.23s-5.6-4.04-7.53-8.41c-1.93-4.37-2.28-6.54-2.98-8.17
                    C467.26,301.11,468.99,297.08,469.68,300.46z"/>
                <path id="XMLID_298_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M480.92,322.47
                    c0,0,0.05,0.07,0.14,0.19c0.09,0.12,0.24,0.3,0.42,0.52c0.19,0.22,0.43,0.48,0.71,0.76c0.29,0.28,0.62,0.59,0.99,0.91
                    c0.38,0.31,0.81,0.63,1.27,0.95c0.47,0.3,0.99,0.61,1.51,0.95c0.53,0.34,1.07,0.68,1.61,1.04c0.54,0.36,1.09,0.73,1.64,1.11
                    c0.55,0.38,1.09,0.76,1.62,1.14c0.53,0.38,1.05,0.77,1.55,1.14c0.5,0.37,0.97,0.74,1.42,1.1c0.45,0.35,0.86,0.7,1.25,1.02
                    c0.19,0.16,0.37,0.32,0.54,0.47c0.17,0.15,0.33,0.3,0.48,0.43c0.3,0.27,0.55,0.52,0.76,0.73c0.05,0.05,0.1,0.1,0.15,0.15
                    c0.04,0.05,0.08,0.09,0.12,0.14c0.08,0.08,0.14,0.16,0.19,0.21c0.1,0.12,0.16,0.18,0.16,0.18s-0.06-0.05-0.18-0.15
                    c-0.12-0.1-0.29-0.25-0.52-0.43c-0.23-0.18-0.5-0.4-0.82-0.64c-0.32-0.25-0.69-0.51-1.09-0.81c-0.2-0.15-0.41-0.3-0.63-0.45
                    c-0.22-0.16-0.44-0.32-0.67-0.48c-0.46-0.33-0.95-0.67-1.46-1.03c-0.51-0.35-1.04-0.72-1.57-1.09c-0.53-0.37-1.08-0.75-1.63-1.12
                    c-0.54-0.38-1.09-0.75-1.63-1.12c-0.54-0.37-1.07-0.73-1.58-1.08c-0.51-0.35-1.01-0.68-1.48-1.02c-0.46-0.35-0.88-0.7-1.24-1.05
                    c-0.36-0.35-0.67-0.69-0.93-1c-0.26-0.31-0.47-0.6-0.63-0.84c-0.16-0.24-0.28-0.44-0.36-0.58
                    C480.95,322.55,480.92,322.47,480.92,322.47z"/>
                <path id="XMLID_297_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M478.46,341.74
                    c0,0,2.45,1.23,4.03,0c1.58-1.23,3.15-1.54,4.2,0.54s2.49,4.53,2.49,4.53s-2.46-3.25-3.53-3.02c-1.06,0.22-3.17-0.35-3.34,1.45
                    c-0.17,1.79,1.4,6.86,0.53,6.32C481.97,351.02,478.46,341.74,478.46,341.74z"/>
                <path id="XMLID_296_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M485.66,339.46
                    c2.26-0.53,4.01-2.1,5.77-2.98s4.35-2.1,6.03,0.7c0,0-2.75-1.04-2.2,0.27c0.55,1.31-0.67,2.18-1.55,2.01
                    c-0.88-0.18-1.84,1.75-2.15,2.28c-0.31,0.53-1.18-0.19-1.71-0.97s0.07,2.02-0.66,2.37C488.45,343.49,485.66,339.46,485.66,339.46z"
                    />
                <path id="XMLID_295_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M514.89,339.3
                    c0,0,6.94-2.2,8.81-4.74c0,0,5.31,2.98,4.79,3.84s-1.8,0.53-2.89,1.58c-1.09,1.05,1.19,1.93,0.75,4.03c-0.44,2.1-0.78,1.23-1.92,0
                    c-1.14-1.23-2.56-2.76-2.73-0.85s-1.57,5.41-3.49,6.98c-1.93,1.58-5.11,1.46-5.11,1.46s2.66-2.68,1.26-2.51s-7.18,1.75-7.35,1.05
                    s3.58-1.4,4.85-2.45s4.4-1.05,5.9-2.1c1.5-1.05,4.2-3.69,3.29-4.82c-0.91-1.13-1.26,1.01-2.49,2.39c-1.23,1.38-2.8,2.25-3.85,1.73
                    c-1.05-0.53-1.93-2.1,0.18-3.15C516.99,340.69,516.8,339.18,514.89,339.3z"/>
                <path id="XMLID_294_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M491.55,349.27
                    c0,0,0.16,0.08,0.42,0.25c0.26,0.17,0.63,0.42,1.05,0.77c0.21,0.17,0.43,0.36,0.65,0.58c0.22,0.22,0.45,0.45,0.67,0.72
                    c0.22,0.26,0.43,0.55,0.61,0.87c0.19,0.31,0.35,0.66,0.46,1.02c0.06,0.18,0.09,0.37,0.12,0.57c0.01,0.1,0.01,0.2,0.02,0.29
                    c0,0.1,0,0.18-0.01,0.27c-0.02,0.37-0.07,0.72-0.14,1.06c-0.07,0.34-0.17,0.65-0.27,0.94c-0.09,0.29-0.2,0.56-0.27,0.8
                    c-0.07,0.25-0.12,0.47-0.1,0.66c0.01,0.19,0.08,0.36,0.17,0.48c0.09,0.12,0.17,0.21,0.23,0.26c0.06,0.05,0.1,0.08,0.1,0.08
                    s-0.04-0.02-0.12-0.06c-0.07-0.04-0.18-0.1-0.3-0.21c-0.12-0.11-0.25-0.28-0.3-0.52c-0.06-0.23-0.05-0.5-0.01-0.77
                    c0.04-0.27,0.11-0.56,0.18-0.85c0.07-0.29,0.13-0.6,0.18-0.92c0.05-0.31,0.08-0.65,0.08-0.97c0-0.08,0-0.18,0-0.25
                    c-0.01-0.08-0.01-0.16-0.02-0.23c-0.02-0.16-0.05-0.31-0.1-0.47c-0.09-0.31-0.23-0.62-0.39-0.91c-0.16-0.29-0.34-0.57-0.53-0.83
                    c-0.19-0.26-0.38-0.51-0.57-0.74c-0.19-0.23-0.38-0.44-0.56-0.64c-0.36-0.39-0.67-0.7-0.89-0.92
                    C491.68,349.39,491.55,349.27,491.55,349.27z"/>
                <path id="XMLID_293_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M507.97,365.55
                    c0,0-1.84-2.1-2.96-2.28s-4.31-1.93-7.08-4.38c0,0-1.09,1.58,1.36,3.33c2.45,1.75,3.57,2.6,3.53,4.28
                    c-0.03,1.68,3.12,1.68,3.12,1.68s-4.18-2.8-2.56-3.85C505.02,363.28,507.97,365.55,507.97,365.55z"/>
                <path id="XMLID_292_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M514.54,373.78l-1.99,3.68
                    c-0.64-2.1-1.41-2.1,0.55-4.73c1.96-2.63,1.09-6.23,1.09-6.23s2.28,2.28,4.55,5.48c2.28,3.2,2.63,4.71,2.63,4.71
                    s-1.95-1.16-3.6-3.61s-2.53-1.4-2.88-0.35C514.54,373.78,515.59,373.08,514.54,373.78"/>
                <path id="XMLID_291_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M527.51,376.69
                    c0,0-1.07-5.71-2.47-8.51s-2.3-4.64-2.3-4.64s3.91,1.84,4.77,4.64s9.44,8.51,9.44,8.51s-6.69-2.83-8.43-4.71
                    C528.53,371.98,528.93,374.52,527.51,376.69z"/>
                <path id="XMLID_290_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M539.4,358.9
                    c0,0,0.08,0.03,0.24,0.09c0.15,0.06,0.38,0.15,0.66,0.28c0.28,0.14,0.62,0.3,1,0.52c0.19,0.1,0.39,0.23,0.6,0.35
                    c0.21,0.13,0.42,0.27,0.64,0.43c0.44,0.31,0.9,0.67,1.34,1.11c0.44,0.44,0.88,0.94,1.23,1.54c0.04,0.07,0.09,0.14,0.13,0.22
                    c0.04,0.07,0.08,0.15,0.13,0.22c0.04,0.07,0.08,0.15,0.13,0.22c0.04,0.07,0.09,0.14,0.13,0.2c0.09,0.14,0.18,0.27,0.28,0.41
                    c0.1,0.14,0.2,0.27,0.3,0.41c0.42,0.54,0.89,1.06,1.37,1.58c0.48,0.52,0.96,1.04,1.42,1.58c0.46,0.53,0.88,1.09,1.27,1.64
                    c0.19,0.28,0.37,0.55,0.55,0.81c0.18,0.26,0.36,0.51,0.54,0.75c0.37,0.48,0.72,0.93,1.07,1.33c0.68,0.81,1.28,1.46,1.71,1.91
                    c0.21,0.22,0.38,0.4,0.5,0.52c0.12,0.12,0.18,0.18,0.18,0.18s-0.07-0.06-0.2-0.16c-0.13-0.1-0.32-0.26-0.56-0.46
                    c-0.23-0.2-0.52-0.45-0.84-0.75c-0.16-0.15-0.32-0.31-0.5-0.48c-0.17-0.17-0.35-0.35-0.54-0.54c-0.37-0.38-0.77-0.81-1.16-1.28
                    c-0.2-0.23-0.39-0.48-0.59-0.74c-0.1-0.13-0.19-0.26-0.29-0.39c-0.09-0.13-0.19-0.27-0.29-0.4c-0.38-0.53-0.82-1.05-1.27-1.57
                    c-0.46-0.52-0.94-1.03-1.42-1.55c-0.48-0.53-0.95-1.06-1.39-1.63c-0.11-0.14-0.22-0.28-0.31-0.43c-0.1-0.15-0.2-0.29-0.29-0.45
                    c-0.05-0.08-0.09-0.15-0.14-0.23c-0.04-0.08-0.08-0.15-0.12-0.22c-0.04-0.07-0.08-0.15-0.12-0.22c-0.04-0.07-0.08-0.14-0.12-0.22
                    c-0.31-0.55-0.69-1.05-1.1-1.49c-0.1-0.11-0.21-0.21-0.31-0.32c-0.11-0.1-0.21-0.2-0.31-0.29c-0.1-0.1-0.21-0.19-0.31-0.28
                    c-0.1-0.09-0.2-0.18-0.31-0.26c-0.41-0.33-0.8-0.62-1.15-0.86c-0.35-0.24-0.67-0.44-0.94-0.6c-0.13-0.08-0.25-0.15-0.36-0.21
                    c-0.1-0.06-0.19-0.11-0.27-0.15C539.48,358.94,539.4,358.9,539.4,358.9z"/>
                <path id="XMLID_289_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M527.51,356.97
                    c0,0,0.05,0.08,0.15,0.23c0.09,0.15,0.26,0.35,0.47,0.6c0.22,0.24,0.51,0.53,0.86,0.82c0.35,0.29,0.77,0.59,1.25,0.9
                    c0.48,0.3,1.01,0.61,1.58,0.92c0.57,0.31,1.19,0.62,1.81,0.98c0.16,0.09,0.31,0.18,0.47,0.27c0.16,0.1,0.32,0.19,0.47,0.29
                    c0.31,0.2,0.63,0.41,0.93,0.63c0.61,0.45,1.21,0.95,1.76,1.5c0.54,0.56,1.04,1.17,1.44,1.83c0.21,0.33,0.38,0.67,0.53,1.02
                    c0.07,0.18,0.14,0.35,0.21,0.53c0.05,0.18,0.11,0.36,0.16,0.54c0.18,0.7,0.31,1.39,0.41,2.04c0.11,0.65,0.18,1.26,0.3,1.81
                    c0.12,0.55,0.28,1.04,0.47,1.45c0.02,0.05,0.05,0.1,0.07,0.15c0.03,0.05,0.05,0.1,0.08,0.14c0.05,0.09,0.1,0.19,0.15,0.27
                    c0.11,0.17,0.2,0.33,0.3,0.46c0.1,0.14,0.18,0.26,0.26,0.36c0.08,0.1,0.15,0.19,0.2,0.26c0.11,0.14,0.17,0.22,0.17,0.22
                    s-0.07-0.07-0.19-0.19c-0.06-0.06-0.14-0.14-0.23-0.23c-0.09-0.1-0.19-0.21-0.3-0.34c-0.11-0.13-0.22-0.28-0.34-0.44
                    c-0.06-0.08-0.12-0.17-0.18-0.26c-0.03-0.05-0.06-0.09-0.09-0.14c-0.03-0.05-0.06-0.1-0.09-0.15c-0.12-0.2-0.23-0.43-0.33-0.68
                    c-0.1-0.25-0.19-0.51-0.27-0.79c-0.16-0.56-0.27-1.17-0.4-1.81c-0.13-0.64-0.28-1.3-0.48-1.98c-0.18-0.66-0.5-1.31-0.88-1.92
                    c-0.38-0.61-0.86-1.19-1.38-1.72c-0.52-0.53-1.09-1.02-1.68-1.46c-0.59-0.44-1.19-0.85-1.8-1.21c-0.61-0.36-1.21-0.71-1.77-1.04
                    c-0.56-0.34-1.09-0.68-1.55-1.02c-0.23-0.17-0.45-0.34-0.66-0.51c-0.2-0.17-0.39-0.33-0.55-0.5c-0.34-0.32-0.6-0.64-0.8-0.91
                    c-0.2-0.28-0.34-0.5-0.41-0.66C527.56,357.06,527.51,356.97,527.51,356.97z"/>
                <path id="XMLID_288_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M523.7,373.96
                    c0,0,0.03,0.03,0.08,0.1c0.05,0.07,0.12,0.16,0.2,0.29c0.17,0.25,0.4,0.62,0.62,1.09c0.11,0.23,0.23,0.49,0.34,0.77
                    c0.11,0.28,0.22,0.58,0.31,0.89c0.1,0.31,0.18,0.64,0.25,0.98c0.07,0.34,0.12,0.68,0.16,1.03c0.03,0.35,0.06,0.7,0.05,1.04
                    c0,0.35-0.02,0.68-0.06,1.01c-0.04,0.33-0.09,0.64-0.16,0.93c-0.07,0.29-0.15,0.57-0.23,0.81c-0.04,0.12-0.08,0.24-0.13,0.35
                    c-0.05,0.11-0.09,0.21-0.13,0.31c-0.08,0.19-0.17,0.36-0.24,0.49c-0.07,0.13-0.14,0.23-0.18,0.3c-0.04,0.07-0.06,0.11-0.06,0.11
                    s0.05-0.17,0.13-0.45c0.04-0.15,0.09-0.32,0.14-0.52c0.02-0.1,0.05-0.21,0.07-0.32c0.02-0.11,0.05-0.23,0.07-0.36
                    c0.05-0.25,0.09-0.52,0.12-0.8c0.04-0.29,0.06-0.59,0.08-0.9c0-0.16,0.01-0.32,0.01-0.48c0-0.16,0-0.32-0.01-0.49
                    c-0.01-0.33-0.03-0.66-0.06-0.99c-0.04-0.33-0.08-0.66-0.13-0.99c-0.06-0.32-0.12-0.64-0.19-0.95c-0.07-0.31-0.15-0.6-0.23-0.88
                    c-0.08-0.28-0.16-0.54-0.24-0.78c-0.08-0.24-0.16-0.46-0.23-0.66c-0.07-0.19-0.13-0.36-0.19-0.5
                    C523.76,374.12,523.7,373.96,523.7,373.96z"/>
                <path id="XMLID_287_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M536.95,373.96
                    c0,0,0.14,0.13,0.36,0.36c0.11,0.12,0.26,0.25,0.41,0.42c0.16,0.16,0.34,0.34,0.54,0.54c0.2,0.2,0.41,0.41,0.62,0.65
                    c0.22,0.24,0.45,0.49,0.67,0.77c0.22,0.28,0.43,0.58,0.62,0.91c0.2,0.32,0.36,0.67,0.51,1.02c0.07,0.18,0.14,0.36,0.2,0.53
                    c0.06,0.18,0.12,0.36,0.17,0.54c0.1,0.36,0.19,0.72,0.27,1.05c0.08,0.34,0.15,0.67,0.21,0.98c0.07,0.31,0.13,0.61,0.18,0.88
                    c0.11,0.55,0.18,1.01,0.23,1.33c0.05,0.32,0.07,0.51,0.07,0.51s-0.07-0.17-0.19-0.48c-0.11-0.31-0.27-0.74-0.45-1.28
                    c-0.09-0.26-0.18-0.55-0.28-0.86c-0.09-0.31-0.2-0.63-0.29-0.96c-0.1-0.34-0.2-0.67-0.31-1.01c-0.06-0.17-0.12-0.34-0.18-0.51
                    c-0.07-0.17-0.13-0.34-0.2-0.5c-0.14-0.33-0.29-0.66-0.46-0.97c-0.17-0.31-0.36-0.6-0.55-0.89c-0.2-0.28-0.39-0.55-0.58-0.8
                    c-0.19-0.25-0.38-0.49-0.54-0.71c-0.17-0.22-0.32-0.43-0.45-0.61c-0.13-0.19-0.24-0.35-0.33-0.49
                    C537.05,374.12,536.95,373.96,536.95,373.96z"/>
                <path id="XMLID_286_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M501.05,351.56
                    c0,0,0.06,0.03,0.17,0.09c0.11,0.06,0.28,0.15,0.49,0.26c0.42,0.23,1.01,0.55,1.73,0.94c0.36,0.19,0.75,0.4,1.16,0.62
                    c0.41,0.22,0.85,0.44,1.3,0.67c0.23,0.11,0.46,0.23,0.69,0.35c0.23,0.12,0.47,0.23,0.71,0.35c0.24,0.11,0.48,0.23,0.72,0.34
                    c0.24,0.11,0.49,0.22,0.73,0.34c0.25,0.11,0.49,0.21,0.74,0.32c0.25,0.1,0.5,0.2,0.74,0.3c0.49,0.19,0.99,0.35,1.47,0.5
                    c0.48,0.15,0.95,0.28,1.4,0.41c0.45,0.12,0.88,0.24,1.27,0.36c0.39,0.12,0.75,0.25,1.06,0.4c0.31,0.14,0.55,0.32,0.73,0.48
                    c0.18,0.17,0.29,0.33,0.35,0.43c0.03,0.05,0.05,0.1,0.07,0.13c0.02,0.03,0.02,0.04,0.02,0.04s-0.04-0.06-0.12-0.15
                    c-0.08-0.1-0.21-0.23-0.4-0.36c-0.19-0.13-0.44-0.26-0.75-0.37c-0.3-0.11-0.66-0.2-1.06-0.28c-0.39-0.08-0.83-0.16-1.29-0.26
                    c-0.46-0.1-0.94-0.2-1.44-0.33c-0.5-0.13-1.01-0.28-1.52-0.47c-0.26-0.09-0.52-0.19-0.76-0.29c-0.25-0.11-0.5-0.22-0.76-0.32
                    c-0.25-0.12-0.5-0.23-0.74-0.35c-0.24-0.12-0.49-0.24-0.73-0.36c-0.48-0.25-0.94-0.5-1.39-0.75c-0.45-0.25-0.87-0.51-1.27-0.75
                    c-0.4-0.24-0.77-0.48-1.11-0.71c-0.34-0.22-0.65-0.44-0.92-0.63c-0.27-0.19-0.51-0.36-0.7-0.5
                    C501.27,351.73,501.05,351.56,501.05,351.56z"/>
                <path id="XMLID_285_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M500,337.88
                    c0,0,0.08,0.04,0.2,0.12c0.06,0.04,0.14,0.08,0.22,0.13c0.09,0.05,0.18,0.11,0.29,0.17c0.1,0.06,0.22,0.12,0.34,0.19
                    c0.12,0.06,0.25,0.13,0.38,0.19c0.13,0.07,0.27,0.12,0.41,0.19c0.07,0.03,0.14,0.06,0.22,0.09c0.07,0.03,0.14,0.06,0.22,0.08
                    l0.22,0.08c0.07,0.02,0.15,0.05,0.22,0.07c0.07,0.02,0.15,0.05,0.22,0.07c0.07,0.02,0.15,0.04,0.22,0.07
                    c0.14,0.05,0.28,0.08,0.41,0.12c0.13,0.04,0.26,0.08,0.38,0.11c0.12,0.03,0.22,0.07,0.32,0.1c0.1,0.03,0.18,0.05,0.25,0.07
                    c0.14,0.04,0.22,0.08,0.22,0.08s-0.08,0.02-0.23,0.04c-0.07,0.01-0.16,0.02-0.26,0.03c-0.1,0.01-0.22,0.01-0.34,0.01
                    c-0.06,0-0.13,0-0.2,0c-0.07,0-0.14-0.01-0.21-0.01c-0.14-0.01-0.3-0.02-0.45-0.05c-0.16-0.03-0.32-0.05-0.48-0.1
                    c-0.08-0.02-0.16-0.04-0.24-0.07c-0.08-0.03-0.16-0.06-0.24-0.09c-0.16-0.06-0.32-0.13-0.47-0.2c-0.15-0.08-0.29-0.16-0.43-0.25
                    c-0.14-0.09-0.26-0.18-0.37-0.28c-0.11-0.1-0.21-0.19-0.3-0.28c-0.09-0.09-0.16-0.18-0.23-0.26c-0.06-0.08-0.11-0.16-0.15-0.22
                    c-0.04-0.06-0.07-0.11-0.09-0.15C500.01,337.9,500,337.88,500,337.88z"/>
                <g id="XMLID_268_">
                    <path id="XMLID_284_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M440.26,300.46
                        c0,0-0.45-0.22-1.04-0.66C439.74,300.7,440.26,300.46,440.26,300.46z"/>
                    <path id="XMLID_269_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M507.97,386.88
                        c0,0-25.22-38.14-35.96-56.23c-10.74-18.1-8.64-20.2-7.71-26.4c0.93-6.2,5.6-7.91,5.6-7.91l1.72-1.88c0,0-1.72,1.88-7.18,1.88
                        c-5.46,0-4.03-8.36-4.03-8.36s0,0,0.7-1.23c0.7-1.23,0.53,0.71,0.88,1.99c0.35,1.28,2.1-0.58,1.93-3.03s2.8-2.1,2.8-2.1
                        s-1.05,4.03,0.35,6.28c1.4,2.25,2.45,1.78,4.9,1.01c2.45-0.76,2.45-3.61,0.7-5.37c-1.75-1.75-2.63-3.5-1.58-4.93
                        c1.05-1.43,2.63,0.73,4.73-1.42c2.1-2.14,4.38-6.61,4.2-8.29c-0.18-1.68-3.5,0.8-3.5,0.8c3.85-3.68,4.38-9.28,4.38-9.28
                        c-2.45,8.06-7.53,8.48-7.53,8.48c2.28-1.5,2.45-3.93,3.15-5.85s4.9-7.88,4.03-8.93c-0.88-1.05-6.13,9.81-7.53,9.46
                        c-1.4-0.35,3.68-5.6,3.15-7c-0.53-1.4-3.85,5.95-5.95,5.95s1.93-1.4,1.4-2.8c-0.53-1.4-2.1-1.23,0.35-2.28
                        c2.45-1.05,5.25-5.6,7.18-9.35c1.93-3.74,9.17-4.58,8.41-7.64c-0.7-2.8,5.43,0.53,9.88,0.35c4.45-0.18,5.01-2.98,8.68-5.08
                        c3.68-2.1,6.42,1.4,6.42,1.4c-3.57,1.23-7.47,3.5-13.34,8.06c-5.87,4.55-9.89,0-9.89,0s-0.7,2.45,3.59,2.91
                        c4.29,0.46,10.94-3.79,12.1-5.71c1.16-1.93,10.04-4.55,10.57-4.38s-0.53,2.8-0.53,2.8c1.23-0.35,2.8-2.28,2.8-2.28
                        c2.28,3.85-2.1,5.78-2.1,5.78c5.78-1.75,2.8-5.96,5.29-7.54c2.49-1.58,1.02,0.88,5.16,8.24c4.14,7.35,7.68,7.17,7.68,7.17
                        c-5.14-2.33-7.54-10.51-7.54-10.51s2.17,0.47,5.67,1.4c3.5,0.93,4.44,6.3,5.37,8.87c0.93,2.57-1.87,5.6-1.79,8.87
                        s-3.81,8.08-3.81,8.08c2.8,0.61,5.37-2.71,5.37-2.71c0.93,1.87-1.17,6.3-1.17,6.3s1.68,0.85,1.87,2.71s-0.7,8.73-1.17,13.7
                        c-0.47,4.96-3.97,11.85-3.97,11.85c2.81,0.99,7.01-4.2,7.47-3.7c0.47,0.5,1.4,5.21,1.4,5.21c3.5-2.7,4.67-14.37,4.67-14.37
                        c1.87,1.62-0.7,12.27-0.7,12.27c10.27-5.03,16.11-21.55,16.11-21.55c1.44,6.39-3.74,12.45-3.74,12.45s6.54-5.02,5.84-11.32
                        c-0.7-6.3-7.01-10.83-7.01-10.83c5.84,2.24,8.64-3.88,8.64-3.88c-3.74,3.04-7.24-1.17-7.24-1.17c7.47-2.33,5.14-9.81,5.14-9.81
                        s-0.93,4.44-6.54,4.2c-5.6-0.23-3.97-12.14-3.97-12.14c1.87,1.63,3.5,8.64,3.5,8.64c1.63-4.44-1.63-10.27-1.63-10.27
                        c2.1,1.17,3.74,7.01,3.74,7.01c0.93-7.24-5.45-12.14-5.45-12.14c5.29-7.94-0.38-14.24-0.38-14.24c2.1,8.64-5.14,9.57-5.14,9.57
                        c3.27-3.27,3.27-4.9,1.4-10.27c-1.87-5.37-8.17-4.67-8.64-7.24c-0.47-2.57,3.5-5.37,2.34-7.01c-1.17-1.63-4.9,1.17-4.9,1.17
                        c-7.01-12.14-19.15-9.81-19.15-9.81c6.01-0.23,4.58,3.04,4.58,3.04c-1.72-2.1-9.48-1.17-9.48-1.17s-2.57-4.9-7.24-5.4
                        c-4.67-0.49-7.13,4.23-7.13,4.23s-1.51-3.74-6.29-4.2c-4.79-0.47-8.08,5.6-8.08,5.6c-2.83-3.97-8.38-3.04-14.92,0
                        c-6.54,3.04-9.81,11.21-11.91,15.18s-2.1,4.2-7.01,7.71s-8.87,12.14-8.87,12.14s-1.4,0.47-3.04,5.84
                        c-1.63,5.37,3.74,8.17,3.74,8.17c-11.44,3.97-7.24,9.34-7.24,9.34s1.87-2.34,3.74-1.87c1.87,0.47-0.47,5.37-0.47,5.37
                        c-2.34-3.5-4.44,0-4.44,0c2.34,0.7,1.17,5.37-0.47,6.33c-1.63,0.96,0.47,6.78,0.47,6.78c-2.33-0.43-3.27-4.94-3.27-4.94
                        s-2.34,5.14,0.23,7.47c2.57,2.33,8.87,3.62,8.87,3.62s-2.57,1.28-3.74,6.3c-0.82,3.51,1.33,5.73,2.7,6.75
                        c-0.33-0.57-0.65-1.61-0.83-3.46c-0.47-4.77,2.1-5.44,2.1-5.44s0,2.51,1.17,8.14c1.17,5.64,7.24,7.2,7.24,7.2
                        c0.47-3.04,6.77-3.5,6.77-3.5s-4.44,5.6-5.37,10.27c-0.93,4.67-2.57,6.54-7.24,8.87s-3.04,7.94-7.24,10.97
                        c-4.2,3.04-10.51,4.9-18.45,8.74c-7.94,3.84-11.48,9.23-11.48,9.23s-16.3,14.87-16.3,36.04L507.97,386.88z M544.48,256.28
                        c0,0-5.6-9-4.2-9c1.4,0,1.4,0,1.4,0s3.68,3.7,4.03,5.3C546.06,254.18,544.48,256.28,544.48,256.28z M452.02,252.6
                        c1.05-0.35,2.66,3.33,5.1,3.68c0,0-6.01-3.85-2.66-3.15c3.35,0.7,6.67,0.54,8.07,1.93c1.4,1.39,0.7,2.97-0.88,2.8
                        c-1.58-0.18-3.15,0.03-4.12,0.01s-2.01-0.89-2.01-0.89C453.77,255.58,450.97,252.95,452.02,252.6z M461.65,281.72
                        c-2.28,1.17-3.64,4.68-4.54,3.63s0.68-1.4-0.89-1.05c-1.58,0.35-2.45,4.08-2.98,3.44c-0.53-0.64,0.15-3.96,2.27-5.02
                        c0,0-0.34-1.4-2.27-0.18c-1.6,1.02,1.93-2.33,4.29-1.95S463.93,280.55,461.65,281.72z M461.65,279.18
                        c-0.35-1.36,2.1-2.26-2.45-1.98s-8.93,1.02-9.98-3.38c-1.05-4.4,2.98-6.5,3.33-7.55c0.35-1.05,0.07-2.8-0.75-2.63
                        c-0.82,0.18,4.96-3.15,2.16-2.45c-2.8,0.7-6.65,1.93-6.65,1.93c1.23-1.23,3.5-3.5,6.3-3.5s5.64,0.7,3.52,1.75
                        c-2.12,1.05-3.35,1.05-2.29,1.75c1.05,0.7,4.38-1.4,4.2,0c-0.18,1.4-3.33,2.45-4.2,4.03c-0.88,1.58-0.66,5.47,2.29,6.67
                        c2.96,1.21,4.64,1.18,6.16,3.38S462,280.53,461.65,279.18z M474.26,272.57c3.33-2.03,0.7,0,0.7,0s-3.97,3.5-7.07,3.68
                        c-3.09,0.18-4.16-2.81-4.16-2.81s1.65,2.28,4.16,1.58c0,0-2.05-1.98-0.1-1.58C469.53,273.79,470.92,274.59,474.26,272.57z
                        M472.51,266.52c0,0-0.75,2.91-2.47,3.16c0,0,2.98,0.25,3.43-1.15c0,0-0.41,3.83-5.67,2.35c-5.26-1.48-5.47-4.45-5.47-4.45
                        s0.38,1.12,2.48,1.7l-1.05-3.02c-0.02-0.05-0.03-0.08-0.03-0.08l0.03,0.08c0.17,0.47,1.2,2.9,4.03,2.9
                        C470.93,268.01,472.51,266.52,472.51,266.52z M477.06,250.68c-1.58,1.23-6.98,4.55-9.27,4.38c-2.29-0.18-5.44-0.53-6.14-6.3
                        c0,0,1.23,2.63,2.28,3.15c1.05,0.53,1.95,2.8,3.86,1.75c1.91-1.05-0.71-1.23-0.71-1.23c-0.88-1.23-0.7-2.1,1.93-3.15
                        c2.63-1.05,5.25-3.15,6.83-3.85c1.58-0.7,3.5-1.23,3.85,0C480.04,246.65,478.64,249.45,477.06,250.68z M492.91,234.04
                        c-0.79,0.88-1.03,1.4-2.4,1.4c-1.37,0-1.37-0.53-3.3-0.7c-1.93-0.18-4.14-1.93-5.22-1.05c-1.08,0.88-2.48,1.4-1.43,2.8
                        s3.85-0.86,3.68,1.15c-0.18,2.01-0.64,3.41-2.25,4.28c-1.61,0.88-4.76,1.93-6.16,2.1c-1.4,0.18-3.49-1.05-2.71-1.93
                        c0.78-0.88,2.71-3.15,2.71-3.15s-2.28,1.05-3.5,1.05s-1.05-0.86-0.7-2.36c0.35-1.5,1.93-2.72,4.2-2.9
                        c2.28-0.18,4.87-0.18,5.43-2.45c0,0-7.53,3.15-11.21-1.4c-0.47-0.58,6.48,0.7,8.41,0c1.93-0.7,6.26-1.52,6.37-1.11
                        c0.11,0.41-1.97,3.73,0.82,2.51s4.13-3.84,4.86-2.62c0.73,1.22,0.56,1,2.4,1.29C494.75,231.24,493.71,233.16,492.91,234.04z
                        M503.42,224.06c-1.66,0.35-0.09,2.1-0.02,2.42c0.07,0.32-1.99,0.21-3.22,0.98c-1.23,0.77-0.35,1.16-0.97,2.21
                        c-0.62,1.05-4.81-5.25-8.49-6.3c-3.68-1.05-4.9,2.63-8.58,4.09c-3.68,1.47-6.3-1.29-6.3-1.29c0.88,0.7,3.85-0.53,3.85-0.53
                        s-1.4-1.93-1.23-2.28s2.98,1.4,2.98,1.4s0.18-1.4,1.58-1.93c1.4-0.53,2.64-1.75,2.64-1.75c-1.39-0.53-3.87-6.48-1.76-3.94
                        c2.1,2.54,3.85,0.13,3.85,0.13s0.18-1.44-2.09-3.89c-2.26-2.45-0.82-5.43-0.82-5.43s0.63,2.98,3.26,4.9
                        c2.63,1.93,4.82,4.29,4.82,4.29c1.11-6.66,6.29-9.19,6.29-9.19s-3.06,3.5-4.11,8.41c-1.05,4.9,4.9,5.43,6.38,5.43
                        s-0.08-1.05,0.8-1.75c0.88-0.7,2.28,1.4,2.74,2.1C505.48,222.83,505.08,223.71,503.42,224.06z M505.02,215.48
                        c-1.34-3.15-1.6-5.95-1.6-5.95s0.9,3.5,4.87,5.95c3.97,2.45,7.05,4.55,4.81,9.28c0,0,0.46-2.45-1.24-3.68
                        C510.16,219.86,506.36,218.63,505.02,215.48z M517.16,235.09c-2.28,2.28-2.28,2.28-2.28,2.28s0.11,0.88-1.79-0.53
                        c0,0-1.47-1.05-1.24-4.55s0.97-4.55,2-6.65c1.03-2.1,3.48-3.33,3.48-3.33s2.4-1.75,5.41-4.03s4.77,0,4.77,0s-6.55,3.15-7.84,7.71
                        c0,0,2.87-1.58,3.07-0.35c0.2,1.23-1.96,1.75-3.07,2.8c-1.11,1.05-3.56,3.85-4.44,4.03c-0.88,0.18-1.38-2.1-1.38-2.1
                        s-1.06,4.55-0.28,4.9c0.78,0.35,5.69-5.43,8.31-5.78c2.63-0.35-0.57,1.05,0.85,1.4c1.42,0.35,3.06-0.7,5.75,1.05
                        c2.68,1.75,2.97,3.15,7.12,2.1c4.15-1.05,6.42-5.95,6.77-4.2c0.35,1.75-3.37,9.11-11.76,5.95
                        C522.24,232.64,519.44,232.81,517.16,235.09z M533.98,244.69c-1.93-1.19-4.87-2.42-6.46-4.69s-0.39-3.3-0.39-3.3
                        s0.51,3.47,3.51,4.7l-2.13-3.76c0,0,2.86,2.88,4.26,3.23c1.4,0.35-1.05,1.23,0.88,1.75s4.38,1.33,5.95,2.07
                        c1.58,0.74-3.97,0-3.97,0s3.14,2.88,4.25,5.4c1.12,2.52,1.82,7.79,1.82,7.79S535.9,245.88,533.98,244.69z M551.62,268.13
                        c-0.39-1.46-4.69-0.29-3.99,0s1.93,1.28,3.33,2.76c1.4,1.48,0.35,1.15-1.05,1.33c-1.4,0.18-1.58-1.23-2.98-2.53
                        c-1.4-1.3-0.35,2.18-1.18,4.13c-0.82,1.95-0.93-4.4-1.74-7.37c-0.82-2.98-0.06,6.65-1.11,6.13c-1.05-0.53-0.35-3.5-0.88-2.88
                        c-0.53,0.62-1.05,5.33-1.05,5.33s-1.05-1.93-0.93-3.79c0.13-1.86,2.15-2.87,2.35-4.09c0.2-1.23-0.02-1.58,1.2-2.1
                        c1.23-0.53-0.7-4.03-0.7-4.03c3.15-0.7,4.03,3.5,4.96,4.03c0.93,0.53,2.92,0.18,4.5,1.4c1.58,1.23,3.33,3.25,3.33,3.25
                        S552.01,269.59,551.62,268.13z"/>
                </g>
                <path id="XMLID_267_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M510.66,278.61
                    c0,0,0.04,0.02,0.1,0.05c0.06,0.03,0.15,0.08,0.24,0.14c0.09,0.06,0.2,0.13,0.3,0.2c0.05,0.04,0.1,0.07,0.16,0.11
                    c0.05,0.04,0.1,0.08,0.16,0.11c0.05,0.04,0.1,0.07,0.15,0.11c0.05,0.03,0.1,0.07,0.15,0.1c0.05,0.03,0.1,0.06,0.14,0.09
                    c0.05,0.02,0.09,0.05,0.14,0.08c0.04,0.02,0.09,0.04,0.13,0.06c0.04,0.02,0.08,0.03,0.11,0.04c0.06,0.02,0.11,0.04,0.11,0.04
                    s-0.03,0.03-0.09,0.07c-0.03,0.02-0.07,0.05-0.12,0.07c-0.05,0.02-0.11,0.03-0.17,0.05c-0.06,0.01-0.13,0.01-0.21,0.01
                    c-0.07,0-0.15-0.02-0.23-0.04c-0.04-0.01-0.08-0.02-0.11-0.04c-0.04-0.01-0.08-0.03-0.11-0.05c-0.07-0.04-0.15-0.08-0.21-0.13
                    c-0.07-0.05-0.13-0.1-0.18-0.16c-0.06-0.05-0.11-0.11-0.15-0.17c-0.05-0.06-0.08-0.12-0.12-0.18c-0.03-0.06-0.06-0.12-0.08-0.17
                    c-0.04-0.11-0.07-0.21-0.08-0.28c-0.01-0.03-0.01-0.07-0.01-0.09C510.66,278.62,510.66,278.61,510.66,278.61z"/>
                <path id="XMLID_266_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M513.58,300.29
                    c0,0,0.02,0.01,0.05,0.02c0.03,0.01,0.08,0.03,0.14,0.05c0.06,0.02,0.13,0.04,0.21,0.07c0.08,0.03,0.17,0.06,0.27,0.1
                    c0.19,0.07,0.42,0.15,0.66,0.24c0.12,0.04,0.24,0.09,0.37,0.13c0.13,0.04,0.25,0.09,0.38,0.13c0.13,0.04,0.25,0.08,0.38,0.12
                    c0.06,0.02,0.13,0.04,0.19,0.06c0.06,0.02,0.12,0.03,0.18,0.05c0.06,0.02,0.12,0.03,0.18,0.05c0.06,0.01,0.12,0.03,0.17,0.04
                    c0.11,0.03,0.22,0.06,0.32,0.08c0.1,0.02,0.19,0.04,0.28,0.06c0.08,0.01,0.16,0.03,0.22,0.04c0.12,0.03,0.2,0.05,0.2,0.05
                    s-0.07,0.03-0.19,0.07c-0.06,0.02-0.14,0.04-0.22,0.07c-0.09,0.02-0.19,0.03-0.3,0.05c-0.11,0.02-0.23,0.02-0.35,0.02
                    c-0.06,0-0.13,0-0.19,0c-0.07,0-0.13-0.01-0.2-0.02c-0.07-0.01-0.14-0.02-0.21-0.02c-0.07-0.01-0.14-0.03-0.21-0.04
                    c-0.14-0.03-0.28-0.07-0.42-0.11c-0.14-0.04-0.27-0.1-0.41-0.16c-0.07-0.03-0.13-0.06-0.19-0.09c-0.06-0.03-0.13-0.06-0.18-0.1
                    c-0.12-0.07-0.23-0.13-0.33-0.21c-0.1-0.07-0.2-0.14-0.28-0.21c-0.17-0.14-0.29-0.27-0.38-0.37
                    C513.62,300.35,513.58,300.29,513.58,300.29z"/>
                <path id="XMLID_265_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M500,300.29
                    c0,0-0.12,0.08-0.34,0.21c-0.11,0.07-0.24,0.15-0.39,0.24c-0.15,0.09-0.32,0.2-0.51,0.31c-0.75,0.45-1.77,1.03-2.81,1.55
                    c-0.26,0.13-0.52,0.26-0.78,0.38c-0.26,0.12-0.52,0.24-0.77,0.35c-0.25,0.11-0.5,0.21-0.73,0.3c-0.12,0.05-0.23,0.09-0.34,0.13
                    c-0.11,0.04-0.22,0.08-0.33,0.11c-0.21,0.08-0.4,0.13-0.58,0.18c-0.17,0.05-0.33,0.08-0.45,0.11c-0.13,0.03-0.23,0.04-0.3,0.05
                    c-0.07,0.01-0.11,0.01-0.11,0.01s0.03-0.02,0.1-0.05c0.06-0.03,0.15-0.07,0.27-0.12c0.11-0.06,0.25-0.13,0.41-0.21
                    c0.16-0.08,0.33-0.18,0.52-0.28c0.38-0.21,0.83-0.45,1.31-0.71c0.24-0.13,0.49-0.26,0.74-0.39c0.25-0.13,0.51-0.26,0.77-0.4
                    c0.26-0.13,0.52-0.26,0.78-0.38c0.26-0.13,0.51-0.25,0.76-0.36c0.25-0.11,0.49-0.22,0.72-0.32c0.23-0.1,0.45-0.19,0.66-0.27
                    c0.41-0.16,0.76-0.28,1.01-0.36C499.86,300.33,500,300.29,500,300.29z"/>
                <path id="XMLID_264_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M509.35,324.34
                    c0,0,0.02,0.01,0.05,0.02c0.03,0.01,0.07,0.03,0.13,0.05c0.06,0.02,0.12,0.05,0.2,0.08c0.08,0.03,0.16,0.06,0.25,0.09
                    c0.37,0.13,0.86,0.28,1.34,0.33c0.12,0.02,0.24,0.02,0.35,0.02c0.06,0,0.11,0,0.17-0.01c0.06-0.01,0.11-0.01,0.16-0.02
                    c0.11-0.02,0.21-0.04,0.3-0.07c0.1-0.03,0.18-0.07,0.27-0.11c0.08-0.04,0.15-0.09,0.22-0.13c0.03-0.02,0.06-0.05,0.09-0.07
                    c0.03-0.02,0.05-0.04,0.07-0.06c0.05-0.04,0.08-0.07,0.1-0.09c0.02-0.02,0.04-0.03,0.04-0.03s0,0.02-0.01,0.05
                    c-0.01,0.03-0.01,0.08-0.04,0.14c-0.01,0.03-0.02,0.06-0.03,0.1c-0.01,0.04-0.03,0.07-0.05,0.11c-0.04,0.08-0.1,0.16-0.17,0.25
                    c-0.08,0.08-0.17,0.17-0.28,0.24c-0.11,0.07-0.24,0.14-0.38,0.18c-0.27,0.09-0.58,0.11-0.88,0.08c-0.15-0.02-0.29-0.04-0.43-0.09
                    c-0.14-0.04-0.27-0.09-0.39-0.15c-0.12-0.06-0.24-0.12-0.34-0.19c-0.1-0.07-0.2-0.14-0.28-0.2c-0.16-0.14-0.28-0.27-0.35-0.37
                    c-0.04-0.05-0.06-0.09-0.08-0.12C509.36,324.35,509.35,324.34,509.35,324.34z"/>
                <path id="XMLID_263_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M469.84,322.47
                    c0,0,0.13,0.17,0.35,0.49c0.1,0.16,0.23,0.35,0.37,0.58c0.14,0.23,0.29,0.48,0.45,0.77c0.16,0.29,0.33,0.6,0.49,0.93
                    c0.17,0.33,0.34,0.69,0.5,1.06c0.16,0.37,0.33,0.76,0.48,1.17c0.15,0.4,0.29,0.82,0.43,1.23c0.06,0.21,0.12,0.42,0.19,0.63
                    c0.06,0.21,0.11,0.42,0.16,0.63c0.1,0.42,0.19,0.84,0.25,1.24c0.07,0.41,0.11,0.8,0.15,1.18c0.04,0.37,0.05,0.73,0.05,1.06
                    c0,0.16,0,0.32-0.01,0.47c-0.01,0.15-0.02,0.29-0.03,0.42c-0.02,0.27-0.06,0.5-0.08,0.68c-0.03,0.19-0.06,0.33-0.08,0.43
                    c-0.02,0.1-0.03,0.15-0.03,0.15s0-0.22,0-0.6c0-0.09-0.01-0.2-0.01-0.31c0-0.11,0-0.24-0.02-0.37c-0.01-0.13-0.02-0.27-0.03-0.42
                    c-0.01-0.15-0.03-0.3-0.05-0.46c-0.01-0.16-0.04-0.32-0.06-0.5c-0.02-0.17-0.05-0.35-0.08-0.53c-0.03-0.18-0.07-0.37-0.1-0.56
                    c-0.04-0.19-0.08-0.38-0.12-0.58c-0.08-0.39-0.19-0.79-0.29-1.2c-0.05-0.2-0.12-0.41-0.17-0.61c-0.06-0.2-0.12-0.41-0.19-0.61
                    c-0.13-0.41-0.26-0.81-0.4-1.21c-0.14-0.4-0.28-0.79-0.42-1.16c-0.14-0.38-0.28-0.74-0.42-1.08c-0.14-0.34-0.27-0.67-0.4-0.96
                    c-0.25-0.6-0.47-1.09-0.62-1.44C469.93,322.67,469.84,322.47,469.84,322.47z"/>
                <path id="XMLID_262_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M525.43,360.65
                    c0,0,0.16,0.17,0.42,0.47c0.07,0.07,0.15,0.14,0.24,0.23c0.09,0.08,0.17,0.17,0.28,0.26c0.11,0.09,0.22,0.18,0.34,0.27
                    c0.13,0.09,0.26,0.18,0.39,0.27c0.14,0.09,0.3,0.17,0.45,0.26c0.16,0.07,0.33,0.16,0.5,0.24c0.09,0.03,0.18,0.07,0.27,0.1
                    c0.05,0.02,0.09,0.04,0.14,0.05c0.05,0.01,0.09,0.03,0.14,0.04c0.09,0.03,0.19,0.06,0.29,0.09c0.1,0.03,0.2,0.05,0.3,0.07
                    c0.2,0.05,0.41,0.08,0.62,0.13c0.11,0.01,0.21,0.03,0.32,0.04c0.11,0.02,0.21,0.03,0.32,0.04c0.43,0.04,0.88,0.06,1.33,0.05
                    c0.11,0,0.22,0,0.34,0l0.17,0l0.08,0l0.1,0c0.06,0,0.11,0,0.18,0c0.07,0,0.13,0.01,0.2,0.01l0.18,0.03
                    c0.06,0.01,0.12,0.02,0.18,0.04c0.48,0.11,0.92,0.34,1.28,0.62c0.37,0.28,0.66,0.61,0.9,0.94c0.24,0.33,0.41,0.68,0.54,1.01
                    c0.13,0.33,0.22,0.64,0.27,0.92c0.06,0.28,0.07,0.53,0.08,0.73c0.01,0.2,0,0.36-0.01,0.47c-0.01,0.11-0.01,0.16-0.01,0.16
                    s-0.01-0.06-0.02-0.16c-0.01-0.11-0.04-0.26-0.08-0.46c-0.04-0.19-0.1-0.43-0.18-0.69c-0.09-0.26-0.2-0.54-0.36-0.84
                    c-0.08-0.15-0.16-0.3-0.26-0.44c-0.1-0.15-0.2-0.3-0.32-0.44c-0.24-0.29-0.52-0.56-0.85-0.79c-0.33-0.23-0.7-0.4-1.1-0.48
                    l-0.15-0.03l-0.15-0.02l-0.14-0.01c-0.05,0-0.11,0-0.16,0l-0.08,0l-0.09,0l-0.17,0.01c-0.12,0.01-0.23,0.01-0.35,0.01
                    c-0.47,0-0.93-0.01-1.39-0.07c-0.23-0.02-0.46-0.07-0.68-0.1c-0.22-0.05-0.44-0.09-0.65-0.16c-0.11-0.03-0.21-0.06-0.31-0.1
                    c-0.1-0.04-0.2-0.08-0.3-0.12c-0.05-0.02-0.1-0.04-0.15-0.06c-0.05-0.02-0.1-0.05-0.14-0.07c-0.09-0.04-0.19-0.09-0.28-0.13
                    c-0.18-0.09-0.35-0.2-0.51-0.29c-0.16-0.11-0.31-0.22-0.45-0.32c-0.13-0.12-0.26-0.23-0.38-0.33c-0.11-0.11-0.22-0.23-0.31-0.33
                    c-0.1-0.1-0.18-0.21-0.25-0.31c-0.07-0.1-0.14-0.19-0.2-0.27c-0.11-0.17-0.19-0.31-0.24-0.4
                    C525.45,360.7,525.43,360.65,525.43,360.65z"/>
                <path id="XMLID_261_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M507.97,362.23
                    c0,0,0.18,0.08,0.48,0.24c0.16,0.07,0.34,0.16,0.55,0.25c0.11,0.05,0.22,0.09,0.34,0.13c0.06,0.02,0.12,0.04,0.19,0.07
                    c0.06,0.02,0.13,0.04,0.2,0.06c0.27,0.08,0.57,0.14,0.88,0.17c0.32,0.03,0.66,0.04,1.02,0.04c0.36,0,0.74,0,1.12,0.01
                    c0.19,0.01,0.39,0.02,0.58,0.04l0.15,0.01l0.07,0.01l0.04,0l0.03,0l0.02,0l0.14,0.02l0.07,0.01l0.08,0.02
                    c0.42,0.08,0.8,0.22,1.16,0.39c0.36,0.17,0.69,0.37,1,0.58c0.31,0.21,0.59,0.43,0.85,0.65c0.26,0.22,0.49,0.44,0.7,0.64
                    c0.42,0.41,0.73,0.79,0.94,1.05c0.21,0.27,0.32,0.43,0.32,0.43s-0.15-0.13-0.4-0.35c-0.26-0.22-0.63-0.53-1.08-0.88
                    c-0.23-0.18-0.48-0.36-0.74-0.55c-0.27-0.19-0.56-0.37-0.86-0.56c-0.3-0.18-0.62-0.35-0.96-0.5c-0.17-0.07-0.34-0.14-0.51-0.19
                    c-0.09-0.03-0.17-0.06-0.26-0.08c-0.09-0.03-0.17-0.04-0.26-0.06l-0.06-0.01l-0.07-0.01l-0.14-0.02l-0.02,0l0,0
                    c-0.01,0,0.01,0,0.01,0l-0.01,0l-0.04,0l-0.07-0.01l-0.14-0.01c-0.19-0.02-0.38-0.03-0.56-0.05c-0.38-0.02-0.75-0.04-1.11-0.06
                    c-0.36-0.02-0.73-0.06-1.07-0.12c-0.17-0.03-0.33-0.07-0.49-0.12c-0.15-0.06-0.3-0.1-0.44-0.17c-0.07-0.03-0.14-0.06-0.2-0.09
                    c-0.06-0.03-0.13-0.07-0.19-0.1c-0.12-0.06-0.23-0.13-0.33-0.19c-0.21-0.12-0.37-0.25-0.51-0.35c-0.14-0.1-0.24-0.19-0.31-0.25
                    C508.01,362.26,507.97,362.23,507.97,362.23z"/>
                <path id="XMLID_260_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M513.79,270.87
                    c0,0,0.03,0.1,0.08,0.27c0.03,0.09,0.05,0.19,0.07,0.31c0.01,0.06,0.02,0.13,0.04,0.2c0.01,0.07,0.02,0.14,0.03,0.22
                    c0.02,0.15,0.03,0.32,0.03,0.5c0.01,0.18,0,0.37-0.01,0.56c-0.02,0.19-0.03,0.39-0.08,0.59c-0.03,0.2-0.09,0.4-0.14,0.6
                    c-0.07,0.2-0.12,0.4-0.2,0.58c-0.07,0.19-0.17,0.37-0.25,0.55c-0.09,0.17-0.18,0.34-0.28,0.48c-0.1,0.15-0.19,0.29-0.29,0.41
                    c-0.05,0.06-0.09,0.12-0.14,0.17c-0.05,0.05-0.09,0.1-0.14,0.15c-0.08,0.09-0.16,0.17-0.23,0.23c-0.13,0.12-0.21,0.19-0.21,0.19
                    s0.05-0.09,0.15-0.23c0.05-0.07,0.11-0.16,0.17-0.27c0.07-0.1,0.14-0.22,0.21-0.35c0.08-0.13,0.15-0.27,0.23-0.42
                    c0.08-0.15,0.15-0.32,0.23-0.48c0.07-0.17,0.14-0.35,0.21-0.53c0.07-0.18,0.12-0.37,0.18-0.56c0.05-0.19,0.11-0.38,0.14-0.57
                    c0.04-0.19,0.07-0.38,0.11-0.56c0.02-0.18,0.05-0.36,0.06-0.53c0.01-0.17,0.03-0.33,0.03-0.48c0.01-0.15,0.01-0.29,0.01-0.41
                    c0-0.12,0-0.23,0-0.32C513.79,270.97,513.79,270.87,513.79,270.87z"/>
                <path id="XMLID_259_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M518.43,271.23
                    c0,0,0.01,0.11,0.03,0.29c0.02,0.19,0.04,0.45,0.07,0.77c0.02,0.16,0.03,0.33,0.05,0.51c0.02,0.18,0.05,0.37,0.07,0.57
                    c0.05,0.39,0.11,0.81,0.18,1.23c0.04,0.21,0.07,0.42,0.11,0.62c0.04,0.2,0.08,0.4,0.13,0.6c0.09,0.39,0.18,0.75,0.26,1.05
                    c0.08,0.31,0.15,0.57,0.21,0.75c0.05,0.18,0.08,0.29,0.08,0.29s-0.06-0.09-0.15-0.26c-0.05-0.08-0.1-0.18-0.16-0.3
                    c-0.06-0.12-0.12-0.25-0.18-0.4c-0.07-0.15-0.13-0.31-0.19-0.49c-0.06-0.17-0.13-0.36-0.18-0.55c-0.05-0.2-0.12-0.4-0.16-0.6
                    c-0.05-0.21-0.09-0.42-0.13-0.63c-0.03-0.21-0.07-0.43-0.09-0.64c-0.03-0.21-0.04-0.42-0.05-0.62c-0.02-0.4-0.02-0.78,0-1.11
                    c0.01-0.16,0.02-0.31,0.03-0.44c0.01-0.13,0.03-0.24,0.04-0.34C518.41,271.34,518.43,271.23,518.43,271.23z"/>
            </g>
            <rect x="2.5" y="386.89" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" width="191.98" height="191.98"/>
            <path id="XMLID_531_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M111.81,573.48
                c-0.37-2-30.97-38.89-32.5-52.53c-1.53-13.64-2.95-24.71-3.52-29.16c-0.57-4.45-0.99-5.67-6.91-8.91
                c-5.92-3.24-9.14-17.73-7.35-22.76c1.8-5.02-0.97-14.68,1.8-18.26c2.77-3.58,24.07-28.39,26.97-34.1
                c2.9-5.7,11.26-10.84,14.92-10.84c3.66,0,15.23,4.04,22.42,4.59c7.19,0.55,19.71-0.59,22.58,5.97c2.87,6.56,7.14,5.96,10,11.96
                c2.85,6.01,2.24,9.43,3.69,15.42c1.45,5.99-0.84,12.94,1.45,16.74s8.94,6.5,7.46,11.28c-1.48,4.78-7.44,7.08-11.02,7.93
                c-3.58,0.84-4.45,1.74-5.7,7.93c-1.26,6.19-3.51,11.11-6.52,14.29c-3.01,3.18-4.49,15.67-5.04,18.2c-0.55,2.53-9.18,4.6-7.44,6.76
                c1.74,2.16,5.48,1.07,6.46,4.89c0.98,3.82,9.16,12.16,12.54,17.98c3.38,5.83,14.63,15.78,16.93,17.63
                c2.31,1.86,10.63,20.37,10.63,20.37h-69.68L111.81,573.48z"/>
            <g id="XMLID_473_">
                <path id="XMLID_527_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M194.49,578.88
                    c0,0-13.13-22.55-15.94-25.53s-4.2-0.35-7.18-1.4c-2.98-1.05-7.71-10.33-12.08-15.24s-13.45-9.87-12.16-10.34
                    c1.28-0.47,8.45-5.02,8.45-5.02s-0.63-0.58-2.5-2.22c-1.87-1.63-10.16-2.45-10.97-2.57c-0.82-0.12,0.23-1.63,1.87-2.8
                    c1.63-1.17,4.44-10.39,5.72-14.94c1.28-4.55,4.79-10.04,5.14-13.89c0.35-3.85,0-9.57,0-11.68s0.35-3.15,0.7-4.09
                    c0.35-0.93-1.28-1.52-3.5-2.69c-2.22-1.17-9.46-0.58-10.27,0c-0.82,0.58-2.8,1.52-4.9,1.52c-2.1,0-2.13,0.81-3.04,3.15
                    c-0.35,0.9-1.4,0.16-2.02,0.47c-0.62,0.31,0.7,1.09,1.63,1.87c0.93,0.78,0,8.87,0.16,10.35c0.16,1.48,1.01,1.01,1.63,2.02
                    c0.62,1.01,0.23,4.75-0.62,5.37c-0.86,0.62-2.1,1.63-2.72,2.26c-0.62,0.62-2.1,1.01-2.57,1.71c-0.47,0.7,4.75,2.02,5.76,2.49
                    c1.01,0.47,4.05-0.39,4.9-1.71c0.86-1.32,0.08-2.88-0.62-3.97c-0.7-1.09-1.63-3.97-1.71-5.76c-0.08-1.79-1.32-2.26-1.63-3.42
                    c-0.31-1.17,0.54-1.25,1.63-0.95c1.09,0.29-0.16-2.86,0-3.87c0.16-1.01-0.16-1.17,1.32-0.86c1.48,0.31,2.26-0.16,1.71-0.16
                    c-0.54,0-3.19-1.71-2.41-2.65c0.78-0.93,2.1-1.01,2.1-1.01s0.08,2.88,3.04,2.88s2.65-2.1,2.96-2.26c0.31-0.16,1.17,0.62,0.86,1.63
                    c-0.31,1.01-3.19,1.87-3.19,1.87s1.25,0.31,2.18-0.16c0.93-0.47,1.4-0.23,0.62,0.7c-0.78,0.93-0.47,1.17-0.31,1.87
                    c0.16,0.7-0.86,1.95-0.86,2.72c0,0.78,2.18-0.78,2.72-1.09c0.54-0.31,1.17-2.26,1.87-3.66c0.7-1.4,1.17-0.85,1.35,2.48
                    c0.18,3.33,0,3.68-2.8,7.88c-2.8,4.2-4.2,9.46-4.9,11.73c-0.7,2.28-1.4,7.71-1.49,11.21c-0.09,3.5-4.81,3.33-7.44,3.5
                    c-2.63,0.18-2.63,2.1-2.1,4.2c0.53,2.1,0,1.75-2.28,2.63c-2.28,0.88-2.8-2.28-3.33-3.15c-0.53-0.88-1.26,1-2.8,2.63
                    c-0.76,0.81-3.06,2.33-6.04,1.39c-2.98-0.94-6.39-3.84-8.67-6.12c-2.28-2.28-5.78-1.05-7.53-1.93
                    c-1.75-0.88-12.08-12.26-11.91-11.38c0.18,0.88,10.68,12.08,11.56,13.48s7.88,4.55,9.28,4.55c1.4,0,4.73,2.63,7,3.68
                    s4.38,2.28,5.78,4.2c1.4,1.93-3.15,2.98-3.15,2.98s5.25,0.35,7,0.18s3.5-3.33,4.38-4.03c0.88-0.7,6.3-3.5,7.71-4.38
                    c1.4-0.88,2.8,1.23,1.05,1.93c-1.75,0.7-1.93,2.98,0.18,4.03c2.1,1.05,6.13,3.5,7.71,7c1.58,3.5-2.1,2.28,0,5.43
                    s8.06,2.28,10.68,4.73c2.63,2.45,4.55,9.46,6.48,13.48c1.93,4.03,6.13,4.2,7.71,8.93c1.58,4.73,0.7,9.77,0.7,9.77H194.49z
                    M141.68,473.72c-0.31,0-0.56-0.25-0.56-0.56c0-0.31,0.25-0.56,0.56-0.56s0.56,0.25,0.56,0.56
                    C142.24,473.47,141.99,473.72,141.68,473.72z M150.78,471.7c-0.31,0.7-0.93,1.71-1.71,1.4c-0.78-0.31-3.82-1.61-5.92-1.79
                    c-1.56-0.13-2-1.09-0.77-1.25c1.23-0.16,4.19-0.23,4.74-0.54c0.54-0.31,2.02-0.54,3.27,0c1.25,0.54,1.25,0.54,1.25,0.54
                    S151.09,471,150.78,471.7z"/>
                <path id="XMLID_524_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M124.94,466.72
                    c-3.15,0-3.04-0.82-7.01-2.8c-3.97-1.98-4.79-0.58-6.3,0c-1.52,0.58-1.87,0.35-4.79,0.93c-2.92,0.58-6.3,2.45-5.95,3.04
                    c0.35,0.58,2.08,1.79,3.04,0.82c2.49-2.53,6.07-2.76,7.55-2.45c1.48,0.31-2.72,1.71-2.1,1.87c0.62,0.16,2.96-1.09,4.11-1.4
                    c1.15-0.31,5.39,2.02,2.2,1.87c-3.19-0.16-5.68,0.62-6.85,1.01c-1.17,0.39-1.87,0.62-3.35,0.08c-1.48-0.54-1.32,0.47-1.01,0.94
                    c0.31,0.47,1.87,0.39,1.01,1.13c-0.86,0.74-1.48-0.27-2.33,0.35c-0.86,0.62,1.79,0.62,1.71,1.07s1.09,0.49,2.02,0
                    c0.93-0.49,2.33-0.91,2.33-0.91c1.87,2.65,3.58,2.65,3.58,2.65c-1.25-0.47-2.26-2.33-2.18-3.15c0.08-0.82,1.73-0.56,1.73-0.56
                    s0.53,2.94,3.41,2.94c2.88,0,3.27-2.94,3.27-2.94s1.48,0.99,1.17,1.98s-3.5,1.58-3.5,1.58s0.78,0.39,0.86,1.17
                    c0.08,0.78-3.46,0.86-3.5,1.56c-0.04,0.7,4.36,1.28,5.41,1.28c1.05,0,3.39-2.69,3.62-3.74s0-2.45,0.82-2.41
                    c0.82,0.04,2.1-0.51,3.04-1.42c0.93-0.91,2.69-3.99,2.69-5.37C129.61,464.44,128.09,466.72,124.94,466.72z M114.04,471.74
                    c-0.31,0-0.56-0.25-0.56-0.56s0.25-0.56,0.56-0.56s0.56,0.25,0.56,0.56S114.36,471.74,114.04,471.74z"/>
                <path id="XMLID_523_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M119.45,490.77
                    c1.17,0.7,1.75,0.93,2.33,0.7c0.58-0.23,0,0.12,0.58-0.23c0.58-0.35,2.45-0.64,3.74,0.32c1.28,0.96,3.15,2.01,2.22,2.36
                    c-0.93,0.35-1.87,0.23-2.92,0c-1.05-0.23-2.33,0.58-4.32-0.7S119.45,490.77,119.45,490.77z"/>
                <path id="XMLID_522_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M121.05,485.63
                    c0,0-4.4,3.5-6.44,4.75c-2.04,1.25,1.32,0.47,3.22-0.31C119.73,489.29,121.05,485.63,121.05,485.63z"/>
                <path id="XMLID_521_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M139.73,494.74
                    c0,0,1.32,0.99,2.51,3.74c1.2,2.75,1.3,6.54,1.3,6.54s0.12-1.01-1.3-1.48c-1.42-0.47-0.8-0.93-0.56-2.18
                    C141.91,500.11,140.2,494.74,139.73,494.74z"/>
                <path id="XMLID_520_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M128.13,496.53
                    c-0.5,1.16-0.08,1.48,0,2.26c0.08,0.78,0.12,1.79,0.45,1.32c0.33-0.47,0.18-1.25,0.49-2.1
                    C129.38,497.15,128.53,495.61,128.13,496.53z"/>
                <path id="XMLID_519_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M112.35,504.7
                    c-1.23-0.16-1.62-1.32,0-2.49c1.62-1.17,2.14-2.88,2.89-2.34c0.75,0.54,1.79,2.46,4.19,2.36s4.34-1.82,6.83-1.58
                    c2.49,0.23,2.65,1.87,4.44,1.01c1.79-0.86,2.65,0.47,4.13,0.93c1.48,0.47,4.59-0.54,4.36,0.31s-1.32,0.78-1.63,1.25
                    c-0.31,0.47,1.5,1.79,1.65,3.81s0.77,2.18-0.4,2.18c-1.17,0-1.87-0.54-2.33,0.39c-0.47,0.93-1.71,1.87-1.71,1.87
                    s-0.62-3.19-1.71-3.04c-1.09,0.16-3.11-0.16-3.42-0.47c-0.31-0.31-2.84-0.08-4.14,0.23c-1.31,0.31-6.29-0.93-4.89-1.48
                    c1.4-0.54,2.02-0.96,2.49-1.57c0.47-0.61,0.54-2.56,1.4-1.47c0.86,1.09,3.04,0.54,5.14,0.78c2.1,0.23,3.81-0.39,4.05-1.01
                    c0.23-0.62-0.16-1.32-0.16-1.32s-18.98-0.7-19.53,0S113.54,504.86,112.35,504.7z"/>
                <path id="XMLID_518_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M119.45,478.74
                    c0,0-0.03,0.03-0.08,0.09c-0.03,0.03-0.06,0.07-0.11,0.11c-0.04,0.04-0.09,0.09-0.15,0.14c-0.06,0.05-0.12,0.1-0.19,0.16
                    c-0.07,0.06-0.15,0.11-0.24,0.17c-0.09,0.06-0.18,0.12-0.28,0.18c-0.1,0.06-0.21,0.13-0.32,0.19c-0.12,0.06-0.24,0.12-0.36,0.19
                    c-0.13,0.06-0.26,0.11-0.4,0.18c-0.07,0.03-0.14,0.06-0.21,0.09c-0.07,0.03-0.15,0.05-0.22,0.08c-0.15,0.05-0.3,0.1-0.46,0.16
                    c-0.16,0.04-0.32,0.09-0.48,0.13c-0.08,0.02-0.16,0.04-0.25,0.07c-0.08,0.02-0.17,0.03-0.25,0.05c-0.34,0.06-0.68,0.14-1.02,0.17
                    c-0.17,0.02-0.35,0.04-0.52,0.06c-0.17,0.02-0.34,0.02-0.51,0.03c-0.34,0.01-0.68,0.03-1,0.01c-0.32-0.01-0.64-0.02-0.93-0.05
                    c-0.3-0.03-0.57-0.07-0.83-0.1c-0.13-0.02-0.25-0.03-0.37-0.05c-0.12-0.02-0.23-0.05-0.33-0.07c-0.21-0.04-0.38-0.08-0.53-0.11
                    c-0.29-0.07-0.46-0.11-0.46-0.11s0.17-0.01,0.47-0.01c0.15,0,0.33,0,0.54,0.01c0.1,0,0.21,0,0.33,0.01c0.12,0,0.24,0,0.37,0
                    c0.25,0,0.53-0.01,0.82,0c0.29,0,0.6-0.02,0.91-0.03c0.16-0.01,0.32,0,0.48-0.02c0.16-0.01,0.33-0.03,0.49-0.04
                    c0.16-0.01,0.33-0.02,0.5-0.04c0.17-0.02,0.33-0.04,0.5-0.06c0.33-0.03,0.66-0.09,0.99-0.14c0.16-0.02,0.32-0.06,0.48-0.09
                    c0.16-0.03,0.31-0.07,0.47-0.1c0.15-0.04,0.3-0.08,0.45-0.12c0.07-0.02,0.15-0.04,0.22-0.06c0.07-0.02,0.14-0.04,0.21-0.06
                    c0.14-0.04,0.27-0.08,0.4-0.12c0.13-0.05,0.25-0.09,0.37-0.13c0.12-0.04,0.23-0.09,0.34-0.13c0.11-0.04,0.21-0.08,0.3-0.13
                    c0.09-0.04,0.18-0.09,0.26-0.12c0.08-0.04,0.15-0.08,0.22-0.11c0.13-0.07,0.23-0.13,0.3-0.17
                    C119.42,478.77,119.45,478.74,119.45,478.74z"/>
                <path id="XMLID_517_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M137.08,480.73
                    c0,0,0.08,0.03,0.22,0.11c0.07,0.04,0.15,0.08,0.24,0.14c0.09,0.06,0.19,0.12,0.3,0.2c0.21,0.16,0.45,0.35,0.69,0.58
                    c0.06,0.06,0.12,0.12,0.18,0.18c0.06,0.06,0.12,0.12,0.18,0.19c0.12,0.13,0.23,0.26,0.34,0.4l0.16,0.21
                    c0.05,0.07,0.1,0.14,0.15,0.21c0.1,0.14,0.19,0.29,0.27,0.43c0.09,0.14,0.16,0.28,0.23,0.42c0.03,0.07,0.07,0.13,0.1,0.2
                    c0.03,0.07,0.05,0.13,0.08,0.19c0.05,0.12,0.09,0.24,0.13,0.34c0.03,0.1,0.05,0.2,0.07,0.27c0.04,0.15,0.05,0.24,0.05,0.24
                    s-0.06-0.07-0.16-0.19c-0.09-0.12-0.23-0.29-0.39-0.49c-0.08-0.1-0.16-0.21-0.25-0.33c-0.09-0.12-0.18-0.24-0.28-0.36
                    c-0.05-0.06-0.1-0.13-0.15-0.19c-0.05-0.06-0.1-0.13-0.15-0.19c-0.1-0.13-0.21-0.27-0.31-0.4c-0.1-0.13-0.21-0.26-0.31-0.4
                    c-0.1-0.13-0.21-0.26-0.31-0.38c-0.2-0.25-0.38-0.48-0.54-0.69c-0.16-0.21-0.29-0.38-0.38-0.5c-0.05-0.06-0.08-0.11-0.11-0.14
                    C137.1,480.75,137.08,480.73,137.08,480.73z"/>
                <path id="XMLID_516_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M142.39,488.33
                    c0,0,0.02,0.03,0.06,0.08c0.04,0.05,0.1,0.14,0.16,0.25c0.06,0.11,0.14,0.25,0.22,0.41c0.08,0.16,0.16,0.34,0.25,0.54
                    c0.17,0.4,0.35,0.87,0.54,1.37c0.09,0.25,0.19,0.51,0.29,0.77c0.1,0.26,0.2,0.53,0.31,0.79c0.1,0.26,0.21,0.52,0.33,0.77
                    c0.11,0.25,0.23,0.5,0.35,0.73c0.12,0.23,0.24,0.45,0.37,0.65c0.13,0.2,0.26,0.39,0.38,0.56c0.07,0.08,0.13,0.16,0.19,0.23
                    c0.06,0.07,0.13,0.14,0.18,0.2c0.12,0.12,0.23,0.23,0.33,0.3c0.09,0.08,0.17,0.14,0.23,0.18c0.05,0.04,0.08,0.06,0.08,0.06
                    s-0.03-0.01-0.1-0.03c-0.06-0.02-0.16-0.05-0.28-0.11c-0.06-0.03-0.12-0.06-0.19-0.1c-0.07-0.04-0.14-0.08-0.22-0.13
                    c-0.07-0.05-0.15-0.11-0.23-0.17c-0.08-0.06-0.16-0.13-0.24-0.21c-0.16-0.15-0.33-0.33-0.49-0.53c-0.16-0.2-0.32-0.42-0.46-0.65
                    c-0.14-0.24-0.29-0.49-0.41-0.75c-0.13-0.26-0.25-0.53-0.36-0.8c-0.22-0.54-0.4-1.1-0.55-1.62c-0.08-0.26-0.14-0.51-0.2-0.75
                    c-0.06-0.24-0.11-0.47-0.17-0.68c-0.05-0.21-0.1-0.4-0.14-0.57c-0.04-0.17-0.08-0.32-0.12-0.44c-0.04-0.12-0.06-0.22-0.09-0.28
                    C142.4,488.37,142.39,488.33,142.39,488.33z"/>
                <path id="XMLID_515_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M138.78,499.41
                    c0,0,0.04,0.05,0.1,0.13c0.03,0.04,0.07,0.09,0.1,0.15c0.04,0.06,0.08,0.13,0.12,0.2c0.09,0.15,0.18,0.33,0.26,0.53
                    c0.02,0.05,0.04,0.1,0.06,0.16c0.02,0.05,0.04,0.11,0.05,0.16c0.03,0.11,0.06,0.23,0.08,0.35c0.02,0.12,0.03,0.24,0.03,0.36
                    c0,0.06,0,0.12,0,0.18c0,0.06,0,0.11-0.01,0.17c-0.01,0.06-0.01,0.11-0.02,0.16c-0.01,0.05-0.02,0.1-0.03,0.15
                    c-0.02,0.1-0.05,0.19-0.08,0.27c-0.03,0.08-0.06,0.16-0.09,0.22c-0.03,0.07-0.06,0.12-0.08,0.17c-0.05,0.09-0.08,0.14-0.08,0.14
                    s0-0.06,0.01-0.16c0-0.05,0.01-0.11,0.02-0.18c0-0.07,0.02-0.15,0.02-0.23c0-0.08,0.01-0.17,0.02-0.27c0-0.05,0-0.1,0.01-0.14
                    c0-0.05,0-0.1,0-0.15c0-0.1-0.01-0.21-0.02-0.31c-0.01-0.11-0.02-0.21-0.04-0.32c-0.02-0.11-0.04-0.21-0.06-0.32
                    c-0.02-0.1-0.05-0.21-0.08-0.31c-0.06-0.2-0.11-0.39-0.15-0.55c-0.05-0.16-0.08-0.3-0.1-0.4
                    C138.79,499.47,138.78,499.41,138.78,499.41z"/>
                <path id="XMLID_514_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M85.71,492.46
                    c0.75,3.64,3,11.38,4.58,13.63c1.58,2.26,4.21,5.23,4.21,5.23s-5.6-4.04-7.53-8.41s-2.28-6.54-2.98-8.17
                    C83.29,493.11,85.02,489.08,85.71,492.46z"/>
                <path id="XMLID_513_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M96.95,514.47
                    c0,0,0.05,0.07,0.14,0.19c0.09,0.12,0.24,0.3,0.42,0.52c0.19,0.22,0.43,0.48,0.71,0.76c0.29,0.28,0.62,0.59,0.99,0.91
                    c0.38,0.31,0.81,0.63,1.27,0.95c0.47,0.3,0.99,0.61,1.51,0.95c0.53,0.34,1.07,0.68,1.61,1.04c0.54,0.36,1.09,0.73,1.64,1.11
                    c0.55,0.38,1.09,0.76,1.62,1.14c0.53,0.38,1.05,0.77,1.55,1.14c0.5,0.37,0.97,0.74,1.42,1.1c0.45,0.35,0.86,0.7,1.25,1.02
                    c0.19,0.16,0.37,0.32,0.54,0.47c0.17,0.15,0.33,0.3,0.48,0.43c0.3,0.27,0.55,0.52,0.76,0.73c0.05,0.05,0.1,0.1,0.15,0.15
                    c0.04,0.05,0.08,0.09,0.12,0.14c0.08,0.08,0.14,0.16,0.19,0.21c0.1,0.12,0.16,0.18,0.16,0.18s-0.06-0.05-0.18-0.15
                    c-0.12-0.1-0.29-0.25-0.52-0.43c-0.23-0.18-0.5-0.4-0.82-0.64c-0.32-0.25-0.69-0.51-1.09-0.81c-0.2-0.15-0.41-0.3-0.63-0.45
                    c-0.22-0.16-0.44-0.32-0.67-0.48c-0.46-0.33-0.95-0.67-1.46-1.03c-0.51-0.35-1.04-0.72-1.57-1.09c-0.53-0.37-1.08-0.75-1.63-1.12
                    c-0.54-0.38-1.09-0.75-1.63-1.12c-0.54-0.37-1.07-0.73-1.58-1.08c-0.51-0.35-1.01-0.68-1.48-1.02c-0.46-0.35-0.88-0.7-1.24-1.05
                    c-0.36-0.35-0.67-0.69-0.93-1c-0.26-0.31-0.47-0.6-0.63-0.84c-0.16-0.24-0.28-0.44-0.36-0.58
                    C96.98,514.55,96.95,514.47,96.95,514.47z"/>
                <path id="XMLID_512_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M94.5,533.74
                    c0,0,2.45,1.23,4.03,0s3.15-1.54,4.2,0.54c1.05,2.08,2.49,4.53,2.49,4.53s-2.46-3.25-3.53-3.02c-1.06,0.22-3.17-0.35-3.34,1.45
                    c-0.17,1.79,1.4,6.86,0.53,6.32C98,543.02,94.5,533.74,94.5,533.74z"/>
                <path id="XMLID_511_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M101.69,531.46
                    c2.26-0.53,4.01-2.1,5.77-2.98c1.75-0.88,4.35-2.1,6.03,0.7c0,0-2.75-1.04-2.2,0.27c0.55,1.31-0.67,2.18-1.55,2.01
                    c-0.88-0.18-1.84,1.75-2.15,2.28c-0.31,0.53-1.18-0.19-1.71-0.97c-0.53-0.78,0.07,2.02-0.66,2.37
                    C104.48,535.49,101.69,531.46,101.69,531.46z"/>
                <path id="XMLID_510_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M130.92,531.3
                    c0,0,6.94-2.2,8.81-4.74c0,0,5.31,2.98,4.79,3.84s-1.8,0.53-2.89,1.58c-1.09,1.05,1.19,1.93,0.75,4.03c-0.44,2.1-0.78,1.23-1.92,0
                    s-2.56-2.76-2.73-0.85c-0.17,1.9-1.57,5.41-3.49,6.98s-5.11,1.46-5.11,1.46s2.66-2.68,1.26-2.51c-1.4,0.18-7.18,1.75-7.35,1.05
                    c-0.18-0.7,3.58-1.4,4.85-2.45c1.28-1.05,4.4-1.05,5.9-2.1c1.5-1.05,4.2-3.69,3.29-4.82c-0.91-1.13-1.26,1.01-2.49,2.39
                    c-1.23,1.38-2.8,2.25-3.85,1.73c-1.05-0.53-1.93-2.1,0.18-3.15C133.02,532.69,132.83,531.18,130.92,531.3z"/>
                <path id="XMLID_509_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M107.58,541.27
                    c0,0,0.16,0.08,0.42,0.25c0.26,0.17,0.63,0.42,1.05,0.77c0.21,0.17,0.43,0.36,0.65,0.58c0.22,0.22,0.45,0.45,0.67,0.72
                    c0.22,0.26,0.43,0.55,0.61,0.87c0.19,0.31,0.35,0.66,0.46,1.02c0.06,0.18,0.09,0.37,0.12,0.57c0.01,0.1,0.01,0.2,0.02,0.29
                    c0,0.1,0,0.18-0.01,0.27c-0.02,0.37-0.07,0.72-0.14,1.06c-0.07,0.34-0.17,0.65-0.27,0.94c-0.09,0.29-0.2,0.56-0.27,0.8
                    c-0.07,0.25-0.12,0.47-0.1,0.66c0.01,0.19,0.08,0.36,0.17,0.48c0.09,0.12,0.17,0.21,0.23,0.26c0.06,0.05,0.1,0.08,0.1,0.08
                    s-0.04-0.02-0.12-0.06c-0.07-0.04-0.18-0.1-0.3-0.21c-0.12-0.11-0.25-0.28-0.3-0.52c-0.06-0.23-0.05-0.5-0.01-0.77
                    c0.04-0.27,0.11-0.56,0.18-0.85c0.07-0.29,0.13-0.6,0.18-0.92c0.05-0.31,0.08-0.65,0.08-0.97c0-0.08,0-0.18,0-0.25
                    c-0.01-0.08-0.01-0.16-0.02-0.23c-0.02-0.16-0.05-0.31-0.1-0.47c-0.09-0.31-0.23-0.62-0.39-0.91c-0.16-0.29-0.34-0.57-0.53-0.83
                    c-0.19-0.26-0.38-0.51-0.57-0.74c-0.19-0.23-0.38-0.44-0.56-0.64c-0.36-0.39-0.67-0.7-0.89-0.92
                    C107.71,541.39,107.58,541.27,107.58,541.27z"/>
                <path id="XMLID_508_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M124.01,557.55
                    c0,0-1.84-2.1-2.96-2.28c-1.11-0.18-4.31-1.93-7.08-4.38c0,0-1.09,1.58,1.36,3.33c2.45,1.75,3.57,2.6,3.53,4.28
                    c-0.03,1.68,3.12,1.68,3.12,1.68s-4.18-2.8-2.56-3.85S124.01,557.55,124.01,557.55z"/>
                <path id="XMLID_507_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M130.57,565.78l-1.99,3.68
                    c-0.64-2.1-1.41-2.1,0.55-4.73c1.96-2.63,1.09-6.23,1.09-6.23s2.28,2.28,4.55,5.48c2.28,3.2,2.63,4.71,2.63,4.71
                    s-1.95-1.16-3.6-3.61c-1.65-2.45-2.53-1.4-2.88-0.35C130.57,565.78,131.62,565.08,130.57,565.78"/>
                <path id="XMLID_506_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M143.55,568.69
                    c0,0-1.07-5.71-2.47-8.51c-1.4-2.8-2.3-4.64-2.3-4.64s3.91,1.84,4.77,4.64c0.86,2.8,9.44,8.51,9.44,8.51s-6.69-2.83-8.43-4.71
                    C144.56,563.98,144.97,566.52,143.55,568.69z"/>
                <path id="XMLID_505_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M155.44,550.9
                    c0,0,0.08,0.03,0.24,0.09c0.15,0.06,0.38,0.15,0.66,0.28c0.28,0.14,0.62,0.3,1,0.52c0.19,0.1,0.39,0.23,0.6,0.35
                    c0.21,0.13,0.42,0.27,0.64,0.43c0.44,0.31,0.9,0.67,1.34,1.11c0.44,0.44,0.88,0.94,1.23,1.54c0.04,0.07,0.09,0.14,0.13,0.22
                    c0.04,0.07,0.08,0.15,0.13,0.22c0.04,0.07,0.08,0.15,0.13,0.22c0.04,0.07,0.09,0.14,0.13,0.2c0.09,0.14,0.18,0.27,0.28,0.41
                    c0.1,0.14,0.2,0.27,0.3,0.41c0.42,0.54,0.89,1.06,1.37,1.58c0.48,0.52,0.96,1.04,1.42,1.58c0.46,0.53,0.88,1.09,1.27,1.64
                    c0.19,0.28,0.37,0.55,0.55,0.81c0.18,0.26,0.36,0.51,0.54,0.75c0.37,0.48,0.72,0.93,1.07,1.33c0.68,0.81,1.28,1.46,1.71,1.91
                    c0.21,0.22,0.38,0.4,0.5,0.52c0.12,0.12,0.18,0.18,0.18,0.18s-0.07-0.06-0.2-0.16c-0.13-0.1-0.32-0.26-0.56-0.46
                    c-0.23-0.2-0.52-0.45-0.84-0.75c-0.16-0.15-0.32-0.31-0.5-0.48c-0.17-0.17-0.35-0.35-0.54-0.54c-0.37-0.38-0.77-0.81-1.16-1.28
                    c-0.2-0.23-0.39-0.48-0.59-0.74c-0.1-0.13-0.19-0.26-0.29-0.39c-0.09-0.13-0.19-0.27-0.29-0.4c-0.38-0.53-0.82-1.05-1.27-1.57
                    c-0.46-0.52-0.94-1.03-1.42-1.55c-0.48-0.53-0.95-1.06-1.39-1.63c-0.11-0.14-0.22-0.28-0.31-0.43c-0.1-0.15-0.2-0.29-0.29-0.45
                    c-0.05-0.08-0.09-0.15-0.14-0.23c-0.04-0.08-0.08-0.15-0.12-0.22c-0.04-0.07-0.08-0.15-0.12-0.22c-0.04-0.07-0.08-0.14-0.12-0.22
                    c-0.31-0.55-0.69-1.05-1.1-1.49c-0.1-0.11-0.21-0.21-0.31-0.32c-0.11-0.1-0.21-0.2-0.31-0.29c-0.1-0.1-0.21-0.19-0.31-0.28
                    c-0.1-0.09-0.2-0.18-0.31-0.26c-0.41-0.33-0.8-0.62-1.15-0.86c-0.35-0.24-0.67-0.44-0.94-0.6c-0.13-0.08-0.25-0.15-0.36-0.21
                    c-0.1-0.06-0.19-0.11-0.27-0.15C155.51,550.94,155.44,550.9,155.44,550.9z"/>
                <path id="XMLID_504_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M143.55,548.97
                    c0,0,0.05,0.08,0.15,0.23c0.09,0.15,0.26,0.35,0.47,0.6c0.22,0.24,0.51,0.53,0.86,0.82c0.35,0.29,0.77,0.59,1.25,0.9
                    c0.48,0.3,1.01,0.61,1.58,0.92c0.57,0.31,1.19,0.62,1.81,0.98c0.16,0.09,0.31,0.18,0.47,0.27c0.16,0.1,0.32,0.19,0.47,0.29
                    c0.31,0.2,0.63,0.41,0.93,0.63c0.61,0.45,1.21,0.95,1.76,1.5c0.54,0.56,1.04,1.17,1.44,1.83c0.21,0.33,0.38,0.67,0.53,1.02
                    c0.07,0.18,0.14,0.35,0.21,0.53c0.05,0.18,0.11,0.36,0.16,0.54c0.18,0.7,0.31,1.39,0.41,2.04c0.11,0.65,0.18,1.26,0.3,1.81
                    c0.12,0.55,0.28,1.04,0.47,1.45c0.02,0.05,0.05,0.1,0.07,0.15c0.03,0.05,0.05,0.1,0.08,0.14c0.05,0.09,0.1,0.18,0.15,0.27
                    c0.11,0.17,0.2,0.33,0.3,0.46c0.1,0.14,0.18,0.26,0.26,0.36c0.08,0.1,0.15,0.19,0.2,0.26c0.11,0.14,0.17,0.22,0.17,0.22
                    s-0.07-0.07-0.19-0.19c-0.06-0.06-0.14-0.14-0.23-0.23c-0.09-0.1-0.19-0.21-0.3-0.34c-0.11-0.13-0.22-0.28-0.35-0.44
                    c-0.06-0.08-0.12-0.17-0.18-0.26c-0.03-0.05-0.06-0.09-0.09-0.14c-0.03-0.05-0.06-0.1-0.09-0.15c-0.12-0.2-0.23-0.43-0.33-0.68
                    c-0.1-0.25-0.19-0.51-0.27-0.79c-0.16-0.56-0.27-1.17-0.4-1.81c-0.13-0.64-0.28-1.3-0.48-1.98c-0.18-0.66-0.5-1.31-0.88-1.92
                    c-0.38-0.61-0.86-1.19-1.38-1.72c-0.52-0.53-1.09-1.02-1.68-1.46c-0.59-0.44-1.19-0.85-1.8-1.21c-0.61-0.36-1.21-0.71-1.77-1.04
                    c-0.56-0.34-1.09-0.68-1.55-1.02c-0.23-0.17-0.45-0.34-0.66-0.51c-0.2-0.17-0.39-0.33-0.55-0.5c-0.34-0.32-0.6-0.64-0.8-0.91
                    c-0.2-0.28-0.34-0.5-0.41-0.66C143.59,549.06,143.55,548.97,143.55,548.97z"/>
                <path id="XMLID_503_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M139.73,565.96
                    c0,0,0.03,0.03,0.08,0.1c0.05,0.06,0.12,0.16,0.2,0.29c0.17,0.25,0.4,0.62,0.62,1.09c0.11,0.23,0.23,0.49,0.34,0.77
                    c0.11,0.28,0.22,0.58,0.31,0.89c0.1,0.31,0.18,0.64,0.25,0.98c0.07,0.34,0.12,0.68,0.16,1.03c0.03,0.35,0.06,0.7,0.05,1.04
                    c0,0.35-0.02,0.68-0.06,1.01c-0.04,0.33-0.09,0.64-0.16,0.93c-0.07,0.29-0.15,0.57-0.23,0.81c-0.04,0.12-0.08,0.24-0.13,0.35
                    c-0.05,0.11-0.09,0.21-0.13,0.31c-0.08,0.19-0.17,0.36-0.24,0.49c-0.07,0.13-0.14,0.23-0.18,0.3c-0.04,0.07-0.06,0.11-0.06,0.11
                    s0.05-0.17,0.13-0.45c0.04-0.15,0.09-0.32,0.14-0.52c0.02-0.1,0.05-0.21,0.07-0.32c0.02-0.11,0.05-0.23,0.07-0.36
                    c0.05-0.25,0.09-0.52,0.12-0.8c0.04-0.29,0.06-0.59,0.08-0.9c0-0.16,0.01-0.32,0.01-0.48c0-0.16,0-0.32-0.01-0.49
                    c-0.01-0.33-0.03-0.66-0.06-0.99c-0.04-0.33-0.08-0.66-0.13-0.99c-0.06-0.32-0.12-0.64-0.19-0.95c-0.07-0.31-0.15-0.6-0.23-0.88
                    c-0.08-0.28-0.16-0.54-0.24-0.78c-0.08-0.24-0.16-0.46-0.23-0.66c-0.07-0.19-0.13-0.36-0.19-0.5
                    C139.79,566.12,139.73,565.96,139.73,565.96z"/>
                <path id="XMLID_502_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M152.98,565.96
                    c0,0,0.14,0.13,0.36,0.36c0.11,0.12,0.26,0.25,0.41,0.42c0.16,0.16,0.34,0.34,0.54,0.54c0.19,0.2,0.41,0.41,0.62,0.65
                    c0.22,0.24,0.45,0.49,0.67,0.77c0.22,0.28,0.43,0.58,0.62,0.91c0.2,0.32,0.36,0.67,0.51,1.02c0.07,0.18,0.14,0.36,0.2,0.53
                    c0.06,0.18,0.12,0.36,0.17,0.54c0.1,0.36,0.19,0.72,0.27,1.05c0.08,0.34,0.15,0.67,0.21,0.98c0.07,0.31,0.13,0.61,0.18,0.88
                    c0.11,0.55,0.18,1.01,0.23,1.33c0.05,0.32,0.07,0.51,0.07,0.51s-0.07-0.17-0.19-0.48c-0.11-0.31-0.27-0.74-0.45-1.28
                    c-0.09-0.26-0.18-0.55-0.28-0.86c-0.09-0.31-0.2-0.63-0.29-0.96c-0.1-0.34-0.2-0.67-0.31-1.02c-0.06-0.17-0.12-0.34-0.18-0.51
                    c-0.07-0.17-0.13-0.34-0.2-0.5c-0.14-0.33-0.29-0.66-0.46-0.97c-0.17-0.31-0.36-0.6-0.55-0.89c-0.2-0.28-0.39-0.55-0.58-0.8
                    c-0.19-0.25-0.38-0.49-0.54-0.71c-0.17-0.22-0.32-0.43-0.45-0.61c-0.13-0.19-0.24-0.35-0.33-0.49
                    C153.08,566.12,152.98,565.96,152.98,565.96z"/>
                <path id="XMLID_501_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M117.09,543.56
                    c0,0,0.06,0.03,0.17,0.09c0.11,0.06,0.28,0.15,0.49,0.26c0.42,0.23,1.01,0.55,1.73,0.94c0.36,0.19,0.75,0.4,1.16,0.62
                    c0.41,0.22,0.85,0.44,1.3,0.67c0.23,0.11,0.46,0.23,0.69,0.35c0.23,0.12,0.47,0.23,0.71,0.35c0.24,0.11,0.48,0.23,0.72,0.34
                    c0.24,0.11,0.49,0.22,0.73,0.34c0.25,0.11,0.49,0.21,0.74,0.32c0.25,0.1,0.5,0.2,0.74,0.3c0.49,0.19,0.99,0.35,1.47,0.5
                    c0.48,0.15,0.95,0.28,1.4,0.41c0.45,0.12,0.88,0.24,1.27,0.36c0.39,0.12,0.75,0.25,1.06,0.4c0.31,0.14,0.55,0.32,0.73,0.48
                    c0.18,0.17,0.29,0.33,0.35,0.43c0.03,0.05,0.05,0.1,0.07,0.13c0.02,0.03,0.02,0.04,0.02,0.04s-0.04-0.06-0.12-0.15
                    c-0.08-0.1-0.21-0.23-0.4-0.36c-0.19-0.13-0.44-0.26-0.75-0.37c-0.3-0.11-0.66-0.2-1.06-0.28c-0.39-0.09-0.83-0.16-1.29-0.26
                    c-0.46-0.1-0.94-0.2-1.44-0.33c-0.5-0.13-1.01-0.28-1.52-0.47c-0.26-0.09-0.52-0.19-0.76-0.29c-0.25-0.11-0.5-0.22-0.76-0.32
                    c-0.25-0.12-0.5-0.23-0.74-0.35c-0.24-0.12-0.49-0.24-0.73-0.36c-0.48-0.25-0.94-0.5-1.39-0.75c-0.45-0.25-0.87-0.51-1.27-0.75
                    c-0.4-0.24-0.77-0.48-1.11-0.71c-0.34-0.22-0.65-0.44-0.92-0.63c-0.27-0.19-0.51-0.36-0.7-0.5
                    C117.3,543.73,117.09,543.56,117.09,543.56z"/>
                <path id="XMLID_500_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M116.03,529.88
                    c0,0,0.08,0.04,0.2,0.12c0.06,0.04,0.14,0.08,0.22,0.13c0.09,0.05,0.18,0.11,0.29,0.17c0.1,0.06,0.22,0.12,0.34,0.19
                    c0.12,0.06,0.25,0.13,0.38,0.19c0.13,0.07,0.27,0.12,0.41,0.19c0.07,0.03,0.14,0.06,0.22,0.09c0.07,0.03,0.14,0.06,0.22,0.08
                    l0.22,0.08c0.07,0.02,0.15,0.05,0.22,0.07c0.07,0.02,0.15,0.05,0.22,0.07c0.07,0.02,0.15,0.04,0.22,0.07
                    c0.14,0.05,0.28,0.08,0.41,0.12c0.13,0.04,0.26,0.08,0.38,0.11c0.12,0.03,0.22,0.07,0.32,0.1c0.1,0.03,0.18,0.05,0.25,0.07
                    c0.14,0.04,0.22,0.08,0.22,0.08s-0.08,0.02-0.23,0.04c-0.07,0.01-0.16,0.02-0.26,0.03c-0.1,0.01-0.22,0.01-0.34,0.01
                    c-0.06,0-0.13,0-0.2,0c-0.07,0-0.14-0.01-0.21-0.01c-0.14-0.01-0.3-0.02-0.45-0.05c-0.16-0.03-0.32-0.05-0.48-0.1
                    c-0.08-0.02-0.16-0.04-0.24-0.07c-0.08-0.03-0.16-0.06-0.24-0.09c-0.16-0.06-0.32-0.13-0.47-0.21c-0.15-0.08-0.29-0.16-0.43-0.25
                    c-0.14-0.09-0.26-0.18-0.37-0.28c-0.11-0.1-0.21-0.19-0.3-0.28c-0.09-0.09-0.16-0.18-0.23-0.26c-0.06-0.08-0.11-0.16-0.15-0.22
                    c-0.04-0.06-0.07-0.11-0.09-0.15C116.05,529.9,116.03,529.88,116.03,529.88z"/>
                <g id="XMLID_483_">
                    <path id="XMLID_499_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M56.29,492.46
                        c0,0-0.45-0.22-1.04-0.66C55.77,492.7,56.29,492.46,56.29,492.46z"/>
                    <path id="XMLID_484_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M124.01,578.88
                        c0,0-25.22-38.14-35.96-56.23c-10.74-18.1-8.64-20.2-7.71-26.4c0.93-6.2,5.6-7.91,5.6-7.91l1.72-1.88c0,0-1.72,1.88-7.18,1.88
                        c-5.46,0-4.03-8.36-4.03-8.36s0,0,0.7-1.23s0.53,0.71,0.88,1.99s2.1-0.58,1.93-3.03s2.8-2.1,2.8-2.1s-1.05,4.03,0.35,6.28
                        c1.4,2.25,2.45,1.78,4.9,1.01c2.45-0.76,2.45-3.61,0.7-5.37c-1.75-1.75-2.63-3.5-1.58-4.93c1.05-1.43,2.63,0.73,4.73-1.42
                        c2.1-2.14,4.38-6.61,4.2-8.29c-0.18-1.68-3.5,0.8-3.5,0.8c3.85-3.68,4.38-9.28,4.38-9.28c-2.45,8.06-7.53,8.48-7.53,8.48
                        c2.28-1.5,2.45-3.93,3.15-5.85c0.7-1.93,4.9-7.88,4.03-8.93c-0.88-1.05-6.13,9.81-7.53,9.46c-1.4-0.35,3.68-5.6,3.15-7
                        s-3.85,5.95-5.95,5.95c-2.1,0,1.93-1.4,1.4-2.8c-0.53-1.4-2.1-1.23,0.35-2.28c2.45-1.05,5.25-5.6,7.18-9.35
                        c1.93-3.74,9.17-4.58,8.41-7.64c-0.7-2.8,5.43,0.53,9.88,0.35c4.45-0.18,5.01-2.98,8.68-5.08c3.68-2.1,6.42,1.4,6.42,1.4
                        c-3.57,1.23-7.47,3.5-13.34,8.06c-5.87,4.55-9.89,0-9.89,0s-0.7,2.45,3.59,2.91c4.29,0.46,10.94-3.79,12.1-5.71
                        c1.16-1.93,10.04-4.55,10.57-4.38c0.53,0.18-0.53,2.8-0.53,2.8c1.23-0.35,2.8-2.28,2.8-2.28c2.28,3.85-2.1,5.78-2.1,5.78
                        c5.78-1.75,2.8-5.96,5.29-7.54c2.49-1.58,1.02,0.88,5.16,8.24c4.14,7.35,7.68,7.17,7.68,7.17c-5.14-2.33-7.54-10.51-7.54-10.51
                        s2.17,0.47,5.67,1.4c3.5,0.93,4.44,6.3,5.37,8.87c0.93,2.57-1.87,5.6-1.79,8.87c0.08,3.27-3.81,8.08-3.81,8.08
                        c2.8,0.61,5.37-2.71,5.37-2.71c0.93,1.87-1.17,6.3-1.17,6.3s1.68,0.85,1.87,2.71s-0.7,8.73-1.17,13.7
                        c-0.47,4.96-3.97,11.85-3.97,11.85c2.81,0.99,7.01-4.2,7.47-3.7c0.47,0.5,1.4,5.21,1.4,5.21c3.5-2.7,4.67-14.37,4.67-14.37
                        c1.87,1.62-0.7,12.27-0.7,12.27c10.27-5.03,16.11-21.55,16.11-21.55c1.44,6.39-3.74,12.45-3.74,12.45s6.54-5.02,5.84-11.32
                        c-0.7-6.3-7.01-10.83-7.01-10.83c5.84,2.24,8.64-3.88,8.64-3.88c-3.74,3.04-7.24-1.17-7.24-1.17c7.47-2.33,5.14-9.81,5.14-9.81
                        s-0.93,4.44-6.54,4.2c-5.6-0.23-3.97-12.14-3.97-12.14c1.87,1.63,3.5,8.64,3.5,8.64c1.63-4.44-1.63-10.27-1.63-10.27
                        c2.1,1.17,3.74,7.01,3.74,7.01c0.93-7.24-5.45-12.14-5.45-12.14c5.29-7.94-0.38-14.24-0.38-14.24c2.1,8.64-5.14,9.57-5.14,9.57
                        c3.27-3.27,3.27-4.9,1.4-10.27c-1.87-5.37-8.17-4.67-8.64-7.24s3.5-5.37,2.34-7.01s-4.9,1.17-4.9,1.17
                        c-7.01-12.14-19.15-9.81-19.15-9.81c6.01-0.23,4.58,3.04,4.58,3.04c-1.72-2.1-9.49-1.17-9.49-1.17s-2.57-4.9-7.24-5.4
                        c-4.67-0.49-7.13,4.23-7.13,4.23s-1.51-3.74-6.29-4.2c-4.79-0.47-8.08,5.6-8.08,5.6c-2.83-3.97-8.38-3.04-14.92,0
                        c-6.54,3.04-9.81,11.21-11.91,15.18c-2.1,3.97-2.1,4.2-7.01,7.71c-4.9,3.5-8.87,12.14-8.87,12.14s-1.4,0.47-3.04,5.84
                        c-1.63,5.37,3.74,8.17,3.74,8.17c-11.44,3.97-7.24,9.34-7.24,9.34s1.87-2.34,3.74-1.87c1.87,0.47-0.47,5.37-0.47,5.37
                        c-2.34-3.5-4.44,0-4.44,0c2.34,0.7,1.17,5.37-0.47,6.33s0.47,6.78,0.47,6.78c-2.34-0.43-3.27-4.94-3.27-4.94s-2.34,5.14,0.23,7.47
                        c2.57,2.33,8.87,3.62,8.87,3.62s-2.57,1.28-3.74,6.3c-0.82,3.51,1.33,5.73,2.7,6.75c-0.33-0.57-0.65-1.61-0.83-3.46
                        c-0.47-4.77,2.1-5.44,2.1-5.44s0,2.51,1.17,8.14c1.17,5.64,7.24,7.2,7.24,7.2c0.47-3.04,6.77-3.5,6.77-3.5s-4.44,5.6-5.37,10.27
                        c-0.93,4.67-2.57,6.54-7.24,8.87c-4.67,2.33-3.04,7.94-7.24,10.97c-4.2,3.04-10.51,4.9-18.45,8.74
                        c-7.94,3.84-11.48,9.23-11.48,9.23s-16.3,14.87-16.3,36.04L124.01,578.88z M160.51,448.28c0,0-5.6-9-4.2-9c1.4,0,1.4,0,1.4,0
                        s3.68,3.7,4.03,5.3C162.09,446.18,160.51,448.28,160.51,448.28z M68.05,444.6c1.05-0.35,2.66,3.33,5.1,3.68
                        c0,0-6.01-3.85-2.66-3.15c3.35,0.7,6.67,0.54,8.07,1.93c1.4,1.39,0.7,2.97-0.88,2.8c-1.58-0.18-3.15,0.03-4.12,0.01
                        c-0.96-0.01-2.01-0.89-2.01-0.89C69.8,447.58,67,444.95,68.05,444.6z M77.68,473.72c-2.28,1.17-3.64,4.68-4.54,3.63
                        c-0.89-1.05,0.68-1.4-0.89-1.05c-1.58,0.35-2.45,4.08-2.98,3.44c-0.53-0.64,0.15-3.96,2.27-5.02c0,0-0.34-1.4-2.27-0.18
                        c-1.6,1.02,1.93-2.33,4.29-1.95C75.93,472.97,79.96,472.55,77.68,473.72z M77.68,471.18c-0.35-1.36,2.1-2.26-2.45-1.98
                        c-4.55,0.27-8.93,1.02-9.98-3.38c-1.05-4.4,2.98-6.5,3.33-7.55c0.35-1.05,0.07-2.8-0.75-2.63c-0.82,0.18,4.96-3.15,2.16-2.45
                        s-6.65,1.93-6.65,1.93c1.23-1.23,3.5-3.5,6.3-3.5c2.8,0,5.64,0.7,3.52,1.75c-2.12,1.05-3.35,1.05-2.29,1.75
                        c1.05,0.7,4.38-1.4,4.2,0c-0.18,1.4-3.33,2.45-4.2,4.03c-0.88,1.58-0.66,5.47,2.29,6.67c2.96,1.21,4.64,1.18,6.16,3.38
                        C80.84,471.4,78.03,472.53,77.68,471.18z M90.29,464.57c3.33-2.03,0.7,0,0.7,0s-3.97,3.5-7.07,3.68
                        c-3.09,0.18-4.16-2.81-4.16-2.81s1.65,2.28,4.16,1.58c0,0-2.05-1.98-0.1-1.58C85.56,465.79,86.96,466.59,90.29,464.57z
                        M88.54,458.52c0,0-0.75,2.91-2.47,3.16c0,0,2.98,0.25,3.43-1.15c0,0-0.41,3.83-5.67,2.35c-5.26-1.48-5.47-4.45-5.47-4.45
                        s0.38,1.12,2.48,1.7l-1.05-3.02c-0.02-0.05-0.03-0.08-0.03-0.08l0.03,0.08c0.17,0.47,1.2,2.9,4.03,2.9
                        C86.97,460.01,88.54,458.52,88.54,458.52z M93.09,442.68c-1.58,1.23-6.98,4.55-9.27,4.38c-2.29-0.18-5.44-0.53-6.14-6.3
                        c0,0,1.23,2.63,2.28,3.15c1.05,0.53,1.95,2.8,3.86,1.75c1.91-1.05-0.71-1.23-0.71-1.23c-0.88-1.23-0.7-2.1,1.93-3.15
                        c2.63-1.05,5.25-3.15,6.83-3.85c1.58-0.7,3.5-1.23,3.85,0C96.07,438.65,94.67,441.45,93.09,442.68z M108.95,426.04
                        c-0.79,0.88-1.03,1.4-2.4,1.4c-1.37,0-1.37-0.53-3.3-0.7c-1.93-0.18-4.14-1.93-5.22-1.05c-1.08,0.88-2.48,1.4-1.43,2.8
                        c1.05,1.4,3.85-0.86,3.68,1.15c-0.18,2.01-0.64,3.41-2.25,4.28s-4.76,1.93-6.16,2.1c-1.4,0.18-3.49-1.05-2.71-1.93
                        c0.78-0.88,2.71-3.15,2.71-3.15s-2.28,1.05-3.5,1.05c-1.23,0-1.05-0.86-0.7-2.36c0.35-1.5,1.93-2.72,4.2-2.9
                        c2.28-0.18,4.87-0.18,5.43-2.45c0,0-7.53,3.15-11.21-1.4c-0.47-0.58,6.48,0.7,8.41,0c1.93-0.7,6.26-1.52,6.37-1.11
                        c0.11,0.41-1.97,3.73,0.82,2.51c2.79-1.23,4.13-3.84,4.86-2.62c0.73,1.22,0.56,1,2.4,1.29
                        C110.78,423.24,109.74,425.16,108.95,426.04z M119.45,416.06c-1.66,0.35-0.09,2.1-0.02,2.42c0.07,0.32-1.99,0.21-3.22,0.98
                        c-1.23,0.77-0.35,1.16-0.97,2.21c-0.62,1.05-4.81-5.25-8.49-6.3c-3.68-1.05-4.9,2.63-8.58,4.09c-3.68,1.47-6.3-1.29-6.3-1.29
                        c0.88,0.7,3.85-0.53,3.85-0.53s-1.4-1.93-1.23-2.28c0.18-0.35,2.98,1.4,2.98,1.4s0.18-1.4,1.58-1.93c1.4-0.53,2.64-1.75,2.64-1.75
                        c-1.39-0.53-3.87-6.48-1.76-3.94c2.1,2.54,3.85,0.13,3.85,0.13s0.18-1.44-2.09-3.89c-2.26-2.45-0.82-5.43-0.82-5.43
                        s0.63,2.98,3.26,4.9c2.63,1.93,4.82,4.29,4.82,4.29c1.11-6.66,6.29-9.19,6.29-9.19s-3.06,3.5-4.11,8.41
                        c-1.05,4.9,4.9,5.43,6.38,5.43c1.48,0-0.08-1.05,0.8-1.75c0.88-0.7,2.28,1.4,2.74,2.1C121.51,414.83,121.11,415.71,119.45,416.06z
                        M121.05,407.48c-1.34-3.15-1.6-5.95-1.6-5.95s0.9,3.5,4.87,5.95c3.97,2.45,7.05,4.55,4.81,9.28c0,0,0.46-2.45-1.24-3.68
                        C126.19,411.86,122.39,410.63,121.05,407.48z M133.2,427.09c-2.28,2.28-2.28,2.28-2.28,2.28s0.11,0.88-1.79-0.53
                        c0,0-1.47-1.05-1.24-4.55c0.23-3.5,0.97-4.55,2-6.65c1.03-2.1,3.48-3.33,3.48-3.33s2.4-1.75,5.41-4.03s4.77,0,4.77,0
                        s-6.55,3.15-7.84,7.71c0,0,2.87-1.58,3.07-0.35c0.2,1.23-1.96,1.75-3.07,2.8c-1.11,1.05-3.56,3.85-4.44,4.03
                        c-0.88,0.18-1.38-2.1-1.38-2.1s-1.06,4.55-0.28,4.9c0.78,0.35,5.69-5.43,8.31-5.78c2.63-0.35-0.57,1.05,0.85,1.4
                        c1.42,0.35,3.06-0.7,5.75,1.05c2.68,1.75,2.97,3.15,7.12,2.1c4.15-1.05,6.42-5.95,6.77-4.2c0.35,1.75-3.37,9.11-11.76,5.95
                        C138.27,424.64,135.47,424.81,133.2,427.09z M150.01,436.69c-1.93-1.19-4.87-2.42-6.46-4.69c-1.59-2.28-0.39-3.3-0.39-3.3
                        s0.51,3.47,3.51,4.7l-2.13-3.76c0,0,2.86,2.88,4.26,3.23c1.4,0.35-1.05,1.23,0.88,1.75c1.93,0.53,4.38,1.33,5.95,2.07
                        c1.58,0.74-3.97,0-3.97,0s3.14,2.88,4.25,5.4c1.12,2.52,1.82,7.79,1.82,7.79S151.93,437.88,150.01,436.69z M167.66,460.13
                        c-0.39-1.46-4.69-0.29-3.99,0c0.7,0.29,1.93,1.28,3.33,2.76c1.4,1.48,0.35,1.15-1.05,1.33c-1.4,0.18-1.58-1.23-2.98-2.53
                        c-1.4-1.3-0.35,2.18-1.18,4.13c-0.82,1.95-0.93-4.4-1.74-7.37c-0.82-2.98-0.06,6.65-1.11,6.13c-1.05-0.53-0.35-3.5-0.88-2.88
                        c-0.53,0.62-1.05,5.33-1.05,5.33s-1.05-1.93-0.93-3.79c0.13-1.86,2.15-2.87,2.35-4.09c0.2-1.23-0.02-1.58,1.2-2.1
                        c1.23-0.53-0.7-4.03-0.7-4.03c3.15-0.7,4.03,3.5,4.96,4.03c0.93,0.53,2.92,0.18,4.5,1.4c1.58,1.23,3.33,3.25,3.33,3.25
                        S168.04,461.59,167.66,460.13z"/>
                </g>
                <path id="XMLID_482_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M126.69,470.61
                    c0,0,0.04,0.02,0.1,0.05c0.06,0.03,0.15,0.08,0.24,0.14c0.09,0.06,0.2,0.13,0.3,0.2c0.05,0.04,0.1,0.07,0.16,0.11
                    c0.05,0.04,0.1,0.08,0.16,0.11c0.05,0.04,0.1,0.07,0.15,0.11c0.05,0.03,0.1,0.07,0.15,0.1c0.05,0.03,0.1,0.06,0.14,0.09
                    c0.05,0.02,0.09,0.05,0.14,0.08c0.04,0.02,0.09,0.04,0.13,0.06c0.04,0.02,0.08,0.03,0.11,0.04c0.06,0.02,0.11,0.04,0.11,0.04
                    s-0.03,0.03-0.09,0.07c-0.03,0.02-0.07,0.05-0.12,0.07c-0.05,0.02-0.11,0.03-0.17,0.05c-0.06,0.01-0.13,0.01-0.21,0.01
                    c-0.07,0-0.15-0.02-0.23-0.04c-0.04-0.01-0.08-0.02-0.11-0.04c-0.04-0.01-0.08-0.03-0.11-0.05c-0.07-0.04-0.15-0.08-0.21-0.13
                    c-0.07-0.05-0.13-0.1-0.18-0.16c-0.06-0.05-0.11-0.11-0.15-0.17c-0.05-0.06-0.08-0.12-0.12-0.18c-0.03-0.06-0.06-0.12-0.08-0.17
                    c-0.04-0.11-0.07-0.21-0.08-0.28c-0.01-0.03-0.01-0.07-0.01-0.09C126.69,470.62,126.69,470.61,126.69,470.61z"/>
                <path id="XMLID_481_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M129.61,492.29
                    c0,0,0.02,0.01,0.05,0.02c0.03,0.01,0.08,0.03,0.14,0.05c0.06,0.02,0.13,0.04,0.21,0.07c0.08,0.03,0.17,0.06,0.27,0.1
                    c0.19,0.07,0.42,0.15,0.66,0.24c0.12,0.04,0.24,0.09,0.37,0.13c0.13,0.04,0.25,0.09,0.38,0.13c0.13,0.04,0.25,0.08,0.38,0.12
                    c0.06,0.02,0.13,0.04,0.19,0.06c0.06,0.02,0.12,0.03,0.18,0.05c0.06,0.02,0.12,0.03,0.18,0.05c0.06,0.01,0.12,0.03,0.17,0.04
                    c0.11,0.03,0.22,0.06,0.32,0.08c0.1,0.02,0.19,0.04,0.28,0.06c0.08,0.01,0.16,0.03,0.22,0.04c0.12,0.03,0.2,0.05,0.2,0.05
                    s-0.07,0.03-0.19,0.07c-0.06,0.02-0.14,0.04-0.22,0.07c-0.09,0.02-0.19,0.03-0.3,0.05c-0.11,0.02-0.23,0.02-0.35,0.02
                    c-0.06,0-0.13,0-0.19,0c-0.07,0-0.13-0.01-0.2-0.02c-0.07-0.01-0.14-0.02-0.21-0.02c-0.07-0.01-0.14-0.03-0.21-0.04
                    c-0.14-0.03-0.28-0.07-0.42-0.11c-0.14-0.04-0.27-0.1-0.41-0.16c-0.07-0.03-0.13-0.06-0.19-0.09c-0.06-0.03-0.13-0.06-0.18-0.1
                    c-0.12-0.07-0.23-0.13-0.33-0.21c-0.1-0.07-0.2-0.14-0.28-0.21c-0.17-0.14-0.29-0.27-0.38-0.37
                    C129.65,492.35,129.61,492.29,129.61,492.29z"/>
                <path id="XMLID_480_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M116.03,492.29
                    c0,0-0.12,0.08-0.34,0.21c-0.11,0.07-0.24,0.15-0.39,0.24c-0.15,0.09-0.32,0.2-0.51,0.31c-0.75,0.45-1.77,1.03-2.81,1.55
                    c-0.26,0.13-0.52,0.26-0.78,0.38c-0.26,0.12-0.52,0.24-0.77,0.35c-0.25,0.11-0.5,0.21-0.73,0.3c-0.12,0.05-0.23,0.09-0.34,0.13
                    c-0.11,0.04-0.22,0.08-0.33,0.11c-0.21,0.08-0.4,0.13-0.58,0.18c-0.17,0.05-0.33,0.08-0.45,0.11c-0.13,0.03-0.23,0.04-0.3,0.05
                    c-0.07,0.01-0.11,0.01-0.11,0.01s0.03-0.02,0.1-0.05c0.06-0.03,0.15-0.07,0.27-0.12c0.11-0.06,0.25-0.13,0.41-0.21
                    c0.16-0.08,0.33-0.18,0.52-0.28c0.38-0.21,0.83-0.45,1.31-0.71c0.24-0.13,0.49-0.26,0.74-0.39c0.25-0.13,0.51-0.26,0.77-0.4
                    c0.26-0.13,0.52-0.26,0.78-0.38c0.26-0.13,0.51-0.25,0.76-0.36c0.25-0.11,0.49-0.22,0.72-0.32c0.23-0.1,0.45-0.19,0.66-0.27
                    c0.41-0.16,0.76-0.28,1.01-0.36C115.89,492.33,116.03,492.29,116.03,492.29z"/>
                <path id="XMLID_479_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M125.39,516.34
                    c0,0,0.02,0.01,0.05,0.02c0.03,0.01,0.07,0.03,0.13,0.05c0.06,0.02,0.12,0.05,0.2,0.08c0.08,0.03,0.16,0.06,0.25,0.09
                    c0.37,0.13,0.86,0.28,1.34,0.33c0.12,0.02,0.24,0.02,0.35,0.02c0.06,0,0.11,0,0.17-0.01c0.06-0.01,0.11-0.01,0.16-0.02
                    c0.11-0.02,0.21-0.04,0.3-0.07c0.1-0.03,0.18-0.07,0.27-0.11c0.08-0.04,0.15-0.09,0.22-0.13c0.03-0.02,0.06-0.05,0.09-0.07
                    c0.03-0.02,0.05-0.04,0.07-0.06c0.05-0.04,0.08-0.07,0.1-0.09c0.02-0.02,0.04-0.03,0.04-0.03s0,0.02-0.01,0.05
                    c-0.01,0.03-0.01,0.08-0.04,0.14c-0.01,0.03-0.02,0.06-0.03,0.1c-0.01,0.04-0.03,0.07-0.05,0.11c-0.04,0.08-0.1,0.16-0.17,0.25
                    c-0.08,0.08-0.17,0.17-0.28,0.24c-0.11,0.07-0.24,0.14-0.38,0.18c-0.27,0.09-0.58,0.11-0.88,0.08c-0.15-0.02-0.29-0.04-0.43-0.09
                    c-0.14-0.04-0.27-0.09-0.39-0.15c-0.12-0.06-0.24-0.12-0.34-0.19c-0.1-0.07-0.2-0.14-0.28-0.2c-0.16-0.14-0.28-0.27-0.35-0.37
                    c-0.04-0.05-0.06-0.09-0.08-0.12C125.39,516.35,125.39,516.34,125.39,516.34z"/>
                <path id="XMLID_478_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M85.87,514.47
                    c0,0,0.13,0.17,0.35,0.49c0.1,0.16,0.23,0.35,0.37,0.58c0.14,0.23,0.29,0.48,0.45,0.77c0.16,0.29,0.33,0.6,0.49,0.93
                    c0.17,0.33,0.34,0.69,0.5,1.06c0.16,0.37,0.33,0.76,0.48,1.17c0.15,0.4,0.29,0.82,0.43,1.23c0.06,0.21,0.12,0.42,0.19,0.63
                    c0.06,0.21,0.11,0.42,0.16,0.63c0.1,0.42,0.19,0.83,0.25,1.24c0.07,0.41,0.11,0.8,0.15,1.18c0.04,0.37,0.05,0.73,0.05,1.06
                    c0,0.16,0,0.32-0.01,0.47c-0.01,0.15-0.02,0.29-0.03,0.42c-0.02,0.27-0.06,0.5-0.08,0.68c-0.03,0.19-0.06,0.33-0.08,0.43
                    c-0.02,0.1-0.03,0.15-0.03,0.15s0-0.22,0-0.6c0-0.09-0.01-0.2-0.01-0.31c0-0.11,0-0.24-0.02-0.37c-0.01-0.13-0.02-0.27-0.03-0.42
                    c-0.01-0.15-0.03-0.3-0.05-0.46c-0.01-0.16-0.04-0.32-0.06-0.5c-0.02-0.17-0.05-0.35-0.08-0.53c-0.03-0.18-0.07-0.37-0.1-0.56
                    c-0.04-0.19-0.08-0.38-0.12-0.58c-0.08-0.39-0.19-0.79-0.29-1.2c-0.05-0.2-0.12-0.41-0.17-0.61c-0.06-0.2-0.12-0.41-0.19-0.61
                    c-0.13-0.41-0.26-0.81-0.4-1.21c-0.14-0.4-0.28-0.79-0.42-1.16c-0.14-0.38-0.28-0.74-0.42-1.08c-0.14-0.34-0.27-0.67-0.4-0.96
                    c-0.25-0.6-0.47-1.09-0.62-1.44C85.96,514.67,85.87,514.47,85.87,514.47z"/>
                <path id="XMLID_477_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M141.46,552.65
                    c0,0,0.16,0.17,0.42,0.47c0.07,0.07,0.15,0.14,0.24,0.23c0.09,0.08,0.17,0.17,0.28,0.26c0.11,0.09,0.22,0.18,0.34,0.27
                    c0.13,0.09,0.26,0.18,0.39,0.27c0.14,0.09,0.3,0.17,0.45,0.26c0.16,0.07,0.33,0.16,0.5,0.24c0.09,0.03,0.18,0.07,0.27,0.1
                    c0.05,0.02,0.09,0.04,0.14,0.05c0.05,0.01,0.09,0.03,0.14,0.04c0.09,0.03,0.19,0.06,0.29,0.09c0.1,0.03,0.2,0.05,0.3,0.07
                    c0.2,0.05,0.41,0.08,0.62,0.13c0.11,0.01,0.21,0.03,0.32,0.04c0.11,0.02,0.21,0.03,0.32,0.04c0.43,0.04,0.88,0.06,1.33,0.05
                    c0.11,0,0.22,0,0.34,0l0.17,0l0.08,0l0.1,0c0.06,0,0.11,0,0.18,0c0.07,0,0.13,0.01,0.19,0.01l0.18,0.03
                    c0.06,0.01,0.12,0.03,0.18,0.04c0.48,0.11,0.92,0.34,1.28,0.62c0.37,0.28,0.66,0.61,0.9,0.94c0.24,0.33,0.41,0.68,0.54,1.01
                    c0.13,0.33,0.22,0.64,0.27,0.92c0.06,0.28,0.07,0.53,0.08,0.73c0.01,0.2,0,0.36-0.01,0.47c-0.01,0.11-0.01,0.16-0.01,0.16
                    s-0.01-0.06-0.02-0.16c-0.01-0.11-0.04-0.26-0.08-0.46c-0.04-0.19-0.1-0.43-0.18-0.69c-0.09-0.26-0.2-0.54-0.36-0.84
                    c-0.08-0.15-0.16-0.3-0.26-0.44c-0.1-0.15-0.2-0.3-0.32-0.44c-0.24-0.29-0.52-0.56-0.85-0.79c-0.33-0.23-0.7-0.4-1.1-0.48
                    l-0.15-0.03l-0.15-0.02l-0.14-0.01c-0.05,0-0.11,0-0.16,0l-0.08,0l-0.09,0l-0.17,0.01c-0.12,0.01-0.23,0.01-0.35,0.01
                    c-0.47,0-0.93-0.01-1.39-0.07c-0.23-0.02-0.46-0.07-0.68-0.1c-0.22-0.05-0.44-0.09-0.65-0.16c-0.11-0.03-0.21-0.06-0.31-0.1
                    c-0.1-0.04-0.2-0.08-0.3-0.12c-0.05-0.02-0.1-0.04-0.15-0.06c-0.05-0.02-0.1-0.05-0.14-0.07c-0.09-0.04-0.19-0.09-0.28-0.13
                    c-0.18-0.09-0.35-0.2-0.51-0.29c-0.16-0.11-0.31-0.22-0.45-0.32c-0.13-0.11-0.26-0.23-0.38-0.33c-0.11-0.11-0.22-0.23-0.31-0.33
                    c-0.1-0.1-0.18-0.21-0.25-0.31c-0.07-0.1-0.14-0.19-0.2-0.27c-0.11-0.17-0.19-0.31-0.24-0.4
                    C141.49,552.7,141.46,552.65,141.46,552.65z"/>
                <path id="XMLID_476_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M124.01,554.23
                    c0,0,0.18,0.08,0.48,0.24c0.16,0.07,0.34,0.16,0.55,0.25c0.11,0.05,0.22,0.09,0.34,0.13c0.06,0.02,0.12,0.04,0.19,0.07
                    c0.06,0.02,0.13,0.04,0.2,0.06c0.27,0.08,0.57,0.14,0.88,0.17c0.32,0.03,0.66,0.04,1.02,0.04c0.36,0,0.74,0,1.12,0.01
                    c0.19,0.01,0.39,0.02,0.58,0.04l0.15,0.01l0.07,0.01l0.04,0l0.03,0l0.02,0l0.14,0.02l0.07,0.01l0.08,0.02
                    c0.42,0.08,0.8,0.22,1.16,0.39c0.36,0.17,0.69,0.37,1,0.58c0.31,0.21,0.59,0.43,0.85,0.65c0.26,0.22,0.49,0.44,0.7,0.64
                    c0.42,0.41,0.73,0.79,0.94,1.05c0.21,0.27,0.32,0.43,0.32,0.43s-0.15-0.13-0.4-0.35c-0.26-0.22-0.63-0.53-1.08-0.88
                    c-0.23-0.18-0.48-0.36-0.74-0.55c-0.27-0.19-0.56-0.37-0.86-0.56c-0.3-0.18-0.62-0.35-0.96-0.5c-0.17-0.07-0.34-0.14-0.51-0.19
                    c-0.09-0.03-0.17-0.06-0.26-0.08c-0.09-0.03-0.17-0.04-0.26-0.06l-0.06-0.01l-0.07-0.01l-0.14-0.02l-0.02,0l0,0
                    c-0.01,0,0.01,0,0.01,0l-0.01,0l-0.04,0l-0.07-0.01l-0.14-0.01c-0.19-0.02-0.38-0.03-0.56-0.05c-0.38-0.02-0.75-0.04-1.11-0.06
                    c-0.36-0.02-0.73-0.06-1.07-0.12c-0.17-0.03-0.33-0.07-0.49-0.12c-0.15-0.06-0.3-0.1-0.44-0.17c-0.07-0.03-0.14-0.06-0.2-0.09
                    c-0.06-0.03-0.13-0.07-0.19-0.1c-0.12-0.06-0.23-0.13-0.33-0.19c-0.21-0.12-0.37-0.25-0.51-0.35c-0.14-0.1-0.24-0.19-0.31-0.25
                    C124.04,554.26,124.01,554.23,124.01,554.23z"/>
                <path id="XMLID_475_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M129.82,462.87
                    c0,0,0.03,0.1,0.08,0.27c0.03,0.09,0.05,0.19,0.07,0.31c0.01,0.06,0.02,0.13,0.04,0.2c0.01,0.07,0.02,0.14,0.03,0.22
                    c0.02,0.15,0.03,0.32,0.03,0.5c0.01,0.18,0,0.37-0.01,0.56c-0.02,0.19-0.03,0.39-0.08,0.59c-0.03,0.2-0.09,0.4-0.14,0.6
                    c-0.07,0.2-0.12,0.4-0.2,0.58c-0.07,0.19-0.17,0.37-0.25,0.55c-0.09,0.17-0.18,0.34-0.28,0.48c-0.1,0.15-0.19,0.29-0.29,0.41
                    c-0.05,0.06-0.09,0.12-0.14,0.17c-0.05,0.05-0.09,0.1-0.14,0.15c-0.08,0.09-0.16,0.17-0.23,0.23c-0.13,0.12-0.21,0.19-0.21,0.19
                    s0.05-0.09,0.15-0.23c0.05-0.07,0.11-0.16,0.17-0.27c0.07-0.1,0.14-0.22,0.21-0.35c0.08-0.13,0.15-0.27,0.23-0.42
                    c0.08-0.15,0.15-0.32,0.23-0.48c0.07-0.17,0.14-0.35,0.21-0.53c0.07-0.18,0.12-0.37,0.18-0.56c0.05-0.19,0.11-0.38,0.14-0.57
                    c0.04-0.19,0.07-0.38,0.11-0.56c0.02-0.18,0.05-0.36,0.06-0.53c0.01-0.17,0.03-0.33,0.03-0.48c0.01-0.15,0.01-0.29,0.01-0.41
                    c0-0.12,0-0.23,0-0.32C129.82,462.97,129.82,462.87,129.82,462.87z"/>
                <path id="XMLID_474_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M134.46,463.23
                    c0,0,0.01,0.11,0.03,0.29c0.02,0.19,0.04,0.45,0.07,0.77c0.02,0.16,0.03,0.33,0.05,0.51c0.02,0.18,0.05,0.37,0.07,0.57
                    c0.05,0.39,0.11,0.81,0.18,1.23c0.04,0.21,0.07,0.42,0.11,0.62c0.04,0.2,0.08,0.4,0.13,0.6c0.09,0.39,0.18,0.75,0.26,1.05
                    c0.08,0.31,0.15,0.57,0.21,0.75c0.05,0.18,0.08,0.29,0.08,0.29s-0.06-0.09-0.15-0.26c-0.05-0.08-0.1-0.18-0.16-0.3
                    c-0.06-0.12-0.12-0.25-0.18-0.4c-0.07-0.15-0.13-0.31-0.19-0.49c-0.06-0.17-0.13-0.36-0.18-0.55c-0.05-0.2-0.12-0.4-0.16-0.6
                    c-0.05-0.21-0.09-0.42-0.13-0.63c-0.03-0.21-0.07-0.43-0.09-0.64c-0.03-0.21-0.04-0.42-0.05-0.62c-0.02-0.4-0.02-0.78,0-1.11
                    c0.01-0.16,0.02-0.31,0.03-0.44c0.01-0.13,0.03-0.24,0.04-0.34C134.44,463.34,134.46,463.23,134.46,463.23z"/>
            </g>
            <rect x="194.49" y="386.89" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" width="191.98" height="191.98"/>
            <path id="XMLID_472_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M303.79,573.48
                c-0.37-2-30.97-38.89-32.5-52.53c-1.53-13.64-2.95-24.71-3.52-29.16c-0.57-4.45-0.99-5.67-6.91-8.91
                c-5.92-3.24-9.14-17.73-7.35-22.76c1.8-5.02-0.97-14.68,1.8-18.26c2.77-3.58,24.07-28.39,26.97-34.1
                c2.9-5.7,11.26-10.84,14.92-10.84c3.66,0,15.23,4.04,22.42,4.59c7.19,0.55,19.71-0.59,22.58,5.97c2.87,6.56,7.14,5.96,10,11.96
                c2.85,6.01,2.24,9.43,3.69,15.42c1.45,5.99-0.84,12.94,1.45,16.74c2.28,3.8,8.94,6.5,7.46,11.28c-1.48,4.78-7.44,7.08-11.02,7.93
                c-3.58,0.84-4.45,1.74-5.7,7.93s-3.51,11.11-6.52,14.29c-3.01,3.18-4.49,15.67-5.04,18.2c-0.55,2.53-9.18,4.6-7.44,6.76
                s5.48,1.07,6.46,4.89c0.98,3.82,9.16,12.16,12.54,17.98c3.38,5.83,14.63,15.78,16.93,17.63c2.31,1.86,10.63,20.37,10.63,20.37
                h-69.68L303.79,573.48z"/>
            <g id="XMLID_193_">
                <path id="XMLID_380_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M386.47,578.88
                    c0,0-13.13-22.55-15.94-25.53c-2.8-2.98-4.2-0.35-7.18-1.4c-2.98-1.05-7.71-10.33-12.08-15.24c-4.38-4.9-13.45-9.87-12.16-10.34
                    c1.28-0.47,8.45-5.02,8.45-5.02s-0.63-0.58-2.5-2.22c-1.87-1.63-10.16-2.45-10.97-2.57c-0.82-0.12,0.23-1.63,1.87-2.8
                    c1.63-1.17,4.44-10.39,5.72-14.94c1.28-4.55,4.79-10.04,5.14-13.89c0.35-3.85,0-9.57,0-11.68s0.35-3.15,0.7-4.09
                    c0.35-0.93-1.28-1.52-3.5-2.69c-2.22-1.17-9.46-0.58-10.27,0c-0.82,0.58-2.8,1.52-4.9,1.52s-2.13,0.81-3.04,3.15
                    c-0.35,0.9-1.4,0.16-2.02,0.47c-0.62,0.31,0.7,1.09,1.63,1.87c0.93,0.78,0,8.87,0.16,10.35c0.16,1.48,1.01,1.01,1.63,2.02
                    c0.62,1.01,0.23,4.75-0.62,5.37c-0.86,0.62-2.1,1.63-2.72,2.26c-0.62,0.62-2.1,1.01-2.57,1.71c-0.47,0.7,4.75,2.02,5.76,2.49
                    c1.01,0.47,4.05-0.39,4.9-1.71c0.86-1.32,0.08-2.88-0.62-3.97c-0.7-1.09-1.63-3.97-1.71-5.76c-0.08-1.79-1.32-2.26-1.63-3.42
                    c-0.31-1.17,0.54-1.25,1.63-0.95c1.09,0.29-0.16-2.86,0-3.87c0.16-1.01-0.16-1.17,1.32-0.86c1.48,0.31,2.26-0.16,1.71-0.16
                    s-3.19-1.71-2.41-2.65c0.78-0.93,2.1-1.01,2.1-1.01s0.08,2.88,3.04,2.88s2.65-2.1,2.96-2.26c0.31-0.16,1.17,0.62,0.86,1.63
                    c-0.31,1.01-3.19,1.87-3.19,1.87s1.25,0.31,2.18-0.16c0.93-0.47,1.4-0.23,0.62,0.7c-0.78,0.93-0.47,1.17-0.31,1.87
                    c0.16,0.7-0.86,1.95-0.86,2.72c0,0.78,2.18-0.78,2.72-1.09c0.54-0.31,1.17-2.26,1.87-3.66s1.17-0.85,1.35,2.48s0,3.68-2.8,7.88
                    c-2.8,4.2-4.2,9.46-4.9,11.73c-0.7,2.28-1.4,7.71-1.49,11.21s-4.81,3.33-7.44,3.5c-2.63,0.18-2.63,2.1-2.1,4.2
                    c0.53,2.1,0,1.75-2.28,2.63c-2.28,0.88-2.8-2.28-3.33-3.15c-0.53-0.88-1.26,1-2.8,2.63c-0.76,0.81-3.06,2.33-6.04,1.39
                    c-2.98-0.94-6.39-3.84-8.67-6.12c-2.28-2.28-5.78-1.05-7.53-1.93c-1.75-0.88-12.08-12.26-11.91-11.38
                    c0.18,0.88,10.68,12.08,11.56,13.48s7.88,4.55,9.28,4.55s4.73,2.63,7,3.68s4.38,2.28,5.78,4.2c1.4,1.93-3.15,2.98-3.15,2.98
                    s5.25,0.35,7,0.18c1.75-0.18,3.5-3.33,4.38-4.03s6.3-3.5,7.71-4.38c1.4-0.88,2.8,1.23,1.05,1.93c-1.75,0.7-1.93,2.98,0.18,4.03
                    c2.1,1.05,6.13,3.5,7.71,7c1.58,3.5-2.1,2.28,0,5.43c2.1,3.15,8.06,2.28,10.68,4.73c2.63,2.45,4.55,9.46,6.48,13.48
                    c1.93,4.03,6.13,4.2,7.71,8.93c1.58,4.73,0.7,9.77,0.7,9.77H386.47z M333.66,473.72c-0.31,0-0.56-0.25-0.56-0.56
                    c0-0.31,0.25-0.56,0.56-0.56s0.56,0.25,0.56,0.56C334.23,473.47,333.97,473.72,333.66,473.72z M342.77,471.7
                    c-0.31,0.7-0.93,1.71-1.71,1.4c-0.78-0.31-3.82-1.61-5.92-1.79c-1.56-0.13-2-1.09-0.77-1.25c1.23-0.16,4.19-0.23,4.74-0.54
                    c0.54-0.31,2.02-0.54,3.27,0s1.25,0.54,1.25,0.54S343.08,471,342.77,471.7z"/>
                <path id="XMLID_377_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M316.93,466.72
                    c-3.15,0-3.04-0.82-7.01-2.8c-3.97-1.98-4.79-0.58-6.3,0c-1.52,0.58-1.87,0.35-4.79,0.93c-2.92,0.58-6.3,2.45-5.95,3.04
                    c0.35,0.58,2.08,1.79,3.04,0.82c2.49-2.53,6.07-2.76,7.55-2.45c1.48,0.31-2.72,1.71-2.1,1.87s2.96-1.09,4.11-1.4
                    c1.15-0.31,5.39,2.02,2.2,1.87s-5.68,0.62-6.85,1.01c-1.17,0.39-1.87,0.62-3.35,0.08c-1.48-0.54-1.32,0.47-1.01,0.94
                    c0.31,0.47,1.87,0.39,1.01,1.13c-0.86,0.74-1.48-0.27-2.33,0.35s1.79,0.62,1.71,1.07c-0.08,0.45,1.09,0.49,2.02,0
                    c0.93-0.49,2.33-0.91,2.33-0.91c1.87,2.65,3.58,2.65,3.58,2.65c-1.25-0.47-2.26-2.33-2.18-3.15s1.73-0.56,1.73-0.56
                    s0.53,2.94,3.41,2.94c2.88,0,3.27-2.94,3.27-2.94s1.48,0.99,1.17,1.98s-3.5,1.58-3.5,1.58s0.78,0.39,0.86,1.17s-3.46,0.86-3.5,1.56
                    c-0.04,0.7,4.36,1.28,5.41,1.28c1.05,0,3.39-2.69,3.62-3.74c0.23-1.05,0-2.45,0.82-2.41c0.82,0.04,2.1-0.51,3.04-1.42
                    c0.93-0.91,2.69-3.99,2.69-5.37C321.6,464.44,320.08,466.72,316.93,466.72z M306.03,471.74c-0.31,0-0.56-0.25-0.56-0.56
                    s0.25-0.56,0.56-0.56c0.31,0,0.56,0.25,0.56,0.56S306.34,471.74,306.03,471.74z"/>
                <path id="XMLID_376_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M311.44,490.77
                    c1.17,0.7,1.75,0.93,2.33,0.7s0,0.12,0.58-0.23c0.58-0.35,2.45-0.64,3.74,0.32c1.28,0.96,3.15,2.01,2.22,2.36
                    c-0.93,0.35-1.87,0.23-2.92,0c-1.05-0.23-2.33,0.58-4.32-0.7C311.09,491.94,311.44,490.77,311.44,490.77z"/>
                <path id="XMLID_375_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M313.03,485.63
                    c0,0-4.4,3.5-6.44,4.75c-2.04,1.25,1.32,0.47,3.22-0.31S313.03,485.63,313.03,485.63z"/>
                <path id="XMLID_374_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M331.71,494.74
                    c0,0,1.32,0.99,2.51,3.74c1.2,2.75,1.3,6.54,1.3,6.54s0.12-1.01-1.3-1.48c-1.42-0.47-0.8-0.93-0.56-2.18
                    C333.89,500.11,332.18,494.74,331.71,494.74z"/>
                <path id="XMLID_373_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M320.12,496.53
                    c-0.5,1.16-0.08,1.48,0,2.26c0.08,0.78,0.12,1.79,0.45,1.32c0.33-0.47,0.18-1.25,0.49-2.1
                    C321.36,497.15,320.51,495.61,320.12,496.53z"/>
                <path id="XMLID_372_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M304.34,504.7
                    c-1.23-0.16-1.62-1.32,0-2.49c1.62-1.17,2.14-2.88,2.89-2.34s1.79,2.46,4.19,2.36c2.4-0.1,4.34-1.82,6.83-1.58
                    c2.49,0.23,2.65,1.87,4.44,1.01c1.79-0.86,2.65,0.47,4.13,0.93c1.48,0.47,4.59-0.54,4.36,0.31c-0.23,0.86-1.32,0.78-1.63,1.25
                    c-0.31,0.47,1.5,1.79,1.65,3.81s0.77,2.18-0.4,2.18s-1.87-0.54-2.33,0.39c-0.47,0.93-1.71,1.87-1.71,1.87s-0.62-3.19-1.71-3.04
                    s-3.11-0.16-3.42-0.47c-0.31-0.31-2.84-0.08-4.14,0.23c-1.31,0.31-6.29-0.93-4.89-1.48c1.4-0.54,2.02-0.96,2.49-1.57
                    c0.47-0.61,0.54-2.56,1.4-1.47c0.86,1.09,3.04,0.54,5.14,0.78c2.1,0.23,3.81-0.39,4.05-1.01c0.23-0.62-0.16-1.32-0.16-1.32
                    s-18.98-0.7-19.53,0S305.52,504.86,304.34,504.7z"/>
                <path id="XMLID_371_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M311.44,478.74
                    c0,0-0.03,0.03-0.08,0.09c-0.03,0.03-0.06,0.07-0.11,0.11c-0.04,0.04-0.09,0.09-0.15,0.14c-0.06,0.05-0.12,0.1-0.19,0.16
                    c-0.07,0.06-0.15,0.11-0.24,0.17c-0.09,0.06-0.18,0.12-0.28,0.18c-0.1,0.06-0.21,0.13-0.32,0.19c-0.12,0.06-0.24,0.12-0.36,0.19
                    c-0.13,0.06-0.26,0.11-0.4,0.18c-0.07,0.03-0.14,0.06-0.21,0.09c-0.07,0.03-0.15,0.05-0.22,0.08c-0.15,0.05-0.3,0.1-0.46,0.16
                    c-0.16,0.04-0.32,0.09-0.48,0.13c-0.08,0.02-0.16,0.04-0.25,0.07c-0.08,0.02-0.17,0.03-0.25,0.05c-0.34,0.06-0.68,0.14-1.02,0.17
                    c-0.17,0.02-0.35,0.04-0.52,0.06c-0.17,0.02-0.34,0.02-0.51,0.03c-0.34,0.01-0.68,0.03-1,0.01c-0.32-0.01-0.64-0.02-0.93-0.05
                    c-0.3-0.03-0.57-0.07-0.83-0.1c-0.13-0.02-0.25-0.03-0.37-0.05c-0.12-0.02-0.23-0.05-0.33-0.07c-0.21-0.04-0.38-0.08-0.53-0.11
                    c-0.29-0.07-0.46-0.11-0.46-0.11s0.17-0.01,0.47-0.01c0.15,0,0.33,0,0.54,0.01c0.1,0,0.21,0,0.33,0.01c0.12,0,0.24,0,0.37,0
                    c0.25,0,0.53-0.01,0.82,0c0.29,0,0.6-0.02,0.91-0.03c0.16-0.01,0.32,0,0.48-0.02c0.16-0.01,0.33-0.03,0.49-0.04
                    c0.16-0.01,0.33-0.02,0.5-0.04c0.17-0.02,0.33-0.04,0.5-0.06c0.33-0.03,0.66-0.09,0.99-0.14c0.16-0.02,0.32-0.06,0.48-0.09
                    c0.16-0.03,0.31-0.07,0.47-0.1c0.15-0.04,0.3-0.08,0.45-0.12c0.07-0.02,0.15-0.04,0.22-0.06c0.07-0.02,0.14-0.04,0.21-0.06
                    c0.14-0.04,0.27-0.08,0.4-0.12c0.13-0.05,0.25-0.09,0.37-0.13c0.12-0.04,0.23-0.09,0.34-0.13c0.11-0.04,0.21-0.08,0.3-0.13
                    c0.09-0.04,0.18-0.09,0.26-0.12c0.08-0.04,0.15-0.08,0.22-0.11c0.13-0.07,0.23-0.13,0.3-0.17
                    C311.4,478.77,311.44,478.74,311.44,478.74z"/>
                <path id="XMLID_370_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M329.07,480.73
                    c0,0,0.08,0.03,0.22,0.11c0.07,0.04,0.15,0.08,0.24,0.14c0.09,0.06,0.19,0.12,0.3,0.2c0.21,0.16,0.45,0.35,0.69,0.58
                    c0.06,0.06,0.12,0.12,0.18,0.18c0.06,0.06,0.12,0.12,0.18,0.19c0.12,0.13,0.23,0.26,0.34,0.4l0.16,0.21
                    c0.05,0.07,0.1,0.14,0.15,0.21c0.1,0.14,0.19,0.29,0.27,0.43c0.09,0.14,0.16,0.28,0.23,0.42c0.03,0.07,0.07,0.13,0.1,0.2
                    c0.03,0.07,0.05,0.13,0.08,0.19c0.05,0.12,0.09,0.24,0.13,0.34c0.03,0.1,0.05,0.2,0.07,0.27c0.04,0.15,0.05,0.24,0.05,0.24
                    s-0.06-0.07-0.16-0.19c-0.09-0.12-0.23-0.29-0.39-0.49c-0.08-0.1-0.16-0.21-0.25-0.33c-0.09-0.12-0.18-0.24-0.28-0.36
                    c-0.05-0.06-0.1-0.13-0.15-0.19c-0.05-0.06-0.1-0.13-0.15-0.19c-0.1-0.13-0.21-0.27-0.31-0.4c-0.1-0.13-0.21-0.26-0.31-0.4
                    c-0.1-0.13-0.21-0.26-0.31-0.38c-0.2-0.25-0.38-0.48-0.54-0.69c-0.16-0.21-0.29-0.38-0.38-0.5c-0.05-0.06-0.08-0.11-0.11-0.14
                    C329.08,480.75,329.07,480.73,329.07,480.73z"/>
                <path id="XMLID_369_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M334.37,488.33
                    c0,0,0.02,0.03,0.06,0.08c0.04,0.05,0.1,0.14,0.16,0.25c0.06,0.11,0.14,0.25,0.22,0.41c0.08,0.16,0.16,0.34,0.25,0.54
                    c0.17,0.4,0.35,0.87,0.54,1.37c0.09,0.25,0.19,0.51,0.29,0.77c0.1,0.26,0.2,0.53,0.31,0.79c0.1,0.26,0.21,0.52,0.33,0.77
                    c0.11,0.25,0.23,0.5,0.35,0.73c0.12,0.23,0.24,0.45,0.37,0.65c0.13,0.2,0.26,0.39,0.38,0.56c0.07,0.08,0.13,0.16,0.19,0.23
                    c0.06,0.07,0.13,0.14,0.18,0.2c0.12,0.12,0.23,0.23,0.33,0.3c0.09,0.08,0.17,0.14,0.23,0.18c0.05,0.04,0.08,0.06,0.08,0.06
                    s-0.03-0.01-0.1-0.03c-0.06-0.02-0.16-0.05-0.28-0.11c-0.06-0.03-0.12-0.06-0.19-0.1c-0.07-0.04-0.14-0.08-0.22-0.13
                    c-0.07-0.05-0.15-0.11-0.23-0.17c-0.08-0.06-0.16-0.13-0.24-0.21c-0.16-0.15-0.33-0.33-0.49-0.53c-0.16-0.2-0.32-0.42-0.46-0.65
                    c-0.14-0.24-0.29-0.49-0.41-0.75c-0.13-0.26-0.25-0.53-0.36-0.8c-0.22-0.54-0.4-1.1-0.55-1.62c-0.08-0.26-0.14-0.51-0.2-0.75
                    c-0.06-0.24-0.11-0.47-0.17-0.68c-0.05-0.21-0.1-0.4-0.14-0.57c-0.04-0.17-0.08-0.32-0.12-0.44c-0.04-0.12-0.06-0.22-0.09-0.28
                    C334.38,488.37,334.37,488.33,334.37,488.33z"/>
                <path id="XMLID_368_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M330.76,499.41
                    c0,0,0.04,0.05,0.1,0.13c0.03,0.04,0.07,0.09,0.1,0.15c0.04,0.06,0.08,0.13,0.12,0.2c0.09,0.15,0.18,0.33,0.26,0.53
                    c0.02,0.05,0.04,0.1,0.06,0.16c0.02,0.05,0.04,0.11,0.05,0.16c0.03,0.11,0.06,0.23,0.08,0.35c0.02,0.12,0.03,0.24,0.03,0.36
                    c0,0.06,0,0.12,0,0.18c0,0.06,0,0.11-0.01,0.17c-0.01,0.06-0.01,0.11-0.02,0.16c-0.01,0.05-0.02,0.1-0.03,0.15
                    c-0.02,0.1-0.05,0.19-0.08,0.27c-0.03,0.08-0.06,0.16-0.09,0.22c-0.03,0.07-0.06,0.12-0.08,0.17c-0.05,0.09-0.08,0.14-0.08,0.14
                    s0-0.06,0.01-0.16c0-0.05,0.01-0.11,0.02-0.18c0-0.07,0.02-0.15,0.02-0.23c0-0.08,0.01-0.17,0.01-0.27c0-0.05,0-0.1,0.01-0.14
                    c0-0.05,0-0.1,0-0.15c0-0.1-0.01-0.21-0.02-0.31c-0.01-0.11-0.02-0.21-0.04-0.32c-0.02-0.11-0.04-0.21-0.06-0.32
                    c-0.02-0.1-0.05-0.21-0.08-0.31c-0.06-0.2-0.11-0.39-0.15-0.55c-0.05-0.16-0.08-0.3-0.1-0.4
                    C330.77,499.47,330.76,499.41,330.76,499.41z"/>
                <path id="XMLID_367_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M277.7,492.46
                    c0.75,3.64,3,11.38,4.58,13.63s4.21,5.23,4.21,5.23s-5.6-4.04-7.53-8.41c-1.93-4.37-2.28-6.54-2.98-8.17
                    C275.27,493.11,277,489.08,277.7,492.46z"/>
                <path id="XMLID_366_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M288.93,514.47
                    c0,0,0.05,0.07,0.14,0.19c0.09,0.12,0.24,0.3,0.42,0.52c0.19,0.22,0.43,0.48,0.71,0.76c0.29,0.28,0.62,0.59,0.99,0.91
                    c0.38,0.31,0.81,0.63,1.27,0.95c0.47,0.3,0.99,0.61,1.51,0.95c0.53,0.34,1.07,0.68,1.61,1.04c0.54,0.36,1.09,0.73,1.64,1.11
                    c0.55,0.38,1.09,0.76,1.62,1.14c0.53,0.38,1.05,0.77,1.55,1.14c0.5,0.37,0.97,0.74,1.42,1.1c0.45,0.35,0.86,0.7,1.25,1.02
                    c0.19,0.16,0.37,0.32,0.54,0.47c0.17,0.15,0.33,0.3,0.48,0.43c0.3,0.27,0.55,0.52,0.76,0.73c0.05,0.05,0.1,0.1,0.15,0.15
                    c0.04,0.05,0.08,0.09,0.12,0.14c0.08,0.08,0.14,0.16,0.19,0.21c0.1,0.12,0.16,0.18,0.16,0.18s-0.06-0.05-0.18-0.15
                    c-0.12-0.1-0.29-0.25-0.52-0.43c-0.23-0.18-0.5-0.4-0.82-0.64c-0.32-0.25-0.69-0.51-1.09-0.81c-0.2-0.15-0.41-0.3-0.63-0.45
                    c-0.22-0.16-0.44-0.32-0.67-0.48c-0.46-0.33-0.95-0.67-1.46-1.03c-0.51-0.35-1.04-0.72-1.57-1.09c-0.53-0.37-1.08-0.75-1.63-1.12
                    c-0.54-0.38-1.09-0.75-1.63-1.12c-0.54-0.37-1.07-0.73-1.58-1.08c-0.51-0.35-1.01-0.68-1.48-1.02c-0.46-0.35-0.88-0.7-1.24-1.05
                    c-0.36-0.35-0.67-0.69-0.93-1c-0.26-0.31-0.47-0.6-0.63-0.84c-0.16-0.24-0.28-0.44-0.36-0.58
                    C288.97,514.55,288.93,514.47,288.93,514.47z"/>
                <path id="XMLID_365_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M286.48,533.74
                    c0,0,2.45,1.23,4.03,0s3.15-1.54,4.2,0.54c1.05,2.08,2.49,4.53,2.49,4.53s-2.46-3.25-3.53-3.02c-1.06,0.22-3.17-0.35-3.34,1.45
                    c-0.17,1.79,1.4,6.86,0.53,6.32C289.98,543.02,286.48,533.74,286.48,533.74z"/>
                <path id="XMLID_364_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M293.67,531.46
                    c2.26-0.53,4.01-2.1,5.77-2.98c1.75-0.88,4.35-2.1,6.03,0.7c0,0-2.75-1.04-2.2,0.27c0.55,1.31-0.67,2.18-1.55,2.01
                    s-1.84,1.75-2.15,2.28c-0.31,0.53-1.18-0.19-1.71-0.97c-0.53-0.78,0.07,2.02-0.66,2.37C296.46,535.49,293.67,531.46,293.67,531.46z
                    "/>
                <path id="XMLID_363_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M322.9,531.3
                    c0,0,6.94-2.2,8.81-4.74c0,0,5.31,2.98,4.79,3.84c-0.52,0.87-1.8,0.53-2.89,1.58c-1.09,1.05,1.19,1.93,0.75,4.03
                    c-0.44,2.1-0.78,1.23-1.92,0c-1.14-1.23-2.56-2.76-2.73-0.85c-0.17,1.9-1.57,5.41-3.49,6.98c-1.93,1.58-5.11,1.46-5.11,1.46
                    s2.66-2.68,1.26-2.51c-1.4,0.18-7.18,1.75-7.35,1.05c-0.18-0.7,3.58-1.4,4.85-2.45c1.28-1.05,4.4-1.05,5.9-2.1
                    c1.5-1.05,4.2-3.69,3.29-4.82c-0.91-1.13-1.26,1.01-2.49,2.39c-1.23,1.38-2.8,2.25-3.85,1.73c-1.05-0.53-1.93-2.1,0.18-3.15
                    C325.01,532.69,324.82,531.18,322.9,531.3z"/>
                <path id="XMLID_362_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M299.57,541.27
                    c0,0,0.16,0.08,0.42,0.25c0.26,0.17,0.63,0.42,1.05,0.77c0.21,0.17,0.43,0.36,0.65,0.58c0.22,0.22,0.45,0.45,0.67,0.72
                    c0.22,0.26,0.43,0.55,0.61,0.87c0.19,0.31,0.35,0.66,0.46,1.02c0.06,0.18,0.09,0.37,0.12,0.57c0.01,0.1,0.01,0.2,0.02,0.29
                    c0,0.1,0,0.18-0.01,0.27c-0.02,0.37-0.07,0.72-0.14,1.06c-0.07,0.34-0.17,0.65-0.27,0.94c-0.09,0.29-0.2,0.56-0.27,0.8
                    c-0.07,0.25-0.12,0.47-0.1,0.66c0.01,0.19,0.08,0.36,0.17,0.48c0.09,0.12,0.17,0.21,0.23,0.26c0.06,0.05,0.1,0.08,0.1,0.08
                    s-0.04-0.02-0.12-0.06c-0.07-0.04-0.18-0.1-0.3-0.21c-0.12-0.11-0.25-0.28-0.3-0.52c-0.06-0.23-0.05-0.5-0.01-0.77
                    c0.04-0.27,0.11-0.56,0.18-0.85c0.07-0.29,0.13-0.6,0.18-0.92c0.05-0.31,0.08-0.65,0.08-0.97c0-0.08,0-0.18,0-0.25
                    c-0.01-0.08-0.01-0.16-0.02-0.23c-0.02-0.16-0.05-0.31-0.1-0.47c-0.09-0.31-0.23-0.62-0.39-0.91c-0.16-0.29-0.34-0.57-0.53-0.83
                    c-0.19-0.26-0.38-0.51-0.57-0.74c-0.19-0.23-0.38-0.44-0.56-0.64c-0.36-0.39-0.67-0.7-0.89-0.92
                    C299.7,541.39,299.57,541.27,299.57,541.27z"/>
                <path id="XMLID_361_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M315.99,557.55
                    c0,0-1.84-2.1-2.96-2.28s-4.31-1.93-7.08-4.38c0,0-1.09,1.58,1.36,3.33s3.57,2.6,3.53,4.28c-0.03,1.68,3.12,1.68,3.12,1.68
                    s-4.18-2.8-2.56-3.85C313.03,555.28,315.99,557.55,315.99,557.55z"/>
                <path id="XMLID_360_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M322.55,565.78l-1.99,3.68
                    c-0.64-2.1-1.41-2.1,0.55-4.73c1.96-2.63,1.09-6.23,1.09-6.23s2.28,2.28,4.55,5.48c2.28,3.2,2.63,4.71,2.63,4.71
                    s-1.95-1.16-3.6-3.61c-1.65-2.45-2.53-1.4-2.88-0.35C322.55,565.78,323.6,565.08,322.55,565.78"/>
                <path id="XMLID_359_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M335.53,568.69
                    c0,0-1.07-5.71-2.47-8.51c-1.4-2.8-2.3-4.64-2.3-4.64s3.91,1.84,4.77,4.64c0.86,2.8,9.44,8.51,9.44,8.51s-6.69-2.83-8.43-4.71
                    C336.54,563.98,336.95,566.52,335.53,568.69z"/>
                <path id="XMLID_358_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M347.42,550.9
                    c0,0,0.08,0.03,0.24,0.09c0.15,0.06,0.38,0.15,0.66,0.28c0.28,0.14,0.62,0.3,1,0.52c0.19,0.1,0.39,0.23,0.6,0.35
                    c0.21,0.13,0.42,0.27,0.64,0.43c0.44,0.31,0.9,0.67,1.34,1.11c0.44,0.44,0.88,0.94,1.23,1.54c0.04,0.07,0.09,0.14,0.13,0.22
                    c0.04,0.07,0.08,0.15,0.13,0.22c0.04,0.07,0.08,0.15,0.13,0.22c0.04,0.07,0.09,0.14,0.13,0.2c0.09,0.14,0.18,0.27,0.28,0.41
                    c0.1,0.14,0.2,0.27,0.3,0.41c0.42,0.54,0.89,1.06,1.37,1.58c0.48,0.52,0.96,1.04,1.42,1.58c0.46,0.53,0.88,1.09,1.27,1.64
                    c0.19,0.28,0.37,0.55,0.55,0.81c0.18,0.26,0.36,0.51,0.54,0.75c0.37,0.48,0.72,0.93,1.07,1.33c0.68,0.81,1.28,1.46,1.71,1.91
                    c0.21,0.22,0.38,0.4,0.5,0.52c0.12,0.12,0.18,0.18,0.18,0.18s-0.07-0.06-0.2-0.16c-0.13-0.1-0.32-0.26-0.56-0.46
                    c-0.23-0.2-0.52-0.45-0.84-0.75c-0.16-0.15-0.32-0.31-0.5-0.48c-0.17-0.17-0.35-0.35-0.54-0.54c-0.37-0.38-0.77-0.81-1.16-1.28
                    c-0.2-0.23-0.39-0.48-0.59-0.74c-0.1-0.13-0.19-0.26-0.29-0.39c-0.09-0.13-0.19-0.27-0.29-0.4c-0.38-0.53-0.82-1.05-1.27-1.57
                    c-0.46-0.52-0.94-1.03-1.42-1.55c-0.48-0.53-0.95-1.06-1.39-1.63c-0.11-0.14-0.22-0.28-0.31-0.43c-0.1-0.15-0.2-0.29-0.29-0.45
                    c-0.05-0.08-0.09-0.15-0.14-0.23c-0.04-0.08-0.08-0.15-0.12-0.22c-0.04-0.07-0.08-0.15-0.12-0.22c-0.04-0.07-0.08-0.14-0.12-0.22
                    c-0.31-0.55-0.69-1.05-1.1-1.49c-0.1-0.11-0.21-0.21-0.31-0.32c-0.11-0.1-0.21-0.2-0.31-0.29c-0.1-0.1-0.21-0.19-0.31-0.28
                    c-0.1-0.09-0.2-0.18-0.31-0.26c-0.41-0.33-0.8-0.62-1.15-0.86c-0.35-0.24-0.67-0.44-0.94-0.6c-0.13-0.08-0.25-0.15-0.36-0.21
                    c-0.1-0.06-0.19-0.11-0.27-0.15C347.5,550.94,347.42,550.9,347.42,550.9z"/>
                <path id="XMLID_357_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M335.53,548.97
                    c0,0,0.05,0.08,0.15,0.23c0.09,0.15,0.26,0.35,0.47,0.6c0.22,0.24,0.51,0.53,0.86,0.82c0.35,0.29,0.77,0.59,1.25,0.9
                    c0.48,0.3,1.01,0.61,1.58,0.92c0.57,0.31,1.19,0.62,1.81,0.98c0.16,0.09,0.31,0.18,0.47,0.27c0.16,0.1,0.32,0.19,0.47,0.29
                    c0.31,0.2,0.63,0.41,0.93,0.63c0.61,0.45,1.21,0.95,1.76,1.5c0.54,0.56,1.04,1.17,1.44,1.83c0.21,0.33,0.38,0.67,0.53,1.02
                    c0.07,0.18,0.14,0.35,0.21,0.53c0.05,0.18,0.11,0.36,0.16,0.54c0.18,0.7,0.31,1.39,0.41,2.04c0.11,0.65,0.18,1.26,0.3,1.81
                    c0.11,0.55,0.28,1.04,0.47,1.45c0.02,0.05,0.05,0.1,0.07,0.15c0.03,0.05,0.05,0.1,0.08,0.14c0.05,0.09,0.1,0.18,0.15,0.27
                    c0.11,0.17,0.2,0.33,0.3,0.46c0.1,0.14,0.18,0.26,0.26,0.36c0.08,0.1,0.15,0.19,0.2,0.26c0.11,0.14,0.17,0.22,0.17,0.22
                    s-0.07-0.07-0.19-0.19c-0.06-0.06-0.14-0.14-0.23-0.23c-0.09-0.1-0.19-0.21-0.3-0.34c-0.11-0.13-0.22-0.28-0.35-0.44
                    c-0.06-0.08-0.12-0.17-0.18-0.26c-0.03-0.05-0.06-0.09-0.09-0.14c-0.03-0.05-0.06-0.1-0.09-0.15c-0.12-0.2-0.23-0.43-0.33-0.68
                    c-0.1-0.25-0.19-0.51-0.27-0.79c-0.16-0.56-0.27-1.17-0.4-1.81c-0.13-0.64-0.28-1.3-0.48-1.98c-0.18-0.66-0.5-1.31-0.88-1.92
                    c-0.38-0.61-0.86-1.19-1.38-1.72c-0.52-0.53-1.09-1.02-1.68-1.46c-0.59-0.44-1.19-0.85-1.8-1.21c-0.61-0.36-1.21-0.71-1.77-1.04
                    c-0.56-0.34-1.09-0.68-1.55-1.02c-0.23-0.17-0.45-0.34-0.66-0.51c-0.2-0.17-0.39-0.33-0.55-0.5c-0.34-0.32-0.6-0.64-0.8-0.91
                    c-0.2-0.28-0.34-0.5-0.41-0.66C335.57,549.06,335.53,548.97,335.53,548.97z"/>
                <path id="XMLID_356_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M331.71,565.96
                    c0,0,0.03,0.03,0.08,0.1c0.05,0.06,0.12,0.16,0.2,0.29c0.17,0.25,0.4,0.62,0.62,1.09c0.11,0.23,0.23,0.49,0.34,0.77
                    c0.11,0.28,0.22,0.58,0.31,0.89c0.1,0.31,0.18,0.64,0.25,0.98c0.07,0.34,0.12,0.68,0.16,1.03c0.03,0.35,0.06,0.7,0.05,1.04
                    c0,0.35-0.02,0.68-0.06,1.01c-0.04,0.33-0.09,0.64-0.16,0.93c-0.07,0.29-0.15,0.57-0.23,0.81c-0.04,0.12-0.08,0.24-0.13,0.35
                    c-0.05,0.11-0.09,0.21-0.13,0.31c-0.08,0.19-0.17,0.36-0.24,0.49c-0.07,0.13-0.14,0.23-0.18,0.3c-0.04,0.07-0.06,0.11-0.06,0.11
                    s0.05-0.17,0.13-0.45c0.04-0.15,0.09-0.32,0.14-0.52c0.02-0.1,0.05-0.21,0.07-0.32c0.02-0.11,0.05-0.23,0.07-0.36
                    c0.05-0.25,0.09-0.52,0.12-0.8c0.04-0.29,0.06-0.59,0.08-0.9c0-0.16,0.01-0.32,0.01-0.48c0-0.16,0-0.32-0.01-0.49
                    c-0.01-0.33-0.03-0.66-0.06-0.99c-0.04-0.33-0.08-0.66-0.13-0.99c-0.06-0.32-0.12-0.64-0.19-0.95c-0.07-0.31-0.15-0.6-0.23-0.88
                    c-0.08-0.28-0.16-0.54-0.24-0.78c-0.08-0.24-0.16-0.46-0.23-0.66c-0.07-0.19-0.13-0.36-0.19-0.5
                    C331.78,566.12,331.71,565.96,331.71,565.96z"/>
                <path id="XMLID_355_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M344.97,565.96
                    c0,0,0.14,0.13,0.36,0.36c0.11,0.12,0.26,0.25,0.41,0.42c0.16,0.16,0.34,0.34,0.54,0.54c0.2,0.2,0.41,0.41,0.62,0.65
                    c0.22,0.24,0.45,0.49,0.67,0.77c0.22,0.28,0.43,0.58,0.62,0.91c0.2,0.32,0.36,0.67,0.51,1.02c0.07,0.18,0.14,0.36,0.2,0.53
                    c0.06,0.18,0.12,0.36,0.17,0.54c0.1,0.36,0.19,0.72,0.27,1.05c0.08,0.34,0.15,0.67,0.21,0.98c0.07,0.31,0.13,0.61,0.18,0.88
                    c0.11,0.55,0.18,1.01,0.23,1.33c0.05,0.32,0.07,0.51,0.07,0.51s-0.07-0.17-0.19-0.48c-0.11-0.31-0.27-0.74-0.45-1.28
                    c-0.09-0.26-0.18-0.55-0.28-0.86c-0.09-0.31-0.2-0.63-0.29-0.96c-0.1-0.34-0.2-0.67-0.31-1.02c-0.06-0.17-0.12-0.34-0.18-0.51
                    c-0.07-0.17-0.13-0.34-0.2-0.5c-0.14-0.33-0.29-0.66-0.46-0.97c-0.17-0.31-0.36-0.6-0.55-0.89c-0.2-0.28-0.39-0.55-0.58-0.8
                    c-0.19-0.25-0.38-0.49-0.54-0.71c-0.17-0.22-0.32-0.43-0.45-0.61c-0.13-0.19-0.24-0.35-0.33-0.49
                    C345.06,566.12,344.97,565.96,344.97,565.96z"/>
                <path id="XMLID_354_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M309.07,543.56
                    c0,0,0.06,0.03,0.17,0.09c0.11,0.06,0.28,0.15,0.49,0.26c0.42,0.23,1.01,0.55,1.73,0.94c0.36,0.19,0.75,0.4,1.16,0.62
                    c0.41,0.22,0.85,0.44,1.3,0.67c0.23,0.11,0.46,0.23,0.69,0.35c0.23,0.12,0.47,0.23,0.71,0.35c0.24,0.11,0.48,0.23,0.72,0.34
                    c0.24,0.11,0.49,0.22,0.73,0.34c0.25,0.11,0.49,0.21,0.74,0.32c0.25,0.1,0.5,0.2,0.74,0.3c0.49,0.19,0.99,0.35,1.47,0.5
                    c0.48,0.15,0.95,0.28,1.4,0.41c0.45,0.12,0.88,0.24,1.27,0.36c0.39,0.12,0.75,0.25,1.06,0.4c0.31,0.14,0.55,0.32,0.73,0.48
                    c0.18,0.17,0.29,0.33,0.35,0.43c0.03,0.05,0.05,0.1,0.07,0.13c0.02,0.03,0.02,0.04,0.02,0.04s-0.04-0.06-0.12-0.15
                    c-0.08-0.1-0.21-0.23-0.4-0.36c-0.19-0.13-0.44-0.26-0.75-0.37c-0.3-0.11-0.66-0.2-1.06-0.28c-0.39-0.09-0.83-0.16-1.29-0.26
                    c-0.46-0.1-0.94-0.2-1.44-0.33c-0.5-0.13-1.01-0.28-1.52-0.47c-0.26-0.09-0.52-0.19-0.76-0.29c-0.25-0.11-0.5-0.22-0.76-0.32
                    c-0.25-0.12-0.5-0.23-0.74-0.35c-0.24-0.12-0.49-0.24-0.73-0.36c-0.48-0.25-0.94-0.5-1.39-0.75c-0.45-0.25-0.87-0.51-1.27-0.75
                    c-0.4-0.24-0.77-0.48-1.11-0.71c-0.34-0.22-0.65-0.44-0.92-0.63c-0.27-0.19-0.51-0.36-0.7-0.5
                    C309.28,543.73,309.07,543.56,309.07,543.56z"/>
                <path id="XMLID_353_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M308.02,529.88
                    c0,0,0.08,0.04,0.2,0.12c0.06,0.04,0.14,0.08,0.22,0.13c0.09,0.05,0.18,0.11,0.29,0.17c0.1,0.06,0.22,0.12,0.34,0.19
                    c0.12,0.06,0.25,0.13,0.38,0.19c0.13,0.07,0.27,0.12,0.41,0.19c0.07,0.03,0.14,0.06,0.22,0.09c0.07,0.03,0.14,0.06,0.22,0.08
                    l0.22,0.08c0.07,0.02,0.15,0.05,0.22,0.07c0.07,0.02,0.15,0.05,0.22,0.07c0.07,0.02,0.15,0.04,0.22,0.07
                    c0.14,0.05,0.28,0.08,0.41,0.12c0.13,0.04,0.26,0.08,0.38,0.11c0.12,0.03,0.22,0.07,0.32,0.1c0.1,0.03,0.18,0.05,0.25,0.07
                    c0.14,0.04,0.22,0.08,0.22,0.08s-0.08,0.02-0.23,0.04c-0.07,0.01-0.16,0.02-0.26,0.03c-0.1,0.01-0.22,0.01-0.34,0.01
                    c-0.06,0-0.13,0-0.2,0c-0.07,0-0.14-0.01-0.21-0.01c-0.14-0.01-0.3-0.02-0.45-0.05c-0.16-0.03-0.32-0.05-0.48-0.1
                    c-0.08-0.02-0.16-0.04-0.24-0.07c-0.08-0.03-0.16-0.06-0.24-0.09c-0.16-0.06-0.32-0.13-0.47-0.21c-0.15-0.08-0.29-0.16-0.43-0.25
                    c-0.14-0.09-0.26-0.18-0.37-0.28c-0.11-0.1-0.21-0.19-0.3-0.28c-0.09-0.09-0.16-0.18-0.23-0.26c-0.06-0.08-0.11-0.16-0.15-0.22
                    c-0.04-0.06-0.07-0.11-0.09-0.15C308.03,529.9,308.02,529.88,308.02,529.88z"/>
                <g id="XMLID_203_">
                    <path id="XMLID_352_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M248.28,492.46
                        c0,0-0.45-0.22-1.04-0.66C247.75,492.7,248.28,492.46,248.28,492.46z"/>
                    <path id="XMLID_204_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M315.99,578.88
                        c0,0-25.22-38.14-35.96-56.23c-10.74-18.1-8.64-20.2-7.71-26.4c0.93-6.2,5.6-7.91,5.6-7.91l1.72-1.88c0,0-1.72,1.88-7.18,1.88
                        c-5.46,0-4.03-8.36-4.03-8.36s0,0,0.7-1.23c0.7-1.23,0.53,0.71,0.88,1.99c0.35,1.28,2.1-0.58,1.93-3.03s2.8-2.1,2.8-2.1
                        s-1.05,4.03,0.35,6.28c1.4,2.25,2.45,1.78,4.9,1.01c2.45-0.76,2.45-3.61,0.7-5.37c-1.75-1.75-2.63-3.5-1.58-4.93
                        c1.05-1.43,2.63,0.73,4.73-1.42c2.1-2.14,4.38-6.61,4.2-8.29c-0.18-1.68-3.5,0.8-3.5,0.8c3.85-3.68,4.38-9.28,4.38-9.28
                        c-2.45,8.06-7.53,8.48-7.53,8.48c2.28-1.5,2.45-3.93,3.15-5.85s4.9-7.88,4.03-8.93c-0.88-1.05-6.13,9.81-7.53,9.46
                        c-1.4-0.35,3.68-5.6,3.15-7c-0.53-1.4-3.85,5.95-5.95,5.95s1.93-1.4,1.4-2.8c-0.53-1.4-2.1-1.23,0.35-2.28
                        c2.45-1.05,5.25-5.6,7.18-9.35c1.93-3.74,9.17-4.58,8.41-7.64c-0.7-2.8,5.43,0.53,9.88,0.35s5.01-2.98,8.68-5.08
                        c3.68-2.1,6.42,1.4,6.42,1.4c-3.57,1.23-7.47,3.5-13.34,8.06c-5.87,4.55-9.89,0-9.89,0s-0.7,2.45,3.59,2.91
                        c4.29,0.46,10.94-3.79,12.1-5.71c1.16-1.93,10.04-4.55,10.57-4.38c0.53,0.18-0.53,2.8-0.53,2.8c1.23-0.35,2.8-2.28,2.8-2.28
                        c2.28,3.85-2.1,5.78-2.1,5.78c5.78-1.75,2.8-5.96,5.29-7.54s1.02,0.88,5.16,8.24c4.14,7.35,7.68,7.17,7.68,7.17
                        c-5.14-2.33-7.54-10.51-7.54-10.51s2.17,0.47,5.67,1.4c3.5,0.93,4.44,6.3,5.37,8.87c0.93,2.57-1.87,5.6-1.79,8.87
                        c0.08,3.27-3.81,8.08-3.81,8.08c2.8,0.61,5.37-2.71,5.37-2.71c0.93,1.87-1.17,6.3-1.17,6.3s1.68,0.85,1.87,2.71
                        s-0.7,8.73-1.17,13.7c-0.47,4.96-3.97,11.85-3.97,11.85c2.81,0.99,7.01-4.2,7.47-3.7c0.47,0.5,1.4,5.21,1.4,5.21
                        c3.5-2.7,4.67-14.37,4.67-14.37c1.87,1.62-0.7,12.27-0.7,12.27c10.27-5.03,16.11-21.55,16.11-21.55
                        c1.44,6.39-3.74,12.45-3.74,12.45s6.54-5.02,5.84-11.32c-0.7-6.3-7.01-10.83-7.01-10.83c5.84,2.24,8.64-3.88,8.64-3.88
                        c-3.74,3.04-7.24-1.17-7.24-1.17c7.47-2.33,5.14-9.81,5.14-9.81s-0.93,4.44-6.54,4.2c-5.6-0.23-3.97-12.14-3.97-12.14
                        c1.87,1.63,3.5,8.64,3.5,8.64c1.63-4.44-1.63-10.27-1.63-10.27c2.1,1.17,3.74,7.01,3.74,7.01c0.93-7.24-5.45-12.14-5.45-12.14
                        c5.29-7.94-0.38-14.24-0.38-14.24c2.1,8.64-5.14,9.57-5.14,9.57c3.27-3.27,3.27-4.9,1.4-10.27c-1.87-5.37-8.17-4.67-8.64-7.24
                        c-0.47-2.57,3.5-5.37,2.34-7.01c-1.17-1.63-4.9,1.17-4.9,1.17c-7.01-12.14-19.15-9.81-19.15-9.81c6.01-0.23,4.58,3.04,4.58,3.04
                        c-1.72-2.1-9.49-1.17-9.49-1.17s-2.57-4.9-7.24-5.4c-4.67-0.49-7.13,4.23-7.13,4.23s-1.51-3.74-6.29-4.2s-8.08,5.6-8.08,5.6
                        c-2.83-3.97-8.38-3.04-14.92,0c-6.54,3.04-9.81,11.21-11.91,15.18s-2.1,4.2-7.01,7.71c-4.9,3.5-8.87,12.14-8.87,12.14
                        s-1.4,0.47-3.04,5.84c-1.63,5.37,3.74,8.17,3.74,8.17c-11.44,3.97-7.24,9.34-7.24,9.34s1.87-2.34,3.74-1.87
                        c1.87,0.47-0.47,5.37-0.47,5.37c-2.34-3.5-4.44,0-4.44,0c2.34,0.7,1.17,5.37-0.47,6.33c-1.63,0.96,0.47,6.78,0.47,6.78
                        c-2.34-0.43-3.27-4.94-3.27-4.94s-2.34,5.14,0.23,7.47c2.57,2.33,8.87,3.62,8.87,3.62s-2.57,1.28-3.74,6.3
                        c-0.82,3.51,1.33,5.73,2.7,6.75c-0.33-0.57-0.65-1.61-0.83-3.46c-0.47-4.77,2.1-5.44,2.1-5.44s0,2.51,1.17,8.14
                        c1.17,5.64,7.24,7.2,7.24,7.2c0.47-3.04,6.77-3.5,6.77-3.5s-4.44,5.6-5.37,10.27c-0.93,4.67-2.57,6.54-7.24,8.87
                        c-4.67,2.33-3.04,7.94-7.24,10.97c-4.2,3.04-10.51,4.9-18.45,8.74c-7.94,3.84-11.48,9.23-11.48,9.23s-16.3,14.87-16.3,36.04
                        L315.99,578.88z M352.5,448.28c0,0-5.6-9-4.2-9s1.4,0,1.4,0s3.68,3.7,4.03,5.3C354.07,446.18,352.5,448.28,352.5,448.28z
                        M260.04,444.6c1.05-0.35,2.66,3.33,5.1,3.68c0,0-6.01-3.85-2.66-3.15c3.35,0.7,6.67,0.54,8.07,1.93c1.4,1.39,0.7,2.97-0.88,2.8
                        c-1.58-0.18-3.15,0.03-4.12,0.01s-2.01-0.89-2.01-0.89C261.79,447.58,258.99,444.95,260.04,444.6z M269.67,473.72
                        c-2.28,1.17-3.64,4.68-4.54,3.63s0.68-1.4-0.89-1.05c-1.58,0.35-2.45,4.08-2.98,3.44c-0.53-0.64,0.15-3.96,2.27-5.02
                        c0,0-0.34-1.4-2.27-0.18c-1.6,1.02,1.93-2.33,4.29-1.95S271.94,472.55,269.67,473.72z M269.67,471.18
                        c-0.35-1.36,2.1-2.26-2.45-1.98s-8.93,1.02-9.98-3.38c-1.05-4.4,2.98-6.5,3.33-7.55c0.35-1.05,0.07-2.8-0.75-2.63
                        c-0.82,0.18,4.96-3.15,2.16-2.45c-2.8,0.7-6.65,1.93-6.65,1.93c1.23-1.23,3.5-3.5,6.3-3.5s5.64,0.7,3.52,1.75
                        c-2.12,1.05-3.35,1.05-2.29,1.75c1.05,0.7,4.38-1.4,4.2,0c-0.18,1.4-3.33,2.45-4.2,4.03c-0.88,1.58-0.66,5.47,2.29,6.67
                        c2.96,1.21,4.64,1.18,6.16,3.38S270.02,472.53,269.67,471.18z M282.27,464.57c3.33-2.03,0.7,0,0.7,0s-3.97,3.5-7.07,3.68
                        c-3.09,0.18-4.16-2.81-4.16-2.81s1.65,2.28,4.16,1.58c0,0-2.05-1.98-0.1-1.58C277.55,465.79,278.94,466.59,282.27,464.57z
                        M280.53,458.52c0,0-0.75,2.91-2.47,3.16c0,0,2.98,0.25,3.43-1.15c0,0-0.41,3.83-5.67,2.35c-5.26-1.48-5.47-4.45-5.47-4.45
                        s0.38,1.12,2.48,1.7l-1.05-3.02c-0.02-0.05-0.03-0.08-0.03-0.08l0.03,0.08c0.17,0.47,1.2,2.9,4.03,2.9
                        C278.95,460.01,280.53,458.52,280.53,458.52z M285.08,442.68c-1.58,1.23-6.98,4.55-9.27,4.38c-2.29-0.18-5.44-0.53-6.14-6.3
                        c0,0,1.23,2.63,2.28,3.15c1.05,0.53,1.95,2.8,3.86,1.75c1.91-1.05-0.71-1.23-0.71-1.23c-0.88-1.23-0.7-2.1,1.93-3.15
                        c2.63-1.05,5.25-3.15,6.83-3.85c1.58-0.7,3.5-1.23,3.85,0S286.65,441.45,285.08,442.68z M300.93,426.04
                        c-0.79,0.88-1.03,1.4-2.4,1.4c-1.37,0-1.37-0.53-3.3-0.7c-1.93-0.18-4.14-1.93-5.22-1.05c-1.08,0.88-2.48,1.4-1.43,2.8
                        s3.85-0.86,3.68,1.15c-0.18,2.01-0.64,3.41-2.25,4.28c-1.61,0.88-4.76,1.93-6.16,2.1c-1.4,0.18-3.49-1.05-2.71-1.93
                        s2.71-3.15,2.71-3.15s-2.28,1.05-3.5,1.05s-1.05-0.86-0.7-2.36c0.35-1.5,1.93-2.72,4.2-2.9c2.28-0.18,4.87-0.18,5.43-2.45
                        c0,0-7.53,3.15-11.21-1.4c-0.47-0.58,6.48,0.7,8.41,0c1.93-0.7,6.26-1.52,6.37-1.11c0.11,0.41-1.97,3.73,0.82,2.51
                        c2.79-1.23,4.13-3.84,4.86-2.62c0.73,1.22,0.56,1,2.4,1.29S301.72,425.16,300.93,426.04z M311.44,416.06
                        c-1.66,0.35-0.09,2.1-0.02,2.42c0.07,0.32-1.99,0.21-3.22,0.98c-1.23,0.77-0.35,1.16-0.97,2.21c-0.62,1.05-4.81-5.25-8.49-6.3
                        c-3.68-1.05-4.9,2.63-8.58,4.09c-3.68,1.47-6.3-1.29-6.3-1.29c0.88,0.7,3.85-0.53,3.85-0.53s-1.4-1.93-1.23-2.28
                        c0.18-0.35,2.98,1.4,2.98,1.4s0.18-1.4,1.58-1.93c1.4-0.53,2.64-1.75,2.64-1.75c-1.39-0.53-3.87-6.48-1.76-3.94
                        c2.1,2.54,3.85,0.13,3.85,0.13s0.18-1.44-2.09-3.89c-2.26-2.45-0.82-5.43-0.82-5.43s0.63,2.98,3.26,4.9
                        c2.63,1.93,4.82,4.29,4.82,4.29c1.11-6.66,6.29-9.19,6.29-9.19s-3.06,3.5-4.11,8.41c-1.05,4.9,4.9,5.43,6.38,5.43
                        s-0.08-1.05,0.8-1.75s2.28,1.4,2.74,2.1S313.1,415.71,311.44,416.06z M313.03,407.48c-1.34-3.15-1.6-5.95-1.6-5.95
                        s0.9,3.5,4.87,5.95c3.97,2.45,7.05,4.55,4.81,9.28c0,0,0.46-2.45-1.24-3.68C318.18,411.86,314.37,410.63,313.03,407.48z
                        M325.18,427.09c-2.28,2.28-2.28,2.28-2.28,2.28s0.11,0.88-1.79-0.53c0,0-1.47-1.05-1.24-4.55c0.23-3.5,0.97-4.55,2-6.65
                        c1.03-2.1,3.48-3.33,3.48-3.33s2.4-1.75,5.41-4.03c3-2.28,4.77,0,4.77,0s-6.55,3.15-7.84,7.71c0,0,2.87-1.58,3.07-0.35
                        c0.2,1.23-1.96,1.75-3.07,2.8c-1.11,1.05-3.56,3.85-4.44,4.03s-1.38-2.1-1.38-2.1s-1.06,4.55-0.28,4.9
                        c0.78,0.35,5.69-5.43,8.31-5.78c2.63-0.35-0.57,1.05,0.85,1.4c1.42,0.35,3.06-0.7,5.75,1.05c2.68,1.75,2.97,3.15,7.12,2.1
                        s6.42-5.95,6.77-4.2c0.35,1.75-3.37,9.11-11.76,5.95S327.46,424.81,325.18,427.09z M341.99,436.69c-1.93-1.19-4.87-2.42-6.46-4.69
                        c-1.59-2.28-0.39-3.3-0.39-3.3s0.51,3.47,3.51,4.7l-2.13-3.76c0,0,2.86,2.88,4.26,3.23c1.4,0.35-1.05,1.23,0.88,1.75
                        c1.93,0.53,4.38,1.33,5.95,2.07c1.58,0.74-3.97,0-3.97,0s3.14,2.88,4.25,5.4c1.12,2.52,1.82,7.79,1.82,7.79
                        S343.92,437.88,341.99,436.69z M359.64,460.13c-0.39-1.46-4.69-0.29-3.99,0s1.93,1.28,3.33,2.76c1.4,1.48,0.35,1.15-1.05,1.33
                        c-1.4,0.18-1.58-1.23-2.98-2.53c-1.4-1.3-0.35,2.18-1.18,4.13c-0.82,1.95-0.93-4.4-1.74-7.37c-0.82-2.98-0.06,6.65-1.11,6.13
                        c-1.05-0.53-0.35-3.5-0.88-2.88c-0.53,0.62-1.05,5.33-1.05,5.33s-1.05-1.93-0.93-3.79c0.13-1.86,2.15-2.87,2.35-4.09
                        c0.2-1.23-0.02-1.58,1.2-2.1c1.23-0.53-0.7-4.03-0.7-4.03c3.15-0.7,4.03,3.5,4.96,4.03c0.93,0.53,2.92,0.18,4.5,1.4
                        c1.58,1.23,3.33,3.25,3.33,3.25S360.03,461.59,359.64,460.13z"/>
                </g>
                <path id="XMLID_202_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M318.68,470.61
                    c0,0,0.04,0.02,0.1,0.05c0.06,0.03,0.15,0.08,0.24,0.14c0.09,0.06,0.2,0.13,0.3,0.2c0.05,0.04,0.1,0.07,0.16,0.11
                    c0.05,0.04,0.1,0.08,0.16,0.11c0.05,0.04,0.1,0.07,0.15,0.11c0.05,0.03,0.1,0.07,0.15,0.1c0.05,0.03,0.1,0.06,0.14,0.09
                    c0.05,0.02,0.09,0.05,0.14,0.08c0.04,0.02,0.09,0.04,0.13,0.06c0.04,0.02,0.08,0.03,0.11,0.04c0.06,0.02,0.11,0.04,0.11,0.04
                    s-0.03,0.03-0.09,0.07c-0.03,0.02-0.07,0.05-0.12,0.07c-0.05,0.02-0.11,0.03-0.17,0.05c-0.06,0.01-0.13,0.01-0.21,0.01
                    c-0.07,0-0.15-0.02-0.23-0.04c-0.04-0.01-0.08-0.02-0.11-0.04c-0.04-0.01-0.08-0.03-0.11-0.05c-0.07-0.04-0.15-0.08-0.21-0.13
                    c-0.07-0.05-0.13-0.1-0.18-0.16c-0.06-0.05-0.11-0.11-0.15-0.17c-0.05-0.06-0.08-0.12-0.12-0.18c-0.03-0.06-0.06-0.12-0.08-0.17
                    c-0.04-0.11-0.07-0.21-0.08-0.28c-0.01-0.03-0.01-0.07-0.01-0.09C318.68,470.62,318.68,470.61,318.68,470.61z"/>
                <path id="XMLID_201_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M321.6,492.29
                    c0,0,0.02,0.01,0.05,0.02c0.03,0.01,0.08,0.03,0.14,0.05c0.06,0.02,0.13,0.04,0.21,0.07c0.08,0.03,0.17,0.06,0.27,0.1
                    c0.19,0.07,0.42,0.15,0.66,0.24c0.12,0.04,0.24,0.09,0.37,0.13c0.13,0.04,0.25,0.09,0.38,0.13c0.13,0.04,0.25,0.08,0.38,0.12
                    c0.06,0.02,0.13,0.04,0.19,0.06c0.06,0.02,0.12,0.03,0.18,0.05c0.06,0.02,0.12,0.03,0.18,0.05c0.06,0.01,0.12,0.03,0.17,0.04
                    c0.11,0.03,0.22,0.06,0.32,0.08c0.1,0.02,0.19,0.04,0.28,0.06c0.08,0.01,0.16,0.03,0.22,0.04c0.12,0.03,0.2,0.05,0.2,0.05
                    s-0.07,0.03-0.19,0.07c-0.06,0.02-0.14,0.04-0.22,0.07c-0.09,0.02-0.19,0.03-0.3,0.05c-0.11,0.02-0.23,0.02-0.35,0.02
                    c-0.06,0-0.13,0-0.19,0c-0.07,0-0.13-0.01-0.2-0.02c-0.07-0.01-0.14-0.02-0.21-0.02c-0.07-0.01-0.14-0.03-0.21-0.04
                    c-0.14-0.03-0.28-0.07-0.42-0.11c-0.14-0.04-0.27-0.1-0.41-0.16c-0.07-0.03-0.13-0.06-0.19-0.09c-0.06-0.03-0.13-0.06-0.18-0.1
                    c-0.12-0.07-0.23-0.13-0.33-0.21c-0.1-0.07-0.2-0.14-0.28-0.21c-0.17-0.14-0.29-0.27-0.38-0.37
                    C321.64,492.35,321.6,492.29,321.6,492.29z"/>
                <path id="XMLID_200_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M308.02,492.29
                    c0,0-0.12,0.08-0.34,0.21c-0.11,0.07-0.24,0.15-0.39,0.24c-0.15,0.09-0.32,0.2-0.51,0.31c-0.75,0.45-1.77,1.03-2.81,1.55
                    c-0.26,0.13-0.52,0.26-0.78,0.38c-0.26,0.12-0.52,0.24-0.77,0.35c-0.25,0.11-0.5,0.21-0.73,0.3c-0.12,0.05-0.23,0.09-0.34,0.13
                    c-0.11,0.04-0.22,0.08-0.33,0.11c-0.21,0.08-0.4,0.13-0.58,0.18c-0.17,0.05-0.33,0.08-0.45,0.11c-0.13,0.03-0.23,0.04-0.3,0.05
                    c-0.07,0.01-0.11,0.01-0.11,0.01s0.03-0.02,0.1-0.05c0.06-0.03,0.15-0.07,0.27-0.12c0.11-0.06,0.25-0.13,0.41-0.21
                    c0.16-0.08,0.33-0.18,0.52-0.28c0.38-0.21,0.83-0.45,1.31-0.71c0.24-0.13,0.49-0.26,0.74-0.39c0.25-0.13,0.51-0.26,0.77-0.4
                    c0.26-0.13,0.52-0.26,0.78-0.38c0.26-0.13,0.51-0.25,0.76-0.36c0.25-0.11,0.49-0.22,0.72-0.32c0.23-0.1,0.45-0.19,0.66-0.27
                    c0.41-0.16,0.76-0.28,1.01-0.36C307.88,492.33,308.02,492.29,308.02,492.29z"/>
                <path id="XMLID_199_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M317.37,516.34
                    c0,0,0.02,0.01,0.05,0.02c0.03,0.01,0.07,0.03,0.13,0.05c0.06,0.02,0.12,0.05,0.2,0.08c0.08,0.03,0.16,0.06,0.25,0.09
                    c0.37,0.13,0.86,0.28,1.34,0.33c0.12,0.02,0.24,0.02,0.35,0.02c0.06,0,0.11,0,0.17-0.01c0.06-0.01,0.11-0.01,0.16-0.02
                    c0.11-0.02,0.21-0.04,0.3-0.07c0.1-0.03,0.18-0.07,0.27-0.11c0.08-0.04,0.15-0.09,0.22-0.13c0.03-0.02,0.06-0.05,0.09-0.07
                    c0.03-0.02,0.05-0.04,0.07-0.06c0.05-0.04,0.08-0.07,0.1-0.09c0.02-0.02,0.04-0.03,0.04-0.03s0,0.02-0.01,0.05
                    c-0.01,0.03-0.01,0.08-0.04,0.14c-0.01,0.03-0.02,0.06-0.03,0.1c-0.01,0.04-0.03,0.07-0.05,0.11c-0.04,0.08-0.1,0.16-0.17,0.25
                    c-0.08,0.08-0.17,0.17-0.28,0.24c-0.11,0.07-0.24,0.14-0.38,0.18c-0.27,0.09-0.58,0.11-0.88,0.08c-0.15-0.02-0.29-0.04-0.43-0.09
                    c-0.14-0.04-0.27-0.09-0.39-0.15c-0.12-0.06-0.24-0.12-0.34-0.19c-0.1-0.07-0.2-0.14-0.28-0.2c-0.16-0.14-0.28-0.27-0.35-0.37
                    c-0.04-0.05-0.06-0.09-0.08-0.12C317.38,516.35,317.37,516.34,317.37,516.34z"/>
                <path id="XMLID_198_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M277.86,514.47
                    c0,0,0.13,0.17,0.35,0.49c0.1,0.16,0.23,0.35,0.37,0.58c0.14,0.23,0.29,0.48,0.45,0.77c0.16,0.29,0.33,0.6,0.49,0.93
                    c0.17,0.33,0.34,0.69,0.5,1.06c0.16,0.37,0.33,0.76,0.48,1.17c0.15,0.4,0.29,0.82,0.43,1.23c0.06,0.21,0.12,0.42,0.19,0.63
                    c0.06,0.21,0.11,0.42,0.16,0.63c0.1,0.42,0.19,0.83,0.25,1.24c0.07,0.41,0.11,0.8,0.15,1.18c0.04,0.37,0.05,0.73,0.05,1.06
                    c0,0.16,0,0.32-0.01,0.47c-0.01,0.15-0.02,0.29-0.03,0.42c-0.02,0.27-0.06,0.5-0.08,0.68c-0.03,0.19-0.06,0.33-0.08,0.43
                    c-0.02,0.1-0.03,0.15-0.03,0.15s0-0.22,0-0.6c0-0.09-0.01-0.2-0.01-0.31c0-0.11,0-0.24-0.02-0.37c-0.01-0.13-0.02-0.27-0.03-0.42
                    c-0.01-0.15-0.03-0.3-0.05-0.46c-0.01-0.16-0.04-0.32-0.06-0.5c-0.02-0.17-0.05-0.35-0.08-0.53c-0.03-0.18-0.07-0.37-0.1-0.56
                    c-0.04-0.19-0.08-0.38-0.12-0.58c-0.08-0.39-0.19-0.79-0.29-1.2c-0.05-0.2-0.12-0.41-0.17-0.61c-0.06-0.2-0.12-0.41-0.19-0.61
                    c-0.13-0.41-0.26-0.81-0.4-1.21c-0.14-0.4-0.28-0.79-0.42-1.16c-0.14-0.38-0.28-0.74-0.42-1.08c-0.14-0.34-0.27-0.67-0.4-0.96
                    c-0.25-0.6-0.47-1.09-0.62-1.44C277.94,514.67,277.86,514.47,277.86,514.47z"/>
                <path id="XMLID_197_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M333.44,552.65
                    c0,0,0.16,0.17,0.42,0.47c0.07,0.07,0.15,0.14,0.24,0.23c0.09,0.08,0.17,0.17,0.28,0.26c0.11,0.09,0.22,0.18,0.34,0.27
                    c0.13,0.09,0.26,0.18,0.39,0.27c0.14,0.09,0.3,0.17,0.45,0.26c0.16,0.07,0.33,0.16,0.5,0.24c0.09,0.03,0.18,0.07,0.27,0.1
                    c0.05,0.02,0.09,0.04,0.14,0.05c0.05,0.01,0.09,0.03,0.14,0.04c0.09,0.03,0.19,0.06,0.29,0.09c0.1,0.03,0.2,0.05,0.3,0.07
                    c0.2,0.05,0.41,0.08,0.62,0.13c0.11,0.01,0.21,0.03,0.32,0.04c0.11,0.02,0.21,0.03,0.32,0.04c0.43,0.04,0.88,0.06,1.33,0.05
                    c0.11,0,0.22,0,0.34,0l0.17,0l0.08,0l0.1,0c0.06,0,0.11,0,0.18,0c0.07,0,0.13,0.01,0.19,0.01l0.18,0.03
                    c0.06,0.01,0.12,0.03,0.18,0.04c0.48,0.11,0.92,0.34,1.28,0.62c0.37,0.28,0.66,0.61,0.9,0.94c0.24,0.33,0.41,0.68,0.54,1.01
                    c0.13,0.33,0.22,0.64,0.27,0.92c0.06,0.28,0.07,0.53,0.08,0.73c0.01,0.2,0,0.36-0.01,0.47c-0.01,0.11-0.01,0.16-0.01,0.16
                    s-0.01-0.06-0.02-0.16c-0.01-0.11-0.04-0.26-0.08-0.46c-0.04-0.19-0.1-0.43-0.18-0.69c-0.09-0.26-0.2-0.54-0.36-0.84
                    c-0.08-0.15-0.16-0.3-0.26-0.44c-0.1-0.15-0.2-0.3-0.32-0.44c-0.24-0.29-0.52-0.56-0.85-0.79c-0.33-0.23-0.7-0.4-1.1-0.48
                    l-0.15-0.03l-0.15-0.02l-0.14-0.01c-0.05,0-0.11,0-0.16,0l-0.08,0l-0.09,0l-0.17,0.01c-0.12,0.01-0.23,0.01-0.35,0.01
                    c-0.47,0-0.93-0.01-1.39-0.07c-0.23-0.02-0.46-0.07-0.68-0.1c-0.22-0.05-0.44-0.09-0.65-0.16c-0.11-0.03-0.21-0.06-0.31-0.1
                    c-0.1-0.04-0.2-0.08-0.3-0.12c-0.05-0.02-0.1-0.04-0.15-0.06c-0.05-0.02-0.1-0.05-0.14-0.07c-0.09-0.04-0.19-0.09-0.28-0.13
                    c-0.18-0.09-0.35-0.2-0.51-0.29c-0.16-0.11-0.31-0.22-0.45-0.32c-0.13-0.11-0.26-0.23-0.38-0.33c-0.11-0.11-0.22-0.23-0.31-0.33
                    c-0.1-0.1-0.18-0.21-0.25-0.31c-0.07-0.1-0.14-0.19-0.2-0.27c-0.11-0.17-0.19-0.31-0.24-0.4
                    C333.47,552.7,333.44,552.65,333.44,552.65z"/>
                <path id="XMLID_196_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M315.99,554.23
                    c0,0,0.18,0.08,0.48,0.24c0.16,0.07,0.34,0.16,0.55,0.25c0.11,0.05,0.22,0.09,0.34,0.13c0.06,0.02,0.12,0.04,0.19,0.07
                    c0.06,0.02,0.13,0.04,0.2,0.06c0.27,0.08,0.57,0.14,0.88,0.17c0.32,0.03,0.66,0.04,1.02,0.04c0.36,0,0.74,0,1.12,0.01
                    c0.19,0.01,0.39,0.02,0.58,0.04l0.15,0.01l0.07,0.01l0.04,0l0.03,0l0.02,0l0.14,0.02l0.07,0.01l0.08,0.02
                    c0.42,0.08,0.8,0.22,1.16,0.39c0.36,0.17,0.69,0.37,1,0.58c0.31,0.21,0.59,0.43,0.85,0.65c0.26,0.22,0.49,0.44,0.7,0.64
                    c0.42,0.41,0.73,0.79,0.94,1.05c0.21,0.27,0.32,0.43,0.32,0.43s-0.15-0.13-0.4-0.35c-0.26-0.22-0.63-0.53-1.08-0.88
                    c-0.23-0.18-0.48-0.36-0.74-0.55c-0.27-0.19-0.56-0.37-0.86-0.56c-0.3-0.18-0.62-0.35-0.96-0.5c-0.17-0.07-0.34-0.14-0.51-0.19
                    c-0.09-0.03-0.17-0.06-0.26-0.08c-0.09-0.03-0.17-0.04-0.26-0.06l-0.06-0.01l-0.07-0.01l-0.14-0.02l-0.02,0l0,0
                    c-0.01,0,0.01,0,0.01,0l-0.01,0l-0.04,0l-0.07-0.01l-0.14-0.01c-0.19-0.02-0.38-0.03-0.56-0.05c-0.38-0.02-0.75-0.04-1.11-0.06
                    c-0.36-0.02-0.73-0.06-1.07-0.12c-0.17-0.03-0.33-0.07-0.49-0.12c-0.15-0.06-0.3-0.1-0.44-0.17c-0.07-0.03-0.14-0.06-0.2-0.09
                    c-0.06-0.03-0.13-0.07-0.19-0.1c-0.12-0.06-0.23-0.13-0.33-0.19c-0.21-0.12-0.37-0.25-0.51-0.35c-0.14-0.1-0.24-0.19-0.31-0.25
                    C316.03,554.26,315.99,554.23,315.99,554.23z"/>
                <path id="XMLID_195_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M321.8,462.87
                    c0,0,0.03,0.1,0.08,0.27c0.03,0.09,0.05,0.19,0.07,0.31c0.01,0.06,0.02,0.13,0.04,0.2c0.01,0.07,0.02,0.14,0.03,0.22
                    c0.02,0.15,0.03,0.32,0.03,0.5c0.01,0.18,0,0.37-0.01,0.56c-0.02,0.19-0.03,0.39-0.08,0.59c-0.03,0.2-0.09,0.4-0.14,0.6
                    c-0.07,0.2-0.12,0.4-0.2,0.58c-0.07,0.19-0.17,0.37-0.25,0.55c-0.09,0.17-0.18,0.34-0.28,0.48c-0.1,0.15-0.19,0.29-0.29,0.41
                    c-0.05,0.06-0.09,0.12-0.14,0.17c-0.05,0.05-0.09,0.1-0.14,0.15c-0.08,0.09-0.16,0.17-0.23,0.23c-0.13,0.12-0.21,0.19-0.21,0.19
                    s0.05-0.09,0.15-0.23c0.05-0.07,0.11-0.16,0.17-0.27c0.07-0.1,0.14-0.22,0.21-0.35c0.08-0.13,0.15-0.27,0.23-0.42
                    c0.08-0.15,0.15-0.32,0.23-0.48c0.07-0.17,0.14-0.35,0.21-0.53c0.07-0.18,0.12-0.37,0.18-0.56c0.05-0.19,0.11-0.38,0.14-0.57
                    c0.04-0.19,0.07-0.38,0.11-0.56c0.02-0.18,0.05-0.36,0.06-0.53c0.01-0.17,0.03-0.33,0.03-0.48c0.01-0.15,0.01-0.29,0.01-0.41
                    c0-0.12,0-0.23,0-0.32C321.8,462.97,321.8,462.87,321.8,462.87z"/>
                <path id="XMLID_194_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M326.45,463.23
                    c0,0,0.01,0.11,0.03,0.29c0.02,0.19,0.04,0.45,0.07,0.77c0.02,0.16,0.03,0.33,0.05,0.51c0.02,0.18,0.05,0.37,0.07,0.57
                    c0.05,0.39,0.11,0.81,0.18,1.23c0.04,0.21,0.07,0.42,0.11,0.62c0.04,0.2,0.08,0.4,0.13,0.6c0.09,0.39,0.18,0.75,0.26,1.05
                    c0.08,0.31,0.15,0.57,0.21,0.75c0.05,0.18,0.08,0.29,0.08,0.29s-0.06-0.09-0.15-0.26c-0.05-0.08-0.1-0.18-0.16-0.3
                    c-0.06-0.12-0.12-0.25-0.18-0.4c-0.07-0.15-0.13-0.31-0.19-0.49c-0.06-0.17-0.13-0.36-0.18-0.55c-0.05-0.2-0.12-0.4-0.16-0.6
                    c-0.05-0.21-0.09-0.42-0.13-0.63c-0.03-0.21-0.07-0.43-0.09-0.64c-0.03-0.21-0.04-0.42-0.05-0.62c-0.02-0.4-0.02-0.78,0-1.11
                    c0.01-0.16,0.02-0.31,0.03-0.44c0.01-0.13,0.03-0.24,0.04-0.34C326.43,463.34,326.45,463.23,326.45,463.23z"/>
            </g>
            <rect x="386.47" y="386.89" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" width="191.98" height="191.98"/>
            <path id="XMLID_192_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M495.78,573.48
                c-0.37-2-30.97-38.89-32.5-52.53c-1.53-13.64-2.95-24.71-3.52-29.16c-0.57-4.45-0.99-5.67-6.91-8.91
                c-5.92-3.24-9.14-17.73-7.35-22.76c1.8-5.02-0.97-14.68,1.8-18.26c2.77-3.58,24.07-28.39,26.97-34.1
                c2.9-5.7,11.26-10.84,14.92-10.84c3.66,0,15.23,4.04,22.42,4.59c7.19,0.55,19.71-0.59,22.58,5.97c2.87,6.56,7.14,5.96,10,11.96
                s2.24,9.43,3.69,15.42c1.45,5.99-0.84,12.94,1.45,16.74s8.94,6.5,7.46,11.28c-1.48,4.78-7.44,7.08-11.02,7.93
                c-3.58,0.84-4.45,1.74-5.7,7.93c-1.26,6.19-3.51,11.11-6.52,14.29c-3.01,3.18-4.49,15.67-5.04,18.2c-0.55,2.53-9.18,4.6-7.44,6.76
                s5.48,1.07,6.46,4.89c0.98,3.82,9.16,12.16,12.54,17.98c3.38,5.83,14.63,15.78,16.93,17.63c2.31,1.86,10.63,20.37,10.63,20.37
                h-69.68L495.78,573.48z"/>
            <g id="XMLID_115_">
                <path id="XMLID_188_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M578.46,578.88
                    c0,0-13.13-22.55-15.94-25.53c-2.8-2.98-4.2-0.35-7.18-1.4c-2.98-1.05-7.71-10.33-12.08-15.24c-4.38-4.9-13.45-9.87-12.16-10.34
                    c1.28-0.47,8.45-5.02,8.45-5.02s-0.63-0.58-2.5-2.22c-1.87-1.63-10.16-2.45-10.97-2.57s0.23-1.63,1.87-2.8
                    c1.63-1.17,4.44-10.39,5.72-14.94c1.28-4.55,4.79-10.04,5.14-13.89c0.35-3.85,0-9.57,0-11.68s0.35-3.15,0.7-4.09
                    c0.35-0.93-1.28-1.52-3.5-2.69c-2.22-1.17-9.46-0.58-10.27,0c-0.82,0.58-2.8,1.52-4.9,1.52c-2.1,0-2.13,0.81-3.04,3.15
                    c-0.35,0.9-1.4,0.16-2.02,0.47c-0.62,0.31,0.7,1.09,1.63,1.87c0.93,0.78,0,8.87,0.16,10.35c0.16,1.48,1.01,1.01,1.63,2.02
                    c0.62,1.01,0.23,4.75-0.62,5.37c-0.86,0.62-2.1,1.63-2.72,2.26c-0.62,0.62-2.1,1.01-2.57,1.71c-0.47,0.7,4.75,2.02,5.76,2.49
                    s4.05-0.39,4.9-1.71c0.86-1.32,0.08-2.88-0.62-3.97c-0.7-1.09-1.63-3.97-1.71-5.76c-0.08-1.79-1.32-2.26-1.63-3.42
                    c-0.31-1.17,0.54-1.25,1.63-0.95s-0.16-2.86,0-3.87c0.16-1.01-0.16-1.17,1.32-0.86c1.48,0.31,2.26-0.16,1.71-0.16
                    c-0.54,0-3.19-1.71-2.41-2.65c0.78-0.93,2.1-1.01,2.1-1.01s0.08,2.88,3.04,2.88c2.96,0,2.65-2.1,2.96-2.26
                    c0.31-0.16,1.17,0.62,0.86,1.63c-0.31,1.01-3.19,1.87-3.19,1.87s1.25,0.31,2.18-0.16c0.93-0.47,1.4-0.23,0.62,0.7
                    c-0.78,0.93-0.47,1.17-0.31,1.87c0.16,0.7-0.86,1.95-0.86,2.72c0,0.78,2.18-0.78,2.72-1.09c0.54-0.31,1.17-2.26,1.87-3.66
                    s1.17-0.85,1.35,2.48c0.18,3.33,0,3.68-2.8,7.88c-2.8,4.2-4.2,9.46-4.9,11.73c-0.7,2.28-1.4,7.71-1.49,11.21s-4.81,3.33-7.44,3.5
                    s-2.63,2.1-2.1,4.2c0.53,2.1,0,1.75-2.28,2.63c-2.28,0.88-2.8-2.28-3.33-3.15c-0.53-0.88-1.26,1-2.8,2.63
                    c-0.76,0.81-3.06,2.33-6.04,1.39c-2.98-0.94-6.39-3.84-8.67-6.12c-2.28-2.28-5.78-1.05-7.53-1.93
                    c-1.75-0.88-12.08-12.26-11.91-11.38c0.18,0.88,10.68,12.08,11.56,13.48s7.88,4.55,9.28,4.55s4.73,2.63,7,3.68s4.38,2.28,5.78,4.2
                    c1.4,1.93-3.15,2.98-3.15,2.98s5.25,0.35,7,0.18s3.5-3.33,4.38-4.03c0.88-0.7,6.3-3.5,7.71-4.38c1.4-0.88,2.8,1.23,1.05,1.93
                    c-1.75,0.7-1.93,2.98,0.18,4.03c2.1,1.05,6.13,3.5,7.71,7c1.58,3.5-2.1,2.28,0,5.43c2.1,3.15,8.06,2.28,10.68,4.73
                    c2.63,2.45,4.55,9.46,6.48,13.48c1.93,4.03,6.13,4.2,7.71,8.93c1.58,4.73,0.7,9.77,0.7,9.77H578.46z M525.65,473.72
                    c-0.31,0-0.56-0.25-0.56-0.56c0-0.31,0.25-0.56,0.56-0.56s0.56,0.25,0.56,0.56C526.21,473.47,525.96,473.72,525.65,473.72z
                    M534.75,471.7c-0.31,0.7-0.93,1.71-1.71,1.4c-0.78-0.31-3.82-1.61-5.92-1.79c-1.56-0.13-2-1.09-0.77-1.25
                    c1.23-0.16,4.19-0.23,4.74-0.54c0.54-0.31,2.02-0.54,3.27,0c1.25,0.54,1.25,0.54,1.25,0.54S535.06,471,534.75,471.7z"/>
                <path id="XMLID_184_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M508.91,466.72
                    c-3.15,0-3.04-0.82-7.01-2.8c-3.97-1.98-4.79-0.58-6.3,0c-1.52,0.58-1.87,0.35-4.79,0.93c-2.92,0.58-6.3,2.45-5.95,3.04
                    c0.35,0.58,2.08,1.79,3.04,0.82c2.49-2.53,6.07-2.76,7.55-2.45c1.48,0.31-2.72,1.71-2.1,1.87s2.96-1.09,4.11-1.4
                    c1.15-0.31,5.39,2.02,2.2,1.87s-5.68,0.62-6.85,1.01c-1.17,0.39-1.87,0.62-3.35,0.08c-1.48-0.54-1.32,0.47-1.01,0.94
                    c0.31,0.47,1.87,0.39,1.01,1.13c-0.86,0.74-1.48-0.27-2.33,0.35s1.79,0.62,1.71,1.07c-0.08,0.45,1.09,0.49,2.02,0
                    c0.93-0.49,2.33-0.91,2.33-0.91c1.87,2.65,3.58,2.65,3.58,2.65c-1.25-0.47-2.26-2.33-2.18-3.15s1.73-0.56,1.73-0.56
                    s0.53,2.94,3.41,2.94c2.88,0,3.27-2.94,3.27-2.94s1.48,0.99,1.17,1.98s-3.5,1.58-3.5,1.58s0.78,0.39,0.86,1.17s-3.46,0.86-3.5,1.56
                    c-0.04,0.7,4.36,1.28,5.41,1.28c1.05,0,3.39-2.69,3.62-3.74c0.23-1.05,0-2.45,0.82-2.41c0.82,0.04,2.1-0.51,3.04-1.42
                    c0.93-0.91,2.69-3.99,2.69-5.37C513.58,464.44,512.06,466.72,508.91,466.72z M498.01,471.74c-0.31,0-0.56-0.25-0.56-0.56
                    s0.25-0.56,0.56-0.56c0.31,0,0.56,0.25,0.56,0.56S498.32,471.74,498.01,471.74z"/>
                <path id="XMLID_183_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M503.42,490.77
                    c1.17,0.7,1.75,0.93,2.33,0.7s0,0.12,0.58-0.23c0.58-0.35,2.45-0.64,3.74,0.32c1.28,0.96,3.15,2.01,2.22,2.36
                    c-0.93,0.35-1.87,0.23-2.92,0c-1.05-0.23-2.33,0.58-4.32-0.7C503.07,491.94,503.42,490.77,503.42,490.77z"/>
                <path id="XMLID_180_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M505.02,485.63
                    c0,0-4.4,3.5-6.44,4.75c-2.04,1.25,1.32,0.47,3.22-0.31S505.02,485.63,505.02,485.63z"/>
                <path id="XMLID_179_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M523.7,494.74
                    c0,0,1.32,0.99,2.51,3.74c1.2,2.75,1.3,6.54,1.3,6.54s0.12-1.01-1.3-1.48c-1.42-0.47-0.8-0.93-0.56-2.18
                    S524.17,494.74,523.7,494.74z"/>
                <path id="XMLID_178_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M512.1,496.53
                    c-0.5,1.16-0.08,1.48,0,2.26c0.08,0.78,0.12,1.79,0.45,1.32c0.33-0.47,0.18-1.25,0.49-2.1C513.35,497.15,512.5,495.61,512.1,496.53
                    z"/>
                <path id="XMLID_177_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M496.32,504.7
                    c-1.23-0.16-1.62-1.32,0-2.49c1.62-1.17,2.14-2.88,2.89-2.34c0.75,0.54,1.79,2.46,4.19,2.36c2.4-0.1,4.34-1.82,6.83-1.58
                    c2.49,0.23,2.65,1.87,4.44,1.01c1.79-0.86,2.65,0.47,4.13,0.93c1.48,0.47,4.59-0.54,4.36,0.31c-0.23,0.86-1.32,0.78-1.63,1.25
                    c-0.31,0.47,1.5,1.79,1.65,3.81s0.77,2.18-0.4,2.18c-1.17,0-1.87-0.54-2.34,0.39c-0.47,0.93-1.71,1.87-1.71,1.87
                    s-0.62-3.19-1.71-3.04c-1.09,0.16-3.11-0.16-3.42-0.47c-0.31-0.31-2.84-0.08-4.14,0.23c-1.31,0.31-6.29-0.93-4.89-1.48
                    c1.4-0.54,2.02-0.96,2.49-1.57c0.47-0.61,0.54-2.56,1.4-1.47c0.86,1.09,3.04,0.54,5.14,0.78c2.1,0.23,3.81-0.39,4.05-1.01
                    c0.23-0.62-0.16-1.32-0.16-1.32s-18.98-0.7-19.53,0S497.51,504.86,496.32,504.7z"/>
                <path id="XMLID_176_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M503.42,478.74
                    c0,0-0.03,0.03-0.08,0.09c-0.03,0.03-0.06,0.07-0.11,0.11c-0.04,0.04-0.09,0.09-0.15,0.14c-0.06,0.05-0.12,0.1-0.19,0.16
                    c-0.07,0.06-0.15,0.11-0.24,0.17c-0.09,0.06-0.18,0.12-0.28,0.18c-0.1,0.06-0.21,0.13-0.32,0.19c-0.12,0.06-0.24,0.12-0.36,0.19
                    c-0.13,0.06-0.26,0.11-0.4,0.18c-0.07,0.03-0.14,0.06-0.21,0.09c-0.07,0.03-0.15,0.05-0.22,0.08c-0.15,0.05-0.3,0.1-0.46,0.16
                    c-0.16,0.04-0.32,0.09-0.48,0.13c-0.08,0.02-0.16,0.04-0.25,0.07c-0.08,0.02-0.17,0.03-0.25,0.05c-0.34,0.06-0.68,0.14-1.02,0.17
                    c-0.17,0.02-0.35,0.04-0.52,0.06c-0.17,0.02-0.34,0.02-0.51,0.03c-0.34,0.01-0.68,0.03-1,0.01c-0.32-0.01-0.64-0.02-0.93-0.05
                    c-0.3-0.03-0.57-0.07-0.83-0.1c-0.13-0.02-0.25-0.03-0.37-0.05c-0.12-0.02-0.23-0.05-0.33-0.07c-0.21-0.04-0.38-0.08-0.53-0.11
                    c-0.29-0.07-0.46-0.11-0.46-0.11s0.17-0.01,0.47-0.01c0.15,0,0.33,0,0.54,0.01c0.1,0,0.21,0,0.33,0.01c0.12,0,0.24,0,0.37,0
                    c0.25,0,0.53-0.01,0.82,0c0.29,0,0.6-0.02,0.91-0.03c0.16-0.01,0.32,0,0.48-0.02c0.16-0.01,0.33-0.03,0.49-0.04
                    c0.16-0.01,0.33-0.02,0.5-0.04c0.17-0.02,0.33-0.04,0.5-0.06c0.33-0.03,0.66-0.09,0.99-0.14c0.16-0.02,0.32-0.06,0.48-0.09
                    c0.16-0.03,0.31-0.07,0.47-0.1c0.15-0.04,0.3-0.08,0.45-0.12c0.07-0.02,0.15-0.04,0.22-0.06c0.07-0.02,0.14-0.04,0.21-0.06
                    c0.14-0.04,0.27-0.08,0.4-0.12c0.13-0.05,0.25-0.09,0.37-0.13c0.12-0.04,0.23-0.09,0.34-0.13c0.11-0.04,0.21-0.08,0.3-0.13
                    c0.09-0.04,0.18-0.09,0.26-0.12c0.08-0.04,0.15-0.08,0.22-0.11c0.13-0.07,0.23-0.13,0.3-0.17
                    C503.39,478.77,503.42,478.74,503.42,478.74z"/>
                <path id="XMLID_175_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M521.05,480.73
                    c0,0,0.08,0.03,0.22,0.11c0.07,0.04,0.15,0.08,0.24,0.14c0.09,0.06,0.19,0.12,0.3,0.2c0.21,0.16,0.45,0.35,0.69,0.58
                    c0.06,0.06,0.12,0.12,0.18,0.18c0.06,0.06,0.12,0.12,0.18,0.19c0.12,0.13,0.23,0.26,0.34,0.4l0.16,0.21
                    c0.05,0.07,0.1,0.14,0.15,0.21c0.1,0.14,0.19,0.29,0.27,0.43c0.09,0.14,0.16,0.28,0.23,0.42c0.03,0.07,0.07,0.13,0.1,0.2
                    c0.03,0.07,0.05,0.13,0.08,0.19c0.05,0.12,0.09,0.24,0.13,0.34c0.03,0.1,0.05,0.2,0.07,0.27c0.04,0.15,0.05,0.24,0.05,0.24
                    s-0.06-0.07-0.16-0.19c-0.09-0.12-0.23-0.29-0.39-0.49c-0.08-0.1-0.16-0.21-0.25-0.33c-0.09-0.12-0.18-0.24-0.28-0.36
                    c-0.05-0.06-0.1-0.13-0.15-0.19c-0.05-0.06-0.1-0.13-0.15-0.19c-0.1-0.13-0.21-0.27-0.31-0.4c-0.1-0.13-0.21-0.26-0.31-0.4
                    c-0.1-0.13-0.21-0.26-0.31-0.38c-0.2-0.25-0.38-0.48-0.54-0.69c-0.16-0.21-0.29-0.38-0.38-0.5c-0.05-0.06-0.08-0.11-0.11-0.14
                    C521.06,480.75,521.05,480.73,521.05,480.73z"/>
                <path id="XMLID_174_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M526.36,488.33
                    c0,0,0.02,0.03,0.06,0.08c0.04,0.05,0.1,0.14,0.16,0.25c0.06,0.11,0.14,0.25,0.22,0.41c0.08,0.16,0.16,0.34,0.25,0.54
                    c0.17,0.4,0.35,0.87,0.54,1.37c0.09,0.25,0.19,0.51,0.29,0.77c0.1,0.26,0.2,0.53,0.31,0.79c0.1,0.26,0.21,0.52,0.33,0.77
                    c0.11,0.25,0.23,0.5,0.35,0.73c0.12,0.23,0.24,0.45,0.37,0.65c0.13,0.2,0.26,0.39,0.38,0.56c0.07,0.08,0.13,0.16,0.19,0.23
                    c0.06,0.07,0.13,0.14,0.18,0.2c0.12,0.12,0.23,0.23,0.33,0.3c0.09,0.08,0.17,0.14,0.23,0.18c0.05,0.04,0.08,0.06,0.08,0.06
                    s-0.03-0.01-0.1-0.03c-0.06-0.02-0.16-0.05-0.28-0.11c-0.06-0.03-0.12-0.06-0.19-0.1c-0.07-0.04-0.14-0.08-0.22-0.13
                    c-0.07-0.05-0.15-0.11-0.23-0.17c-0.08-0.06-0.16-0.13-0.24-0.21c-0.16-0.15-0.33-0.33-0.49-0.53c-0.16-0.2-0.32-0.42-0.46-0.65
                    c-0.14-0.24-0.29-0.49-0.41-0.75c-0.13-0.26-0.25-0.53-0.36-0.8c-0.22-0.54-0.4-1.1-0.55-1.62c-0.08-0.26-0.14-0.51-0.2-0.75
                    c-0.06-0.24-0.11-0.47-0.17-0.68c-0.05-0.21-0.1-0.4-0.14-0.57c-0.04-0.17-0.08-0.32-0.12-0.44c-0.04-0.12-0.06-0.22-0.09-0.28
                    C526.37,488.37,526.36,488.33,526.36,488.33z"/>
                <path id="XMLID_173_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M522.74,499.41
                    c0,0,0.04,0.05,0.1,0.13c0.03,0.04,0.07,0.09,0.1,0.15c0.04,0.06,0.08,0.13,0.12,0.2c0.09,0.15,0.18,0.33,0.26,0.53
                    c0.02,0.05,0.04,0.1,0.06,0.16c0.02,0.05,0.04,0.11,0.05,0.16c0.03,0.11,0.06,0.23,0.08,0.35c0.02,0.12,0.03,0.24,0.03,0.36
                    c0,0.06,0,0.12,0,0.18c0,0.06,0,0.11-0.01,0.17c-0.01,0.06-0.01,0.11-0.02,0.16c-0.01,0.05-0.02,0.1-0.03,0.15
                    c-0.02,0.1-0.05,0.19-0.08,0.27c-0.03,0.08-0.06,0.16-0.09,0.22c-0.03,0.07-0.06,0.12-0.08,0.17c-0.05,0.09-0.08,0.14-0.08,0.14
                    s0-0.06,0.01-0.16c0.01-0.05,0.01-0.11,0.02-0.18c0-0.07,0.02-0.15,0.02-0.23c0-0.08,0.01-0.17,0.02-0.27c0-0.05,0-0.1,0.01-0.14
                    c0-0.05,0-0.1,0-0.15c0-0.1-0.01-0.21-0.02-0.31c-0.01-0.11-0.02-0.21-0.04-0.32c-0.02-0.11-0.04-0.21-0.06-0.32
                    c-0.02-0.1-0.05-0.21-0.08-0.31c-0.06-0.2-0.11-0.39-0.15-0.55c-0.05-0.16-0.08-0.3-0.1-0.4
                    C522.75,499.47,522.74,499.41,522.74,499.41z"/>
                <path id="XMLID_172_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M469.68,492.46
                    c0.75,3.64,3,11.38,4.58,13.63s4.21,5.23,4.21,5.23s-5.6-4.04-7.53-8.41c-1.93-4.37-2.28-6.54-2.98-8.17
                    C467.26,493.11,468.99,489.08,469.68,492.46z"/>
                <path id="XMLID_171_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M480.92,514.47
                    c0,0,0.05,0.07,0.14,0.19c0.09,0.12,0.24,0.3,0.42,0.52c0.19,0.22,0.43,0.48,0.71,0.76c0.29,0.28,0.62,0.59,0.99,0.91
                    c0.38,0.31,0.81,0.63,1.27,0.95c0.47,0.3,0.99,0.61,1.51,0.95c0.53,0.34,1.07,0.68,1.61,1.04c0.54,0.36,1.09,0.73,1.64,1.11
                    c0.55,0.38,1.09,0.76,1.62,1.14c0.53,0.38,1.05,0.77,1.55,1.14c0.5,0.37,0.97,0.74,1.42,1.1c0.45,0.35,0.86,0.7,1.25,1.02
                    c0.19,0.16,0.37,0.32,0.54,0.47c0.17,0.15,0.33,0.3,0.48,0.43c0.3,0.27,0.55,0.52,0.76,0.73c0.05,0.05,0.1,0.1,0.15,0.15
                    c0.04,0.05,0.08,0.09,0.12,0.14c0.08,0.08,0.14,0.16,0.19,0.21c0.1,0.12,0.16,0.18,0.16,0.18s-0.06-0.05-0.18-0.15
                    c-0.12-0.1-0.29-0.25-0.52-0.43c-0.23-0.18-0.5-0.4-0.82-0.64c-0.32-0.25-0.69-0.51-1.09-0.81c-0.2-0.15-0.41-0.3-0.63-0.45
                    c-0.22-0.16-0.44-0.32-0.67-0.48c-0.46-0.33-0.95-0.67-1.46-1.03c-0.51-0.35-1.04-0.72-1.57-1.09c-0.53-0.37-1.08-0.75-1.63-1.12
                    c-0.54-0.38-1.09-0.75-1.63-1.12c-0.54-0.37-1.07-0.73-1.58-1.08c-0.51-0.35-1.01-0.68-1.48-1.02c-0.46-0.35-0.88-0.7-1.24-1.05
                    c-0.36-0.35-0.67-0.69-0.93-1c-0.26-0.31-0.47-0.6-0.63-0.84c-0.16-0.24-0.28-0.44-0.36-0.58
                    C480.95,514.55,480.92,514.47,480.92,514.47z"/>
                <path id="XMLID_170_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M478.46,533.74
                    c0,0,2.45,1.23,4.03,0s3.15-1.54,4.2,0.54c1.05,2.08,2.49,4.53,2.49,4.53s-2.46-3.25-3.53-3.02c-1.06,0.22-3.17-0.35-3.34,1.45
                    c-0.17,1.79,1.4,6.86,0.53,6.32C481.97,543.02,478.46,533.74,478.46,533.74z"/>
                <path id="XMLID_168_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M485.66,531.46
                    c2.26-0.53,4.01-2.1,5.77-2.98c1.75-0.88,4.35-2.1,6.03,0.7c0,0-2.75-1.04-2.2,0.27c0.55,1.31-0.67,2.18-1.55,2.01
                    s-1.84,1.75-2.15,2.28c-0.31,0.53-1.18-0.19-1.71-0.97c-0.53-0.78,0.07,2.02-0.66,2.37C488.45,535.49,485.66,531.46,485.66,531.46z
                    "/>
                <path id="XMLID_167_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M514.89,531.3
                    c0,0,6.94-2.2,8.81-4.74c0,0,5.31,2.98,4.79,3.84s-1.8,0.53-2.89,1.58c-1.09,1.05,1.19,1.93,0.75,4.03c-0.44,2.1-0.78,1.23-1.92,0
                    c-1.14-1.23-2.56-2.76-2.73-0.85c-0.17,1.9-1.57,5.41-3.49,6.98c-1.93,1.58-5.11,1.46-5.11,1.46s2.66-2.68,1.26-2.51
                    c-1.4,0.18-7.18,1.75-7.35,1.05c-0.18-0.7,3.58-1.4,4.85-2.45c1.28-1.05,4.4-1.05,5.9-2.1c1.5-1.05,4.2-3.69,3.29-4.82
                    c-0.91-1.13-1.26,1.01-2.49,2.39c-1.23,1.38-2.8,2.25-3.85,1.73c-1.05-0.53-1.93-2.1,0.18-3.15
                    C516.99,532.69,516.8,531.18,514.89,531.3z"/>
                <path id="XMLID_166_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M491.55,541.27
                    c0,0,0.16,0.08,0.42,0.25c0.26,0.17,0.63,0.42,1.05,0.77c0.21,0.17,0.43,0.36,0.65,0.58c0.22,0.22,0.45,0.45,0.67,0.72
                    c0.22,0.26,0.43,0.55,0.61,0.87c0.19,0.31,0.35,0.66,0.46,1.02c0.06,0.18,0.09,0.37,0.12,0.57c0.01,0.1,0.01,0.2,0.02,0.29
                    c0,0.1,0,0.18-0.01,0.27c-0.02,0.37-0.07,0.72-0.14,1.06c-0.07,0.34-0.17,0.65-0.27,0.94c-0.09,0.29-0.2,0.56-0.27,0.8
                    c-0.07,0.25-0.12,0.47-0.1,0.66c0.01,0.19,0.08,0.36,0.17,0.48c0.09,0.12,0.17,0.21,0.23,0.26c0.06,0.05,0.1,0.08,0.1,0.08
                    s-0.04-0.02-0.12-0.06c-0.07-0.04-0.18-0.1-0.3-0.21c-0.12-0.11-0.25-0.28-0.3-0.52c-0.06-0.23-0.05-0.5-0.01-0.77
                    c0.04-0.27,0.11-0.56,0.18-0.85c0.07-0.29,0.13-0.6,0.18-0.92c0.05-0.31,0.08-0.65,0.08-0.97c0-0.08,0-0.18,0-0.25
                    c-0.01-0.08-0.01-0.16-0.02-0.23c-0.02-0.16-0.05-0.31-0.1-0.47c-0.09-0.31-0.23-0.62-0.39-0.91c-0.16-0.29-0.34-0.57-0.53-0.83
                    c-0.19-0.26-0.38-0.51-0.57-0.74c-0.19-0.23-0.38-0.44-0.56-0.64c-0.36-0.39-0.67-0.7-0.89-0.92
                    C491.68,541.39,491.55,541.27,491.55,541.27z"/>
                <path id="XMLID_165_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M507.97,557.55
                    c0,0-1.84-2.1-2.96-2.28s-4.31-1.93-7.08-4.38c0,0-1.09,1.58,1.36,3.33s3.57,2.6,3.53,4.28c-0.03,1.68,3.12,1.68,3.12,1.68
                    s-4.18-2.8-2.56-3.85C505.02,555.28,507.97,557.55,507.97,557.55z"/>
                <path id="XMLID_164_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M514.54,565.78l-1.99,3.68
                    c-0.64-2.1-1.41-2.1,0.55-4.73c1.96-2.63,1.09-6.23,1.09-6.23s2.28,2.28,4.55,5.48c2.28,3.2,2.63,4.71,2.63,4.71
                    s-1.95-1.16-3.6-3.61c-1.65-2.45-2.53-1.4-2.88-0.35C514.54,565.78,515.59,565.08,514.54,565.78"/>
                <path id="XMLID_163_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M527.51,568.69
                    c0,0-1.07-5.71-2.47-8.51c-1.4-2.8-2.3-4.64-2.3-4.64s3.91,1.84,4.77,4.64c0.86,2.8,9.44,8.51,9.44,8.51s-6.69-2.83-8.43-4.71
                    C528.53,563.98,528.93,566.52,527.51,568.69z"/>
                <path id="XMLID_162_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M539.4,550.9
                    c0,0,0.08,0.03,0.24,0.09c0.15,0.06,0.38,0.15,0.66,0.28c0.28,0.14,0.62,0.3,1,0.52c0.19,0.1,0.39,0.23,0.6,0.35
                    c0.21,0.13,0.42,0.27,0.64,0.43c0.44,0.31,0.9,0.67,1.34,1.11c0.44,0.44,0.88,0.94,1.23,1.54c0.04,0.07,0.09,0.14,0.13,0.22
                    c0.04,0.07,0.08,0.15,0.13,0.22c0.04,0.07,0.08,0.15,0.13,0.22c0.04,0.07,0.09,0.14,0.13,0.2c0.09,0.14,0.18,0.27,0.28,0.41
                    c0.1,0.14,0.2,0.27,0.3,0.41c0.42,0.54,0.89,1.06,1.37,1.58c0.48,0.52,0.96,1.04,1.42,1.58c0.46,0.53,0.88,1.09,1.27,1.64
                    c0.19,0.28,0.37,0.55,0.55,0.81c0.18,0.26,0.36,0.51,0.54,0.75c0.37,0.48,0.72,0.93,1.07,1.33c0.68,0.81,1.28,1.46,1.71,1.91
                    c0.21,0.22,0.38,0.4,0.5,0.52c0.12,0.12,0.18,0.18,0.18,0.18s-0.07-0.06-0.2-0.16c-0.13-0.1-0.32-0.26-0.56-0.46
                    c-0.23-0.2-0.52-0.45-0.84-0.75c-0.16-0.15-0.32-0.31-0.5-0.48c-0.17-0.17-0.35-0.35-0.54-0.54c-0.37-0.38-0.77-0.81-1.16-1.28
                    c-0.2-0.23-0.39-0.48-0.59-0.74c-0.1-0.13-0.19-0.26-0.29-0.39c-0.09-0.13-0.19-0.27-0.29-0.4c-0.38-0.53-0.82-1.05-1.27-1.57
                    c-0.46-0.52-0.94-1.03-1.42-1.55c-0.48-0.53-0.95-1.06-1.39-1.63c-0.11-0.14-0.22-0.28-0.31-0.43c-0.1-0.15-0.2-0.29-0.29-0.45
                    c-0.05-0.08-0.09-0.15-0.14-0.23c-0.04-0.08-0.08-0.15-0.12-0.22c-0.04-0.07-0.08-0.15-0.12-0.22c-0.04-0.07-0.08-0.14-0.12-0.22
                    c-0.31-0.55-0.69-1.05-1.1-1.49c-0.1-0.11-0.21-0.21-0.31-0.32c-0.11-0.1-0.21-0.2-0.31-0.29c-0.1-0.1-0.21-0.19-0.31-0.28
                    c-0.1-0.09-0.2-0.18-0.31-0.26c-0.41-0.33-0.8-0.62-1.15-0.86c-0.35-0.24-0.67-0.44-0.94-0.6c-0.13-0.08-0.25-0.15-0.36-0.21
                    c-0.1-0.06-0.19-0.11-0.27-0.15C539.48,550.94,539.4,550.9,539.4,550.9z"/>
                <path id="XMLID_161_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M527.51,548.97
                    c0,0,0.05,0.08,0.15,0.23c0.09,0.15,0.26,0.35,0.47,0.6c0.22,0.24,0.51,0.53,0.86,0.82c0.35,0.29,0.77,0.59,1.25,0.9
                    c0.48,0.3,1.01,0.61,1.58,0.92c0.57,0.31,1.19,0.62,1.81,0.98c0.16,0.09,0.31,0.18,0.47,0.27c0.16,0.1,0.32,0.19,0.47,0.29
                    c0.31,0.2,0.63,0.41,0.93,0.63c0.61,0.45,1.21,0.95,1.76,1.5c0.54,0.56,1.04,1.17,1.44,1.83c0.21,0.33,0.38,0.67,0.53,1.02
                    c0.07,0.18,0.14,0.35,0.21,0.53c0.05,0.18,0.11,0.36,0.16,0.54c0.18,0.7,0.31,1.39,0.41,2.04c0.11,0.65,0.18,1.26,0.3,1.81
                    c0.12,0.55,0.28,1.04,0.47,1.45c0.02,0.05,0.05,0.1,0.07,0.15c0.03,0.05,0.05,0.1,0.08,0.14c0.05,0.09,0.1,0.18,0.15,0.27
                    c0.11,0.17,0.2,0.33,0.3,0.46c0.1,0.14,0.18,0.26,0.26,0.36c0.08,0.1,0.15,0.19,0.2,0.26c0.11,0.14,0.17,0.22,0.17,0.22
                    s-0.07-0.07-0.19-0.19c-0.06-0.06-0.14-0.14-0.23-0.23c-0.09-0.1-0.19-0.21-0.3-0.34c-0.11-0.13-0.22-0.28-0.34-0.44
                    c-0.06-0.08-0.12-0.17-0.18-0.26c-0.03-0.05-0.06-0.09-0.09-0.14c-0.03-0.05-0.06-0.1-0.09-0.15c-0.12-0.2-0.23-0.43-0.33-0.68
                    c-0.1-0.25-0.19-0.51-0.27-0.79c-0.16-0.56-0.27-1.17-0.4-1.81c-0.13-0.64-0.28-1.3-0.48-1.98c-0.18-0.66-0.5-1.31-0.88-1.92
                    c-0.38-0.61-0.86-1.19-1.38-1.72c-0.52-0.53-1.09-1.02-1.68-1.46c-0.59-0.44-1.19-0.85-1.8-1.21c-0.61-0.36-1.21-0.71-1.77-1.04
                    c-0.56-0.34-1.09-0.68-1.55-1.02c-0.23-0.17-0.45-0.34-0.66-0.51c-0.2-0.17-0.39-0.33-0.55-0.5c-0.34-0.32-0.6-0.64-0.8-0.91
                    c-0.2-0.28-0.34-0.5-0.41-0.66C527.56,549.06,527.51,548.97,527.51,548.97z"/>
                <path id="XMLID_160_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M523.7,565.96
                    c0,0,0.03,0.03,0.08,0.1c0.05,0.06,0.12,0.16,0.2,0.29c0.17,0.25,0.4,0.62,0.62,1.09c0.11,0.23,0.23,0.49,0.34,0.77
                    c0.11,0.28,0.22,0.58,0.31,0.89c0.1,0.31,0.18,0.64,0.25,0.98c0.07,0.34,0.12,0.68,0.16,1.03c0.03,0.35,0.06,0.7,0.05,1.04
                    c0,0.35-0.02,0.68-0.06,1.01c-0.04,0.33-0.09,0.64-0.16,0.93c-0.07,0.29-0.15,0.57-0.23,0.81c-0.04,0.12-0.08,0.24-0.13,0.35
                    c-0.05,0.11-0.09,0.21-0.13,0.31c-0.08,0.19-0.17,0.36-0.24,0.49c-0.07,0.13-0.14,0.23-0.18,0.3c-0.04,0.07-0.06,0.11-0.06,0.11
                    s0.05-0.17,0.13-0.45c0.04-0.15,0.09-0.32,0.14-0.52c0.02-0.1,0.05-0.21,0.07-0.32c0.02-0.11,0.05-0.23,0.07-0.36
                    c0.05-0.25,0.09-0.52,0.12-0.8c0.04-0.29,0.06-0.59,0.08-0.9c0-0.16,0.01-0.32,0.01-0.48c0-0.16,0-0.32-0.01-0.49
                    c-0.01-0.33-0.03-0.66-0.06-0.99c-0.04-0.33-0.08-0.66-0.13-0.99c-0.06-0.32-0.12-0.64-0.19-0.95c-0.07-0.31-0.15-0.6-0.23-0.88
                    c-0.08-0.28-0.16-0.54-0.24-0.78c-0.08-0.24-0.16-0.46-0.23-0.66c-0.07-0.19-0.13-0.36-0.19-0.5
                    C523.76,566.12,523.7,565.96,523.7,565.96z"/>
                <path id="XMLID_159_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M536.95,565.96
                    c0,0,0.14,0.13,0.36,0.36c0.11,0.12,0.26,0.25,0.41,0.42c0.16,0.16,0.34,0.34,0.54,0.54c0.2,0.2,0.41,0.41,0.62,0.65
                    c0.22,0.24,0.45,0.49,0.67,0.77c0.22,0.28,0.43,0.58,0.62,0.91c0.2,0.32,0.36,0.67,0.51,1.02c0.07,0.18,0.14,0.36,0.2,0.53
                    c0.06,0.18,0.12,0.36,0.17,0.54c0.1,0.36,0.19,0.72,0.27,1.05c0.08,0.34,0.15,0.67,0.21,0.98c0.07,0.31,0.13,0.61,0.18,0.88
                    c0.11,0.55,0.18,1.01,0.23,1.33c0.05,0.32,0.07,0.51,0.07,0.51s-0.07-0.17-0.19-0.48c-0.11-0.31-0.27-0.74-0.45-1.28
                    c-0.09-0.26-0.18-0.55-0.28-0.86c-0.09-0.31-0.2-0.63-0.29-0.96c-0.1-0.34-0.2-0.67-0.31-1.02c-0.06-0.17-0.12-0.34-0.18-0.51
                    c-0.07-0.17-0.13-0.34-0.2-0.5c-0.14-0.33-0.29-0.66-0.46-0.97c-0.17-0.31-0.36-0.6-0.55-0.89c-0.2-0.28-0.39-0.55-0.58-0.8
                    c-0.19-0.25-0.38-0.49-0.54-0.71c-0.17-0.22-0.32-0.43-0.45-0.61c-0.13-0.19-0.24-0.35-0.33-0.49
                    C537.05,566.12,536.95,565.96,536.95,565.96z"/>
                <path id="XMLID_158_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M501.05,543.56
                    c0,0,0.06,0.03,0.17,0.09c0.11,0.06,0.28,0.15,0.49,0.26c0.42,0.23,1.01,0.55,1.73,0.94c0.36,0.19,0.75,0.4,1.16,0.62
                    c0.41,0.22,0.85,0.44,1.3,0.67c0.23,0.11,0.46,0.23,0.69,0.35c0.23,0.12,0.47,0.23,0.71,0.35c0.24,0.11,0.48,0.23,0.72,0.34
                    c0.24,0.11,0.49,0.22,0.73,0.34c0.25,0.11,0.49,0.21,0.74,0.32c0.25,0.1,0.5,0.2,0.74,0.3c0.49,0.19,0.99,0.35,1.47,0.5
                    c0.48,0.15,0.95,0.28,1.4,0.41c0.45,0.12,0.88,0.24,1.27,0.36c0.39,0.12,0.75,0.25,1.06,0.4c0.31,0.14,0.55,0.32,0.73,0.48
                    c0.18,0.17,0.29,0.33,0.35,0.43c0.03,0.05,0.05,0.1,0.07,0.13c0.02,0.03,0.02,0.04,0.02,0.04s-0.04-0.06-0.12-0.15
                    c-0.08-0.1-0.21-0.23-0.4-0.36c-0.19-0.13-0.44-0.26-0.75-0.37c-0.3-0.11-0.66-0.2-1.06-0.28c-0.39-0.09-0.83-0.16-1.29-0.26
                    c-0.46-0.1-0.94-0.2-1.44-0.33c-0.5-0.13-1.01-0.28-1.52-0.47c-0.26-0.09-0.52-0.19-0.76-0.29c-0.25-0.11-0.5-0.22-0.76-0.32
                    c-0.25-0.12-0.5-0.23-0.74-0.35c-0.24-0.12-0.49-0.24-0.73-0.36c-0.48-0.25-0.94-0.5-1.39-0.75c-0.45-0.25-0.87-0.51-1.27-0.75
                    c-0.4-0.24-0.77-0.48-1.11-0.71c-0.34-0.22-0.65-0.44-0.92-0.63c-0.27-0.19-0.51-0.36-0.7-0.5
                    C501.27,543.73,501.05,543.56,501.05,543.56z"/>
                <path id="XMLID_157_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M500,529.88
                    c0,0,0.08,0.04,0.2,0.12c0.06,0.04,0.14,0.08,0.22,0.13c0.09,0.05,0.18,0.11,0.29,0.17c0.1,0.06,0.22,0.12,0.34,0.19
                    c0.12,0.06,0.25,0.13,0.38,0.19c0.13,0.07,0.27,0.12,0.41,0.19c0.07,0.03,0.14,0.06,0.22,0.09c0.07,0.03,0.14,0.06,0.22,0.08
                    l0.22,0.08c0.07,0.02,0.15,0.05,0.22,0.07c0.07,0.02,0.15,0.05,0.22,0.07c0.07,0.02,0.15,0.04,0.22,0.07
                    c0.14,0.05,0.28,0.08,0.41,0.12c0.13,0.04,0.26,0.08,0.38,0.11c0.12,0.03,0.22,0.07,0.32,0.1c0.1,0.03,0.18,0.05,0.25,0.07
                    c0.14,0.04,0.22,0.08,0.22,0.08s-0.08,0.02-0.23,0.04c-0.07,0.01-0.16,0.02-0.26,0.03c-0.1,0.01-0.22,0.01-0.34,0.01
                    c-0.06,0-0.13,0-0.2,0c-0.07,0-0.14-0.01-0.21-0.01c-0.14-0.01-0.3-0.02-0.45-0.05c-0.16-0.03-0.32-0.05-0.48-0.1
                    c-0.08-0.02-0.16-0.04-0.24-0.07c-0.08-0.03-0.16-0.06-0.24-0.09c-0.16-0.06-0.32-0.13-0.47-0.21c-0.15-0.08-0.29-0.16-0.43-0.25
                    c-0.14-0.09-0.26-0.18-0.37-0.28c-0.11-0.1-0.21-0.19-0.3-0.28c-0.09-0.09-0.16-0.18-0.23-0.26c-0.06-0.08-0.11-0.16-0.15-0.22
                    c-0.04-0.06-0.07-0.11-0.09-0.15C500.01,529.9,500,529.88,500,529.88z"/>
                <g id="XMLID_139_">
                    <path id="XMLID_156_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M440.26,492.46
                        c0,0-0.45-0.22-1.04-0.66C439.74,492.7,440.26,492.46,440.26,492.46z"/>
                    <path id="XMLID_140_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M507.97,578.88
                        c0,0-25.22-38.14-35.96-56.23c-10.74-18.1-8.64-20.2-7.71-26.4c0.93-6.2,5.6-7.91,5.6-7.91l1.72-1.88c0,0-1.72,1.88-7.18,1.88
                        c-5.46,0-4.03-8.36-4.03-8.36s0,0,0.7-1.23c0.7-1.23,0.53,0.71,0.88,1.99c0.35,1.28,2.1-0.58,1.93-3.03s2.8-2.1,2.8-2.1
                        s-1.05,4.03,0.35,6.28c1.4,2.25,2.45,1.78,4.9,1.01c2.45-0.76,2.45-3.61,0.7-5.37c-1.75-1.75-2.63-3.5-1.58-4.93
                        c1.05-1.43,2.63,0.73,4.73-1.42c2.1-2.14,4.38-6.61,4.2-8.29c-0.18-1.68-3.5,0.8-3.5,0.8c3.85-3.68,4.38-9.28,4.38-9.28
                        c-2.45,8.06-7.53,8.48-7.53,8.48c2.28-1.5,2.45-3.93,3.15-5.85s4.9-7.88,4.03-8.93c-0.88-1.05-6.13,9.81-7.53,9.46
                        c-1.4-0.35,3.68-5.6,3.15-7c-0.53-1.4-3.85,5.95-5.95,5.95s1.93-1.4,1.4-2.8c-0.53-1.4-2.1-1.23,0.35-2.28
                        c2.45-1.05,5.25-5.6,7.18-9.35c1.93-3.74,9.17-4.58,8.41-7.64c-0.7-2.8,5.43,0.53,9.88,0.35s5.01-2.98,8.68-5.08
                        c3.68-2.1,6.42,1.4,6.42,1.4c-3.57,1.23-7.47,3.5-13.34,8.06c-5.87,4.55-9.89,0-9.89,0s-0.7,2.45,3.59,2.91
                        c4.29,0.46,10.94-3.79,12.1-5.71c1.16-1.93,10.04-4.55,10.57-4.38c0.53,0.18-0.53,2.8-0.53,2.8c1.23-0.35,2.8-2.28,2.8-2.28
                        c2.28,3.85-2.1,5.78-2.1,5.78c5.78-1.75,2.8-5.96,5.29-7.54c2.49-1.58,1.02,0.88,5.16,8.24c4.14,7.35,7.68,7.17,7.68,7.17
                        c-5.14-2.33-7.54-10.51-7.54-10.51s2.17,0.47,5.67,1.4c3.5,0.93,4.44,6.3,5.37,8.87c0.93,2.57-1.87,5.6-1.79,8.87
                        s-3.81,8.08-3.81,8.08c2.8,0.61,5.37-2.71,5.37-2.71c0.93,1.87-1.17,6.3-1.17,6.3s1.68,0.85,1.87,2.71s-0.7,8.73-1.17,13.7
                        c-0.47,4.96-3.97,11.85-3.97,11.85c2.81,0.99,7.01-4.2,7.47-3.7c0.47,0.5,1.4,5.21,1.4,5.21c3.5-2.7,4.67-14.37,4.67-14.37
                        c1.87,1.62-0.7,12.27-0.7,12.27c10.27-5.03,16.11-21.55,16.11-21.55c1.44,6.39-3.74,12.45-3.74,12.45s6.54-5.02,5.84-11.32
                        c-0.7-6.3-7.01-10.83-7.01-10.83c5.84,2.24,8.64-3.88,8.64-3.88c-3.74,3.04-7.24-1.17-7.24-1.17c7.47-2.33,5.14-9.81,5.14-9.81
                        s-0.93,4.44-6.54,4.2c-5.6-0.23-3.97-12.14-3.97-12.14c1.87,1.63,3.5,8.64,3.5,8.64c1.63-4.44-1.63-10.27-1.63-10.27
                        c2.1,1.17,3.74,7.01,3.74,7.01c0.93-7.24-5.45-12.14-5.45-12.14c5.29-7.94-0.38-14.24-0.38-14.24c2.1,8.64-5.14,9.57-5.14,9.57
                        c3.27-3.27,3.27-4.9,1.4-10.27c-1.87-5.37-8.17-4.67-8.64-7.24c-0.47-2.57,3.5-5.37,2.34-7.01s-4.9,1.17-4.9,1.17
                        c-7.01-12.14-19.15-9.81-19.15-9.81c6.01-0.23,4.58,3.04,4.58,3.04c-1.72-2.1-9.48-1.17-9.48-1.17s-2.57-4.9-7.24-5.4
                        c-4.67-0.49-7.13,4.23-7.13,4.23s-1.51-3.74-6.29-4.2s-8.08,5.6-8.08,5.6c-2.83-3.97-8.38-3.04-14.92,0
                        c-6.54,3.04-9.81,11.21-11.91,15.18s-2.1,4.2-7.01,7.71c-4.9,3.5-8.87,12.14-8.87,12.14s-1.4,0.47-3.04,5.84
                        c-1.63,5.37,3.74,8.17,3.74,8.17c-11.44,3.97-7.24,9.34-7.24,9.34s1.87-2.34,3.74-1.87c1.87,0.47-0.47,5.37-0.47,5.37
                        c-2.34-3.5-4.44,0-4.44,0c2.34,0.7,1.17,5.37-0.47,6.33c-1.63,0.96,0.47,6.78,0.47,6.78c-2.33-0.43-3.27-4.94-3.27-4.94
                        s-2.34,5.14,0.23,7.47c2.57,2.33,8.87,3.62,8.87,3.62s-2.57,1.28-3.74,6.3c-0.82,3.51,1.33,5.73,2.7,6.75
                        c-0.33-0.57-0.65-1.61-0.83-3.46c-0.47-4.77,2.1-5.44,2.1-5.44s0,2.51,1.17,8.14c1.17,5.64,7.24,7.2,7.24,7.2
                        c0.47-3.04,6.77-3.5,6.77-3.5s-4.44,5.6-5.37,10.27c-0.93,4.67-2.57,6.54-7.24,8.87c-4.67,2.33-3.04,7.94-7.24,10.97
                        c-4.2,3.04-10.51,4.9-18.45,8.74c-7.94,3.84-11.48,9.23-11.48,9.23s-16.3,14.87-16.3,36.04L507.97,578.88z M544.48,448.28
                        c0,0-5.6-9-4.2-9c1.4,0,1.4,0,1.4,0s3.68,3.7,4.03,5.3C546.06,446.18,544.48,448.28,544.48,448.28z M452.02,444.6
                        c1.05-0.35,2.66,3.33,5.1,3.68c0,0-6.01-3.85-2.66-3.15c3.35,0.7,6.67,0.54,8.07,1.93c1.4,1.39,0.7,2.97-0.88,2.8
                        c-1.58-0.18-3.15,0.03-4.12,0.01s-2.01-0.89-2.01-0.89C453.77,447.58,450.97,444.95,452.02,444.6z M461.65,473.72
                        c-2.28,1.17-3.64,4.68-4.54,3.63s0.68-1.4-0.89-1.05c-1.58,0.35-2.45,4.08-2.98,3.44c-0.53-0.64,0.15-3.96,2.27-5.02
                        c0,0-0.34-1.4-2.27-0.18c-1.6,1.02,1.93-2.33,4.29-1.95S463.93,472.55,461.65,473.72z M461.65,471.18
                        c-0.35-1.36,2.1-2.26-2.45-1.98s-8.93,1.02-9.98-3.38c-1.05-4.4,2.98-6.5,3.33-7.55c0.35-1.05,0.07-2.8-0.75-2.63
                        c-0.82,0.18,4.96-3.15,2.16-2.45c-2.8,0.7-6.65,1.93-6.65,1.93c1.23-1.23,3.5-3.5,6.3-3.5s5.64,0.7,3.52,1.75
                        c-2.12,1.05-3.35,1.05-2.29,1.75c1.05,0.7,4.38-1.4,4.2,0c-0.18,1.4-3.33,2.45-4.2,4.03c-0.88,1.58-0.66,5.47,2.29,6.67
                        c2.96,1.21,4.64,1.18,6.16,3.38S462,472.53,461.65,471.18z M474.26,464.57c3.33-2.03,0.7,0,0.7,0s-3.97,3.5-7.07,3.68
                        c-3.09,0.18-4.16-2.81-4.16-2.81s1.65,2.28,4.16,1.58c0,0-2.05-1.98-0.1-1.58C469.53,465.79,470.92,466.59,474.26,464.57z
                        M472.51,458.52c0,0-0.75,2.91-2.47,3.16c0,0,2.98,0.25,3.43-1.15c0,0-0.41,3.83-5.67,2.35c-5.26-1.48-5.47-4.45-5.47-4.45
                        s0.38,1.12,2.48,1.7l-1.05-3.02c-0.02-0.05-0.03-0.08-0.03-0.08l0.03,0.08c0.17,0.47,1.2,2.9,4.03,2.9
                        C470.93,460.01,472.51,458.52,472.51,458.52z M477.06,442.68c-1.58,1.23-6.98,4.55-9.27,4.38c-2.29-0.18-5.44-0.53-6.14-6.3
                        c0,0,1.23,2.63,2.28,3.15c1.05,0.53,1.95,2.8,3.86,1.75c1.91-1.05-0.71-1.23-0.71-1.23c-0.88-1.23-0.7-2.1,1.93-3.15
                        c2.63-1.05,5.25-3.15,6.83-3.85c1.58-0.7,3.5-1.23,3.85,0S478.64,441.45,477.06,442.68z M492.91,426.04
                        c-0.79,0.88-1.03,1.4-2.4,1.4c-1.37,0-1.37-0.53-3.3-0.7c-1.93-0.18-4.14-1.93-5.22-1.05c-1.08,0.88-2.48,1.4-1.43,2.8
                        s3.85-0.86,3.68,1.15c-0.18,2.01-0.64,3.41-2.25,4.28c-1.61,0.88-4.76,1.93-6.16,2.1c-1.4,0.18-3.49-1.05-2.71-1.93
                        s2.71-3.15,2.71-3.15s-2.28,1.05-3.5,1.05s-1.05-0.86-0.7-2.36c0.35-1.5,1.93-2.72,4.2-2.9c2.28-0.18,4.87-0.18,5.43-2.45
                        c0,0-7.53,3.15-11.21-1.4c-0.47-0.58,6.48,0.7,8.41,0c1.93-0.7,6.26-1.52,6.37-1.11c0.11,0.41-1.97,3.73,0.82,2.51
                        c2.79-1.23,4.13-3.84,4.86-2.62c0.73,1.22,0.56,1,2.4,1.29S493.71,425.16,492.91,426.04z M503.42,416.06
                        c-1.66,0.35-0.09,2.1-0.02,2.42c0.07,0.32-1.99,0.21-3.22,0.98c-1.23,0.77-0.35,1.16-0.97,2.21c-0.62,1.05-4.81-5.25-8.49-6.3
                        c-3.68-1.05-4.9,2.63-8.58,4.09c-3.68,1.47-6.3-1.29-6.3-1.29c0.88,0.7,3.85-0.53,3.85-0.53s-1.4-1.93-1.23-2.28
                        c0.18-0.35,2.98,1.4,2.98,1.4s0.18-1.4,1.58-1.93c1.4-0.53,2.64-1.75,2.64-1.75c-1.39-0.53-3.87-6.48-1.76-3.94
                        c2.1,2.54,3.85,0.13,3.85,0.13s0.18-1.44-2.09-3.89c-2.26-2.45-0.82-5.43-0.82-5.43s0.63,2.98,3.26,4.9
                        c2.63,1.93,4.82,4.29,4.82,4.29c1.11-6.66,6.29-9.19,6.29-9.19s-3.06,3.5-4.11,8.41c-1.05,4.9,4.9,5.43,6.38,5.43
                        s-0.08-1.05,0.8-1.75s2.28,1.4,2.74,2.1S505.08,415.71,503.42,416.06z M505.02,407.48c-1.34-3.15-1.6-5.95-1.6-5.95
                        s0.9,3.5,4.87,5.95c3.97,2.45,7.05,4.55,4.81,9.28c0,0,0.46-2.45-1.24-3.68C510.16,411.86,506.36,410.63,505.02,407.48z
                        M517.16,427.09c-2.28,2.28-2.28,2.28-2.28,2.28s0.11,0.88-1.79-0.53c0,0-1.47-1.05-1.24-4.55c0.23-3.5,0.97-4.55,2-6.65
                        c1.03-2.1,3.48-3.33,3.48-3.33s2.4-1.75,5.41-4.03s4.77,0,4.77,0s-6.55,3.15-7.84,7.71c0,0,2.87-1.58,3.07-0.35
                        c0.2,1.23-1.96,1.75-3.07,2.8c-1.11,1.05-3.56,3.85-4.44,4.03c-0.88,0.18-1.38-2.1-1.38-2.1s-1.06,4.55-0.28,4.9
                        c0.78,0.35,5.69-5.43,8.31-5.78c2.63-0.35-0.57,1.05,0.85,1.4c1.42,0.35,3.06-0.7,5.75,1.05c2.68,1.75,2.97,3.15,7.12,2.1
                        s6.42-5.95,6.77-4.2c0.35,1.75-3.37,9.11-11.76,5.95C522.24,424.64,519.44,424.81,517.16,427.09z M533.98,436.69
                        c-1.93-1.19-4.87-2.42-6.46-4.69s-0.39-3.3-0.39-3.3s0.51,3.47,3.51,4.7l-2.13-3.76c0,0,2.86,2.88,4.26,3.23
                        c1.4,0.35-1.05,1.23,0.88,1.75c1.93,0.53,4.38,1.33,5.95,2.07c1.58,0.74-3.97,0-3.97,0s3.14,2.88,4.25,5.4
                        c1.12,2.52,1.82,7.79,1.82,7.79S535.9,437.88,533.98,436.69z M551.62,460.13c-0.39-1.46-4.69-0.29-3.99,0s1.93,1.28,3.33,2.76
                        c1.4,1.48,0.35,1.15-1.05,1.33c-1.4,0.18-1.58-1.23-2.98-2.53c-1.4-1.3-0.35,2.18-1.18,4.13c-0.82,1.95-0.93-4.4-1.74-7.37
                        c-0.82-2.98-0.06,6.65-1.11,6.13c-1.05-0.53-0.35-3.5-0.88-2.88c-0.53,0.62-1.05,5.33-1.05,5.33s-1.05-1.93-0.93-3.79
                        c0.13-1.86,2.15-2.87,2.35-4.09c0.2-1.23-0.02-1.58,1.2-2.1c1.23-0.53-0.7-4.03-0.7-4.03c3.15-0.7,4.03,3.5,4.96,4.03
                        c0.93,0.53,2.92,0.18,4.5,1.4c1.58,1.23,3.33,3.25,3.33,3.25S552.01,461.59,551.62,460.13z"/>
                </g>
                <path id="XMLID_138_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M510.66,470.61
                    c0,0,0.04,0.02,0.1,0.05c0.06,0.03,0.15,0.08,0.24,0.14c0.09,0.06,0.2,0.13,0.3,0.2c0.05,0.04,0.1,0.07,0.16,0.11
                    c0.05,0.04,0.1,0.08,0.16,0.11c0.05,0.04,0.1,0.07,0.15,0.11c0.05,0.03,0.1,0.07,0.15,0.1c0.05,0.03,0.1,0.06,0.14,0.09
                    c0.05,0.02,0.09,0.05,0.14,0.08c0.04,0.02,0.09,0.04,0.13,0.06c0.04,0.02,0.08,0.03,0.11,0.04c0.06,0.02,0.11,0.04,0.11,0.04
                    s-0.03,0.03-0.09,0.07c-0.03,0.02-0.07,0.05-0.12,0.07c-0.05,0.02-0.11,0.03-0.17,0.05c-0.06,0.01-0.13,0.01-0.21,0.01
                    c-0.07,0-0.15-0.02-0.23-0.04c-0.04-0.01-0.08-0.02-0.11-0.04c-0.04-0.01-0.08-0.03-0.11-0.05c-0.07-0.04-0.15-0.08-0.21-0.13
                    c-0.07-0.05-0.13-0.1-0.18-0.16c-0.06-0.05-0.11-0.11-0.15-0.17c-0.05-0.06-0.08-0.12-0.12-0.18c-0.03-0.06-0.06-0.12-0.08-0.17
                    c-0.04-0.11-0.07-0.21-0.08-0.28c-0.01-0.03-0.01-0.07-0.01-0.09C510.66,470.62,510.66,470.61,510.66,470.61z"/>
                <path id="XMLID_137_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M513.58,492.29
                    c0,0,0.02,0.01,0.05,0.02c0.03,0.01,0.08,0.03,0.14,0.05c0.06,0.02,0.13,0.04,0.21,0.07c0.08,0.03,0.17,0.06,0.27,0.1
                    c0.19,0.07,0.42,0.15,0.66,0.24c0.12,0.04,0.24,0.09,0.37,0.13c0.13,0.04,0.25,0.09,0.38,0.13c0.13,0.04,0.25,0.08,0.38,0.12
                    c0.06,0.02,0.13,0.04,0.19,0.06c0.06,0.02,0.12,0.03,0.18,0.05c0.06,0.02,0.12,0.03,0.18,0.05c0.06,0.01,0.12,0.03,0.17,0.04
                    c0.11,0.03,0.22,0.06,0.32,0.08c0.1,0.02,0.19,0.04,0.28,0.06c0.08,0.01,0.16,0.03,0.22,0.04c0.12,0.03,0.2,0.05,0.2,0.05
                    s-0.07,0.03-0.19,0.07c-0.06,0.02-0.14,0.04-0.22,0.07c-0.09,0.02-0.19,0.03-0.3,0.05c-0.11,0.02-0.23,0.02-0.35,0.02
                    c-0.06,0-0.13,0-0.19,0c-0.07,0-0.13-0.01-0.2-0.02c-0.07-0.01-0.14-0.02-0.21-0.02c-0.07-0.01-0.14-0.03-0.21-0.04
                    c-0.14-0.03-0.28-0.07-0.42-0.11c-0.14-0.04-0.27-0.1-0.41-0.16c-0.07-0.03-0.13-0.06-0.19-0.09c-0.06-0.03-0.13-0.06-0.18-0.1
                    c-0.12-0.07-0.23-0.13-0.33-0.21c-0.1-0.07-0.2-0.14-0.28-0.21c-0.17-0.14-0.29-0.27-0.38-0.37
                    C513.62,492.35,513.58,492.29,513.58,492.29z"/>
                <path id="XMLID_136_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M500,492.29
                    c0,0-0.12,0.08-0.34,0.21c-0.11,0.07-0.24,0.15-0.39,0.24c-0.15,0.09-0.32,0.2-0.51,0.31c-0.75,0.45-1.77,1.03-2.81,1.55
                    c-0.26,0.13-0.52,0.26-0.78,0.38c-0.26,0.12-0.52,0.24-0.77,0.35c-0.25,0.11-0.5,0.21-0.73,0.3c-0.12,0.05-0.23,0.09-0.34,0.13
                    c-0.11,0.04-0.22,0.08-0.33,0.11c-0.21,0.08-0.4,0.13-0.58,0.18c-0.17,0.05-0.33,0.08-0.45,0.11c-0.13,0.03-0.23,0.04-0.3,0.05
                    c-0.07,0.01-0.11,0.01-0.11,0.01s0.03-0.02,0.1-0.05c0.06-0.03,0.15-0.07,0.27-0.12c0.11-0.06,0.25-0.13,0.41-0.21
                    c0.16-0.08,0.33-0.18,0.52-0.28c0.38-0.21,0.83-0.45,1.31-0.71c0.24-0.13,0.49-0.26,0.74-0.39c0.25-0.13,0.51-0.26,0.77-0.4
                    c0.26-0.13,0.52-0.26,0.78-0.38c0.26-0.13,0.51-0.25,0.76-0.36c0.25-0.11,0.49-0.22,0.72-0.32c0.23-0.1,0.45-0.19,0.66-0.27
                    c0.41-0.16,0.76-0.28,1.01-0.36C499.86,492.33,500,492.29,500,492.29z"/>
                <path id="XMLID_133_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M509.35,516.34
                    c0,0,0.02,0.01,0.05,0.02c0.03,0.01,0.07,0.03,0.13,0.05c0.06,0.02,0.12,0.05,0.2,0.08c0.08,0.03,0.16,0.06,0.25,0.09
                    c0.37,0.13,0.86,0.28,1.34,0.33c0.12,0.02,0.24,0.02,0.35,0.02c0.06,0,0.11,0,0.17-0.01c0.06-0.01,0.11-0.01,0.16-0.02
                    c0.11-0.02,0.21-0.04,0.3-0.07c0.1-0.03,0.18-0.07,0.27-0.11c0.08-0.04,0.15-0.09,0.22-0.13c0.03-0.02,0.06-0.05,0.09-0.07
                    c0.03-0.02,0.05-0.04,0.07-0.06c0.05-0.04,0.08-0.07,0.1-0.09c0.02-0.02,0.04-0.03,0.04-0.03s0,0.02-0.01,0.05
                    c-0.01,0.03-0.01,0.08-0.04,0.14c-0.01,0.03-0.02,0.06-0.03,0.1c-0.01,0.04-0.03,0.07-0.05,0.11c-0.04,0.08-0.1,0.16-0.17,0.25
                    c-0.08,0.08-0.17,0.17-0.28,0.24c-0.11,0.07-0.24,0.14-0.38,0.18c-0.27,0.09-0.58,0.11-0.88,0.08c-0.15-0.02-0.29-0.04-0.43-0.09
                    c-0.14-0.04-0.27-0.09-0.39-0.15c-0.12-0.06-0.24-0.12-0.34-0.19c-0.1-0.07-0.2-0.14-0.28-0.2c-0.16-0.14-0.28-0.27-0.35-0.37
                    c-0.04-0.05-0.06-0.09-0.08-0.12C509.36,516.35,509.35,516.34,509.35,516.34z"/>
                <path id="XMLID_130_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M469.84,514.47
                    c0,0,0.13,0.17,0.35,0.49c0.1,0.16,0.23,0.35,0.37,0.58c0.14,0.23,0.29,0.48,0.45,0.77c0.16,0.29,0.33,0.6,0.49,0.93
                    c0.17,0.33,0.34,0.69,0.5,1.06c0.16,0.37,0.33,0.76,0.48,1.17c0.15,0.4,0.29,0.82,0.43,1.23c0.06,0.21,0.12,0.42,0.19,0.63
                    c0.06,0.21,0.11,0.42,0.16,0.63c0.1,0.42,0.19,0.83,0.25,1.24c0.07,0.41,0.11,0.8,0.15,1.18c0.04,0.37,0.05,0.73,0.05,1.06
                    c0,0.16,0,0.32-0.01,0.47c-0.01,0.15-0.02,0.29-0.03,0.42c-0.02,0.27-0.06,0.5-0.08,0.68c-0.03,0.19-0.06,0.33-0.08,0.43
                    c-0.02,0.1-0.03,0.15-0.03,0.15s0-0.22,0-0.6c0-0.09-0.01-0.2-0.01-0.31c0-0.11,0-0.24-0.02-0.37c-0.01-0.13-0.02-0.27-0.03-0.42
                    c-0.01-0.15-0.03-0.3-0.05-0.46c-0.01-0.16-0.04-0.32-0.06-0.5c-0.02-0.17-0.05-0.35-0.08-0.53c-0.03-0.18-0.07-0.37-0.1-0.56
                    c-0.04-0.19-0.08-0.38-0.12-0.58c-0.08-0.39-0.19-0.79-0.29-1.2c-0.05-0.2-0.12-0.41-0.17-0.61c-0.06-0.2-0.12-0.41-0.19-0.61
                    c-0.13-0.41-0.26-0.81-0.4-1.21c-0.14-0.4-0.28-0.79-0.42-1.16c-0.14-0.38-0.28-0.74-0.42-1.08c-0.14-0.34-0.27-0.67-0.4-0.96
                    c-0.25-0.6-0.47-1.09-0.62-1.44C469.93,514.67,469.84,514.47,469.84,514.47z"/>
                <path id="XMLID_127_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M525.43,552.65
                    c0,0,0.16,0.17,0.42,0.47c0.07,0.07,0.15,0.14,0.24,0.23c0.09,0.08,0.17,0.17,0.28,0.26c0.11,0.09,0.22,0.18,0.34,0.27
                    c0.13,0.09,0.26,0.18,0.39,0.27c0.14,0.09,0.3,0.17,0.45,0.26c0.16,0.07,0.33,0.16,0.5,0.24c0.09,0.03,0.18,0.07,0.27,0.1
                    c0.05,0.02,0.09,0.04,0.14,0.05c0.05,0.01,0.09,0.03,0.14,0.04c0.09,0.03,0.19,0.06,0.29,0.09c0.1,0.03,0.2,0.05,0.3,0.07
                    c0.2,0.05,0.41,0.08,0.62,0.13c0.11,0.01,0.21,0.03,0.32,0.04c0.11,0.02,0.21,0.03,0.32,0.04c0.43,0.04,0.88,0.06,1.33,0.05
                    c0.11,0,0.22,0,0.34,0l0.17,0l0.08,0l0.1,0c0.06,0,0.11,0,0.18,0c0.07,0,0.13,0.01,0.2,0.01l0.18,0.03
                    c0.06,0.01,0.12,0.03,0.18,0.04c0.48,0.11,0.92,0.34,1.28,0.62c0.37,0.28,0.66,0.61,0.9,0.94c0.24,0.33,0.41,0.68,0.54,1.01
                    c0.13,0.33,0.22,0.64,0.27,0.92c0.06,0.28,0.07,0.53,0.08,0.73c0.01,0.2,0,0.36-0.01,0.47c-0.01,0.11-0.01,0.16-0.01,0.16
                    s-0.01-0.06-0.02-0.16c-0.01-0.11-0.04-0.26-0.08-0.46c-0.04-0.19-0.1-0.43-0.18-0.69c-0.09-0.26-0.2-0.54-0.36-0.84
                    c-0.08-0.15-0.16-0.3-0.26-0.44c-0.1-0.15-0.2-0.3-0.32-0.44c-0.24-0.29-0.52-0.56-0.85-0.79c-0.33-0.23-0.7-0.4-1.1-0.48
                    l-0.15-0.03l-0.15-0.02l-0.14-0.01c-0.05,0-0.11,0-0.16,0l-0.08,0l-0.09,0l-0.17,0.01c-0.12,0.01-0.23,0.01-0.35,0.01
                    c-0.47,0-0.93-0.01-1.39-0.07c-0.23-0.02-0.46-0.07-0.68-0.1c-0.22-0.05-0.44-0.09-0.65-0.16c-0.11-0.03-0.21-0.06-0.31-0.1
                    c-0.1-0.04-0.2-0.08-0.3-0.12c-0.05-0.02-0.1-0.04-0.15-0.06c-0.05-0.02-0.1-0.05-0.14-0.07c-0.09-0.04-0.19-0.09-0.28-0.13
                    c-0.18-0.09-0.35-0.2-0.51-0.29c-0.16-0.11-0.31-0.22-0.45-0.32c-0.13-0.11-0.26-0.23-0.38-0.33c-0.11-0.11-0.22-0.23-0.31-0.33
                    c-0.1-0.1-0.18-0.21-0.25-0.31c-0.07-0.1-0.14-0.19-0.2-0.27c-0.11-0.17-0.19-0.31-0.24-0.4
                    C525.45,552.7,525.43,552.65,525.43,552.65z"/>
                <path id="XMLID_124_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M507.97,554.23
                    c0,0,0.18,0.08,0.48,0.24c0.16,0.07,0.34,0.16,0.55,0.25c0.11,0.05,0.22,0.09,0.34,0.13c0.06,0.02,0.12,0.04,0.19,0.07
                    c0.06,0.02,0.13,0.04,0.2,0.06c0.27,0.08,0.57,0.14,0.88,0.17c0.32,0.03,0.66,0.04,1.02,0.04c0.36,0,0.74,0,1.12,0.01
                    c0.19,0.01,0.39,0.02,0.58,0.04l0.15,0.01l0.07,0.01l0.04,0l0.03,0l0.02,0l0.14,0.02l0.07,0.01l0.08,0.02
                    c0.42,0.08,0.8,0.22,1.16,0.39c0.36,0.17,0.69,0.37,1,0.58c0.31,0.21,0.59,0.43,0.85,0.65c0.26,0.22,0.49,0.44,0.7,0.64
                    c0.42,0.41,0.73,0.79,0.94,1.05c0.21,0.27,0.32,0.43,0.32,0.43s-0.15-0.13-0.4-0.35c-0.26-0.22-0.63-0.53-1.08-0.88
                    c-0.23-0.18-0.48-0.36-0.74-0.55c-0.27-0.19-0.56-0.37-0.86-0.56c-0.3-0.18-0.62-0.35-0.96-0.5c-0.17-0.07-0.34-0.14-0.51-0.19
                    c-0.09-0.03-0.17-0.06-0.26-0.08c-0.09-0.03-0.17-0.04-0.26-0.06l-0.06-0.01l-0.07-0.01l-0.14-0.02l-0.02,0l0,0
                    c-0.01,0,0.01,0,0.01,0l-0.01,0l-0.04,0l-0.07-0.01l-0.14-0.01c-0.19-0.02-0.38-0.03-0.56-0.05c-0.38-0.02-0.75-0.04-1.11-0.06
                    c-0.36-0.02-0.73-0.06-1.07-0.12c-0.17-0.03-0.33-0.07-0.49-0.12c-0.15-0.06-0.3-0.1-0.44-0.17c-0.07-0.03-0.14-0.06-0.2-0.09
                    c-0.06-0.03-0.13-0.07-0.19-0.1c-0.12-0.06-0.23-0.13-0.33-0.19c-0.21-0.12-0.37-0.25-0.51-0.35c-0.14-0.1-0.24-0.19-0.31-0.25
                    C508.01,554.26,507.97,554.23,507.97,554.23z"/>
                <path id="XMLID_121_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M513.79,462.87
                    c0,0,0.03,0.1,0.08,0.27c0.03,0.09,0.05,0.19,0.07,0.31c0.01,0.06,0.02,0.13,0.04,0.2c0.01,0.07,0.02,0.14,0.03,0.22
                    c0.02,0.15,0.03,0.32,0.03,0.5c0.01,0.18,0,0.37-0.01,0.56c-0.02,0.19-0.03,0.39-0.08,0.59c-0.03,0.2-0.09,0.4-0.14,0.6
                    c-0.07,0.2-0.12,0.4-0.2,0.58c-0.07,0.19-0.17,0.37-0.25,0.55c-0.09,0.17-0.18,0.34-0.28,0.48c-0.1,0.15-0.19,0.29-0.29,0.41
                    c-0.05,0.06-0.09,0.12-0.14,0.17c-0.05,0.05-0.09,0.1-0.14,0.15c-0.08,0.09-0.16,0.17-0.23,0.23c-0.13,0.12-0.21,0.19-0.21,0.19
                    s0.05-0.09,0.15-0.23c0.05-0.07,0.11-0.16,0.17-0.27c0.07-0.1,0.14-0.22,0.21-0.35c0.08-0.13,0.15-0.27,0.23-0.42
                    c0.08-0.15,0.15-0.32,0.23-0.48c0.07-0.17,0.14-0.35,0.21-0.53c0.07-0.18,0.12-0.37,0.18-0.56c0.05-0.19,0.11-0.38,0.14-0.57
                    c0.04-0.19,0.07-0.38,0.11-0.56c0.02-0.18,0.05-0.36,0.06-0.53c0.01-0.17,0.03-0.33,0.03-0.48c0.01-0.15,0.01-0.29,0.01-0.41
                    c0-0.12,0-0.23,0-0.32C513.79,462.97,513.79,462.87,513.79,462.87z"/>
                <path id="XMLID_118_" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.8183" stroke-miterlimit="10" d="M518.43,463.23
                    c0,0,0.01,0.11,0.03,0.29c0.02,0.19,0.04,0.45,0.07,0.77c0.02,0.16,0.03,0.33,0.05,0.51c0.02,0.18,0.05,0.37,0.07,0.57
                    c0.05,0.39,0.11,0.81,0.18,1.23c0.04,0.21,0.07,0.42,0.11,0.62c0.04,0.2,0.08,0.4,0.13,0.6c0.09,0.39,0.18,0.75,0.26,1.05
                    c0.08,0.31,0.15,0.57,0.21,0.75c0.05,0.18,0.08,0.29,0.08,0.29s-0.06-0.09-0.15-0.26c-0.05-0.08-0.1-0.18-0.16-0.3
                    c-0.06-0.12-0.12-0.25-0.18-0.4c-0.07-0.15-0.13-0.31-0.19-0.49c-0.06-0.17-0.13-0.36-0.18-0.55c-0.05-0.2-0.12-0.4-0.16-0.6
                    c-0.05-0.21-0.09-0.42-0.13-0.63c-0.03-0.21-0.07-0.43-0.09-0.64c-0.03-0.21-0.04-0.42-0.05-0.62c-0.02-0.4-0.02-0.78,0-1.11
                    c0.01-0.16,0.02-0.31,0.03-0.44c0.01-0.13,0.03-0.24,0.04-0.34C518.41,463.34,518.43,463.23,518.43,463.23z"/>
            </g>
            </svg>

           </div>

            <div className='botones'>
                <div className='btn'>
                    <img src={btnReiniciar} alt="imagen" onClick={reiniciar}/>
                </div>

                <div className='btn'>
                    <img src={btnDescargar} alt="imagen" onClick={download} />
                </div>
            </div>
        </div>
    )
}

export default DibujoPrueba